import Address from "./Address";
import UserInfo from "./UserInfo";

class OfficialDetails {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.Country = data.Country;
      this.CategoryId = data.CategoryId;
      this.DesignationId = data.DesignationId;
      this.RegistrationId = data.RegistrationId;
      this.DistrictId = data.DistrictId;
      this.User = new UserInfo(data.User);
      this.address1 = new Address(data.User.Address1);
      this.address2 = new Address(data.User.Address2);
    } else {
      this.id = -1;
      this.Country = null;
      this.RegistrationId = null;
      this.CategoryId = null;
      this.DesignationId = null;
      this.DistrictId = null;
      this.User = new UserInfo();
      this.address1 = new Address();
      this.address2 = new Address();
    }
  }
}

export default OfficialDetails;

import React, { useState, useEffect } from "react";
import "../styles/lightTheme.css";
import "../styles/darkTheme.css";
import "../styles/themetoggle.css";
import { THEME_COLOR } from "../utils/localstorage_variables";
import { getThemeColors } from "../assets/SvgIcons";

const ThemeToggler = ({ close }) => {
  const allroots = ["dark-theme", "light-theme"];
  let [isLightTheme, setIsLightTheme] = useState();

  useEffect(() => {
    let th = localStorage.getItem(THEME_COLOR);
    if (th) {
      isLightTheme = th;
      setIsLightTheme(isLightTheme);
    } else {
      isLightTheme = allroots[0];
      setIsLightTheme(isLightTheme);
    }
    getThemeColors();
    const theme = isLightTheme;
    document.documentElement.className = theme;
  }, [isLightTheme]);

  const toggleTheme = (id) => {
    setIsLightTheme(allroots[id]);
    getThemeColors();
    localStorage.setItem(THEME_COLOR, allroots[id]);
    window.location.reload();
  };

  return (
    <div className="theme-toggler">
      <div className="theme-popup">
        <div  className="d-flex gap-4 align-items-center  cursor-pointer"  onClick={() => {
              toggleTheme(1);
            }}>
          <button
            className="circle-button-light"
          ></button>
          <p className="mt-2" style={{color:'var(--text-color)'}} >Light Theme</p>
        </div>

        <div className="d-flex gap-4 align-items-center  cursor-pointer" onClick={() => {
              toggleTheme(0);
            }} >
          <button
            
            className="circle-button-dark"
          ></button>
          <p className="mt-2" style={{color:'var(--text-color)'}} >Dark Theme</p>
        </div>
      </div>
    </div>
  );
};

export default ThemeToggler;

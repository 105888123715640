import React, { useEffect, useState } from "react";
import "../styles/changepassword.css";
import ResetPassword from "../componants/Resetpassword";
import { USER_ID } from "../utils/localstorage_variables";
import { getData } from "../utils/localstorage_constants";
import { reset_password } from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";
function ChangePassword({ onclose }) {
  let [userid, setUserId] = useState(null);
  let [user, setUser] = useState(new ResetPassword());
  const [isValid, setIsValid] = useState(false);
  const [respsucces, setRespSucces] = useState(false);

  const [message, setMessage] = useState();
  let [confirmpassword, setConfirmPassword] = useState();
  useEffect(() => {
    const loadData = async () => {
      try {
        let userId = await getData(USER_ID);
        userid = userId;
        setUserId(userid);
        user.UserId = userid;
        setUser(user);
      } catch (error) {
        console.log("Error loading initial data: ", error);
      }
    };

    loadData();
  }, []);

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };
  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      onclose();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };

  const handleResetPassword = async () => {
    delete user.PhoneNumber;
    if (user.OldPassword) {
      if (user.newPassword) {
        if (confirmpassword) {
          if (user.newPassword === confirmpassword) {
            console.log("user", user);
            let resp = await reset_password(user);
            console.log("resp", resp);
            if (resp.success) {
              succespopup();
              setMessage("Password Changed Successfully..!");
              user = new ResetPassword();
              setUser(user);
            }
          } else {
            validationpopupvisible();
            setMessage("password and confirm password dont match");
          }
        } else {
          validationpopupvisible();
          setMessage("Enter confirm password");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter new password");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter old password");
    }
  };

  const handlePhoneNumber = (event) => {
    user.PhoneNumber = event.target.value;
    setUser(user);
  };

  const handleOldPassword = (event) => {
    user.OldPassword = event.target.value;
    setUser(user);
  };
  const handleNewPassword = (event) => {
    user.newPassword = event.target.value;
    setUser(user);
  };
  const handleConfirmPassword = (event) => {
    confirmpassword = event.target.value;
    setConfirmPassword(confirmpassword);
    console.log("user", user);
  };
  return (
    <div className="change_password_popup">
      <div className="change_password_popup_inner">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className=" change_text ms-2 mt-4"> Change Password </h1>
          <div onClick={onclose} className="close">
            X
          </div>
        </div>{" "}
        <div className="d-flex align-items-center justify-content-around">
          <input
            className="old_and_new_password"
            type="password"
            placeholder="Enter old password"
            onChange={handleOldPassword}
          />
          {/* <button className="verify_btn">Verify Password</button> */}
        </div>
        <input
          className="old_and_new_password"
          type="password"
          placeholder="Enter new password"
          onChange={handleNewPassword}
        />
        <input
          className="old_and_new_password"
          type="password"
          placeholder="Confirm new password"
          onChange={handleConfirmPassword}
        />
        <div className="d-flex justify-content-center">
          <button className="verify_btn " onClick={() => handleResetPassword()}>
            Confirm & Change
          </button>
        </div>
        {isValid && validationpopup(validationpopupvisible)}
        {respsucces && validationpopup(succespopup)}
      </div>
    </div>
  );
}
export default ChangePassword;

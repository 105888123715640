import "../styles/addmatchplayers.css";
import React, { useState, useEffect, useRef } from "react";
import matchcreation from "../styles/matchcreation.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable, { createTheme } from "react-data-table-component";
import {
  get_match_type,
  get_tournaments_type,
  get_ground_data,
  get_player_data,
  get_official_data,
  get_teams,
  create_match,
  update_match,
  get_match_data,
  get_matchs_from_server,
  get_match_status,
  copy_match_to_local,
  add_player_to_match,
} from "../api/serverWrapper";
import {
  custometableStylesmatches,
  customtableStyles,
  conditionalRowStyles,
} from "../assets/tablestyle";
import AddPlayerToMatch from "../componants/AddPlayerToMatch";
import ValidationModal from "./ValidationModal";

function AddMatchPlayers({ onClose, matchData }) {
  console.log("matchData : ", matchData);
  let matchId = matchData.id;
  let TeamAName = matchData.TeamA.TeamName;
  let TeamBName = matchData.TeamB.TeamName;
  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const [teamAPlayers, setTeamAPlayers] = useState([]);
  const [teamBPlayers, setTeamBPlayers] = useState([]);

  let [addPlayer, setAddPlayer] = useState(new AddPlayerToMatch());

  useEffect(() => {
    getPlayerList("A", matchData.TeamAId);
    getPlayerList("B", matchData.TeamBId);
  }, []);

  const PlayerNameCell = ({ row }) => {
    const firstNameLetter = row.Player.User.FirstName.charAt(0);
    const lastNameLetter = row.Player.User.LastName
      ? row.Player.User.LastName.charAt(0)
      : "";

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            color: "#30a161",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          {firstNameLetter}
          {lastNameLetter}
        </div>
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          {row.Player.User.FirstName} {row.Player.User.LastName}
          <span style={{ fontSize: 8 }}>
            {" "}
            {row.Player.PlayerType ? row.Player.PlayerType.PlayerType : ""}
          </span>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "Player Name",
      selector: (row) => row.Player.User.FirstName, // Use the first name for sorting
      sortable: true,
      cell: (row) => <PlayerNameCell row={row} />,
    },
  ];

  const [teamASelectedPlayers, setTeamASelectedPlayers] = useState([]);
  let [teamAselectedPlayerCount, setTeamAselectedPlayerCount] = useState(0);
  let [teamBselectedPlayerCount, setTeamBselectedPlayerCount] = useState(0);
  const updateTeamAPlayerSelection = (selection) => {
    setTeamASelectedPlayers(selection.selectedRows);
    addPlayer.TeamAPlayerList = [];
    selection.selectedRows.forEach((row) => {
      addPlayer.TeamAPlayerList.push(row.Player.PlayerId);
    });
    setAddPlayer(addPlayer);
    setTeamAselectedPlayerCount(selection.selectedCount);
  };

  const [teamBSelectedPlayers, setTeamBSelectedPlayers] = useState([]);
  const updateTeamBPlayerSelection = (selection) => {
    setTeamBSelectedPlayers(selection.selectedRows);
    addPlayer.TeamBPlayerList = [];
    selection.selectedRows.forEach((row) => {
      addPlayer.TeamBPlayerList.push(row.Player.PlayerId);
    });
    setAddPlayer(addPlayer);
    setTeamBselectedPlayerCount(selection.selectedCount);
  };

  const getPlayerList = async (team, teamid) => {
    try {
      console.log(teamid);
      let resp = await get_player_data(teamid);
      let plist = resp.data.playersList;
      plist.sort((a, b) =>
        a.Player.User.FirstName.localeCompare(b.Player.User.FirstName)
      );
      if (team == "A") {
        setTeamAPlayers(plist);
      } else {
        setTeamBPlayers(plist);
      }
    } catch (e) {
      console.log("getting player type error", e);
    }
  };

  const renderTeamAPlayers = () => {
    return teamASelectedPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const [teamAViceCaptainPlayers, setTeamAViceCaptainPlayers] = useState([]);
  const renderTeamAViceCaptainPlayers = () => {
    return teamAViceCaptainPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const [teamBViceCaptainPlayers, setTeamBViceCaptainPlayers] = useState([]);
  const renderTeamBViceCaptainPlayers = () => {
    return teamBViceCaptainPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const renderTeamBPlayers = () => {
    return teamBSelectedPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };

  const handleTeamACaptainSelection = (event) => {
    const selectedCaptainId = parseInt(event.target.value);
    addPlayer.TeamACaptainId = selectedCaptainId;
    setAddPlayer(addPlayer);
    console.log(selectedCaptainId);
    const availableViceCaptainPlayers = teamASelectedPlayers.filter(
      (player) => player.Player.PlayerId !== selectedCaptainId
    );
    setTeamAViceCaptainPlayers(availableViceCaptainPlayers);
  };
  const handleTeamAVCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    addPlayer.TeamAViceCaptainId = val;
    setAddPlayer(addPlayer);
  };

  const handleTeamAKeeperSelection = (event) => {
    const val = parseInt(event.target.value);
    addPlayer.TeamAWicketKeeperId = val;
    setAddPlayer(addPlayer);
  };
  const handleTeamBCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    addPlayer.TeamBCaptainId = val;
    setAddPlayer(addPlayer);
    const availableViceCaptainPlayers = teamBSelectedPlayers.filter(
      (player) => player.Player.PlayerId !== val
    );
    setTeamBViceCaptainPlayers(availableViceCaptainPlayers);
  };
  const handleTeamBVCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    addPlayer.TeamBViceCaptainId = val;
    setAddPlayer(addPlayer);
  };
  const handleTeamBKeeperSelection = (event) => {
    const val = parseInt(event.target.value);
    addPlayer.TeamBWicketKeeperId = val;
    setAddPlayer(addPlayer);
  };

  let [NoofPlayers, setNoofPlayers] = useState(11);
  const handleNoofPlayers = (event) => {
    console.log(event.target.val);
    let noOfPly = event.target.val;
    setNoofPlayers(noOfPly);
    const val = parseInt(event.target.value);
    addPlayer.NumberOfPlayers = val;
    setAddPlayer(addPlayer);
  };

  const addplayerToMatch = async () => {
    let noofplayers =
      (addPlayer.TeamAPlayerList.length + addPlayer.TeamBPlayerList.length) / 2;
    NoofPlayers = noofplayers;
    setNoofPlayers(noofplayers);
    addPlayer.matchId = matchId;
    addPlayer.NumberOfPlayers = NoofPlayers;
    setAddPlayer(addPlayer);
    console.log("addPlayer : ", addPlayer);

    if (teamAselectedPlayerCount !== teamBselectedPlayerCount) {
      setValidationData("Both Team Players should Equal");
      return;
    }

    if (teamAselectedPlayerCount < addPlayer.NumberOfPlayers) {
      setValidationData(
        "Team " +
          TeamAName +
          " should have at least" +
          addPlayer.NumberOfPlayers +
          " Players"
      );

      return;
    }
    if (teamAselectedPlayerCount < addPlayer.NumberOfPlayers) {
      setValidationData(
        "Team" +
          TeamBName +
          " should have at least" +
          addPlayer.NumberOfPlayers +
          " Players"
      );

      return;
    }

    if (
      addPlayer.TeamAWicketKeeperId === null ||
      addPlayer.TeamACaptainId === null
    ) {
      setValidationData("Please select " + TeamAName + " captains and keeper");
      return;
    }
    if (
      addPlayer.TeamBWicketKeeperId === null ||
      addPlayer.TeamBCaptainId === null
    ) {
      setValidationData("Please select " + TeamBName + " captains and keeper");
      return;
    }
    if (addPlayer.TeamAViceCaptainId === null) {
      setValidationData("Please select " + TeamAName + " vice captain");
      return;
    }
    if (addPlayer.TeamBViceCaptainId === null) {
      setValidationData("Please select " + TeamBName + " vice captain");
      return;
    }
    let resp = await add_player_to_match(addPlayer);
    console.log(resp);
    if (resp.code === 200) {
      setValidationData("Players Added successfully");
      setTimeout(() => {
        onClose();
      }, 2000);
    } else {
      setValidationData(` ${resp.error.massage} `);
    }
  };

  const [validationData, setValidationData] = useState(null);

  const handleValidation = () => {
    setValidationData(null);
  };

  return (
    <div className="add-match-players-popup">
      <div className="add-match-players-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Select Players</h1>
          <div className="close" onClick={() => onClose()}>
            X
          </div>
        </div>
        <div className="d-flex align-items-center ms-4">
          <div
            style={{ marginLeft: 10 }}
            className="title2 d-flex align-items-center w-25"
          >
            {TeamAName} :{" "}
            {<div className="ms-2"> {teamAselectedPlayerCount}</div>}
          </div>
          <div
            style={{ marginLeft: 60 }}
            className="title2 d-flex align-items-center "
          >
            {TeamBName} :
            {<div className="ms-2">{teamBselectedPlayerCount}</div>}
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex ms-4">
            <div className="custom-table">
              <div className="custom-datatable">
                {teamAPlayers.length > 0 ? (
                  <DataTable
                    columns={columns}
                    fixedHeader
                    selectableRows
                    data={teamAPlayers}
                    fixedHeaderScrollHeight="500px"
                    theme="solarized"
                    customStyles={customtableStyles}
                    onSelectedRowsChange={updateTeamAPlayerSelection}
                  ></DataTable>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    {" "}
                    <h1>No players </h1>{" "}
                  </div>
                )}
              </div>
            </div>

            <div className="custom-table left">
              <div className="custom-datatable">
                {teamBPlayers.length > 0 ? (
                  <DataTable
                    fixedHeader
                    columns={columns}
                    selectableRows
                    data={teamBPlayers}
                    fixedHeaderScrollHeight="500px"
                    theme="solarized"
                    customStyles={customtableStyles}
                    onSelectedRowsChange={updateTeamBPlayerSelection}
                  ></DataTable>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    {" "}
                    <h1>No players </h1>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <div className="d-flex align-items-center justify-content-between ">
              <div className="d-flex align-items-center">
                <label className="ms-4 no-of">No of Players</label>
                <input
                  type="text"
                  className="no-of-players ms-4"
                  placeholder="Players"
                  value={NoofPlayers}
                  onChange={handleNoofPlayers}
                />
              </div>
            </div>
            <div className="bottom-container ms-4 mt-4">
              <div className="select-captains-add">
                <div className="">
                  <div className="select-types-add">
                    <label className="select-match-type-label">Captain</label>
                    <select onChange={handleTeamACaptainSelection}>
                      <option value="0">Select Captain</option>
                      {renderTeamAPlayers()}
                    </select>
                  </div>
                  <div className="select-types-add">
                    <label>Vice Captain </label>
                    <select onChange={handleTeamAVCaptainSelection}>
                      <option value="0">Select Vice Captain</option>
                      {renderTeamAViceCaptainPlayers()}
                    </select>
                  </div>
                  <div className="select-types-add">
                    <label>Wicket keeper</label>
                    <select onChange={handleTeamAKeeperSelection}>
                      <option value="0">Select Wicket keeper </option>
                      {renderTeamAPlayers()}
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="select-types-add">
                    <label>Captain</label>
                    <select onChange={handleTeamBCaptainSelection}>
                      <option value="0">Select Captain</option>
                      {renderTeamBPlayers()}
                    </select>
                  </div>
                  <div className="select-types-add">
                    <label>Vice Captain </label>
                    <select onChange={handleTeamBVCaptainSelection}>
                      <option value="0">Select Vice Captain</option>
                      {renderTeamBViceCaptainPlayers()}
                    </select>
                  </div>
                  <div className="select-types-add">
                    <label>Wicket keeper</label>
                    <select onChange={handleTeamBKeeperSelection}>
                      <option value="0">Wicket keeper</option>
                      {renderTeamBPlayers()}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex  align-items-center justify-content-center mt-4"
              onClick={() => addplayerToMatch()}
            >
              <button className="save-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
      {validationData && (
        <ValidationModal data={validationData} onClose={handleValidation} />
      )}
    </div>
  );
}

export default AddMatchPlayers;

class Playeredit {
  constructor() {
    this.PlayerId = null;
    this.RegistrationId = null;
    this.DistrictId = null;
    this.PlayerTypeId = null;
    this.BattingStyleId = null;
    this.BowlingStyleId = null;
    this.BowlingHandId = null;
    this.BattingHandId = null;
    this.userInfo = {};
    this.TeamId = null;
    this.address1 = null;
    this.address2 = null;
  }
}

export default Playeredit;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/score.css";
import classNames from "classnames";
import {
  get_batting_shot_data,
  get_fielding_type,
  get_bowling_action,
  get_appealt_types,
  get_delivery_result,
  get_match_data,
  get_match_info,
  get_match_inn_info,
  create_score,
  get_match_inn_running_info,
  update_inn_start_data,
  get_wicket_types,
  delete_last_ball,
  set_match_follow_on,
  update_match_status,
  update_score,
  get_score,
  get_tournaments_type,
  get_player_ruuning_data,
} from "../api/serverWrapper";
import ReactPlayer from "react-player";
import MatchStatus from "../componants/MatchStatus";
import DismissalTypes from "../componants/DismissalTypes";
import MatchTypes from "../componants/MatchTypes";
import MatchInnStatus from "../componants/MatchInnStatus";
import WicketModal from "./WicketModal";
import ValidationModal from "./ValidationModal";
import DeleteBall from "./DeleteBall";
import {
  getBowlerStats,
  generateCommentary,
  getPlayerList,
  getWicketkeeperName,
} from "../componants/CommonFuncations";
import EditBall from "./EditBall";
import { useNavigate } from "react-router-dom";
import AnalyseOtherBtn from "./AnalyseOtherBtn";
import AnalyseAddPlayer from "./AnalyseAddPlayer";
// import DeclareInning from "./DeclareInning";
export default function Score() {
  //drag Line
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  let [startX, setStartX] = useState(0);
  let [startY, setStartY] = useState(0);
  let [endX, setEndX] = useState(0);
  let [endY, setEndY] = useState(0);
  const [lineColor, setLineColor] = useState("#83324F");

  const draw = (e) => {
    const newX = e.nativeEvent.offsetX;
    const newY = e.nativeEvent.offsetY;
    const startX = 150;
    const startY = 136;
    drawLines(startX, startY, newX, newY);
  };

  const drawLines = (x1, y1, x2, y2) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = lineColor;
    ctx.lineCap = "round";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    setStartX(x1);
    setStartY(y1);
    setEndX(x2);
    setEndY(y2);
  };

  //drag line ends

  // ball pitch starts
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [ballPitchArea, setBallPitchArea] = useState(null);
  let [bowlingPlayers, setBowlingPlayers] = useState();
  const handlePitchClick = (e) => {
    const pitchRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - pitchRect.left;
    const offsetY = e.clientY - pitchRect.top;
    const pitchWidth = pitchRect.width;
    const pitchHeight = pitchRect.height;
    const ballX = (offsetX / pitchWidth) * 280;
    const ballY = (offsetY / pitchHeight) * 300;
    if (
      (ballX >= 87 && ballX <= 210 && ballY >= 5 && ballY <= 22) ||
      (ballX >= 92 && ballX <= 210 && ballY >= 20 && ballY <= 22)
    ) {
      setBallPitchArea("Stumps");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 88 && ballX <= 212 && ballY >= 20 && ballY <= 24) ||
      (ballX >= 83 && ballX <= 221 && ballY >= 27 && ballY <= 50)
    ) {
      setBallPitchArea("2M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 74 && ballX <= 225 && ballY >= 48 && ballY <= 81) ||
      (ballX >= 81 && ballX <= 216 && ballY >= 50 && ballY <= 78)
    ) {
      setBallPitchArea("4M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 66 && ballX <= 223 && ballY >= 93 && ballY <= 113) ||
      (ballX >= 70 && ballX <= 222 && ballY >= 91 && ballY <= 113)
    ) {
      setBallPitchArea("8M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 24 && ballX <= 272 && ballY >= 122 && ballY <= 290) ||
      (ballX >= 64 && ballX <= 232 && ballY >= 124 && ballY <= 293)
    ) {
      setBallPitchArea("HalfWay");
      setBallPosition({ x: ballX, y: ballY });
    }
  };

  //ball pitch ends
  const [matchList, setMatchList] = useState([]);
  let [tournaments, setTournaments] = useState([]);
  let [matchInfo, setMatchInfo] = useState(null);
  const [matchUmpires, setMatchUmpires] = useState([]);
  let [innInfo, setInnInfo] = useState(null);
  const [srikerData, setSrikerData] = useState([]);
  const [nonSrikerData, setNonSrikerData] = useState([]);

  const [currOverScore, setCurrOverScore] = useState([]);
  const [preOverScore, preCurrOverScore] = useState([]);
  const [ballsList, setBballsList] = useState([]);

  const [innOverStr, setInnOverStr] = useState("");

  // const filteredList = ballsList.filter((item) =>
  //   `${item.OverNum}.${item.BallNum}`.includes(searchStr)
  // );

  const scoreDet = ["Run", "Ball", "Four", "Six", "S/R"];
  const [activeFrontBackTab, setactiveFrontBackTab] = useState("FrontFoot");
  const [activeFastSpinTab, setactiveFastSpinTab] = useState("fast");
  const [battingShotList, setFootsList] = useState([]);
  const [fieldingTypes, setFieldingTypes] = useState([]);
  const [bowlingAction, setBowlingAction] = useState([]);
  const [appealtTypes, setAppealtTypes] = useState([]);
  const [Referred, setReferred] = useState([{ id: 1, type: "Referred" }]);
  const [wicketTypeList, setWicketTypeList] = useState([]);
  const [iswicketPopup, setIsWicketTypePopup] = useState(false);

  const [strikerAt, setStrikerAt] = useState([
    { id: 1, striker: "Cam-1" },
    { id: 2, striker: "Cam-4" },
  ]);

  const [stepOut, setStepOup] = useState([{ id: 1, step: "Step Out" }]);
  const [newBall, setNewBall] = useState([{ id: 1, step: "New Ball Taken" }]);
  const [wktType, setWktType] = useState([
    { id: 1, type: "Over wkt" },
    { id: 2, type: "Round wkt" },
  ]);

  const [umpireTypes, setUmpireTypes] = useState([
    { id: 1, umpType: "Near end umpire" },
    { id: 2, umpType: "Far end umpire" },
  ]);
  const [appealResult, setAppealResult] = useState([
    { id: 1, res: "Pass" },
    { id: 2, res: "Fail" },
  ]);

  const [appealedTeam, setAppealedTeam] = useState([
    { id: 1, res: "Bat" },
    { id: 2, res: "Bowl" },
  ]);
  const [cuurentPlayingPlayer, setCuurentPlayingPlayer] = useState([
    { name: "Dayalan Hemalatha *", runs: 74 },
    { name: "Bharati Fulmali", runs: 3 },
  ]);

  const [oveRunhighlights, setOveRunhighlights] = useState([
    { ball: 1.2, highlight: " Saika Ishaque to Bharati Fulmali, 1 run" },
    { ball: 1.2, highlight: " Saika Ishaque to Bharati Fulmali, 1 run" },
  ]);

  const [NoOrWide, setNoOrWide] = useState([
    { id: 1, decision: "W" },
    { id: 2, decision: "NB" },
  ]);

  const [ByeOrLegBye, setByeOrLegBye] = useState([
    { id: 1, decision: "B" },
    { id: 2, decision: "LB" },
  ]);

  const [delivaryResult, setDelivaryResult] = useState([]);
  const [panalityRunType, setPanalityRunType] = useState([
    { id: 1, type: "Bat" },
    { id: 2, type: "Bowl" },
  ]);
  const [shortRunType, setshortRunType] = useState([
    { id: 1, type: "Striker end" },
    { id: 2, type: "Non striker end" },
  ]);

  const updateScore = [0, 1, 2, 3, 4, 5, 6, 7];

  const [isFour, setIsFour] = useState([
    { id: 4, run: 4 },
    { id: 6, run: 6 },
  ]);

  const [selectedIsBoundery, setIsBoundery] = useState(null);
  const [selectedRun, setSelectedRun] = useState(null);
  const [totalRun, setTotalRun] = useState(0);
  const [penalityRuns, setPenalityRuns] = useState(0);
  const [shortRuns, setShortRuns] = useState(0);

  const handleTotalRun = (e) => {
    setTotalRun(e.target.value);
  };

  const updateShortRuns = (e) => {
    setShortRuns(e.target.value);
  };

  const updatepenalityRuns = (e) => {
    setPenalityRuns(e.target.value);
  };
  const handleIsBoundery = (index) => {
    setIsBoundery((prevIndex) => (prevIndex === index ? null : index));
    setSelectedRun((prevIndex) => (prevIndex === index ? null : index));
    if (selectedRun !== index) {
      setTotalRun(updateScore[index].toString());
    } else {
      setTotalRun(0);
      setSelectedRun(0);
    }
  };

  const handleRunClick = (index) => {
    setIsBoundery(null);
    setSelectedRun((prevIndex) => (prevIndex === index ? null : index));
    if (selectedRun !== index) {
      setTotalRun(updateScore[index].toString());
    } else {
      setTotalRun(0);
      setSelectedRun(0);
    }
  };

  const handleFastSpinTab = (tab) => {
    setactiveFastSpinTab(tab);
  };

  const [activeCamTab, setActiveCamTab] = useState("cam1");
  const handleCamTab = (tab) => {
    setActiveCamTab(tab);
  };
  let defualtBowlerData = [
    { count: 0, bowlingType: "Over" },
    { count: 0, bowlingType: "Mdn" },
    { count: 0, bowlingType: "Runs" },
    { count: 0, bowlingType: "Wkt" },
    { count: 0, bowlingType: "Wd" },
    { count: 0, bowlingType: "Nb" },
    { count: 0, bowlingType: "Econ" },
  ];
  let [bowlerData, setBowlerData] = useState(defualtBowlerData);

  const loadInitialData = async () => {
    await getBattingShots();
    await getFieldingType();
    await getBowlingAction();
    await getAppealtTypes();
    await getDeliveryResult();
    await getMatchList();
    await getWicketType();
    await getTounamentList();
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const getMatchList = async () => {
    try {
      let resp = await get_match_data();
      console.log(resp.data.matches);
      let filiterData = resp.data.matches.filter((status)=> status.MatchStatus === MatchStatus.MATCH_STARTED.id || status.MatchStatus === MatchStatus.MATCH_TOSS_COMPLETED.id || status.MatchStatus === MatchStatus.MATCH_COMPLETED.id)
      setMatchList(filiterData);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getTounamentList = async () =>{
    try {
      let tournamentsdata = await get_tournaments_type();
      tournaments = tournamentsdata.data.tournaments;
      setTournaments(tournaments);
    } catch (error) {
      console.log("error ", error);
    }
  }

  function setUpdatedSelections(innInfo) {
    SetSelecteStrikerId(innInfo.CurrStrikerId);
    SetSelectedNonStrikerId(innInfo.CurrNonStrikerId);
    SetSelectedBowlerId(innInfo.CurrBowlerId);
    SetMainUmpireId(innInfo.currUmpireId);
    SetLegUmpireId(innInfo.currentLegUmpireId);
    if (innInfo.CurrStrikerId && playersData) {
      let p = playersData.get(innInfo.CurrStrikerId);
      playerBattingStyle = p.Player.BattingStyleId;
      console.log("playerBattingStyle", p);
      setPlayerBattingStyle(playerBattingStyle);
      console.log("playerBattingStyle", playerBattingStyle);
    }
  }
  const getMatchInfo = async (matchId) => {
    try {
      console.log(matchId);
      let resp = await get_match_info(matchId);
      matchInfo = resp.data;
      setMatchInfo(matchInfo);
      console.log("matchData", matchInfo);
      let umpireData = [
        matchInfo.Umpire1,
        matchInfo.Umpire2,
        matchInfo.Umpire3,
      ];
      setMatchUmpires(umpireData);
      let innres = await get_match_inn_info(matchId, matchInfo.CurrInnNum);

      console.log("innres", innres);
      innInfo = innres.data;
      setInnInfo(innInfo);

      setUpdatedSelections(innInfo);

      setDefaultOnLoadOrSaveBall();

      let res = await get_match_inn_running_info(
        matchId,
        matchInfo.CurrInnNum,
        innInfo.CurrOverNum - 1
      );
      console.log("get_match_inn_running_info", res);
      updateInnRunningData(res.data, innInfo);

      let bowlingplayer = await bowlingPlayer();
      bowlingPlayers = bowlingplayer;
      setBowlingPlayers(bowlingPlayers);
    } catch (error) {
      console.log("error ", error);
    }

    // alert("loaded Match details for scoring");
  };

  const bowlingPlayer = () => {
    const players =
      innInfo?.BowlingTeamId === matchInfo.TeamAId
        ? matchInfo.TeamAPlayerList
        : matchInfo.TeamBPlayerList;
    let d = players.filter((item) => item.InnId === innInfo.InningNumber);
    return d;
  };

  const getDeliveryResult = async () => {
    try {
      let resp = await get_delivery_result();
      setDelivaryResult(resp.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getBattingShots = async () => {
    try {
      let resp = await get_batting_shot_data();
      setFootsList(resp.data);
    } catch (e) {
      console.log("error ", e);
    }
  };

  const getFieldingType = async () => {
    try {
      let resp = await get_fielding_type();
      setFieldingTypes(resp.data);
    } catch (e) {
      console.log("error ", e);
    }
  };

  const getBowlingAction = async () => {
    try {
      let resp = await get_bowling_action();
      setBowlingAction(resp.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getAppealtTypes = async () => {
    try {
      let resp = await get_appealt_types();
      setAppealtTypes(resp.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleTabClick = (tab) => {
    setactiveFrontBackTab(tab);
  };

  const getWicketType = async () => {
    try {
      let resp = await get_wicket_types();
      setWicketTypeList(resp.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleMatchDetails = (item) => {
    console.log("item", item);
    navigate("/scorecard", { state: { data: item } });
  };

  const renderMatchUmpires = (selectedUmpireId) => {
    return matchUmpires.map((item, index) => {
      if (item.id !== selectedUmpireId) {
        return (
          <option style={{ textAlign: "center" }} key={item.id} value={item.id}>
            {item.User.FirstName} {item.User.LastName}
          </option>
        );
      }
      return null;
    });
  };

  const renderFieldingTypeOptions = () => {
    return fieldingTypes.map((item, index) => (
      <option key={index} value={item.id}>
        {item.Type}
      </option>
    ));
  };

  const renderMatchListOptions = () => {
    return matchList.map((item, index) => (
      <option key={index} value={item.id}>
        {item.MatchNameOrTitle} ({item.id})
      </option>
    ));
  };

  const BastmanPop = () => {
    if (matchInfo && innInfo) {
      let battingPlyerList =
        innInfo.BattingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      battingPlyerList = battingPlyerList.filter(
        (obj) =>
          obj.InnId === matchInfo.CurrInnNum &&
          obj.IsOut === false &&
          obj.PlayerId != selectedNonStrikerId &&
          obj.PlayerId != selecteStrikerId
      );

      return (
        <div className="validation-popup">
          <div className="validation-success">
            <div className="d-flex  align-items-center justify-content-between">
              <h1 className="danger ms-2 "> Select BatsMan </h1>
            </div>
            <div className="inner">
              {battingPlyerList.map((item, index) => (
                <div
                  key={index}
                  className="item d-flex setBatsmanSelection-items-center gap-3"
                  onClick={() => setBatsmanSelection(item.PlayerId)}
                >
                  <input
                    type="radio"
                    id={`batsman-${item.PlayerId}`} // Assuming item has an 'id' property
                    checked={item.PlayerId === batsMan}
                    style={{ display: "none" }}
                  />
                  <div
                    className={
                      item.PlayerId === batsMan
                        ? "check-box-style selected-item"
                        : "check-box-style"
                    }
                  >
                    ✔
                  </div>
                  <label
                    htmlFor={`batsman-${item.PlayerId}`}
                    onClick={() => setBatsmanSelection(item.PlayerId)} // Passing the 'id' to the handler
                  >
                    {item.Player.User.FirstName} {item.Player.User.LastName}
                  </label>
                </div>
              ))}
            </div>
            {showError && (
              <p className="error-message mx-2 mt-4">Please select a batsman</p>
            )}
            <div className="d-flex  gap-3 justify-content-end me-3">
              <button className="btn1 mt-4" onClick={() => handleBatsman()}>
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  const BowlersPop = () => {
    if (matchInfo && innInfo) {
      let fieldingPlayerList =
        innInfo.BowlingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      fieldingPlayerList = fieldingPlayerList.filter(
        (obj) =>
          obj.InnId === matchInfo.CurrInnNum && obj.PlayerId != selectedBowlerId
      );
      return (
        <div className="validation-popup">
          <div className="validation-success">
            <div className="d-flex  align-items-center justify-content-between">
              <h1 className="danger ms-2 "> Select Bowler </h1>
              {/* <div className="close" onClick={() => setShowBowlerPopup(false)}>
                X
              </div> */}
            </div>
            <div className="inner">
              {fieldingPlayerList.map((item, index) => (
                <div
                  key={index}
                  className="item d-flex align-items-center gap-3"
                  onClick={() => bowlerSelection(item.PlayerId)}
                >
                  <input
                    type="radio"
                    id={`bowler-${item.PlayerId}`}
                    checked={item.PlayerId === bowler}
                    style={{ display: "none" }}
                  />
                  <div
                    className={
                      item.PlayerId === bowler
                        ? "check-box-style selected-item"
                        : "check-box-style"
                    }
                  >
                    ✔
                  </div>
                  <label
                    htmlFor={`bowler-${item.PlayerId}`}
                    onClick={() => bowlerSelection(item.PlayerId)}
                  >
                    {item.Player.User.FirstName} {item.Player.User.LastName}
                  </label>
                </div>
              ))}
            </div>
            {showError && (
              <p className="error-message mx-2 mt-4">
                Please select New Bowler
              </p>
            )}
            <div className="d-flex  gap-3 justify-content-end me-3">
              <button className="btn1 mt-4" onClick={() => handleBowler()}>
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  // const renderBattingPlayers = () => {
  //   if (matchInfo && innInfo) {
  //     let battingPlyerList =
  //       innInfo.BattingTeamId === matchInfo.TeamAId
  //         ? matchInfo.TeamAPlayerList
  //         : matchInfo.TeamBPlayerList;

  //     battingPlyerList = battingPlyerList.filter(
  //       (obj) => obj.InnId === matchInfo.CurrInnNum && obj.IsOut === false
  //     );

  //     let resp = battingPlyerList.map((player, index) => (
  //       <option key={index} value={player.PlayerId}>
  //         {player.Player.User.FirstName} {player.Player.User.LastName}
  //       </option>
  //     ));

  //     return resp;
  //   } else {
  //     return <option>No players available</option>;
  //   }
  // };

  let [playerBattingStyle, setPlayerBattingStyle] = useState(null);

  const renderBattingPlayers = (selectedPlayerId) => {
    if (matchInfo && innInfo) {
      let battingPlayerList =
        innInfo.BattingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      battingPlayerList = battingPlayerList.filter(
        (obj) => obj.InnId === matchInfo.CurrInnNum && obj.IsOut === false
      );

      let resp = battingPlayerList.map((player, index) => {
        // Exclude the selected player from the options
        if (player.PlayerId !== selectedPlayerId) {
          return (
            <option key={index} value={player.PlayerId}>
              {player.Player.User.FirstName} {player.Player.User.LastName}
            </option>
          );
        }
        return null;
      });

      return resp;
    } else {
      return <option>No players available</option>;
    }
  };
  const renderBowlerPlayers = () => {
    if (matchInfo && innInfo) {
      let fieldingPlayerList =
        innInfo.BowlingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      fieldingPlayerList = fieldingPlayerList.filter(
        (obj) => obj.InnId === matchInfo.CurrInnNum
      );

      return fieldingPlayerList.map((player, index) => (
        <option key={index} value={player.PlayerId}>
          {player.Player.User.FirstName} {player.Player.User.LastName}
        </option>
      ));
    } else {
      return <option>No players available</option>;
    }
  };

  const renderAppealTypeOptions = () => {
    return appealtTypes.map((item, index) => (
      <option key={item.id} value={item.id}>
        {item.AppealType}
      </option>
    ));
  };

  const START_INNING = "Start Inning";
  const LOAD_INN = "Load Inning";
  const START_POWER_PLAY = "Start Power Play";
  const STOP_POWER_PLAY = "Start Power Play";

  const loadMatchInfoInitialy = async () => {
    setWaiting(true);
    // setDefaultOnLoadOrSaveBall();
    if (selectedMatch === null) {
      setValidationData("Please Select the Match");
      return;
    }
    await loadMatchInfo();
    setValidationData("loaded Match details for scoring");
    setWaiting(false);
  };
  let [playersData, setPlayersData] = useState(false);
  const loadMatchInfo = async () => {
    await getMatchInfo(selectedMatch);

    // console.log("MatchStatus",);
    // alert(innInfo.InnStatus);
    if (matchInfo) {
      if (matchInfo.MatchStatus === MatchStatus.MATCH_COMPLETED.id) {
        setMatchStatusAction(MatchStatus.MATCH_COMPLETED.status);
        return;
      }

      if (innInfo.InnStatus === MatchInnStatus.NOT_STARTED.id) {
        setMatchStatusAction(START_INNING);
      } else if (innInfo.InnStatus === MatchInnStatus.IN_PROGRESS.id) {
        setMatchStatusAction(START_POWER_PLAY);
      }
      playersData = getPlayerList(matchInfo);
      setPlayersData(playersData);
      if (innInfo.CurrStrikerId && playersData) {
        let p = playersData.get(innInfo.CurrStrikerId);
        console.log("innInfo.CurrStrikerId", innInfo.CurrStrikerId, p);
        playerBattingStyle = p.Player.BattingStyleId;
        setPlayerBattingStyle(playerBattingStyle);
      }
    }
    setSelectedBowlingSide(1);
  };

  const [matchStatusAction, setMatchStatusAction] =
    useState("Match Not Loaded");
  const [isInPowerPlay, setIsInPowerPlay] = useState(false);

  function isInningDataSelected() {
    if (legUmpireId === null) {
      setValidationData("please select near end umpire");
      return false;
    }
    if (mainUmpireId === null) {
      setValidationData("please select far end umpire");
      return false;
    }
    if (selecteStrikerId === null) {
      setValidationData("please select Striker");
      return false;
    }
    if (selectedNonStrikerId === null) {
      setValidationData("please select Non Striker");
      return false;
    }
    if (selectedBowlerId === null) {
      setValidationData("please select Bowler");
      return false;
    }
    return true;
  }
  const updateMathchPlayersData = async () => {
    if (isInningDataSelected() === false) {
      return;
    }
    let data = {
      CurrStrikerId: selecteStrikerId,
      CurrNonStrikerId: selectedNonStrikerId,
      CurrBowlerId: selectedBowlerId,
      id: innInfo.id,
      currentLegUmpireId: legUmpireId,
      currUmpireId: mainUmpireId,
    };
    let res = await update_inn_start_data(data);
    console.log("update_inn_start_data", res);
    innInfo = res.data.inn;
    setInnInfo(innInfo);
  };
  const updateMatchStatus = async () => {
    if (matchStatusAction === START_INNING) {
      if (isInningDataSelected() === false) {
        return;
      }
      await updateMathchPlayersData();

      if (innInfo.InnStatus === MatchInnStatus.IN_PROGRESS.id) {
        setMatchStatusAction(START_POWER_PLAY);
        await update_match_status(
          innInfo.MatchId,
          MatchStatus.MATCH_STARTED.id
        );
      }
    } else {
      if (matchStatusAction === START_POWER_PLAY) {
      }
    }
  };

  let [selectedMatch, setSelectedMatch] = useState(null);
  const [matchTypeInning, setmatchTypeInning] = useState(2);
  const handlegetMatch = (e) => {
    setSelectedMatch(e.target.value);
    const selectedMatchId = parseInt(e.target.value);
    const selectedMatch = matchList.find(
      (match) => match.id === selectedMatchId
    );
    console.log("selectedMatch", selectedMatch);
    if (selectedMatch) {
      setmatchTypeInning(selectedMatch.MatchType);
      console.log(selectedMatch.MatchType);
    }
  };
  const handleTournament = async (event) => {
    const selectedTournamentId = event.target.value;
    let tId = parseInt(event.target.value)
     

    if (selectedTournamentId !== "") {
      let resp = await get_match_data(selectedTournamentId,true);
      console.log(resp.data.matches);
      let filiterData = resp.data.matches.filter((status)=> status.MatchStatus === MatchStatus.MATCH_STARTED.id || status.MatchStatus === MatchStatus.MATCH_TOSS_COMPLETED.id || status.MatchStatus === MatchStatus.MATCH_COMPLETED.id)
      setMatchList(filiterData);
       
    }
  };
  const START_CUT = "Start";
  const END_CUT = "End";
  const OTHER = "Other";
  const [selectedDeliveryResult, setSelectedDeliveryResult] = useState(null);
  const [selectedAppealType, setSelectedAppealType] = useState(null);
  const [selectedFielderType, setSelectedFielderType] = useState(null);
  const [selectedBattingShot, setSelectedBattingShot] = useState(null);
  const [selectedBowlingAction, setSelectedBowlingAction] = useState(null);
  const [selectedWideOrNo, setSelectedWideOrNo] = useState(null);
  const [selectedByeOrLegBye, setSelectedByeOrLegBye] = useState(null);
  const [apealUmpire, setApealUmpire] = useState(null);
  const [apealResult, setApealResult] = useState(null);
  const [selectedAppealedTeam, setSelectedAppealedTeam] = useState(null);
  const [isStepOut, setIsStepOut] = useState(null);
  const [isNewBallTaken, setIsNewBallTaken] = useState(null);
  let [selectedStrikerAt, setSelectedStrikerAt] = useState(null);
  const [selectedBowlingSide, setSelectedBowlingSide] = useState(null);
  const [selectedPanalityRunsTeam, setSelectedPanalityRunsTeam] =
    useState(null);
  const [selectedShortRunSide, SetSelectedShortRunSide] = useState(null);
  const [selectedReferred, setSelectedReferred] = useState(null);
  const [startOrStopCutTxt, setStartOrStopCutTxt] = useState(START_CUT);
  const [otherTxt, setOtherText] = useState(OTHER);

  let [selectedBowlerId, SetSelectedBowlerId] = useState(null);

  let [bowler, setBowler] = useState(null);
  let [batsMan, setBatsman] = useState(null);

  const handleBattingShotSelection = (id) => {
    setSelectedBattingShot(id);
  };

  const handleBowlingAction = (id) => {
    setSelectedBowlingAction(id);
  };

  const handleBallTypeDecision = (id) => {
    setSelectedWideOrNo((prevIndex) => (prevIndex === id ? null : id));
  };
  const handleByeOrLegBye = (id) => {
    setSelectedByeOrLegBye((prevIndex) => (prevIndex === id ? null : id));
  };
  const handleDeliveryResult = (index) => {
    setSelectedDeliveryResult((prevIndex) =>
      prevIndex === index ? null : index
    );
  };
  const [showBatsmanPopup, setShowBatsPopup] = useState(false);
  const [showBowlerPopup, setShowBowlerPopup] = useState(false);

  const [showError, setShowError] = useState(false);

  const setBatsmanSelection = async (id) => {
    batsMan = id;
    setBatsman(batsMan);
  };

  const handleBatsman = async () => {
    if (!batsMan) {
      setShowError(true);
      return;
    } else {
      if (isOverChanging) {
        if (isWicketSriker) {
          selecteStrikerId = selectedNonStrikerId;
          SetSelecteStrikerId(selecteStrikerId);
        }
        selectedNonStrikerId = batsMan;
        SetSelectedNonStrikerId(selectedNonStrikerId);

        setShowBatsPopup(false);
        handleOverChange();
      } else {
        if (isWicketSriker) {
          selecteStrikerId = batsMan;
          SetSelecteStrikerId(batsMan);
        } else {
          selectedNonStrikerId = batsMan;
          SetSelectedNonStrikerId(batsMan);
        }
        setWaiting(true);
        setShowBatsPopup(false);
        await dataUpdateOnPlayerSelection();
        await loadMatchInfo();
        setWaiting(false);
      }

      batsMan = null;
      setBatsman(batsMan);
      setShowError(false);
      isStrikerwicket = false;
      setIsStrikerwicket(isStrikerwicket);
    }
  };

  let [previousBowlerId, setPreviousBowlerId] = useState(null);

  const bowlerSelection = async (id) => {
    bowler = id;
    setBowler(bowler);
  };

  const handleBowler = async () => {
    if (!bowler) {
      setShowError(true);
      return;
    } else {
      setWaiting(true);

      setShowBowlerPopup(false);

      previousBowlerId = selectedBowlerId;
      setPreviousBowlerId(previousBowlerId);

      selectedBowlerId = bowler;
      SetSelectedBowlerId(selectedBowlerId);

      await dataUpdateOnPlayerSelection();

      bowler = null;
      setBowler(bowler);
      setShowError(false);
      setWaiting(false);
    }
  };

  const handleAppealTypes = (event) => {
    setSelectedAppealType(event.target.value);
    console.log("event.target.value", event.target.value);
  };

  const handleFilderType = (index) => {
    setSelectedFielderType(index);
  };

  const handleUmpireType = (id) => {
    setApealUmpire((prevIndex) => (prevIndex === id ? null : id));
  };
  const handleAppealResult = (id) => {
    setApealResult((prevIndex) => (prevIndex === id ? null : id));
  };
  const handleAppealedTeam = (id) => {
    setSelectedAppealedTeam((prevIndex) => (prevIndex === id ? null : id));
  };

  const handleStepOut = (id) => {
    setIsStepOut((prevIndex) => (prevIndex === id ? null : id));
  };
  const handleNewBall = (id) => {
    setIsNewBallTaken((prevIndex) => (prevIndex === id ? null : id));
  };

  const handleStrikerAt = (id) => {
    setSelectedStrikerAt(id);
  };
  const handleBowlingSideSelection = (id) => {
    setSelectedBowlingSide(id);
  };
  const handlePanalityRunsTeam = (id) => {
    setSelectedPanalityRunsTeam((prevIndex) => (prevIndex === id ? null : id));
    setPenalityRuns(5);
  };

  const handleShortRunSide = (id) => {
    SetSelectedShortRunSide((prevIndex) => (prevIndex === id ? null : id));
  };

  const handleReferred = (id) => {
    setSelectedReferred((prevIndex) => (prevIndex === id ? null : id));
  };

  const handleLoadOver = async () => {
    setSelectedBall(null);

    const str = innOverStr;

    // Split the string by the dot
    const parts = str.split(".");
    if (parts.length < 2) {
      setValidationData(
        "Please enter InnNumber.OverNumner to load the over details"
      );
      return;
    }
    // Extract the integer parts before and after the dot
    const innNum = parseInt(parts[0]);
    const overNum = parseInt(parts[1]);
    console.log("loading data for ", matchInfo.id, innNum, overNum);

    setWaiting(true);
    let res = await get_match_inn_running_info(
      matchInfo.id,
      innNum,
      overNum
    );
    let innres = await get_match_inn_info(matchInfo.id, innNum);

    console.log("innres", innres);
    innInfo = innres.data;
    setInnInfo(innInfo);
    console.log(res.data);
    updateInnRunningData(res.data, innInfo);
    let resp = await get_score(matchInfo.id, innNum, overNum);
    console.log(resp);
    if (resp.code == 200) {
      let twoOVersBalls = resp.data.scores;
      twoOVersBalls.forEach((b) => {
        if (playersData) {
          let c = generateCommentary(
            playersData,
            b,
            battingShotList,
            fieldingTypes,
            getWicketkeeperName(matchInfo, bowlingPlayers)
          );
          b.Description = c;
        }
      });
      setBballsList(resp.data.scores);

      setInnOverStr("");
    }
    setWaiting(false);
  };

  let [editingBallInfo, setEditingBallInfo] = useState(null);
  const [selectedBall, setSelectedBall] = useState(null);

  const cancleEditBall = () => {
    setShowEditBallPopup(false);
    setEditBall(null);
    setInnOverStr(null);
    setEditingBallInfo(null);
    setSelectedBall(null);
  };

  const editConfirm = () => {
    setShowEditBallPopup(false);
    setSelectedBall(null);
    prepareForEdit();
  };

  const handleBallClick = async (index) => {
    setShowEditBallPopup(true);
    setSelectedBall(index);
    let ballInfo = ballsList[index];
    console.log(ballInfo);
    
    editingBallInfo = ballInfo;
    setEditingBallInfo(editingBallInfo);
  };
  const prepareForEdit = async () => {
    let ballInfo = editingBallInfo;
    if (ballInfo === null || matchInfo === null) {
      setValidationData("Please load the match first");
    }
    if (
      ballInfo.OverNum === innInfo.CurrOverNum - 1 &&
      ballInfo.InnNum === matchInfo.CurrInnNum
    ) {
      setValidationData(
        "cannot edit current over data, to modify delete and add"
      );
      return;
    }
    setWaiting(true)
    let resp = await get_player_ruuning_data(ballInfo.MatchId,ballInfo.InnNum,ballInfo.BattingStrikerId,ballInfo.BattingNonStrikerId,ballInfo.BowlerId)
    console.log(resp);
  
    updateInnplayerStats(resp.data);

    setEditBall(`${ballInfo.OverNum}.${ballInfo.BallNum}`);

    setInnOverStr(`${ballInfo.OverNum}.${ballInfo.BallNum}`);

    console.log("ballsList[0].TotalRun ", ballInfo.TotalRun);
    editBallInfo = ballInfo;

    let actualRuns = ballInfo.TotalRun;
    if (ballInfo.IsWideBall === true || ballInfo.IsNoBall === true) {
      actualRuns--;
    }

    setSelectedRun(actualRuns);
    setTotalRun(actualRuns);

    setEditBallInfo(editBallInfo);

    SetSelectedBowlerId(ballInfo.BowlerId);
    SetSelectedNonStrikerId(ballInfo.BattingNonStrikerId);
    SetSelecteStrikerId(ballInfo.BattingStrikerId);
    setBallPosition({
      x: ballInfo.BowledPositionX,
      y: ballInfo.BowledPositionY,
    });
    setSelectedStrikerAt(ballInfo.BattingStrikerEndId);
    setSelectedBattingShot(ballInfo.BattingShotTypeId);
    setSelectedBowlingAction(ballInfo.BowlingAction);
    SetSelectedFielderId(ballInfo.FielderId);

    if (ballInfo.LegUmpireId === ballInfo.ApealedToUmpireId) {
      setApealUmpire(2);
    }
    if (ballInfo.MainUmpireId === ballInfo.ApealedToUmpireId) {
      setApealUmpire(1);
    }

    let res = await get_match_inn_info(ballInfo.MatchId, ballInfo.InnNum);
    let inn = res.data;
    console.log(
      "setSelectedAppealedTeam",
      inn.BattingTeamId,
      inn.BowlingTeamId,
      ballInfo.ApealedTeamId
    );
    if (inn.BattingTeamId == ballInfo.ApealedTeamId) {
      console.log("BattingTeamId");
      setSelectedAppealedTeam(1);
    }
    if (inn.BowlingTeamId == ballInfo.ApealedTeamId) {
      console.log("BowlingTeamId");
      setSelectedAppealedTeam(2);
    }

    if (ballInfo.BattingIsStepOut) {
      setIsStepOut(1);
    } else {
      setIsStepOut(null);
    }

    setSelectedAppealedTeam(ballInfo);
    SetSelectedFieldingType(ballInfo.FieldingTypeId);
    setSelectedBowlingSide(ballInfo.BowlingSideId);
    setSelectedDeliveryResult(ballInfo.DeliveryResultId);

    if (ballInfo.PenaltyTeamId === innInfo.BattingTeamId) {
      handlePanalityRunsTeam(1);
    }
    if (ballInfo.PenaltyTeamId === innInfo.BowlingTeamId) {
      handlePanalityRunsTeam(2);
    }
    if (ballInfo.ApealedTypeId) {
      setSelectedAppealType(ballInfo.ApealedTypeId);
    }

    if (ballInfo.IsRefrredTo3RdUmpire) {
      setSelectedReferred(1);
    }

    drawLines(
      ballInfo.BattingWWX1,
      ballInfo.BattingWWY1,
      ballInfo.BattingWWX2,
      ballInfo.BattingWWY2
    );

    if (ballInfo.IsFour === true) {
      setIsBoundery(4);
      setSelectedRun(4);
    }
    if (ballInfo.IsSix === true) {
      setIsBoundery(6);
      setSelectedRun(6);
    }

    if (ballInfo.IsWideBall === true) {
      setSelectedWideOrNo(1);
    }

    if (ballInfo.IsNoBall === true) {
      setSelectedWideOrNo(2);
    }

    if (ballInfo.IsBye) {
      setSelectedByeOrLegBye(1);
    }

    if (ballInfo.IsLegbye) {
      setSelectedByeOrLegBye(2);
    }
    setWaiting(false)
  };
  const updateBall = async () => {
    let score = BuildScoreData();

    score.id = editingBallInfo.id;
    score.OverNum = editBallInfo.OverNum;
    score.BallNum = editBallInfo.BallNum;
    score.InnNum = editBallInfo.InnNum;

    console.log("Edit Score ", score);

    setWaiting(true);
    //return ;

    let res = await update_score(score);
    console.log(res);

    if (res.code === 200) {
      setValidationData("Score update successfully");
      setWaiting(false);
      editingBallInfo = null;
      setEditingBallInfo(null);
    } else {
      setValidationData("Score update failed");
      setWaiting(false);
    }
    setInnOverStr("");
    setEditBall(null);
    setDefaultOnLoadOrSaveBall();
  };

  const [editBall, setEditBall] = useState(null);

  let [editBallInfo, setEditBallInfo] = useState(null);

  const [showEditBallPopup, setShowEditBallPopup] = useState(false);

  const [showDeleteBallPopup, setShowDeleteBallPopup] = useState(false);
  const cancleDltBall = () => {
    setShowDeleteBallPopup(false);
  };
  const [lastBall, setLastball] = useState(null);

  const deleteLastBall = () => {
    setShowDeleteBallPopup(!showDeleteBallPopup);
    setLastball(ballsList[0].OverNum + "." + ballsList[0].BallNum);
  };
  const [WicketType, setWicketType] = useState(null);
  let [outPlayerId, setOutPlayerId] = useState(null);
  const handleWicket = async (id) => {
    setIsWicketTypePopup(true);
  };

  const deleteConfirm = async () => {
    console.log(
      "ballsList[0].PreStateId",
      ballsList[0].PreStateId,
      ballsList[0].id
    );
    setWaiting(true);
    let resp = await delete_last_ball(ballsList[0].PreStateId, ballsList[0].id);
    console.log(resp);
    if (resp.code === 200) {
      setValidationData("Last Ball Deleted...");
      await loadMatchInfo();
    } else {
      setValidationData("Last Ball Not Deleted...");
    }
    setShowDeleteBallPopup(false);
    setWaiting(false);
  };

  function OperationConfirmPopup() {
    return (
      <div className="validation-popup">
        <div className="validation-success-popup-inner ">
          <div className="d-flex  align-items-center justify-content-between">
            <h1 className="danger ms-2 mt-4"> Declare/End </h1>
            <div onClick={handleCloseOpPopup} className="close">
              X
            </div>
          </div>
          <div className="inner-layout">
            <div className="mx-4">
              <h5 style={{ color: "var(--text-color)" }} className="success">
                {OPERATION_STRS[currOperationId]}{" "}
              </h5>
            </div>
          </div>
          <div className="d-flex  gap-3 justify-content-end me-3">
            <button className="btn1 mt-4" onClick={handleCloseOpPopup}>
              NO
            </button>
            <button className="btn1 mt-4" onClick={confirmOperation}>
              YES
            </button>
          </div>
        </div>
      </div>
    );
  }

  const getExtraBallCount = (overNumber, ballNumber) => {
    let c = 0;
    console.log("getExtraBallCount", ballsList.length, overNumber, ballNumber);
    for (let i = 0; i < ballsList.length; i++) {
      let item = ballsList[i];
      //console.log("getExtraBallCount", item.OverNum, item.BallNum);
      if (item.OverNum === overNumber && item.BallNum === ballNumber) {
        c++;
      }
    }

    return c;
  };

  const [otherBtn, setOtherBtn] = useState(false);
  const handleOtherBtn = async () => {
    setOtherBtn(!otherBtn);
  };

  const [playerType,setPlayerType] = useState(null)

  const [isAddPlayer, setISAddPlayer] = useState(false);
  const handleAddPlayerPopup = (type) => {
    setISAddPlayer(!isAddPlayer);
    setPlayerType(type)
    setOtherBtn(false);
  };

  let firstPressTimeRef = useRef(null);
  let isLiveCut = true;
  const startOrStopVidoeCut = async () => {

    startOrStopCutTxt === START_CUT
      ? setStartOrStopCutTxt(END_CUT)
      : setStartOrStopCutTxt(START_CUT);

      if ( isLiveCut == false && startOrStopCutTxt === END_CUT){
        return ; // ball but is sent when start is pressed
      }

      let matchId = matchInfo.id;
      let inningId = 0;
      let inningNumber = innInfo.InningNumber;
      let overId = 0;
      //let overNumber = innInfo.CurrOverNum - 1;
      let overNumber = innInfo.CurrOverNum;
      let ballId = 0;
      let ballNumber = innInfo.CurrBallNum;
      let extraCount = getExtraBallCount(overNumber - 1, ballNumber);

      let durationInSeconds = 0;
      let  op = 0;
      const queryStr = `BallNo=${matchId}_${inningId}_${inningNumber}_${overId}_${overNumber}_${ballId}_${ballNumber}_${extraCount}_${durationInSeconds}`;
    
        if(startOrStopCutTxt === START_CUT ){
            firstPressTimeRef.current = Date.now(); // This persists across renders
            op = 1; // start cut
        }
        else if (startOrStopCutTxt === END_CUT) {
        
            const now = Date.now();
        
            if (firstPressTimeRef.current !== null) {
                durationInSeconds = Math.round((now - firstPressTimeRef.current) / 1000);
                
            }
            op = 2; // end cut
            firstPressTimeRef = null;
        }
        sendCutBallRequest(queryStr+"_"+op);
  };

  const sendCutBallRequest = (querystr) => {
    // Append loader or perform any UI changes as needed
    console.log("sendCutBallRequest querystr: " + querystr);
    const url = "http://localhost:3000/sendEvent?BallNo=" + querystr;
    toast("Video cut started", {
      autoClose: 1000, // Close the toast after 2000 milliseconds (2 seconds)
    });
    axios({
      method: "post",
      url: url,
      data: {},
      responseType: "json",
    })
      .then(function (msg) {
        console.log("sendCutBallRequest success");
      })
      .catch(function (error) {
        console.log("sendCutBallRequest error");
      });
  };

  const getTargetDisplayStr = (innInfo) => {
    let str = "";
    if (matchInfo.MatchType === MatchTypes.TEST.id) {
      if (innInfo.InningNumber === 4) {
        str =
          innInfo.TargetRuns > 0 ? "Target : " + (innInfo.TargetRuns + 1) : "";
        str +=
          ",   Runs Needed : " +
          (innInfo.TargetRuns + 1 - innInfo.InnTotalRuns);
      } else if (innInfo.InningNumber === 2 || innInfo.InningNumber === 3) {
        let val = 0;
        if (innInfo.InningNumber === 2) {
          val =
            innInfo.TargetRuns > 0 ? -innInfo.TargetRuns : innInfo.TargetRuns;
        } else {
          if (matchInfo.IsMatchFollowOn) {
            val =
              innInfo.TargetRuns > 0 ? -innInfo.TargetRuns : innInfo.TargetRuns;
          } else {
            val =
              innInfo.TargetRuns > 0 ? innInfo.TargetRuns : innInfo.TargetRuns;
          }
        }
        //alert(val)
        let diff = val + innInfo.InnTotalRuns;
        console.log(
          "......................" +
            val +
            " " +
            diff +
            " " +
            innInfo.InnTotalRuns
        );
        str = diff < 0 ? "Trail by " + -diff : "Leading by : " + diff;
      }
    } else {
      if (innInfo.InningNumber === 2) {
        str =
          innInfo.TargetRuns > 0 ? "Target : " + (innInfo.TargetRuns + 1) : "";
      }
    }

    // console.log(str);
    return <div>{str}</div>;
  };

  let [matchFromScoreUpdate, setMatchFromScoreUpdate] = useState(null); // just used for post processing on score update
  let [isWicketSriker, setIsWicketSriker] = useState(false);
  let [isOverChanging, setIsOverChanging] = useState(false);

  function BuildScoreData() {
    if (selectedStrikerAt === null) {
      setValidationData("please select stiker focusing camera");
      return;
    }

    if (
      selectedBowlerId === null ||
      selectedBowlerId === "" ||
      isNaN(selectedBowlerId)
    ) {
      setValidationData("please select Bowler");
      return;
    }

    console.log(endX + " : " + endY);

    let score = {
      BattingStrikerId: selecteStrikerId,
      BattingNonStrikerId: selectedNonStrikerId,
      BattingStrikerEndId: selectedStrikerAt,
      BattingIsStepOut: isStepOut === null ? false : true,

      BattingShotGroupsId: activeFrontBackTab === "FrontFoot" ? 1 : 2,
      BowlerId: selectedBowlerId,

      IsWideBall: false,
      IsNoBall: false,
      IsBye: false,
      IsLegbye: false,
      isCatchDrop: false,

      IsWicket: false,
      IsFour: selectedIsBoundery == 4 ? true : false,
      IsSix: selectedIsBoundery == 6 ? true : false,

      OverNum: innInfo.CurrOverNum - 1,
      BallNum: innInfo.CurrBallNum,
      InningId: innInfo.id,
      InnNum: matchInfo.CurrInnNum,
      MatchId: matchInfo.id,

      BowlingLengthId: 1,

      BowledPositionX: Math.round(ballPosition.x),
      BowledPositionY: Math.round(ballPosition.y),

      BattingWWX1: Math.round(startX),
      BattingWWY1: Math.round(startY),
      BattingWWX2: Math.round(endX),
      BattingWWY2: Math.round(endY),

      BattingWWPositionId: 1,

      //to be updated
      TotalRun: parseInt(totalRun),

      ShortRuns: parseInt(shortRuns),

      LegUmpireId: legUmpireId,
      MainUmpireId: mainUmpireId,
    };

    if (selectedBattingShot) {
      score.BattingShotTypeId = selectedBattingShot;
    }
    if (selectedBowlingSide) {
      score.BowlingSideId = selectedBowlingSide;
    }
    if (selectedBowlingAction) {
      score.BowlingAction = selectedBowlingAction;
    }
    if (selectedDeliveryResult) {
      score.DeliveryResultId = selectedDeliveryResult;
    }

    //Set Bye or leg bye
    if (selectedByeOrLegBye) {
      if (selectedByeOrLegBye === 1) {
        score.IsBye = true;
      } else {
        score.IsLegbye = true;
      }
    }

    if (selectedShortRunSide) {
      score.ShortRunEnd = selectedShortRunSide;
    }

    if (selectedPanalityRunsTeam) {
      score.PenaltyRuns = parseInt(penalityRuns);
      score.PenaltyTeamId =
        selectedPanalityRunsTeam === 1
          ? innInfo.BattingTeamId
          : innInfo.BowlingTeamId; // need to update team Id
    }

    if (WicketType) {
      let isRunOutOrCaught =
        WicketType == DismissalTypes.CAUGHT.id ||
        WicketType == DismissalTypes.RUN_OUT.id;
      //alert(WicketType+"+"+DismissalTypes.CAUGHT.id+"isRunOutOrCaught"+isRunOutOrCaught+":"+selectedFielderId)
      if (isRunOutOrCaught && selectedFielderId === "") {
        setValidationData("please select  field for the out");
        return;
      }
      if (selectedFielderId) {
        score.OutByPlayerId = selectedFielderId; //to be updated
      }
      score.OutPlayerId = outPlayerId;
      score.WicketType = WicketType;
      score.IsWicket = true;
    }

    if (selectedReferred) {
      if (apealUmpire === null) {
        setValidationData("please select apealUmpire");
        return;
      }

      // if (selectedAppealedTeam === null) {
      //   setValidationData("please select DRS Taken Team");
      //   return;
      // }

      if (selectedAppealType === "") {
        setValidationData("please select AppealType");
        return;
      }

      score.IsRefrredTo3RdUmpire = selectedReferred;

      score.ApealedTeamId =
        selectedAppealedTeam == 1
          ? innInfo.BattingTeamId
          : innInfo.BowlingTeamId;
    }

    if (apealUmpire !== null) {
      score.ApealedToUmpireId = apealUmpire === 1 ? mainUmpireId : legUmpireId;
    }

    if (selectedAppealType !== "") {
      score.ApealedTypeId = selectedAppealType;
    }

    if (selectedFielderId) {
      score.FielderId = selectedFielderId;
      if (selectedFieldingType) {
        score.FieldingTypeId = selectedFieldingType;
      }
      if (selectedFielderId) {
        score.SupportingFielderId = selectedFielderId;
      }
    }
    let ballType = selectedWideOrNo;
    switch (ballType) {
      case 1:
        score.IsWideBall = true;
        break;
      case 2:
        score.IsNoBall = true;
        break;
    }

    return score;
  }

  const SaveNewBallScore = async () => {
    if (innInfo.InnStatus === MatchInnStatus.NOT_STARTED.id) {
      setValidationData("please start the Inning first");
      return;
    }
    if (matchInfo.MatchStatus === MatchStatus.MATCH_COMPLETED.id) {
      setValidationData("Match completed cannot do scoring now");
      return;
    }

    let score = BuildScoreData();
    console.log("BuildScoreData", score);
    if (score === undefined) return;

    score.isOpEndInning = isDeclearInning;

    console.log("Score info : ", score);
    setWaiting(true);

    let res = await create_score(score);
    console.log(res);

    // After saving the ball
    if (res.code === 200) {
      innInfo = res.data.inn;
      let m = res.data.match;
      matchFromScoreUpdate = m;
      setMatchFromScoreUpdate(matchFromScoreUpdate);
      console.log("matchFromScoreUpdate", matchFromScoreUpdate);
      if (res.data.matchStatus.id === MatchStatus.MATCH_COMPLETED.id) {
        setWaiting(false);
        setValidationData("Match complete ," + m.MatchResultDescription);
        setMatchStatusAction(MatchStatus.MATCH_COMPLETED.status);
        isDeclearInning = false;
        setIsDeclearInning(isDeclearInning);
        return;
      }
      if (res.data.inningStatus.id === MatchInnStatus.COMPLETED.id) {
        if (m.CurrInnNum === 3) {
          // ask user to set if needs follow on
          setWaiting(false);
          currOperationId = OPERATION_FOLLOW_ON;
          setCurrOperationId(currOperationId);
          showOperationConfirmPopup = true;
          setShowOperationConfirmPopup(showOperationConfirmPopup);
        } else {
          await loadNextInning();
        }

        return;
      }
      if (res.data.isWicket) {
        isOverChanging = res.data.isOverChange;
        setIsOverChanging(isOverChanging);
        isWicketSriker = false;
        if (score.BattingStrikerId == outPlayerId) {
          isWicketSriker = true;
        }
        setIsWicketSriker(isWicketSriker);

        setShowBatsPopup(true);

        setWaiting(false);
        return;
      }

      setInnInfo(innInfo);
      setUpdatedSelections(innInfo);
      updateInnRunningData(res.data, innInfo);

      setDefaultOnLoadOrSaveBall();

      setWaiting(false);

      if (res.data.isOverChange === true) {
        handleOverChange();
      }
    } else {
      setValidationData("Score update failed");
      setWaiting(false);
    }

    console.log("Score update response : ", res);
  };

  const loadNextInning = async () => {
    setValidationData(
      "innings completed , load next innings ? " +
        matchFromScoreUpdate.id +
        " : " +
        matchFromScoreUpdate.CurrInnNum
    );
    // selectedMatch = m.id;
    // setSelectedMatch(selectedMatch);
    selecteStrikerId = null;
    selectedNonStrikerId = null;
    selectedBowlerId = null;
    SetSelecteStrikerId(null);
    SetSelectedNonStrikerId(null);
    SetSelectedBowlerId(null);
    bowler = null;
    setBowler(bowler);
    previousBowlerId = null;
    setPreviousBowlerId(previousBowlerId);
    isDeclearInning = false;
    setIsDeclearInning(isDeclearInning);
    await loadMatchInfo();
    setWaiting(false);
  };
  const OPERATION_INN_DECLEAR = 1;
  const OPERATION_FOLLOW_ON = 2;
  const OPERATION_NO = 0;
  const OPERATION_STRS = [
    "No Operation",
    "Do you want Declare/End  Inning?",
    "Do you want to move the match to Follow-on ?",
  ];
  let [currOperationId, setCurrOperationId] = useState(OPERATION_NO);

  let [isDeclearInning, setIsDeclearInning] = useState(false);
  let [opFollowOn, setOpFollowOn] = useState(false);

  const confirmOperation = async () => {
    console.log("confirmOperation====");

    //close the popup
    showOperationConfirmPopup = false;
    setShowOperationConfirmPopup(showOperationConfirmPopup);

    if (currOperationId === OPERATION_INN_DECLEAR) {
      // update the server
      isDeclearInning = true;
      setIsDeclearInning(isDeclearInning);
      SaveNewBallScore();
      currOperationId = OPERATION_NO;
    } else if (currOperationId === OPERATION_FOLLOW_ON) {
      // update match to follow on
      setWaiting(true);
      setWaiting(true);
      console.log("confirmOperation", matchFromScoreUpdate);
      let res = await set_match_follow_on(matchFromScoreUpdate.id);
      console.log(res);
      if (res.code === 200) {
        matchFromScoreUpdate = innInfo = res.data.match;
        setMatchFromScoreUpdate(matchFromScoreUpdate);
        await loadNextInning();
      } else {
        setWaiting(false);
        setValidationData("Change to followon  failed");
      }
      currOperationId = OPERATION_NO;
    }
  };

  let [showOperationConfirmPopup, setShowOperationConfirmPopup] =
    useState(false);

  const handleDeclareOrEndInning = () => {
    currOperationId = OPERATION_INN_DECLEAR;
    setCurrOperationId(currOperationId);
    setShowOperationConfirmPopup(true);
  };

  const handleCloseOpPopup = async () => {
    setShowOperationConfirmPopup(false);
    if (currOperationId === OPERATION_INN_DECLEAR) {
      // update the server
      isDeclearInning = false;
      setIsDeclearInning(isDeclearInning);
      SaveNewBallScore();
    } else if (currOperationId === OPERATION_FOLLOW_ON) {
      await loadNextInning();
    }
    currOperationId = OPERATION_NO;
    setCurrOperationId(currOperationId);
  };

  async function handleOverChange() {
    bowler = previousBowlerId;
    setBowler(bowler);
    setShowBowlerPopup(true);
    selectedStrikerAt = selectedStrikerAt === 1 ? 2 : 1;
    console.log("selectedStrikerAt", selectedStrikerAt);
    setSelectedStrikerAt(selectedStrikerAt);
    setSelectedBowlingSide(1);
  }

  function setDefaultOnLoadOrSaveBall() {
    //Set Defaults

    setApealResult(null);
    setSelectedPanalityRunsTeam(null);
    setPenalityRuns(0);
    setIsStepOut(null);
    SetSelectedFielderId("");
    SetSelectedFieldingType("");
    setSelectedDeliveryResult(1);
    setIsBoundery(null);
    setSelectedWideOrNo(null);
    setSelectedAppealType("");
    setSelectedByeOrLegBye(null);
    setSelectedReferred(null);
    setSelectedAppealedTeam(null);
    setApealUmpire(null);
    SetSelectedShortRunSide(null);
    setIsNewBallTaken(null);
    setSelectedRun(0);
    setTotalRun(0);
    setWicketType(null);
    setSelectedWicketTypeName("");
    setStrikerOrNonStriker("");
    startX = 0;
    startY = 0;
    endX = 0;
    endY = 0;
    setStartX(0);
    setStartY(0);
    setEndX(0);
    setEndY(0);
    drawLines(startX, startY, endX, endY);
    setBallPosition({ x: 0, y: 0 });
    setSelectedBattingShot(null);
    // setSelectedBowlingSide(null);
    setSelectedBowlingAction(null);
    setSelectedDeliveryResult(null);
    setBowler(null);
    setBatsman(null);
    isDeclearInning = false;
    setIsDeclearInning(isDeclearInning);
    setEditBall(null);
    setInnOverStr(null);
    setEditingBallInfo(null);
    setSelectedBall(null);
  }
  function updateInnRunningData(runningData, innData) {
    let oversData = runningData.oversData;

    let balls = [];
    if (oversData) {
      setCurrOverScore(oversData.currOver);
      preCurrOverScore(oversData.preOver);

      let v1balls = balls.concat(oversData.currOver);
      v1balls = [...v1balls].reverse();
      let v2balls = balls.concat(oversData.preOver);
      v2balls = [...v2balls].reverse();
      balls = v1balls.concat(v2balls);
      balls.forEach((b) => {
        if (playersData) {
          let c = generateCommentary(
            playersData,
            b,
            battingShotList,
            fieldingTypes,
            getWicketkeeperName(matchInfo, bowlingPlayers)
          );
          b.Description = c;
        }
      });

      setBballsList(balls);
    }

    //Striker data
    let strikerData = [0, 0, 0, 0, 0];
    let nonStrikerData = [0, 0, 0, 0, 0];
    bowlerData = defualtBowlerData;
    if (runningData.striker) {
      let s = runningData.striker;
      let ns = runningData.nonStriker;
      let b = runningData.bowler;

      console.log("bowler ", b);
      let srr =
        s.BallsPlayed > 0
          ? ((s.RunsScored / s.BallsPlayed) * 100).toFixed(2)
          : 0;
      strikerData = [
        s.RunsScored,
        s.BallsPlayed,
        s.Boundary4s,
        s.Boundary6s,
        srr,
      ];

      let nsrr =
        ns.BallsPlayed > 0
          ? ((ns.RunsScored / ns.BallsPlayed) * 100).toFixed(2)
          : 0;
      nonStrikerData = [
        ns.RunsScored,
        ns.BallsPlayed,
        ns.Boundary4s,
        ns.Boundary6s,
        nsrr,
      ];

      let data = getBowlerStats(b);
      bowlerData[0].count = data.v;

      bowlerData[1].count = b.MaidenOvers;
      bowlerData[2].count = b.RunsGiven;
      bowlerData[3].count = b.WicketsTaken;
      bowlerData[4].count = b.WideBallBowled;
      bowlerData[5].count = b.NoBallsBowled;
      bowlerData[6].count = data.e;
    }
    setSrikerData(strikerData);
    setNonSrikerData(nonStrikerData);
    setBowlerData(bowlerData);

    if (balls.length > 0) {
      let sEnd =
        balls[0] && balls[0].BattingStrikerEndId
          ? balls[0].BattingStrikerEndId
          : 1;
      console.log("b.BattingStrikerEndId", balls[0].BattingStrikerEndId);
      setSelectedStrikerAt(sEnd);
      //Bowling side
      let bSide =
        balls[0] && balls[0].BowlingSideId ? balls[0].BowlingSideId : 1;
      console.log("b.BowlingSideId", balls[0].BowlingSideId);
      setSelectedBowlingSide(bSide);
    }
  }

  function updateInnplayerStats(runningData){
    console.log(runningData);
    //Striker data
    let strikerData = [0, 0, 0, 0, 0];
    let nonStrikerData = [0, 0, 0, 0, 0];
    bowlerData = defualtBowlerData;
    if (runningData.striker) {
      let s = runningData.striker;
      let ns = runningData.nonStriker;
      let b = runningData.bowler;

      console.log("bowler ", b);
      let srr =
        s.BallsPlayed > 0
          ? ((s.RunsScored / s.BallsPlayed) * 100).toFixed(2)
          : 0;
      strikerData = [
        s.RunsScored,
        s.BallsPlayed,
        s.Boundary4s,
        s.Boundary6s,
        srr,
      ];

      let nsrr =
        ns.BallsPlayed > 0
          ? ((ns.RunsScored / ns.BallsPlayed) * 100).toFixed(2)
          : 0;
      nonStrikerData = [
        ns.RunsScored,
        ns.BallsPlayed,
        ns.Boundary4s,
        ns.Boundary6s,
        nsrr,
      ];

      let data = getBowlerStats(b);
      bowlerData[0].count = data.v;

      bowlerData[1].count = b.MaidenOvers;
      bowlerData[2].count = b.RunsGiven;
      bowlerData[3].count = b.WicketsTaken;
      bowlerData[4].count = b.WideBallBowled;
      bowlerData[5].count = b.NoBallsBowled;
      bowlerData[6].count = data.e;
    }
    console.log(strikerData);
    setSrikerData(strikerData);
    setNonSrikerData(nonStrikerData);
    setBowlerData(bowlerData);
  }

  let [mainUmpireId, SetMainUmpireId] = useState(null);
  const handleMainUmpireChange = async (event) => {
    mainUmpireId = parseInt(event.target.value);
    SetMainUmpireId(mainUmpireId);
    await dataUpdateOnPlayerSelection();
  };
  let [legUmpireId, SetLegUmpireId] = useState(null);
  const handleLegUmpireChange = async (event) => {
    legUmpireId = parseInt(event.target.value);
    SetLegUmpireId(legUmpireId);
    await dataUpdateOnPlayerSelection();
  };

  let [selecteStrikerId, SetSelecteStrikerId] = useState(null);

  const dataUpdateOnPlayerSelection = async () => {
    if (innInfo.InnStatus === MatchInnStatus.IN_PROGRESS.id) {
      await updateMathchPlayersData();
      await loadMatchInfo();
    }
  };

  const handleStrikerSelection = async (event) => {
    selecteStrikerId = parseInt(event.target.value);
    SetSelecteStrikerId(selecteStrikerId);
    //console.log("editBall", editBall);
    if (editBall === null || editBall === false) {
      await dataUpdateOnPlayerSelection();
    }
  };
  let [selectedNonStrikerId, SetSelectedNonStrikerId] = useState(null);
  const handleNonStrikerSelection = async (event) => {
    selectedNonStrikerId = parseInt(event.target.value);
    SetSelectedNonStrikerId(selectedNonStrikerId);
    if (editBall === null || editBall === false) {
      await dataUpdateOnPlayerSelection();
    }
  };

  //let [selectedBowlerId, SetSelectedBowlerId] = useState(null);
  const handleSwapStrikers = async () => {
    if (innInfo.InnStatus === MatchInnStatus.NOT_STARTED.id) {
      setValidationData("please start the Inning first");
      return;
    }

    waiting = true;
    waitingStr = "Updating";
    setWaitingStr(waitingStr);
    setWaiting(waiting);

    // Swap the selected striker IDs
    const temp = selecteStrikerId;
    selecteStrikerId = selectedNonStrikerId;
    SetSelecteStrikerId(selectedNonStrikerId);
    selectedNonStrikerId = temp;
    SetSelectedNonStrikerId(temp);
    await updateMathchPlayersData();
    await loadMatchInfo();
    waiting = false;
    setWaiting(waiting);
  };

  const handleSwapUmpires = async () => {
    // Swap the selected umpire IDs

    if (innInfo.InnStatus === MatchInnStatus.NOT_STARTED.id) {
      setValidationData("please start the Inning first");
      return;
    }

    waiting = true;
    setWaiting(waiting);

    const temp = mainUmpireId;
    mainUmpireId = legUmpireId;
    SetMainUmpireId(mainUmpireId);
    legUmpireId = temp;
    SetLegUmpireId(legUmpireId);
    await updateMathchPlayersData();
    await loadMatchInfo();

    waiting = false;
    setWaiting(waiting);
  };

  const handleBowlerSelection = async (event) => {
    selectedBowlerId = parseInt(event.target.value);
    SetSelectedBowlerId(selectedBowlerId);
    if (editBall === null || editBall === false) {
      await dataUpdateOnPlayerSelection();
    }
  };

  const [selectedFielderId, SetSelectedFielderId] = useState(null);
  const handleFielderSelection = (event) => {
    const val = parseInt(event.target.value);
    SetSelectedFielderId(val);
  };

  const [selectedFieldingType, SetSelectedFieldingType] = useState(null);
  const handleFieldingTypeSelection = (event) => {
    const val = parseInt(event.target.value);
    SetSelectedFieldingType(val);
  };

  const closeWicketPopup = () => {
    setIsWicketTypePopup(false);

    if (isStrikerwicket) {
      outPlayerId = selecteStrikerId;
      setOutPlayerId(selecteStrikerId);
    } else {
      outPlayerId = selectedNonStrikerId;
      setOutPlayerId(selectedNonStrikerId);
    }
    console.log("wiket Type ", WicketType, isStrikerwicket, outPlayerId);
  };

  const cancle = () => {
    setWicketType(null);
    setSelectedWicketTypeName(null);
    setStrikerOrNonStriker(null);
    setIsWicketTypePopup(false);
  };

  const [iningId1, setInningId1] = useState([1, 2]);
  const [iningList, setInningList] = useState([1, 2, 3, 4]);
  const renderInning = () => {
    return iningId1.map((item, index) => <option value={item}>{item}</option>);
  };
  const renderTestInning = () => {
    return iningList.map((item, index) => <option value={item}>{item}</option>);
  };
  const [selectedWicketTypeName, setSelectedWicketTypeName] = useState("");
  const [strikerOrNonStriker, setStrikerOrNonStriker] = useState("");
  let [isStrikerwicket, setIsStrikerwicket] = useState(false);

  const [validationData, setValidationData] = useState(null);
  const handleCloseModal = () => {
    setValidationData(null);
  };

  let [waiting, setWaiting] = useState(false);
  let [waitingStr, setWaitingStr] = useState("Updating");
  const startWaiting = () => {
    setWaiting(true);
  };

  const stopWaiting = () => {
    setWaiting(false);
  };

  return (
    <div>
      {waiting && (
        <div className="overlay">
          <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <div class="spinner-border text-light ms-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div className="text-light my-4 load-txt"> {waitingStr} </div>
          </div>
        </div>
      )}
      <section className={waiting ? "waiting" : "score-container"}>
        <div className="d-flex align-item-center d">
          <div className="Match">
            <div className="select-match">
              <select
                className="select-inning"
                name=""
                id=""
                onChange={handleTournament}
              >
                <option value="">Select Tournment</option>
                {Array.isArray(tournaments) &&
                tournaments.map((Tournamnet) => (
                  <option key={Tournamnet.id} value={Tournamnet.id}>
                    {Tournamnet.TournamentName}
                  </option>
                ))}
              </select>
              <select
                className="select-match-dropdown"
                name=""
                id=""
                onChange={handlegetMatch}
              >
                <option value="">Select match</option>
                {renderMatchListOptions()}
              </select>
              {/* <select className="select-inning" name="" id="">
                <option value=""> Select Inning </option>
                {matchTypeInning === 3 ? renderTestInning() : renderInning()}
              </select> */}
              <button
                className="load-match-btn"
                onClick={loadMatchInfoInitialy}
              >
                Load Match
              </button>
              {
                <button className="power-ply-btn" onClick={updateMatchStatus}>
                  {matchStatusAction}
                </button>
              }
            </div>
          </div>
          <div className="mx-4">
            <div className="select-umpires">
              <div className="">
                <select value={mainUmpireId} onChange={handleMainUmpireChange}>
                  <option value=""> Near end umpire</option>
                  {renderMatchUmpires(legUmpireId)}
                </select>
                <p className="umpire-txt mt-1">Near End Umpire</p>
              </div>
              <div className="swap-img-select" onClick={handleSwapUmpires}>
                ⇋
              </div>
              {/* {
                <img
                  height={36}
                  width={35}
                  src={require("../assets/images/swap1.png")}
                  alt=""
                  onClick={handleSwapUmpires}
                  style={{ cursor: "pointer" }}
                  className="swap-img-select"
                />
              } */}
              <div className="">
                <select value={legUmpireId} onChange={handleLegUmpireChange}>
                  <option value="">Far end umpire</option>
                  {renderMatchUmpires(mainUmpireId)}
                </select>
                <p className="umpire-txt mt-1">Far End Umpire</p>
              </div>
            </div>
          </div>
        </div>
        <div className="all-score-det">
          <div className="updatinging-score">
            {innInfo && (
              <div className="scoring-board">
                <div className="d-flex align-item-center justify-content-between mx-2 ">
                  <div className="d-flex align-item-center gap-1">
                    <h3>
                      {innInfo.BattingTeam
                        ? innInfo.BattingTeam.TeamShortName
                        : "NA"}{" "}
                      :{" "}
                    </h3>
                    <h4>
                      {innInfo.InnTotalRuns}/{innInfo.TotalWickets}
                    </h4>
                    <h5>
                      ({innInfo.CurrOverNum - 1}.
                      {innInfo.CurrBallNum != 0 ? innInfo.CurrBallNum - 1 : 0}){" "}
                    </h5>
                  </div>
                  <div className="">
                    <h5>
                      CRR :
                      {/* {innInfo.InnTotalRuns}{innInfo.CurrOverNum}{innInfo.CurrBallNum} */}
                      {(
                        innInfo.InnTotalRuns /
                        (innInfo.CurrOverNum -
                          1 +
                          (innInfo.CurrBallNum - 1) / 6)
                      ).toFixed(2)}
                    </h5>
                  </div>
                </div>
                <div className="ms-2">
                  <h5>{getTargetDisplayStr(innInfo)}</h5>
                </div>
                <h4 className="ms-2 mt-2" style={{ fontSize: 15 }}>
                  Previous Over
                </h4>
                <div className="gap-2 d-flex mx-2 ">
                  {preOverScore.map((score, index) => (
                    <div className="pre-over">
                      {score.TotalRun}{" "}
                      {preOverScore.length - 1 !== index ? "-" : ""}
                      <div style={{ fontSize: 10 }}>
                        {score.IsWideBall ? "WD" : ""}
                        {score.IsNoBall ? "NB" : ""}
                        {score.IsWicket ? "W" : ""}
                        {score.IsBye ? "b" : ""}
                        {score.IsLegbye ? "lb" : ""}
                      </div>
                    </div>
                  ))}
                </div>
                <h4 className="ms-2 mt-2" style={{ fontSize: 15 }}>
                  Current Over
                </h4>
                {/* <div className="player-score">
                {cuurentPlayingPlayer.map((item) => (
                  <div className="d-flex my-2 justify-content-between mx-2">
                    <h2>{item.name}</h2>
                    <h2>{item.runs}</h2>
                  </div>
                ))}
              </div> */}
                {
                  <div className="gap-2 d-flex mx-2 mt-2">
                    {currOverScore.map((score, index) => (
                      <div
                        className={classNames("score-item", {
                          wicket: score.IsWicket,
                        })}
                      >
                        <div>
                          {score.TotalRun}
                          <div style={{ fontSize: 8 }}>
                            {score.IsWideBall ? "WD" : ""}
                            {score.IsNoBall ? "NB" : ""}
                            {score.IsWicket ? "W" : ""}
                            {score.IsBye ? "b" : ""}
                            {score.IsLegbye ? "lb" : ""}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }

                <div className="d-flex justify-content-end mt-2">
                  {" "}
                  <div
                    className="update-btn me-2"
                    style={{ width: "33%", height: 30, fontSize: 14 }}
                    onClick={() => handleMatchDetails(matchInfo)}
                  >
                    Scorecard
                  </div>
                  <div
                    className="update-btn"
                    style={{ width: "33%", height: 30, fontSize: 14 }}
                    onClick={() => deleteLastBall()}
                  >
                    Revert Ball
                  </div>
                </div>
              </div>
            )}

            {validationData && (
              <ValidationModal
                data={validationData}
                onClose={handleCloseModal}
              />
            )}
            <div className="d-flex mt-4">
              <button
                className={
                  activeCamTab === "cam1" ? "active-front" : "front-btn"
                }
                onClick={() => handleCamTab("cam1")}
              >
                {" "}
                Cam1
              </button>
              <button
                className={
                  activeCamTab === "cam2" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam2")}
              >
                {" "}
                Cam2
              </button>
              <button
                className={
                  activeCamTab === "cam3" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam3")}
              >
                {" "}
                Cam3
              </button>
              <button
                className={
                  activeCamTab === "cam4" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam4")}
              >
                {" "}
                Cam4
              </button>
              <button
                className={
                  activeCamTab === "cam5" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam5")}
              >
                {" "}
                Cam5
              </button>
              <button
                className={activeCamTab === "cam6" ? "active-back" : "back-btn"}
                onClick={() => handleCamTab("cam6")}
              >
                {" "}
                Cam6
              </button>
            </div>

            <div style={{ height: 300 }} className="cam-container">
              {
                activeCamTab === "cam1"
                //  &&
                // <ReactPlayer

                //   controls={true}
                //   url={require("../assets/video/c11.mp4")}
                //   height="100%"
                //   width="100%"
                // />
              }
              {
                activeCamTab === "cam2"
                //  && <ReactPlayer

                //   controls={true}
                //   url={require("../assets/video/c21.mp4")}
                //   height="100%"
                //   width="100%"
                // />
              }
            </div>
            <div className="mt-3 scoring-desc-live">
              {innInfo && (
                <div className="d-flex mt-2 gap-2">
                  <input
                    type="text"
                    placeholder="Inn.Over"
                    value={innOverStr}
                    onChange={(e) => setInnOverStr(e.target.value)}
                    className="search-overs ms-2"
                  />
                  <div
                    className="update-btn"
                    style={{ width: "50%" }}
                    onClick={() => handleLoadOver()}
                  >
                    Load Over
                  </div>

                  {showDeleteBallPopup && (
                    <DeleteBall
                      onClose={cancleDltBall}
                      data={lastBall}
                      deleteConfirm={deleteConfirm}
                    />
                  )}
                </div>
              )}
              <div className="mx-2 mt-2">
                {ballsList.map((item, index) => (
                  <div
                    key={index}
                    className={`d-flex justify-content-between align-items-center gap-2 ${
                      index === selectedBall ? "highlight" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBallClick(index)}
                  >
                    <div className="ball">
                      {item.OverNum}.{item.BallNum}
                    </div>
                    <p className="mt-2">{item.Description}</p>
                  </div>
                ))}
                {showEditBallPopup && (
                  <EditBall
                    onClose={cancleEditBall}
                    data={editBall}
                    editConfirm={editConfirm}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="batting-score">
            <div className="striker">
              <div className="d-flex justify-content-between">
                <h2> Striker</h2>
                <h1>Batting</h1>
              </div>

              <div className="select-striker">
                <select
                  value={selecteStrikerId}
                  onChange={handleStrikerSelection}
                >
                  <option value="">Select player</option>
                  {renderBattingPlayers(selectedNonStrikerId)}
                </select>
                <div className="gap-2 d-flex mx-2">
                  {srikerData.map((score, index) => (
                    <div className="score-item1">{score}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="striker my-2 ">
              <div className="d-flex runs-type ">
                <h2> Non Striker</h2>
                <div className="swap-img-2" onClick={handleSwapStrikers}>
                  ↿⇂
                </div>
                {/* <img
                  height={20}
                  width={20}
                  className="swap-img-2"
                  src={require("../assets/images/swap2.png")}
                  alt=""
                  onClick={handleSwapStrikers}
                /> */}
                <div className="d-flex gap">
                  {scoreDet.map((scoredet, index) => (
                    <div className="score-item-desc">{scoredet}</div>
                  ))}
                </div>
              </div>
              <div className="select-striker">
                <select
                  value={selectedNonStrikerId}
                  onChange={handleNonStrikerSelection}
                >
                  <option value="">Select player</option>
                  {renderBattingPlayers(selecteStrikerId)}
                </select>
                <div className="gap-2 d-flex mx-2 ">
                  {nonSrikerData.map((score, index) => (
                    <div className="score-item1">{score}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="striker-at">
              <h2 className="pt-2">Striker Camera</h2>
              <div className="d-flex align-item-center gap-3 me-3">
                {strikerAt.map((item) => (
                  <div className="d-flex gap-2 align-items-center">
                    <input
                      type="radio"
                      checked={item.id === selectedStrikerAt}
                      id={`striker-${item.id}`}
                      onChange={() => handleStrikerAt(item.id)}
                      style={{ display: "none" }}
                    />
                    <div
                      className={
                        item.id === selectedStrikerAt
                          ? "check-box-style selected-item"
                          : "check-box-style"
                      }
                      onClick={() => handleStrikerAt(item.id)}
                    >
                      ✔
                    </div>
                    <label
                      htmlFor={`striker-${item.id}`}
                      onClick={() => handleStrikerAt(item.id)}
                    >
                      {item.striker}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {!editBall ? (
              <div className=" ms-1">
                <div className="d-flex">
                  <button
                    className="update-btn mt-2 "
                    style={{ width: "100%" }}
                    onClick={SaveNewBallScore}
                  >
                    Save{" "}
                  </button>

                  {showOperationConfirmPopup && OperationConfirmPopup()}
                </div>
                <div className="d-flex align-items-center mt-2">
                  <button
                    className="update-btn  ms-2"
                    style={{ width: "50%" }}
                    onClick={startOrStopVidoeCut}
                  >
                    {startOrStopCutTxt}
                  </button>

                  {/*<button
                      className="update-btn  ms-2"
                      style={{ width: "50%" }}
                      onClick={handleDeclareOrEndInning}
                    >
                      End / Declare Inn
                    </button> */}
                  <button
                    className="update-btn  ms-2"
                    style={{ width: "50%" }}
                    onClick={handleOtherBtn}
                  >
                    {otherTxt}
                  </button>
                  {otherBtn && (
                    <AnalyseOtherBtn
                      handleDeclareOrEndInning={handleDeclareOrEndInning}
                      handleOtherBtn={handleOtherBtn}
                      handleAddPlayerPopup={handleAddPlayerPopup}
                    />
                  )}
                  {isAddPlayer && <AnalyseAddPlayer handleAddPlayerPopup={handleAddPlayerPopup} matchInfo={matchInfo}  innInfo={innInfo} playerType={playerType} />}
                </div>
              </div>
            ) : (
              <div className="update-btn mt-4" onClick={updateBall}>
                Update Ball
              </div>
            )}

            {showBatsmanPopup && BastmanPop()}
            {showBowlerPopup && BowlersPop()}

            <h2 className="mt-3 bow-len">Bowling Length</h2>
            <div className="mt-4">
              <div className="pitch-section ">
                <div
                  className={
                    playerBattingStyle === 1
                      ? "cricket-pitch2"
                      : "cricket-pitch"
                  }
                  onClick={handlePitchClick}
                >
                  {ballPosition.x !== 0 && ballPosition.y !== 0 && (
                    <div
                      className="ball"
                      style={{ left: ballPosition.x, top: ballPosition.y }}
                    >
                      {" "}
                    </div>
                  )}
                  {ballPosition.x !== 0 && ballPosition.y !== 0 && (
                    <p className="ball-position ms-4">
                      {ballPitchArea === "Stumps" ? "Stumps" : ""}
                      {ballPitchArea === "2M" ? "2M" : ""}
                      {ballPitchArea === "4M" ? "4M" : ""}
                      {ballPitchArea === "8M" ? "8M" : ""}
                      {ballPitchArea === "HalfWay" ? "HalfWay" : ""}
                      {ballPitchArea &&
                        ` (${Math.round(ballPosition.x)}, ${Math.round(
                          ballPosition.y
                        )})`}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="tagging-det mt-4">
              <h1 className="d-flex justify-content-end ">Tagging</h1>
              <div className="d-flex gap-3 ball-type-desc">
                {NoOrWide.map((item) => (
                  <div
                    className="d-flex gap-2"
                    key={item.id}
                    onClick={() => handleBallTypeDecision(item.id)}
                  >
                    <input
                      type="radio"
                      id={`decision-${item.id}`}
                      checked={item.id === selectedWideOrNo}
                      style={{ display: "none" }}
                    />
                    <div
                      className={
                        item.id === selectedWideOrNo
                          ? "check-box-style selected-item"
                          : "check-box-style"
                      }
                    >
                      ✔
                    </div>
                    <label
                      htmlFor={`decision-${item.id}`}
                      onClick={() => handleBallTypeDecision(item.id)}
                    >
                      {item.decision}
                    </label>
                  </div>
                ))}
                <div className="is-bye-leg-bye d-flex gap-3">
                  {ByeOrLegBye.map((item) => (
                    <div
                      className="d-flex gap-2"
                      key={item.id}
                      onClick={() => handleByeOrLegBye(item.id)}
                    >
                      <input
                        type="radio"
                        id={`byeorlegbye-${item.id}`}
                        checked={item.id === selectedByeOrLegBye}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === selectedByeOrLegBye
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`byeorlegbye-${item.id}`}
                        onClick={() => handleByeOrLegBye(item.id)}
                      >
                        {item.decision}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <h2>Appeal </h2>
                {Referred.map((item) => (
                  <div
                    className="d-flex gap-2"
                    key={item.id}
                    onClick={() => handleReferred(item.id)}
                  >
                    <input
                      type="radio"
                      id={`referred-${item.id}`}
                      checked={item.id === selectedWideOrNo}
                      style={{ display: "none" }}
                    />
                    <div
                      className={
                        item.id === selectedReferred
                          ? "check-box-style selected-item"
                          : "check-box-style"
                      }
                    >
                      ✔
                    </div>
                    <label
                      htmlFor={`referred-${item.id}`}
                      onClick={() => handleReferred(item.id)}
                    >
                      {item.type}
                    </label>
                  </div>
                ))}
              </div>

              <div className="d-flex gap-2 mt-1 ms-4">
                {umpireTypes.map((item, index) => (
                  <div
                    className="d-flex align-item-center gap-2"
                    key={index}
                    onClick={() => handleUmpireType(item.id)}
                  >
                    <input
                      type="radio"
                      id={`ump-${item.id}`}
                      style={{ visibility: "hidden", position: "absolute" }}
                    />
                    <div
                      className={
                        item.id === apealUmpire
                          ? "check-box-style selected-item"
                          : "check-box-style"
                      }
                    >
                      ✔
                    </div>
                    <label
                      htmlFor={`ump-${item.id}`}
                      onClick={() => handleUmpireType(item.id)}
                    >
                      {item.umpType}
                    </label>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="mt-3">DRS Taken Team</h2>
                <div className="d-flex gap-2 mt-1">
                  {appealedTeam.map((item, index) => (
                    <div
                      className="d-flex align-item-center gap-2"
                      key={index}
                      onClick={() => handleAppealedTeam(item.id)}
                    >
                      <input
                        type="radio"
                        id={`appealedteam-${item.id}`}
                        style={{ visibility: "hidden", position: "absolute" }}
                      />
                      <div
                        className={
                          item.id === selectedAppealedTeam
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`appealedteam-${item.id}`}
                        onClick={() => handleAppealedTeam(item.id)}
                      >
                        {item.res}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/*
          <div className="d-flex justify-content-between align-items-center">
                <h2 className="mt-3">Appeal Result</h2>
                <div className="d-flex gap-2 mt-1">
                  {appealResult.map((item, index) => (
                    <div className="d-flex align-item-center gap-2" key={index}>
                      <input
                        type="radio"
                        checked= {apealResult===item.id}
                        id={`appealresult-${item.id}`}
                        style={{ visibility: "hidden", position: "absolute" }}
                        onClick={() => handleAppealResult(item.id)}
                      />
                      <div
                        className={
                          item.id === apealResult
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                        onClick={() => handleAppealResult(item.id)}
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`appealresult-${item.id}`}
                        onChange={() => handleAppealResult(item.id)}
                      >
                        {item.res}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
                      */}

              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mt-3">Appeal Type</h2>
                <select
                  className="select-appeal-type"
                  name=""
                  id=""
                  value={selectedAppealType}
                  onChange={handleAppealTypes}
                >
                  <option value="">Appeal Type</option>
                  {renderAppealTypeOptions()}
                </select>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="update-btn mt-2"
                  style={{ width: "150px" }}
                  onClick={() => handleWicket(WicketType)}
                >
                  Wicket
                </button>
                <h1 className="mt-3 w-50">
                  {strikerOrNonStriker ? ` ${strikerOrNonStriker} , ` : ""}
                  {selectedWicketTypeName ? selectedWicketTypeName : ""}
                </h1>
              </div>
              {iswicketPopup && (
                <WicketModal
                  data={wicketTypeList}
                  onClose={closeWicketPopup}
                  setSelectedWicketTypeName={setSelectedWicketTypeName}
                  setStrikerOrNonStriker={setStrikerOrNonStriker}
                  setIsWicketForStriker={setIsStrikerwicket}
                  setWicketType={setWicketType}
                  cancle={cancle}
                />
              )}
            </div>
          </div>

          <div className="bowling-score">
            <div className="select-player">
              <div className="d-flex justify-content-between">
                <h2>Bowler</h2>
                <h1> Bowling/Fielding</h1>
              </div>

              <select
                className="select-player-select mt-1"
                value={selectedBowlerId}
                onChange={handleBowlerSelection}
              >
                <option value="">Select Bowler</option>
                {renderBowlerPlayers()}
              </select>
              <div className="d-flex align-item-center justify-content-between my-2 mt-3 mx-2">
                {bowlerData.map((item, index) => (
                  <div className="" key={index}>
                    <div className="score-item1">{item.count}</div>
                    <div className="over-bowl-desc">{item.bowlingType}</div>
                  </div>
                ))}
              </div>

              <div
                className="d-flex over-around-wkt align-item-center gap-3 ms-2 "
                style={{ marginTop: 20 }}
              >
                {wktType.map((item, index) => (
                  <div className="d-flex align-item-center gap-2" key={index}>
                    <input
                      type="radio"
                      checked={selectedBowlingSide === item.id}
                      id={`wkt-${item.id}`}
                      onChange={() => handleBowlingSideSelection(item.id)}
                      style={{ visibility: "hidden", position: "absolute" }}
                    />
                    <div
                      className={
                        item.id === selectedBowlingSide
                          ? "check-box-style selected-item"
                          : "check-box-style"
                      }
                      onClick={() => handleBowlingSideSelection(item.id)}
                    >
                      ✔
                    </div>
                    <label
                      htmlFor={`wkt-${item.id}`}
                      onClick={() => handleBowlingSideSelection(item.id)}
                    >
                      {item.type}
                    </label>
                  </div>
                ))}
              </div>
              <div className="switch-foots-tab mt-4">
                <div className="d-flex ms-2">
                  <button
                    className={
                      activeFastSpinTab === "fast"
                        ? "active-front"
                        : "front-btn"
                    }
                    onClick={() => handleFastSpinTab("fast")}
                  >
                    {" "}
                    Fast{" "}
                  </button>
                  <button
                    className={
                      activeFastSpinTab === "spin" ? "active-back" : "back-btn"
                    }
                    onClick={() => handleFastSpinTab("spin")}
                  >
                    {" "}
                    Spin{" "}
                  </button>
                </div>
                <div className="select-foot-tab d-flex">
                  {bowlingAction
                    .filter((item) => {
                      if (activeFastSpinTab === "fast") {
                        return (
                          item.ActionGroupId === 3 || item.ActionGroupId === 1
                        );
                      } else if (activeFastSpinTab === "spin") {
                        return (
                          item.ActionGroupId === 3 || item.ActionGroupId === 2
                        );
                      } else {
                        return false;
                      }
                    })
                    .map((item, index) => (
                      <div
                        className="d-flex gap-3 column"
                        key={index}
                        onClick={() => handleBowlingAction(item.id)}
                      >
                        <input
                          type="radio"
                          id={`action-${item.id}`}
                          checked={item.id === selectedBowlingAction}
                          onChange={() => handleBowlingAction(item.id)}
                          style={{ visibility: "hidden", position: "absolute" }}
                        />
                        <div
                          className={
                            item.id === selectedBowlingAction
                              ? "check-box-style2 selected-item"
                              : "check-box-style2"
                          }
                        >
                          ✔
                        </div>
                        <label htmlFor={`action-${item.id}`}>
                          {item.actionType}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="switch-foots-tab mt-3">
                  <div className="d-flex ms-2">
                    <button
                      className={
                        activeFrontBackTab === "FrontFoot"
                          ? "active-front"
                          : "front-btn"
                      }
                      onClick={() => handleTabClick("FrontFoot")}
                    >
                      {" "}
                      FrontFoot
                    </button>
                    <button
                      className={
                        activeFrontBackTab === "BackFoot"
                          ? "active-back"
                          : "back-btn"
                      }
                      onClick={() => handleTabClick("BackFoot")}
                    >
                      {" "}
                      BackFoot{" "}
                    </button>
                  </div>
                  <div className="select-foot-tab d-flex">
                    {battingShotList
                      .filter((item) => {
                        if (activeFrontBackTab === "FrontFoot") {
                          return item.GroupId === 3 || item.GroupId === 1;
                        } else if (activeFrontBackTab === "BackFoot") {
                          return item.GroupId === 3 || item.GroupId === 2;
                        } else {
                          return false;
                        }
                      })
                      .map((item, index) => (
                        <div
                          className="d-flex gap-3 align-items-center column"
                          key={index}
                          onClick={() => handleBattingShotSelection(item.id)}
                        >
                          <input
                            type="radio"
                            id={`foot-${item.id}`}
                            style={{ display: "none" }}
                          />
                          <div
                            className={
                              item.id === selectedBattingShot
                                ? "check-box-style2 selected-item"
                                : "check-box-style2"
                            }
                          >
                            ✔
                          </div>
                          <label
                            htmlFor={`foot-${item.id}`}
                            onClick={() => handleBattingShotSelection(item.id)}
                          >
                            {item.ShotName}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2 ms-2 align-items-center">
                  <label className="select-fielder-label" htmlFor="">
                    Fielder
                  </label>
                  <select
                    className="select-fielder-select"
                    value={selectedFielderId}
                    onChange={handleFielderSelection}
                  >
                    <option value="">Fielder</option>
                    {renderBowlerPlayers()}
                  </select>
                </div>
                <div className="d-flex justify-content-between mt-2 ms-2 align-items-center">
                  <label className="select-fielder-label" htmlFor="">
                    Fielding Type
                  </label>
                  <select
                    className="select-fielder-select"
                    value={selectedFieldingType}
                    onChange={handleFieldingTypeSelection}
                  >
                    <option value="">Fielding Type</option>
                    {renderFieldingTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="fieling-deatils-container">
            {/* <div className="fieling-deatils">
              <h1> Fielding</h1>
              <div className="d-flex ">
                <h2>Filder</h2>
                <h2 style={{ marginLeft: "41%" }}>Filder Type</h2>
              </div>
              <div className="d-flex justify-content-between">
                <div className="filder"></div>
                <div className="filder ps-3 pt-3">
                  {fieldingTypes.map((item) => (
                    <div
                      className="filder-checkbox d-flex align-items-center"
                      key={item.id}
                    >
                      <input
                        type="radio"
                        id={`fildertype-${item.id}`}
                        checked={item.id === selectedFielderType}
                        onChange={() => handleFilderType(item.id)}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === selectedFielderType
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                        onClick={() => handleFilderType(item.id)}
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`fildertype-${item.id}`}
                        onClick={() => handleFilderType(item.id)}
                      >
                        {item.Type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            <div className="d-flex justify-content-center align-item-center mx-4 mt-4">
              <canvas
                ref={canvasRef}
                width={300}
                height={300}
                onMouseDown={draw}
                className={
                  playerBattingStyle === 1
                    ? "cricket_ground2"
                    : "cricket_ground"
                }
              />
            </div>

            <div className="update-det mt-3">
              <h1>Score</h1>
              <div className=""></div>
              <div className="d-flex align-items-center gap-4">
                <h2>Is Boundary</h2>
                <div className="d-flex align-items-center gap-2">
                  {isFour.map((score, index) => (
                    <div key={index} className="">
                      <button
                        onClick={() => handleIsBoundery(score.id)}
                        className={
                          selectedIsBoundery === score.id
                            ? "update-run active-run"
                            : "update-run"
                        }
                      >
                        {score.run}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h2 className="mt-2">Run's</h2>
              </div>
              <div
                className="gap-3 d-flex mx-2 mt-2  "
                style={{ flexWrap: "wrap" }}
              >
                {updateScore.map((score, index) => (
                  <div
                    className={
                      selectedRun === index
                        ? "score-curr active-run"
                        : "score-curr"
                    }
                    onClick={() => handleRunClick(index)}
                  >
                    {score}
                  </div>
                ))}

                <input
                  type="text"
                  className="update-item-input"
                  placeholder="Runs"
                  value={totalRun}
                  onChange={handleTotalRun}
                />
              </div>

              <div className="d-flex align-items-center gap-2 mt-3">
                <h4 className="mt-3"> Penalty runs</h4>
                <div className="d-flex align-items-center gap-4  mt-2">
                  <input
                    type="text"
                    className="score-item-input"
                    value={penalityRuns}
                    onChange={updatepenalityRuns}
                  />
                  {panalityRunType.map((item) => (
                    <div
                      className=""
                      onClick={() => handlePanalityRunsTeam(item.id)}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <input
                          type="radio"
                          checked={selectedPanalityRunsTeam === item.id}
                          id={`panelity-${item.id}`}
                          style={{ display: "none" }}
                        />
                        <div
                          className={
                            item.id === selectedPanalityRunsTeam
                              ? "check-box-style selected-item"
                              : "check-box-style"
                          }
                        >
                          ✔
                        </div>
                        <label
                          htmlFor={`panelity-${item.id}`}
                          onClick={() => handlePanalityRunsTeam(item.id)}
                        >
                          {item.type}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-2">
                <h4 className="mt-1"> Short run</h4>
                <input
                  type="text"
                  className="score-item-input ms-4"
                  value={shortRuns}
                  onChange={updateShortRuns}
                />
                <div className=" non-striker-postion">
                  {shortRunType.map((item) => (
                    <div
                      className="d-flex gap-3 align-items-center mt-2"
                      onClick={() => handleShortRunSide(item.id)}
                    >
                      <input
                        type="radio"
                        id={`short-${item.id}`}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === selectedShortRunSide
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`short-${item.id}`}
                        onClick={() => handleShortRunSide(item.id)}
                      >
                        {item.type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <h4> Delivery result</h4>
                <div className="delivery-result ms-3">
                  {delivaryResult.map((item, index) => (
                    <div
                      key={index}
                      className="item d-flex align-items-center gap-2"
                      onClick={() => handleDeliveryResult(item.id)}
                    >
                      <input
                        type="radio"
                        id={`delivery-${item.id}`} // Assuming item has an 'id' property
                        checked={item.id === selectedDeliveryResult}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === selectedDeliveryResult
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`delivery-${item.id}`}
                        onClick={() => handleDeliveryResult(item.id)} // Passing the 'id' to the handler
                      >
                        {item.resultType}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 align-items-center me-5 ms-2">
                <h4> Step Out</h4>
                <div className="setp_out">
                  {stepOut.map((item) => (
                    <div
                      className="d-flex gap-2 align-items-center"
                      onClick={() => handleStepOut(item.id)}
                    >
                      <input
                        type="radio"
                        checked={isStepOut === stepOut.id}
                        id={`step-${item.id}`}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === isStepOut
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`step-${item.id}`}
                        onClick={() => handleStepOut(item.id)}
                      >
                        {item.step}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 align-items-center me-2 ms-2">
                <h4> New Ball Taken</h4>
                <div className="setp_out">
                  {newBall.map((item) => (
                    <div
                      className="d-flex gap-2 align-items-center"
                      onClick={() => handleNewBall(item.id)}
                    >
                      <input
                        type="radio"
                        id={`newball-${item.id}`}
                        style={{ display: "none" }}
                      />
                      <div
                        className={
                          item.id === isNewBallTaken
                            ? "check-box-style selected-item"
                            : "check-box-style"
                        }
                      >
                        ✔
                      </div>
                      <label
                        htmlFor={`newball-${item.id}`}
                        onClick={() => handleNewBall(item.id)}
                      >
                        {item.step}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

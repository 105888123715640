import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "../styles/teamcreation.css";
import {
  conditionalRowStylesPlayer,
  customtableStyles,
  conditionalRowStyles,
} from "../assets/tablestyle";
import { EditIcon, DeleteIcon } from "../assets/SvgIcons";
import {
  get_player_data,
  get_players,
  get_team_data,
  get_teams,
  create_team,
  update_team,
  get_team_types,
  get_tournaments_type,
  get_player_stats,
  get_player_stats_all,
  get_team_stats,
  update_image,
  get_user_documnets,
  get_districts,
} from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";
import Team from "../componants/Teamcreation";
import { TEAM_IMG_TITLE } from "../utils/constants";
import jsPDF from "jspdf";
import { playerInfo, showTeam } from "../componants/CommonFuncations";
import { tablecreateTheme } from "../assets/globalcolors";
import { getData } from "../utils/localstorage_constants";
import {
  LOGIN_USER_DISTRICT_ID,
  USER_SYSTEM_ROLE,
} from "../utils/localstorage_variables";
import UserSystemRole from "../componants/UserSystemRole";

function Teamcreation() {
  let [superadmin, setSuperadmin] = useState(false);
  let [districtid, setDistrictId] = useState(null);
  let dist = [
    1, 1, 1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 2, 1, 3, 1, 1,
    1, 1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 2, 1, 3, 1, 1, 1,
    1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 2,
  ];
  const [teams, setTeams] = useState();
  const [teams1, setTeams1] = useState();

  const [players, setPlayers] = useState();
  const [teamtypes, setTeamTypes] = useState();
  const [ispopupvisible, setIsPopupVisible] = useState(false);
  const [team, setTeam] = useState(new Team());
  const [isEdit, setIsEdit] = useState();
  const [teamname, setTeamName] = useState();
  const [teamshortname, setTeamShortName] = useState();
  const [teamdescription, setTeamDescription] = useState();
  const [captain, setCaptain] = useState();
  const [vicecaptain, setViceCaptain] = useState();
  const [teamtype, setTeamType] = useState(2);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState();
  const [respsucces, setRespSucces] = useState(false);
  const [tournamentType, settournamentType] = useState([]);
  const [playerStats, setPlayerStats] = useState([]);
  const [allData, setAllData] = useState(null);
  const [matchStats, setMatchStats] = useState([]);
  const [teamStats, setTeamStats] = useState({});
  const [playerId, setPlayerId] = useState(null);
  const [loading, setLoading] = useState(false);
  let [teamlogo, setTeamlogo] = useState("");
  let [selectedlogo, setSelectedlogo] = useState();
  let [systemRole, setSystemRole] = useState();
  let [districts, setDistricts] = useState();

  let dataLoaded = false;
  const get_api_data = async () => {
    if (dataLoaded) {
      return;
    }
    dataLoaded = true;

    systemRole = await getData(USER_SYSTEM_ROLE);
    setSystemRole(systemRole);
    let districtId = getData(LOGIN_USER_DISTRICT_ID);
    if (systemRole === UserSystemRole.ADMIN.id) {
      setSuperadmin(true);
    } else if (systemRole === UserSystemRole.DISTRICT_ADMIN.id) {
      districtid = districtId;
      setDistrictId(districtid);
      setSuperadmin(false);
    } else {
      setSuperadmin(false);
    }
    console.log("get_api_data called ");
    let teams = await get_teams();
    let team = teams.data.teams;
    let updatedteam = team.map((t, index) => ({
      ...t,
      DistrictId: dist[index],
    }));
    setTeams(updatedteam);
    setTeams1(updatedteam);

    let district = await get_districts();
    districts = district.data;
    setDistricts(districts);
    console.log("districts", districts);
    // let players1 = await get_players();
    // setPlayers(players1.data.players);
    let teamtypes = await get_team_types();
    setTeamTypes(teamtypes.data);
    let tournamentTyperesp = await get_tournaments_type();
    settournamentType(tournamentTyperesp.data.tournaments);
  };

  useEffect(() => {
    console.log("useEffect with []");
    get_api_data();
  }, []);

  useEffect(() => {
    console.log("useEffect with [team]");
    setTeamName(team?.TeamName);
    setTeamShortName(team?.TeamShortName);
    setTeamDescription(team?.Description);
    setCaptain(team?.CaptainId);
    setViceCaptain(team?.ViceCaptainId);
    setTeamType(team?.TeamType);
    setSelectedlogo(team?.TeamLogoUrl);
  }, [team]);

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });
  const TeamInfo = async (id) => {
    let teaminfo1 = await get_player_data(id);
    return teaminfo1;
  };

  const renderDistricts = () => {
    return districts.map((item) => <option value=""> {item.Name} </option>);
  };

  const TeamLogo = ({ row }) => {
    // console.log(row.LogoUrl);
    return (
      <div
        onClick={() => handleTeam(row)}
        style={{ display: "flex", alignItems: "center" }}
      >
        {row.TeamLogoUrl ? (
          <img
            width={50}
            height={50}
            style={{
              borderRadius: "50%",
              marginRight: "15px",
            }}
            src={row.TeamLogoUrl}
            alt=""
          />
        ) : (
          <div className="">
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "15px",
              }}
              src={require("../assets/images/team_logo.png")}
              alt=""
            />
          </div>
        )}
      </div>
    );
  };

  const column = [
    {
      selector: (row) => <TeamLogo row={row} />,
      width: "90px",
    },
    {
      name: "Team Name",
      selector: (row) => row.TeamName + "  " + "(" + row.TeamShortName + ")",
      // width: "300px",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description + " - Team Id : " + row.id + "",
      sortable: true,
    },
    {
      name: "Team Type",
      selector: (row) => (teamtypes ? teamtypes[row?.TeamType - 1]?.Type : ""),
      sortable: true,
    },
    // {
    //   name: "Captain",
    //   selector: (row) => row,
    //   cell: (row) => row.CaptainId,
    // },
    // {
    //   name: "Vice Captain",
    //   selector: (row) => row,
    //   cell: (row) => row.ViceCaptainId,
    // },

    (systemRole === UserSystemRole.ADMIN.id ||
      systemRole === UserSystemRole.DISTRICT_ADMIN.id) && {
      name: "Edit",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px", marginRight: "25px" }}
          src={EditIcon}
          onClick={() => editTeam(row)}
        />
      ),
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "right",
        };
      },
    },
    (systemRole === UserSystemRole.ADMIN.id ||
      systemRole === UserSystemRole.DISTRICT_ADMIN.id) && {
      name: "Delete",
      selector: (row) => (
        <img style={{ height: "25px", width: "25px" }} src={DeleteIcon} />
      ),
      width: "100px",
    },
  ];

  const editTeam = (editingteam) => {
    console.log("editingteam", editingteam);
    setTeam(editingteam);
    setIsEdit(!isEdit);
    togglepopup();
  };

  const togglepopup = () => {
    setIsPopupVisible(!ispopupvisible);
    if (isEdit) {
      setIsEdit(!isEdit);
      setTeam(new Team());
    }
    if (selectedlogo) {
      setSelectedlogo(null);
    }
  };

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      window.location.reload();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopup = (popup) => {
    console.log("open");
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };

  const handleTeamDetails = (event) => {
    const { name, value } = event.target;
    if (name === "TeamName") {
      setTeamName(value);
      setTeam({
        ...team,
        [name]: value,
      });
    } else if (name === "TeamShortName") {
      setTeamShortName(value);
      setTeam({
        ...team,
        [name]: value,
      });
    } else if (name === "Description") {
      setTeamDescription(value);
      setTeam({
        ...team,
        [name]: value,
      });
    } else if (name === "CaptainId") {
      setCaptain(value);
      setTeam({
        ...team,
        [name]: value,
      });
    } else if (name === "ViceCaptainId") {
      setViceCaptain(value);
      setTeam({
        ...team,
        [name]: value,
      });
    } else if (name === "TeamType") {
      setTeamType(value);
      setTeam({
        ...team,
        [name]: value,
      });
    }
  };

  const createTeam = async () => {
    // delete team.TeamLogoUrl;
    delete team.id;
    if (team.CaptainId === null && team.ViceCaptainId === null) {
      delete team.CaptainId;
      delete team.ViceCaptainId;
    }

    if (team.TeamName) {
      if (team.TeamShortName) {
        if (team.TeamType) {
          let resp = await create_team(team);
          let id = resp.data ? resp.data.id : "";
          console.log(resp);
          if (resp.code === 200) {
            setMessage("Team Created...!!!");
            if (teamlogo !== "" && id !== "") {
              console.log("teamlogo", teamlogo);
              try {
                const resp = await update_image(id, teamlogo, TEAM_IMG_TITLE);
                console.log(resp);
                if (resp.code === 200) {
                }
              } catch (error) {
                console.error(error);
              }
            }

            succespopup();
            togglepopup();
          } else {
            validationpopupvisible();
            setMessage(resp.message);
          }
        } else {
          validationpopupvisible();
          setMessage("Enter Team Type");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Team shortname");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Team Name");
    }
  };

  const updateTeam = async () => {
    delete team.TeamLogoUrl;
    if (team.CaptainId === null && team.ViceCaptainId === null) {
      delete team.CaptainId;
      delete team.ViceCaptainId;
    }

    if (team.TeamName) {
      if (team.TeamShortName) {
        if (team.TeamType) {
          let resp = await update_team(team);
          console.log(resp);
          if (resp.code === 200) {
            setMessage("Team Updated...!!!");
            if (teamlogo !== "") {
              console.log("teamlogo", teamlogo);
              try {
                const resp = await update_image(
                  team.id,
                  teamlogo,
                  TEAM_IMG_TITLE
                );
                console.log(resp);
                if (resp.code === 200) {
                }
              } catch (error) {
                console.error(error);
              }
            }

            succespopup();
            togglepopup();
          } else {
            validationpopupvisible();
            setMessage(resp.message);
          }
        } else {
          validationpopupvisible();
          setMessage("Enter Team Type");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Team shortname");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Team Name");
    }
  };

  const changeHandler = (event) => {
    const filteredData = teams
      .filter((item) =>
        item.TeamName.toLowerCase().includes(event.target.value.toLowerCase())
      )
      .sort((a, b) => a.TeamName.localeCompare(b.TeamName));
    setTeams1(filteredData);
  };

  const handleImg = (e) => {
    let file = e.target.files[0];
    if (file) {
      teamlogo = file;
      setTeamlogo(teamlogo);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedlogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("selectedlogo", selectedlogo);
  };

  const addandeditteampopup = () => {
    return (
      <div className="overlay">
        <div className="team_popup">
          <div className="pop_up_add_text_container_team d-flex me-5">
            <span className="pop_up_add_text">
              {isEdit ? "Edit Team" : "Add Team"}
            </span>
            <input
              type="file"
              id="TeamLogoUrl"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="TeamLogoUrl"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Team Logo</div> */}
              {selectedlogo ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedlogo}
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/team_logo.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="pop_up_inputs">
            <input
              className="team_details_teamname"
              type="text"
              name="TeamName"
              placeholder="Team Name"
              value={teamname}
              onChange={handleTeamDetails}
            />
            <input
              className="team_details_shortname"
              type="text"
              name="TeamShortName"
              placeholder="Short Name"
              value={teamshortname}
              onChange={handleTeamDetails}
            />
          </div>
          <div className="pop_up_inputs">
            <input
              className="team_details_teamname"
              name="Description"
              placeholder="Description"
              value={teamdescription}
              onChange={handleTeamDetails}
            />
          </div>
          <div className="pop_up_inputs">
            {/* <select
              className="team_details_select"
              name="CaptainId"
              value={captain}
              onChange={handleTeamDetails}
            >
              <option>Choose Captain</option>
              {Array.isArray(players) &&
                players.map((Player) => (
                  <option key={Player.PlayerId} value={Player.PlayerId}>
                    {Player.User.FirstName}
                  </option>
                ))}
            </select>
            <select
              className="team_details_select"
              name="ViceCaptainId"
              value={vicecaptain}
              onChange={handleTeamDetails}
            >
              <option value="">Choose Vice Captain</option>
              {Array.isArray(players) &&
                players.map((Player) => (
                  <option key={Player.PlayerId} value={Player.PlayerId}>
                    {Player.User.FirstName}
                  </option>
                ))}
            </select> */}
            <select
              className="team_details_select"
              name="TeamType"
              value={teamtype}
              onChange={handleTeamDetails}
            >
              <option value="">Choose Team Type</option>
              {Array.isArray(teamtypes) &&
                teamtypes.map((Teamtypes) => (
                  <option key={Teamtypes.id} value={Teamtypes.id}>
                    {Teamtypes.Type}
                  </option>
                ))}
            </select>
            <select
              className="team_details_select"
              name="TeamType"
              value={teamtype}
              onChange={handleTeamDetails}
            >
              <option value="">Select Tournament</option>
              {renderTournamentTypeOptions()}
            </select>
            <select className="team_details_select">
              <option value="">Select Distict</option>
              {renderDistricts()}
            </select>
          </div>
          <div className="popup_buttons">
            <button className="popup_button_cancel" onClick={togglepopup}>
              cancel
            </button>

            <button
              className="popup_button_save"
              onClick={isEdit ? updateTeam : createTeam}
            >
              {isEdit ? "Save" : "Create"}
            </button>
          </div>
          <button className="close-button" onClick={togglepopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      </div>
    );
  };
  const [teamData, setTeamData] = useState([]);
  const [Players, setPalyers] = useState([]);
  const handleTeam = async (row) => {
    console.log(row);
    setTeamData(row);
    let resp = await get_player_data(row.id);
    setPalyers(resp.data.playersList);
    console.log(resp.data.playersList);
    setIsShowTeam(true);

    let teamstats = await get_team_stats(row.id);
    console.log(teamstats.data);
    setTeamStats(teamstats.data);
    console.log(teamStats.testMatchCount);
  };
  const [isShowTeam, setIsShowTeam] = useState(false);

  const closeTeamModel = () => {
    setIsShowTeam(false);
  };
  const [isPlayerInfo, setIsPlayerInfo] = useState(null);
  const handlePlayerInfo = async (player) => {
    setIsPlayerInfo(player);
    console.log(player);
    console.log(player.PlayerId);
    setPlayerId(player.PlayerId);
    try {
      let resp = await get_player_stats_all(player.PlayerId);
      console.log(resp.data.playerDetails);
      setAllData(resp.data.playerDetails);
      setPlayerStats(resp.data.summary);
      setMatchStats(resp.data.matchStats);
      console.log(allData);
    } catch (error) {
      console.error("Error fetching player stats:", error);
    }
  };
  const closePlayerInfo = () => {
    setIsPlayerInfo(null);
  };
  const [btnbowling, setBtnBowling] = useState(false);
  const [btnbatting, setBtnBatting] = useState(true);
  const togglebattingandbowling = () => {
    setBtnBatting(!btnbatting);
    setBtnBowling(!btnbowling);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const rowHeight = 10;
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 2;
    let startY = margin;

    function setBackground() {
      // Set text color to white
      doc.setTextColor("#ffffff");

      // Set background color to black for each page
      doc.setFillColor("#000000");
      doc.rect(0, 0, pageWidth, pageHeight, "F");
    }

    setBackground();
    doc.setFontSize(9);
    const imgData = isPlayerInfo.Player.User.ProfilePhotoUrl;
    const imgWidth = 50;
    const imgHeight = 50;
    const imgX = pageWidth - imgWidth - margin;
    const imgY = margin;
    doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Name : " +
        isPlayerInfo.Player.User.FirstName +
        " " +
        isPlayerInfo.Player.User.LastName,
      margin,
      startY + 15
    );
    doc.text(
      "Player Type : " + isPlayerInfo.Player.PlayerType.PlayerType,
      margin,
      startY + 21
    );

    const middle = pageWidth / 2;
    let startXLeft = margin;
    let startXRight = middle + margin;
    doc.setTextColor("#ffc52f");
    doc.text("Player Batting Summary", startXLeft, startY + 30);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#ffffff");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `No of Hundreds : ${playerStats.hundreds}`,
      startXLeft,
      startY + 45
    );
    doc.text(`No of Fifties : ${playerStats.fifties}`, startXLeft, startY + 55);
    doc.text(`Average Runs : ${playerStats.avgRuns}`, startXLeft, startY + 65);

    doc.text(`Total Runs : ${playerStats.totalRuns}`, startXRight, startY + 35);
    doc.text(`No of 6s : ${playerStats.sixes}`, startXRight, startY + 45);
    doc.text(`No of 4s : ${playerStats.fours}`, startXRight, startY + 55);
    doc.text(`Strike rate : ${playerStats.fours}`, startXRight, startY + 65);

    startY += 45;
    doc.setTextColor("#ffc52f");
    doc.text("Player Bowling Summary", startXLeft, startY + 30);
    doc.setTextColor("#ffffff");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `Bowling Economy : ${playerStats.bowlingEconomy}`,
      startXLeft,
      startY + 45
    );
    doc.text(
      `Wickets Taken : ${playerStats.wicketsTaken}`,
      startXLeft,
      startY + 55
    );
    doc.text(
      `Overs Bowled: ${playerStats.oversBowled}`,
      startXLeft,
      startY + 65
    );

    doc.text(
      `Total Wickets : ${playerStats.wicketsTaken}`,
      startXRight,
      startY + 35
    );
    doc.text(`Total Maiden : ${playerStats.maidens}`, startXRight, startY + 45);
    doc.text(
      `Wicket5Fors : ${playerStats.wicket5Fors}`,
      startXRight,
      startY + 55
    );
    doc.text(
      `Total RunsGiven : ${playerStats.runsGiven}`,
      startXRight,
      startY + 65
    );

    startY += 70;

    function addDataToPDF(stats, columnPositions, title) {
      startY += 10;
      doc.setFontSize(10);
      doc.setTextColor("#ffffff"); // Set text color to white
      doc.setFillColor("#101713"); // Set background color for table header column name
      doc.rect(margin, startY, pageWidth - 2 * margin, rowHeight, "F"); // Draw rectangle for header
      doc.setTextColor("#ffffff"); // Set text color to white for header

      // Render title inside the header with the specified background color and padding
      doc.text(title, margin, startY + 8);

      startY += 10;

      doc.setFontSize(8);
      const headerColumnWidths = columnPositions.map(({ name }) => {
        return (
          (doc.getStringUnitWidth(name) * 8) / doc.internal.scaleFactor + 4
        );
      });

      const totalWidth = headerColumnWidths.reduce(
        (total, width) => total + width,
        0
      );
      const spaceBetweenColumns =
        (pageWidth - 2 * margin - totalWidth) / (columnPositions.length - 1);
      let startX = margin;
      columnPositions.forEach(({ name }, index) => {
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0); // Remove border
        doc.setFillColor("#101713"); // Set background color for table cells
        const rectWidth = headerColumnWidths[index] + spaceBetweenColumns;
        doc.rect(startX, startY, rectWidth, rowHeight, "F");
        const lines = doc.splitTextToSize(name, headerColumnWidths[index]);
        doc.setTextColor("#ffffff"); // Set text color to white for table cells
        doc.text(lines, startX + 2, startY + 8, {
          lineHeightFactor: rowHeight / doc.internal.getLineHeight(),
        });

        startX += rectWidth;
      });
      startY += rowHeight;
      stats.forEach((data) => {
        let startX = margin;
        columnPositions.forEach(({ selector }, index) => {
          const value = selector(data);
          const rectWidth = headerColumnWidths[index] + spaceBetweenColumns;
          doc.rect(startX, startY, rectWidth, rowHeight, "S");
          doc.text(String(value), startX + 2, startY + 8);
          startX += rectWidth;
        });
        startY += rowHeight;
      });
    }

    addDataToPDF(matchStats, battingcolumn, "Batting Statistics");
    if (startY > pageHeight - margin) {
      doc.addPage();
      setBackground(); // Set background color for the new page
      startY = margin;
    }
    addDataToPDF(matchStats, bowlingcolumn, "Bowling Statistics");
    doc.save(`player_stats_${isPlayerInfo.Player.User.FirstName}.pdf`);
  };

  const battingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "INNS",
      selector: (row) => row.BattingInnsCount,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.totalRuns,
      sortable: true,
      textAlign: "left",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "HS",
      selector: (row) => row.topScore,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.avgRuns,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "SR",
      selector: (row) => row.sr,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "100s",
      selector: (row) => row.hundreds,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "50s",
      selector: (row) => row.fifties,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      width: "70px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];
  const bowlingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "OV",
      selector: (row) => row.oversBowled,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "MAIDEN",
      selector: (row) => row.maidens,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.runsGiven,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "WKTS",
      selector: (row) => row.wicketsTaken,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "3-FORS",
      selector: (row) => row.wicket3Fors,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "FIFERS",
      selector: (row) => row.wicket5Fors,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.bowlingAverage,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "ECON",
      selector: (row) => row.bowlingEconomy,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "BEST",
      selector: (row) => row.bowlingBest + "/" + row.RunsOnBest,
      sortable: true,
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];
  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };
  const handleTournament = async (event) => {
    setLoading(true);

    if (event.target.value !== "all") {
      try {
        let resp = await get_player_stats(event.target.value, playerId);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    } else {
      try {
        let resp = await get_player_stats_all(playerId);
        console.log(resp.data);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    }
    setLoading(false);
  };

  const [playerActiveTab, setPlayerActiveTab] = useState("playerinfo");
  const handleplayerTabs = (tab) => {
    setPlayerActiveTab(tab);
  };
  const [userDoc, setUserDoc] = useState([]);
  const [uploadDocPoup, setUploadDocPopup] = useState(false);
  const handlePlayerDoc = () => {
    setUploadDocPopup(!uploadDocPoup);
    if (isPlayerInfo) {
      getUserDoc(isPlayerInfo.Player.User.UserId);
    }
  };

  const getDistrictName = (districtId) => {
    let dist = districts?.find((district) => district.id === districtId);
    if (dist) {
      return dist.Name;
    }
    return "";
  };

  const getUserDoc = async (id) => {
    let docData = await get_user_documnets(id);
    console.log("docData", docData);

    let d = convertDataToArray(docData.data);
    setUserDoc(d);
  };

  const convertDataToArray = (data) => {
    return Object.keys(data).map((key) => ({
      fileName: key,
      fileUrl: data[key].fileUrl,
    }));
  };
  return (
    <div className="container_team">
      <div className="layout_conatiner">
        <div>
          <div className="btn_layout">
            <div className="search_content">
              <div>
                <span className="search_text">Team List</span>
              </div>
              <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search"
                  onChange={changeHandler}
                />
              </div>
            </div>
            {systemRole === UserSystemRole.DISTRICT_ADMIN.id && (
              <div className="search_content ms-2">
                <div>
                  <span className="search_text">District :</span>
                </div>
                <div className="ms-2">
                  <span className="dist_name">
                    {getDistrictName(districtid)}
                  </span>
                </div>
              </div>
            )}
            {(systemRole === UserSystemRole.ADMIN.id ||
              systemRole === UserSystemRole.DISTRICT_ADMIN.id) && (
              <div>
                <button className="btn1" onClick={() => togglepopup()}>
                  Create
                </button>
              </div>
            )}
          </div>
          <div className="team_table">
            {teams1 ? (
              <DataTable
                columns={column}
                data={
                  superadmin
                    ? teams1
                    : teams1.filter((t) => t.DistrictId === districtid)
                }
                customStyles={customtableStyles}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeader
                theme="solarized"
                fixedHeaderScrollHeight="700px"
                onRowClicked={(row) => handleTeam(row)}
              ></DataTable>
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  marginTop: 100,
                }}
              >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {ispopupvisible && addandeditteampopup()}
        {respsucces && validationpopup(succespopup)}
        {isShowTeam &&
          showTeam(
            teamData,
            closeTeamModel,
            teamStats,
            Players,
            handlePlayerInfo
          )}
        {isPlayerInfo &&
          playerInfo(
            playerStats,
            closePlayerInfo,
            handleTournament,
            renderTournamentTypeOptions,
            btnbatting,
            togglebattingandbowling,
            btnbowling,
            isPlayerInfo,
            generatePDF,
            allData,
            matchStats,
            battingcolumn,
            bowlingcolumn,
            loading,
            handleplayerTabs,
            playerActiveTab,
            userDoc,
            handlePlayerDoc
          )}
      </div>
    </div>
  );
}

export default Teamcreation;

import axios from "axios";
const { log_debug, log_info, log_error } = require("../utils/log");

const TIMEOUT = 10000;

export const doGetRequest = async (url) => {
  log_debug(url);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    timeout: TIMEOUT,
  });
  const resJson = await response.json();
  log_debug(JSON.stringify(resJson));
  return resJson;
};

export const doPostRequest = async (url, data) => {
  console.log(url);
  console.log(JSON.stringify(data));
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    timeout: TIMEOUT,
  });
  const resJson = await response.json();
  log_debug(JSON.stringify(resJson));
  return resJson;
};

export const doPostRequestImageFile = async (url, formData) => {
  console.log(url);
  console.log(JSON.stringify(formData));
  try {
    const response = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'multipart/form-data' // This header is not needed for FormData
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error occurred:", error);
    throw error; // Propagate the error
  }
};

export const doDeleteRequest = async (url) => {
  log_debug(url);
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    timeout: TIMEOUT,
  });
  const resJson = await response.json();
  log_debug(JSON.stringify(resJson));
  return resJson;
};

export const doPutRequest = async (url, data) => {
  log_debug(url);
  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    timeout: TIMEOUT,
  });
  const resJson = await response.json();
  log_debug(JSON.stringify(resJson));
  return resJson;
};

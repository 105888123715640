class GroundAddressinfo {
  constructor() {
    this.id = -1;
    this.City = null;
    this.State = null;
    this.Country = null;
    this.AddressDetails = null;
  }
}

export default GroundAddressinfo;

import React, { useEffect, useState } from "react";
import "../styles/ground.css";
import DataTable, { createTheme } from "react-data-table-component";
import {
  customtableStyles,
  conditionalRowStylesGround,
} from "../assets/tablestyle";
import {tablecreateTheme} from '../assets/globalcolors'
import { EditIcon ,DeleteIcon} from "../assets/SvgIcons";
import {
  get_ground_data,
  create_ground,
  update_ground,
  update_image,
} from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";
import Groundadd from "../componants/Groundadd";
import Groundedit from "../componants/Groundedit";
import GroundAddressinfo from "../componants/GroundAddressinfo";
import { GROUND_IMG_TITLE } from "../utils/constants";

function Ground() {
  let groundadd = new Groundadd();
  let groundedit = new Groundedit();
  let groundaddress = new GroundAddressinfo();
  const [grounds, setGrounds] = useState([]);
  const [grounds1, setGrounds1] = useState([]);

  const [ground, setGround] = useState(null);
  const [isGroundAddPopupOpen, setisGroundAddPopupOpen] = useState(false);
  const [isGroundEditPopupOpen, setisGroundEditPopupOpen] = useState(false);
  const [groundaddressid, setGroundAddressId] = useState();
  const [groundaddresscity, setGroundAddressCity] = useState();
  const [groundaddressstate, setGroundAddressState] = useState();
  const [groundaddresscountry, setGroundAddressCountry] = useState();
  const [groundaddressdetails, setGroundAddressDetails] = useState();
  const [groundid, setGroundId] = useState();
  const [groundname, setGroundName] = useState();
  const [grounddescription, setGroundDescription] = useState();
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState();
  const [respsucces, setRespSucces] = useState(false);
  let [selectedgroundimg, setSelectedGroundImg] = useState(null);
  let [groundimg, setGroundImg] = useState("");
  let [isLoading, setIsLoading] = useState(false);

  createTheme("solarized", {
    background: {
      default: 'var(--main-background-color)',
    },
  });

  const GrounImage = ({ row }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.logoUrl ? (
          <img
            width={50}
            height={50}
            style={{
              borderRadius: "50%",
              marginRight: "15px",
            }}
            src={row.logoUrl}
            alt=""
          />
        ) : (
          <div className="">
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "15px",
              }}
              src={require("../assets/images/team_logo.png")}
              alt=""
            />
          </div>
        )}
      </div>
    );
  };

  const columns = [
    { selector: (row) => <GrounImage row={row} />, width: "90px" },
    { name: "Name", selector: (row) => row.Groundname, sortable: true },
    {
      name: "Address",
      selector: (row) => row.Address?.AddressDetails,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
    },
    {
      name: "Edit",
      selector: (row) =>
        row.id === ground?.id ? (
          <img
            style={{ height: "25px", width: "25px", marginRight: "25px" }}
            src={EditIcon}
            onClick={() => editGround(row)}
          />
        ) : (
          <img
            style={{ height: "25px", width: "25px", marginRight: "25px" }}
            src={EditIcon}
            onClick={() => editGround(row)}
          />
        ),
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "right",
        };
      },
    },
    {
      name: "Delete",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px" }}
          src={DeleteIcon}
        />
      ),
      width: "100px",
    },
  ];

  const fetchGrounds = async () => {
    isLoading = true;
    setIsLoading(isLoading);
    let grounds = await get_ground_data();
    console.log("grounds", grounds);
    setGrounds(grounds.data.grounds);
    setGrounds1(grounds.data.grounds);
    isLoading = false;
    setIsLoading(isLoading);
  };

  useEffect(() => {
    setGroundId(ground?.id);
    setGroundAddressCity(ground?.Address.City);
    setGroundAddressCountry(ground?.Address.Country);
    setGroundAddressState(ground?.Address.State);
    setGroundAddressDetails(ground?.Address.AddressDetails);
    setGroundAddressId(ground?.Address.id);
    setGroundName(ground?.Groundname);
    setGroundDescription(ground?.Description);
    setSelectedGroundImg(ground?.logoUrl);
  }, [ground]);

  useEffect(() => {
    fetchGrounds();
  }, []);

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      window.location.reload();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };

  const toggleAddGroundPopup = () => {
    setisGroundAddPopupOpen(!isGroundAddPopupOpen);
    if (selectedgroundimg) {
      setSelectedGroundImg(null);
    }
  };

  const toggleEditGroundPopup = () => {
    setisGroundEditPopupOpen(!isGroundEditPopupOpen);
    if (ground) {
      setGround(null);
    }
  };

  const handleImg = (e) => {
    let file = e.target.files[0];
    if (file) {
      groundimg = file;
      setGroundImg(groundimg);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedGroundImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("selectedlogo", selectedgroundimg);
  };

  const editGround = (ground) => {
    console.log(ground);
    setGround(ground);
    toggleEditGroundPopup();
  };

  const handleGroundname = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundName(value);
      // groundedit.Groundname = value;
    }
    groundadd.Groundname = value;
  };
  const handleDescription = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundDescription(value);
      // groundedit.Description = value;
    }
    groundadd.Description = value;
  };

  const handleCountry = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundAddressCountry(value);
      // groundaddress.Country = value;
    }
    groundaddress.Country = value;
  };
  const handleState = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundAddressState(value);
      // groundaddress.State = value;
    }
    groundaddress.State = value;
  };
  const handleCity = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundAddressCity(value);
      // groundaddress.City = value;
    }
    groundaddress.City = value;
  };
  const handleAddressinfo = (e) => {
    const { value } = e.target;
    if (isGroundEditPopupOpen) {
      setGroundAddressDetails(value);
      // groundaddress.AddressDetails = value;
    }
    groundaddress.AddressDetails = value;
  };
  const handleAddground = async () => {
    groundadd.AddressInfo = groundaddress;
    console.log("ground", groundadd);
    if (groundadd.Groundname) {
      if (groundadd.Description) {
        if (groundadd.AddressInfo.Country) {
          if (groundadd.AddressInfo.State) {
            if (groundadd.AddressInfo.City) {
              if (groundadd.AddressInfo.AddressDetails) {
                let resp = await create_ground(groundadd);
                let id = resp.data ? resp.data.id : "";
                console.log("resp", resp);
                if (resp.code === 200) {
                  if (groundimg !== "" && id !== "") {
                    console.log("teamlogo", groundimg);
                    try {
                      const resp = await update_image(
                        id,
                        groundimg,
                        GROUND_IMG_TITLE
                      );
                      console.log(resp);
                      if (resp.code === 200) {
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  succespopup();
                  setMessage("Ground added...!!!");
                  toggleAddGroundPopup();
                } else {
                  validationpopupvisible();
                  setMessage(resp.message);
                }
              } else {
                validationpopupvisible();
                setMessage("Enter Ground Address");
              }
            } else {
              validationpopupvisible();
              setMessage("Enter Ground City");
            }
          } else {
            validationpopupvisible();
            setMessage("Enter Ground State");
          }
        } else {
          validationpopupvisible();
          setMessage("Enter Ground Country");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Ground Description");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Ground Name");
    }
  };

  const handleEditground = async () => {
    groundedit.id = groundid;

    groundaddress.id = groundaddressid;
    groundaddress.City = groundaddresscity;
    groundaddress.State = groundaddressstate;
    groundaddress.Country = groundaddresscountry;
    groundaddress.AddressDetails = groundaddressdetails;

    groundedit.Description = grounddescription;
    groundedit.Groundname = groundname;
    groundedit.AddressInfo = groundaddress;
    console.log(groundedit);
    if (groundedit.Groundname) {
      if (groundedit.Description) {
        if (groundedit.AddressInfo.Country) {
          if (groundedit.AddressInfo.State) {
            if (groundedit.AddressInfo.City) {
              if (groundedit.AddressInfo.AddressDetails) {
                let resp = await update_ground(groundedit);
                console.log("resp", resp);
                if (resp.code === 200) {
                  if (groundimg !== "") {
                    console.log("teamlogo", groundimg);
                    try {
                      const resp = await update_image(
                        groundid,
                        groundimg,
                        GROUND_IMG_TITLE
                      );
                      console.log(resp);
                      if (resp.code === 200) {
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  succespopup();
                  setMessage("Ground Updated...!!!");
                  toggleEditGroundPopup();
                } else {
                  validationpopupvisible();
                  setMessage(resp.message);
                }
              } else {
                validationpopupvisible();
                setMessage("Enter Ground Address");
              }
            } else {
              validationpopupvisible();
              setMessage("Enter Ground City");
            }
          } else {
            validationpopupvisible();
            setMessage("Enter Ground State");
          }
        } else {
          validationpopupvisible();
          setMessage("Enter Ground Country");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Ground Description");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Ground Name");
    }
  };

  const changeHandler = (event) => {
    const filteredData = grounds
      .filter((item) =>
        item.Groundname.toLowerCase().includes(event.target.value.toLowerCase())
      )
      .sort((a, b) => a.Groundname.localeCompare(b.Groundname));
    setGrounds1(filteredData);
  };

  const editgroundpopup = () => {
    return (
      <div className="overlay">
        <div className="ground_popup">
          <div className="pop_up_add_text_container_ground d-flex me-5">
            <span className="pop_up_add_text">Edit Ground</span>
            <input
              type="file"
              id="TeamLogoUrl"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="TeamLogoUrl"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Team Logo</div> */}
              {selectedgroundimg ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedgroundimg}
                    alt="Selected Image"
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/team_logo.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Ground Name"
              value={groundname}
              onChange={handleGroundname}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Description"
              value={grounddescription}
              onChange={handleDescription}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_details"
              type="text"
              placeholder="Country"
              value={groundaddresscountry}
              onChange={handleCountry}
            />
            <input
              className="ground_details"
              type="text"
              placeholder="State"
              value={groundaddressstate}
              onChange={handleState}
            />
            <input
              className="ground_details"
              type="text"
              placeholder="City"
              value={groundaddresscity}
              onChange={handleCity}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Address"
              value={groundaddressdetails}
              onChange={handleAddressinfo}
            />
          </div>
          <div className="popup_buttons">
            <button
              className="popup_button_cancel"
              onClick={toggleEditGroundPopup}
            >
              cancel
            </button>

            <button className="popup_button_save" onClick={handleEditground}>
              Save
            </button>
          </div>
          <button className="close-button" onClick={toggleEditGroundPopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      </div>
    );
  };

  const addgroundpopup = () => {
    return (
      <div className="overlay">
        <div className="ground_popup">
          <div className="pop_up_add_text_container_ground d-flex me-5">
            <span className="pop_up_add_text">Add Ground</span>
            <input
              type="file"
              id="TeamLogoUrl"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="TeamLogoUrl"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Team Logo</div> */}
              {selectedgroundimg ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedgroundimg}
                    alt="Selected Image"
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/team_logo.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Ground Name"
              onChange={handleGroundname}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Description"
              onChange={handleDescription}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_details"
              type="text"
              placeholder="Country"
              onChange={handleCountry}
            />
            <input
              className="ground_details"
              type="text"
              placeholder="State"
              onChange={handleState}
            />
            <input
              className="ground_details"
              type="text"
              placeholder="City"
              onChange={handleCity}
            />
          </div>
          <div className="ground_pop_up_inputs">
            <input
              className="ground_name"
              type="text"
              placeholder="Address"
              onChange={handleAddressinfo}
            />
          </div>
          <div className="popup_buttons">
            <button
              className="popup_button_cancel"
              onClick={toggleAddGroundPopup}
            >
              cancel
            </button>

            <button className="popup_button_save" onClick={handleAddground}>
              Create
            </button>
          </div>
          <button className="close-button" onClick={toggleAddGroundPopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      </div>
    );
  };

  return (
    <div className="container_ground">
      <div className="layout_conatiner">
        <div>
          <div className="btn_layout">
            <div className="search_content">
              <div>
                <span className="search_text">Ground List</span>
              </div>
              <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search"
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div>
              <button className="btn1" onClick={toggleAddGroundPopup}>
                Create
              </button>
            </div>
          </div>
          <div className="ground_table">
            {isLoading === false ? (
              <DataTable
                columns={columns}
                data={grounds1}
                customStyles={customtableStyles}
                fixedHeader
                theme="solarized"
                fixedHeaderScrollHeight="550px"
              ></DataTable>
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  marginTop: 100,
                }}
              >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
          <div></div>
        </div>
        {isGroundAddPopupOpen && addgroundpopup()}
        {isGroundEditPopupOpen && editgroundpopup()}
        {respsucces && validationpopup(succespopup)}
      </div>
    </div>
  );
}

export default Ground;

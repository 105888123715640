import { IS_LOGGED, USER_DATA, USER_ID } from "./localstorage_variables";

export const saveData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const getData = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const removeData = async () => {
  try {
    localStorage.removeItem(IS_LOGGED);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_DATA);
  } catch (error) {
    console.error("Error retrieving data:", error);
  }
};

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable, { createTheme } from "react-data-table-component";
import { tablecreateTheme } from "../assets/globalcolors";
import "../styles/playercreation.css";
import html2canvas from "html2canvas";
import Playeredit from "../componants/Playeredit";
import UserInfo from "../componants/UserInfo";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  EditIcon,
  DeleteIcon,
  DownloadIcon,
  AddIcon,
} from "../assets/SvgIcons";
import { ShowPlayerInfo } from "../componants/CommonFuncations";
import { useNavigate } from "react-router-dom";

import {
  get_playing_hand,
  get_gender,
  get_bowling_style,
  get_teams,
  get_players,
  create_player,
  update_player,
  get_player_type,
  update_image,
  get_districts,
  get_player_stats,
  get_player_stats_all,
  get_tournaments_type,
  get_user_documnets,
  update_user_doc,
  get_user_by_id,
  delete_player,
  get_country_states,
  get_state_districts,
  get_district_cities,
  approve_user,
} from "../api/serverWrapper";
import {
  conditionalRowStylesPlayer,
  customtableStyles,
  conditionalRowStyles,
} from "../assets/tablestyle";
import ValidationModal from "./ValidationModal";
import { PLAYER_IMG_TITLE } from "../utils/constants";
import jsPDF from "jspdf";
import UploadDocs from "./UploadDocs";
import UserSystemRole from "../componants/UserSystemRole";
import { getData } from "../utils/localstorage_constants";
import {
  IS_LOGGED,
  USER_DATA,
  USER_ID,
  USER_SYSTEM_ROLE,
  LOGIN_USER_DISTRICT_ID,
} from "../utils/localstorage_variables";
import { Call, SignalCellularNullRounded } from "@mui/icons-material";
import Address from "../componants/Address";
import AddTeamForPlayers from "./AddTeamForPlayers";
import ShowScoreTable from "./ShowScoreTable";

function Playercreation() {
  const navigate = useNavigate();
  let [superadmin, setSuperadmin] = useState(false);
  let [systemrole, setSystemRole] = useState();
  const [rowData, setRowData] = useState();
  const [rowData1, setRowData1] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [playinHand, setPlayingHand] = useState([]);
  const [gender, setGender] = useState([]);
  const [userDoc, setUserDoc] = useState([]);
  const [bowlingstyle, setBowlingStyle] = useState([]);
  const [teams, setTeams] = useState([]);
  const [playertypes, setPlayerTypes] = useState([]);
  const [playereditpopup, setPlayereditPopup] = useState(false);
  const [playereditdetails, setPlayereditDetails] = useState(null);
  let [isedit, setIsEdit] = useState(false);
  let [districts, setDistricts] = useState();
  const [tournamentType, settournamentType] = useState([]);
  const [playerStats, setPlayerStats] = useState([]);
  let playeredit = new Playeredit();
  let user = new UserInfo();
  let editaddress1 = new Address();
  let editaddress2 = new Address();

  let [states, setStates] = useState();
  let [statedistricts, setStatedistricts] = useState();
  let [districtcities, setDistrictCities] = useState();

  let [selectedstate, setSelectedState] = useState();
  let [selecteddist, setSelectedDistrict] = useState();
  let [selectedcity, setSelectedCity] = useState();
  let [zipCode, setZipCode] = useState(null);

  let [address1, setAddress1] = useState(new Address());
  let [address2, setAddress2] = useState(new Address());

  let [statedistricts2, setStatedistricts2] = useState();
  let [districtcities2, setDistrictCities2] = useState();
  let [selectedstate2, setSelectedState2] = useState();
  let [selecteddist2, setSelectedDistrict2] = useState();
  let [selectedcity2, setSelectedCity2] = useState();
  let [zipCode2, setZipCode2] = useState(null);

  const [playerdetails, setPlayerDetails] = useState({
    PlayerTypeId: null,
    RegistrationId: null,
    DistrictId: null,
    BattingStyleId: null,
    BowlingStyleId: null,
    BowlingHandId: 1,
    BattingHandId: 1,
    userInfo: {},
    TeamId: null,
    address1: {},
    address2: {},
  });

  const [userinfo, setUserInfo] = useState(new UserInfo());

  const toggleAddplayerPopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setPlayerDetails({
      PlayerTypeId: null,
      RegistrationId: null,
      DistrictId: null,
      BattingStyleId: null,
      BowlingStyleId: null,
      BowlingHandId: 1,
      BattingHandId: 1,
      userInfo: {},
      TeamId: null,
      address1: {},
      address2: {},
    });
    setUserInfo(new UserInfo());
    if (selectedPlayerImage) {
      setSelectedPlayerImage(null);
    }
    setSelectedAddressDoc(null);
    setSelectedAadharDoc(null);
    setSelectedDobDoc(null);
    setSelectedMarkSheetDoc(null);
    setSelectedPancardDoc(null);
  };
  let [offset, setOffset] = useState(0);

  const [userid, setUserId] = useState();
  const [userfirstname, setUserFirstName] = useState();
  const [userfathername, setUserFatherName] = useState();
  const [userlastname, setUserLastName] = useState();
  const [userdob, setUserDob] = useState();
  const [usergender, setUserGender] = useState();
  const [userphonenumber, setUserPhoneNumber] = useState();
  const [playerid, setPlayerId] = useState();
  const [playeridSelect, setPlayerIdSelect] = useState();
  const [playertypeid, setPlayerTypeId] = useState();
  const [playerbattingstyleid, setPlayerBattingStyleId] = useState();
  const [playerbowlingstyleid, setPlayerBowlingStyleId] = useState();
  const [playerbattinghandid, setPlayerBattingHandId] = useState();
  const [playerbowlinghandid, setPlayerBowlingHandId] = useState();
  const [playerteamid, setPlayerTeamId] = useState();
  const [usersystemrole, setUserSystemRole] = useState();
  const [playerregisterid, setPlayerRegisterId] = useState();
  const [playerdistrictid, setPlayerDistrictId] = useState();
  const [isValid, setIsValid] = useState(false);
  const [showdocpopup, setShowDocpopup] = useState(false);
  const [message, setMessage] = useState();
  const [respsucces, setRespSucces] = useState(false);
  const [allData, setAllData] = useState(null);
  let [playeraddress1, setPlayerAddress1] = useState();
  let [playerstate1, setPlayerState1] = useState();
  const [playerdistrict1, setPlayerDistrict1] = useState();
  const [playercity1, setPlayerCity1] = useState();
  let [playeraddress2, setPlayerAddress2] = useState();
  const [playerstate2, setPlayerState2] = useState();
  const [playerdistrict2, setPlayerDistrict2] = useState();
  const [playercity2, setPlayerCity2] = useState();
  const [updatePlayerImg, setUpdatePlayerImg] = useState();
  const [playerapprove, setPlayerApprove] = useState();

  let [userId, setId] = useState(null);
  let [admin, setAdmin] = useState();
  let [profileImg, setProfileImg] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalender1, setShowCalendar1] = useState(false);
  const [playerDob, setPlayerDob] = useState(null);
  const [matchStats, setMatchStats] = useState([]);
  const [loading, setLoading] = useState(false);
  let [districtid, setDistrictId] = useState(null);
  const [activeTab, setActiveTab] = useState("nonapproved");
  const [playerActiveTab, setPlayerActiveTab] = useState("playerinfo");
  let [approval, setApproval] = useState(0);
  let [isapprove, setIsApprove] = useState(false);
  let [deleteplayer, setDeletePlayer] = useState(null);
  let [deleteplayersuccess, setDeletePlayerSuccess] = useState(false);
  const [selectedAddressDoc, setSelectedAddressDoc] = useState(null);
  const [addressDoc, setAddressDoc] = useState("");
  const [toShow, setToShow] = useState();
  const [editaddressDoc, setEditAddressDoc] = useState(null);
  const [editdobDoc, setEditDobDoc] = useState(null);
  const [editmarksheetDoc, setEditMarkSheetDoc] = useState(null);
  const [editadharDoc, setEditAdharDoc] = useState(null);
  const [editpanDoc, setEditPanDoc] = useState(null);

  const showDocumnet = (type) => {
    showDocpopupVisible();
    if (type === "address") {
      setToShow(selectedAddressDoc);
    }
    if (type === "DOB") {
      setToShow(selectedDobDoc);
    }
    if (type === "marksheet") {
      setToShow(selectedMarkSheetDoc);
    }
    if (type === "adhar") {
      setToShow(selectedAadharDoc);
    }
    if (type === "pan") {
      setToShow(selectedPancardDoc);
    }
  };
  const showDocpopupVisible = () => {
    setShowDocpopup(!showdocpopup);
    console.log("showdocpopup", showdocpopup);
  };
  const showDoc = () => {
    console.log("toshow", toShow);
    return (
      <div className=" showdoc">
        <div className="  align-items-center justify-content-between">
          <button className="close-button" onClick={showDocpopupVisible}>
            X
          </button>
        </div>
        <iframe
          src={toShow}
          width="80%"
          height="80%"
          title="Selected PDF"
        ></iframe>{" "}
      </div>
    );
  };
  const handleAddressDocument = (e) => {
    console.log("file", e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        if (isedit) {
          setEditAddressDoc(e.target.files[0]);
        } else {
          setAddressDoc(e.target.files[0]);
        }
        setSelectedAddressDoc(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
  };
  const [selectedDobDoc, setSelectedDobDoc] = useState(null);
  const [dobDoc, setDobDoc] = useState("");
  const handleDobDocument = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        if (isedit) {
          setEditDobDoc(e.target.files[0]);
        } else {
          setDobDoc(e.target.files[0]);
        }
        setSelectedDobDoc(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
    console.log(document);
  };
  const [selectedMarkSheetDoc, setSelectedMarkSheetDoc] = useState(null);
  const [MarkSheetDoc, setMarkSheetDoc] = useState("");
  const handleMarkSheetDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        if (isedit) {
          setEditMarkSheetDoc(e.target.files[0]);
        } else {
          setMarkSheetDoc(e.target.files[0]);
        }
        setSelectedMarkSheetDoc(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
    console.log(document);
  };
  const [selectedAadharDoc, setSelectedAadharDoc] = useState(null);
  const [AadharDoc, setAadharDoc] = useState("");
  const handleAadharDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        if (isedit) {
          setEditAdharDoc(e.target.files[0]);
        } else {
          setAadharDoc(e.target.files[0]);
        }
        setSelectedAadharDoc(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
  };
  const [selectedPancardDoc, setSelectedPancardDoc] = useState(null);
  const [PanCardDoc, setPancardDoc] = useState("");
  const handlePancardDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        if (isedit) {
          setEditPanDoc(e.target.files[0]);
        } else {
          setPancardDoc(e.target.files[0]);
        }
        setSelectedPancardDoc(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
  };
  useEffect(() => {
    //var userData = localStorage.getItem("userData");
    //var data = JSON.parse(userData);
    // setId(data.UserId);
    setUserId(playereditdetails?.User.UserId);
    setUserFirstName(playereditdetails?.User.FirstName);
    setUserLastName(playereditdetails?.User.LastName);
    setUpdatePlayerImg(playereditdetails?.User.ProfilePhotoUrl);
    setUserSystemRole(playereditdetails?.User.SystemRole);
    setUserDob(playereditdetails?.User.DOB);
    setUserGender(playereditdetails?.User.Gender);
    setUserPhoneNumber(playereditdetails?.User.PhoneNumber);
    setUserFatherName(playereditdetails?.User.FatherName);

    setPlayerId(playereditdetails?.PlayerId);
    setPlayerRegisterId(playereditdetails?.RegistrationId);
    setPlayerDistrictId(playereditdetails?.DistrictId);
    setPlayerTypeId(playereditdetails?.PlayerType?.id);
    setPlayerBattingStyleId(playereditdetails?.BattingStyle?.id);
    setPlayerBowlingStyleId(playereditdetails?.BowlingStyle?.id);
    setPlayerBattingHandId(playereditdetails?.BowlingHand?.id);
    setPlayerBowlingHandId(playereditdetails?.BowlingHand?.id);
    setPlayerTeamId(playereditdetails?.sdm_teams[0]?.id);

    setPlayerAddress2(playereditdetails?.User.Address2?.AddressDetails);
    setPlayerState2(playereditdetails?.User.Address2?.StateId);
    setPlayerDistrict2(playereditdetails?.User.Address2?.DistrictId);
    setPlayerCity2(playereditdetails?.User.Address2?.CityId);
    setPlayerAddress1(playereditdetails?.User.Address1?.AddressDetails);
    setPlayerState1(playereditdetails?.User.Address1?.StateId);
    setPlayerDistrict1(playereditdetails?.User.Address1?.DistrictId);
    setPlayerCity1(playereditdetails?.User.Address1?.CityId);
    setPlayerApprove(playereditdetails?.User.IsApproved);
    // console.log(
    //   "editAddressDetails r",
    //   statedistricts,
    //   districtcities,
    //   playerdistrict1
    // );
    // console.log(
    //   "editAddressDetails2 r",
    //   statedistricts2,
    //   districtcities2,
    //   playerdistrict2
    // );
    editAddressDetails();
  }, [
    playereditdetails,
    profileImg,
    // dobDoc,
    // addressDoc,
    // AadharDoc,
    // PanCardDoc,
    // MarkSheetDoc,
  ]);

  const editAddressDetails = async () => {
    if (
      playereditdetails?.User.Address1?.StateId &&
      playereditdetails?.User.Address1?.DistrictId
    ) {
      await editAddressDetails1();
    }
    if (
      playereditdetails?.User.Address2?.StateId &&
      playereditdetails?.User.Address2?.DistrictId
    ) {
      await editAddressDetails2();
    }
    if (playereditdetails?.User.UserId) {
      await playerDocuments();
    }
    // console.log(
    //   "editAddressDetails",
    //   statedistricts,
    //   districtcities,
    //   playerdistrict1
    // );
    // console.log(
    //   "editAddressDetails2",
    //   statedistricts2,
    //   districtcities2,
    //   playerdistrict2
    // );
  };

  const editAddressDetails1 = async () => {
    let dist = await get_state_districts(
      playereditdetails?.User.Address1.StateId
    );
    statedistricts = dist.data;
    setStatedistricts(statedistricts);
    let city = await get_district_cities(
      playereditdetails?.User.Address1.StateId,
      playereditdetails?.User.Address1.DistrictId
    );
    districtcities = city.data;
    setDistrictCities(districtcities);
    // console.log("selectedcity1", selectedcity, districtcities);
    const selectedCityData = districtcities.find(
      (city) => city.id === playereditdetails?.User.Address1?.CityId
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData.Pin !== null) {
      zipCode = selectedCityData.Pin;
      setZipCode(zipCode);
    } else {
      zipCode = "";
      setZipCode(zipCode);
    }
  };

  const playerDocuments = async () => {
    let doc = await get_user_documnets(playereditdetails?.User.UserId);

    let docarray = convertDataToArray(doc.data);
    console.log("doc", docarray);
    for (let i = 0; i < docarray.length; i++) {
      if (docarray[i].name === "Aadhar_Card") {
        setAadharDoc(docarray[i]);
        setSelectedAadharDoc(docarray[i].fileUrl);
      }
      if (docarray[i].name === "Address_Doc") {
        setAddressDoc(docarray[i]);
        setSelectedAddressDoc(docarray[i].fileUrl);
      }
      if (docarray[i].name === "Dob_Doc") {
        setDobDoc(docarray[i]);
        setSelectedDobDoc(docarray[i].fileUrl);
      }
      if (docarray[i].name === "Marks_Sheet") {
        setMarkSheetDoc(docarray[i]);
        setSelectedMarkSheetDoc(docarray[i].fileUrl);
      }
      if (docarray[i].name === "Pan_card") {
        setPancardDoc(docarray[i]);
        setSelectedPancardDoc(docarray[i].fileUrl);
      }
    }
  };

  const editAddressDetails2 = async () => {
    let dist = await get_state_districts(
      playereditdetails?.User.Address2.StateId
    );
    statedistricts2 = dist.data;
    setStatedistricts2(statedistricts2);
    let city = await get_district_cities(
      playereditdetails?.User.Address2.StateId,
      playereditdetails?.User.Address2.DistrictId
    );
    districtcities2 = city.data;
    setDistrictCities2(districtcities2);
    const selectedCityData = districtcities2.find(
      (city) => city.id === playereditdetails?.User.Address2?.CityId
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData.Pin !== null) {
      zipCode2 = selectedCityData.Pin;
      setZipCode2(zipCode2);
    } else {
      zipCode2 = "";
      setZipCode2(zipCode2);
    }
  };

  let is_api_data_loaded = false;

  const get_api_data = async () => {
    setRowData1(null);
    let state = await get_country_states();
    states = state.data;
    setStates(states);
    let user = await get_user_by_id(userId);
    admin = user.data;
    console.log("admin", admin);
    setAdmin(admin);
    let district = await get_districts();
    districts = district.data;
    setDistricts(districts);
    var gender = await get_gender();
    setGender(gender.data);

    var teams = await get_teams();
    setTeams(teams.data.teams);
    console.log("teams.data.teams", teams.data.teams);
    var players = await get_players(offset, null, districtid);
    setRowData1(players.data.players);
    console.log("playersdata", players.data.players);
    console.log("payers", players.data.players.length);
    setRowData(players.data.players);

    // offset = players.data.players.length;
    // setOffset(offset);
    var playinghand = await get_playing_hand();
    setPlayingHand(playinghand.data);

    var bowlingstyle = await get_bowling_style();
    setBowlingStyle(bowlingstyle.data);

    var playertypes = await get_player_type();
    setPlayerTypes(playertypes.data);

    let tournamentTyperesp = await get_tournaments_type();
    settournamentType(tournamentTyperesp.data.tournaments);
  };

  useEffect(() => {
    if (is_api_data_loaded) {
      return;
    }
    let userid = getData(USER_ID);
    userId = userid;
    setId(userId);
    is_api_data_loaded = true;

    let districtId = getData(LOGIN_USER_DISTRICT_ID);
    let systemRole = getData(USER_SYSTEM_ROLE);
    systemrole = systemRole;
    setSystemRole(systemrole);
    if (systemRole === UserSystemRole.ADMIN.id) {
      setSuperadmin(true);
    } else if (systemRole === UserSystemRole.DISTRICT_ADMIN.id) {
      districtid = districtId;
      setDistrictId(districtid);
      setSuperadmin(false);
    } else {
      setSuperadmin(false);
    }

    get_api_data();
    // if (superadmin) {
    //   columnsplayer.splice(1, 0, {
    //     name: "Approval",
    //     selector: (row) =>
    //       row?.User.IsApproved ? "Approved" : <Approval row={row} />,
    //     center: true,
    //   });
    // }
  }, []);

  const convertDataToArray = (data) => {
    return Object.keys(data).map((key) => ({
      name: key,
      fileUrl: data[key].fileUrl,
    }));
  };

  const getUserDoc = async (id) => {
    let docData = await get_user_documnets(id);
    console.log("docData", docData);

    let d = convertDataToArray(docData.data);
    setUserDoc(d);
  };

  const toggeleditPlayerpopup = async (row) => {
    // console.log(row);
    if (playereditdetails) {
      isedit = false;
      setIsEdit(isedit);
      setPlayereditDetails(null);
      setAddress1(new Address());
      setAddress2(new Address());
      setEditAddressDoc(null);
      setEditAdharDoc(null);
      setEditDobDoc(null);
      setEditMarkSheetDoc(null);
      setEditPanDoc(null);
    } else {
      console.log("row", row);
      isedit = true;
      setIsEdit(isedit);
      setPlayereditDetails(row);
    }
    setPlayereditPopup(!playereditpopup);

    if (selectedPlayerImage) {
      setSelectedPlayerImage(null);
    }
  };

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const PlayerNameCell = ({ row }) => {
    const firstNameLetter = row.User.FirstName
      ? row.User.FirstName.charAt(0)
      : "";
    const lastNameLetter = row.User.LastName ? row.User.LastName.charAt(0) : "";
    return (
      <div
        onClick={() => handlePlayer(row)}
        className="batsman_bowler_container"
      >
        {row.User.ProfilePhotoUrl ? (
          <img
            className="batsman_bowler_img"
            src={row.User.ProfilePhotoUrl}
            alt=""
          />
        ) : (
          <div className="name_cell">
            {firstNameLetter}
            {lastNameLetter}
          </div>
        )}
        <div>
          {row.User.FirstName +
            "   " +
            (row.User.LastName ? row.User.LastName : "")}
        </div>{" "}
      </div>
    );
  };

  const Teamname = ({ row }) => {
    const teamname = teams.find(
      (item) => row.sdm_teams[0]?.sdm_team_players.TeamId === item.id
    );
    if (teamname) return teamname.TeamName;
    else return "-";
  };

  const handleApprovepopup = (row) => {
    if (playereditdetails) {
      setPlayereditDetails(null);
    } else {
      setPlayereditDetails(row);
    }
    isapprove = !isapprove;
    setIsApprove(isapprove);
  };

  const handlePlayerApprove = async (type) => {
    let user = { userId: userid, approveState: type };
    let resp = await approve_user(user);
    if (resp.success) {
      if (type === 1) {
        setMessage("Player is Approved...!!!");
      }
      if (type === 2) {
        setMessage("Player is Rejected...!!!");
      }
    }
    handleApprovepopup();
    succespopup();
  };

  const handleApprovePlayer = () => {
    return (
      <div className="overlay">
        <div className="approval_popup">
          <div
            className="title2 d-flex justify-content-end me-4 "
            style={{ cursor: "pointer" }}
            onClick={handleApprovepopup}
          >
            X
          </div>
          <div className="pop_up_add_text_container d-flex flex-column align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">
              Are you sure to approve player..!
            </span>
            <div>
              <button
                className="popup_button_save"
                onClick={() => handlePlayerApprove(1)}
              >
                Approve
              </button>
              <button
                className="popup_button_cancel"
                onClick={() => handlePlayerApprove(2)}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteplayerpopup = () => {
    return (
      <div className="overlay">
        <div className="approval_popup">
          <div className="pop_up_add_text_container d-flex flex-column align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">
              Are you sure to delete player..!
            </span>
            <div>
              <button
                className="popup_button_save"
                onClick={deleteplayerpopupvisible}
              >
                Cancel
              </button>
              <button
                className="popup_button_cancel"
                onClick={() => deletePlayer(deleteplayer)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteSuccess = () => {
    return (
      <div className="overlay">
        <div className="approval_popup">
          <div className="pop_up_add_text_container d-flex flex-column align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">
              Player deleted succesfully..
            </span>
            <div>
              <button
                className="popup_button_save"
                onClick={() => deletesuccesspopup()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Approval = ({ row }) => {
    if (row?.User.IsApproved === 1) {
      return "Approved";
    } else if (row?.User.IsApproved === 2) {
      return "Rejected";
    } else {
      return (
        <div className="approve_btn" onClick={() => handleApprovepopup(row)}>
          <span>Approve/Reject</span>
        </div>
      );
    }
  };
  const conditionalRowStylesdis = [
    {
      when: (row) => row.id === districtid,
      style: {
        color: "red",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const columnsdist = [
    {
      name: "Districts",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },
  ];

  const columnsplayer = [
    {
      name: "Registration Id",
      selector: (row) => (row.RegistrationId ? row.RegistrationId : "-"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Name",
      selector: (row) => <PlayerNameCell row={row} />,
      sortable: true,
      width: "300px",
    },
    systemrole === UserSystemRole.ADMIN.id && {
      name: "Approval",
      width: "200px",

      selector: (row) => <Approval row={row} />,
      center: true,
    },
    {
      name: "Father Name",
      selector: (row) => row.User.FatherName,
      sortable: true,
      width: "250px",
    },
    {
      name: "DOB",
      selector: (row) => row.User.DOB,
      width: "150px",
      center: true,
    },
    {
      name: "Gender",
      selector: (row) =>
        row.User.Gender ? gender[row.User.Gender - 1].Gender : "-",
      sortable: true,
      width: "110px",
    },

    // {
    //   name: "Team",
    //   selector: (row) => <Teamname row={row} />,
    //   sortable: true,
    //   width: "220px",
    // },

    {
      name: "Batting Style",
      selector: (row) => (row.BattingStyle ? row.BattingStyle.hand : "-"),
      width: "150px",
      sortable: true,
    },
    {
      name: "Bowling Style",
      selector: (row) =>
        row.BowlingStyle ? row.BowlingStyle.BowlingStyle : "-",
      sortable: true,
      width: "190px",
    },
    {
      name: "Player Type",
      selector: (row) => row.PlayerType.PlayerType,
      width: "150px",
      sortable: true,
    },
    {
      name: "Edit",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px", marginRight: "25px" }}
          src={EditIcon}
          onClick={() => toggeleditPlayerpopup(row)}
        />
      ),
      width: "80px",
      headerStyle: () => {
        return {
          textAlign: "right",
        };
      },
    },
    {
      name: "Delete",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px" }}
          src={DeleteIcon}
          onClick={() => deleteplayerpopupvisible(row)}
        />
      ),
      width: "80px",
    },
    // {
    //   name: "Add Team",
    //   selector: (row) => (
    //     <img
    //       style={{ height: "25px", width: "25px" }}
    //       src={AddIcon}
    //       onClick={() => handelAddTeam(row)}
    //     />
    //   ),
    //   center: true,
    //   width: "120px",
    // },
  ];

  const deleteplayerpopupvisible = (row) => {
    if (deleteplayer) {
      deleteplayer = null;
      setDeletePlayer(deleteplayer);
    } else {
      deleteplayer = row;
      setDeletePlayer(deleteplayer);
    }
  };

  const [isAddTeamForPlayers, setISAddTeamForPlayers] = useState(false);

  const handelAddTeam = () => {
    setISAddTeamForPlayers(true);
  };
  const closeAddTeam = () => {
    setISAddTeamForPlayers(false);
  };

  const deletePlayer = async (player) => {
    console.log("player delete", player);
    let resp = await delete_player(player?.PlayerId);
    console.log("resp delete", resp);
    if (resp.code === 200) {
      deleteplayersuccess = true;
      setDeletePlayerSuccess(deleteplayersuccess);
    }
    deleteplayerpopupvisible(player);
  };

  const deletesuccesspopup = () => {
    deleteplayersuccess = false;
    setDeletePlayerSuccess(deleteplayersuccess);
    window.location.reload();
  };

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      window.location.reload();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };
  // const handleDistrictChange = (event) => {
  //   const value = event.target.value === "" ? null : event.target.value;
  //   setDistrictId(value);
  // };

  const hanbleTabClick = (tab, isapproved) => {
    setActiveTab(tab);
    setApproval(isapproved);
    // localStorage.setItem("activeTab", tab);
  };

  const handleplayerTabs = (tab) => {
    setPlayerActiveTab(tab);
  };

  const clicktabplayerdoc = () => {
    handleplayerTabs("Player Docs");
    handlePlayerDoc();
  };

  const handleDistrictChange = async (row) => {
    if (row.id !== "") {
      districtid = row.id;
    } else {
      districtid = null;
    }
    setDistrictId(districtid);

    await get_api_data();
  };
  const handleSearch = async (event) => {
    console.log(event.target.value);
    offset = 0;
    setOffset(offset);
    var players = await get_players(offset, event.target.value, districtid);
    setRowData1(players.data.players);
    console.log("payers", players.data.players.length);
    setRowData(players.data.players);
    // const filteredData = rowData
    //   .filter((item) =>
    //     item.User.FirstName.toLowerCase().includes(
    //       event.target.value.toLowerCase()
    //     )
    //   )
    //   .sort((a, b) => a.User.FirstName.localeCompare(b.User.FirstName));
    // setRowData1(filteredData);
  };

  const handleDob = (date) => {
    setPlayerDob(date);
    let dateformat = formatDate(date);

    console.log(dateformat);
    setUserInfo({
      ...userinfo,
      ["DOB"]: dateformat,
    });
    setShowCalendar(false);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const removeSelectedDocument = () => {
    setSelectedAddressDoc(null);
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userinfo,
      [name]: value,
    });
    setPlayerDetails({
      ...playerdetails,
      ["userInfo"]: userinfo,
    });
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setPlayerDetails({
      ...playerdetails,
      [name]: value,
      ["userInfo"]: userinfo,
    });
  };
  const handleStateChange1 = async (e) => {
    selectedstate = e.target.value;
    setSelectedState(selectedstate);
    let dist = await get_state_districts(selectedstate);
    statedistricts = dist.data;
    setStatedistricts(statedistricts);
    address1.StateId = selectedstate;
    setAddress1(address1);
    setPlayerDetails({
      ...playerdetails,
      ["address1"]: address1,
    });
  };

  const handlestateDistrictChange1 = async (e) => {
    selecteddist = e.target.value;
    setSelectedDistrict(selecteddist);
    let city = await get_district_cities(selectedstate, selecteddist);
    districtcities = city.data;
    setDistrictCities(districtcities);
    address1.DistrictId = selecteddist;
    setAddress1(address1);
    setPlayerDetails({
      ...playerdetails,
      ["address1"]: address1,
    });
  };

  const handleCityChange1 = async (e) => {
    selectedcity = e.target.value;
    setSelectedCity(selectedcity);
    console.log(selectedcity);
    address1.CityId = selectedcity;
    setAddress1(address1);

    // console.log("selectedcity", selectedcity);
    const selectedCityData = districtcities.find(
      (city) => String(city.id) === selectedcity
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      zipCode = selectedCityData.Pin;
      setZipCode(zipCode); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode = "";
      setZipCode(zipCode);
    }
    setPlayerDetails({
      ...playerdetails,
      ["address1"]: address1,
    });
  };
  const handleAddress1 = async (e) => {
    address1.AddressDetails = e.target.value;
    setAddress1(address1);
    console.log("address1", address1);
    setPlayerDetails({
      ...playerdetails,
      ["address1"]: address1,
    });
  };

  const handleAddress2 = async (e) => {
    address2.AddressDetails = e.target.value;
    setAddress2(address2);
    setPlayerDetails({
      ...playerdetails,
      ["address2"]: address2,
    });
  };

  const handleStateChange2 = async (e) => {
    selectedstate2 = e.target.value;
    setSelectedState2(selectedstate2);
    let dist = await get_state_districts(selectedstate2);
    statedistricts2 = dist.data;
    setStatedistricts2(statedistricts2);
    address2.StateId = selectedstate2;
    setAddress2(address2);
    setPlayerDetails({
      ...playerdetails,
      ["address2"]: address2,
    });
  };

  const handlestateDistrictChange2 = async (e) => {
    selecteddist2 = e.target.value;
    setSelectedDistrict2(selecteddist2);
    let city = await get_district_cities(selectedstate2, selecteddist2);
    districtcities2 = city.data;
    setDistrictCities2(districtcities2);
    address2.DistrictId = selectedstate2;
    setAddress2(address2);
    setPlayerDetails({
      ...playerdetails,
      ["address2"]: address2,
    });
  };

  const handleCityChange2 = async (e) => {
    selectedcity2 = e.target.value;
    setSelectedCity2(selectedcity2);
    address2.CityId = selectedstate2;
    setAddress2(address2);

    // console.log(id, name);
    const selectedCityData = districtcities2.find(
      (city) => String(city.id) === selectedcity2
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      setZipCode2(selectedCityData.Pin); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode2 = "";
      setZipCode2(zipCode2);
    }
    setPlayerDetails({
      ...playerdetails,
      ["address2"]: address2,
    });
  };

  const handleeditChangeAddress1 = async (e) => {
    playeraddress1 = e.target.value;
    setPlayerAddress1(playeraddress1);
  };

  const handleeditStateChange1 = async (e) => {
    setPlayerState1(e.target.value);
    let dist = await get_state_districts(e.target.value);
    statedistricts = dist.data;
    setStatedistricts(statedistricts);
  };

  const handleeditstateDistrictChange1 = async (e) => {
    setPlayerDistrict1(e.target.value);
    let city = await get_district_cities(playerstate1, e.target.value);
    districtcities = city.data;
    setDistrictCities(districtcities);
  };

  const handleeditCityChange1 = async (e) => {
    setPlayerCity1(e.target.value);
    // console.log("selectedcity", selectedcity);
    const selectedCityData = districtcities.find(
      (city) => String(city.id) === playercity1
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      zipCode = selectedCityData.Pin;
      setZipCode(zipCode); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode = "";
      setZipCode(zipCode);
    }
  };
  const handleeditChangeAddress2 = async (e) => {
    playeraddress2 = e.target.value;
    setPlayerAddress2(playeraddress2);
  };

  const handleeditStateChange2 = async (e) => {
    setPlayerState2(e.target.value);
    let dist = await get_state_districts(e.target.value);
    statedistricts2 = dist.data;
    setStatedistricts2(statedistricts2);
  };
  const handleeditstateDistrictChange2 = async (e) => {
    setPlayerDistrict2(e.target.value);
    let city = await get_district_cities(playerstate2, e.target.value);
    districtcities2 = city.data;
    setDistrictCities2(districtcities2);
  };
  const handleeditCityChange2 = async (e) => {
    setPlayerCity2(e.target.value);
    const selectedCityData = districtcities2.find(
      (city) => String(city.id) === playercity2
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      zipCode2 = selectedCityData.Pin;
      setZipCode2(zipCode2); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode2 = "";
      setZipCode2(zipCode2);
    }
  };

  const playeradd = async () => {
    // if (
    //   playerdetails.address2.StateId === null &&
    //   playerdetails.address2.AddressDetails === null &&
    //   playerdetails.address2.CityId === null &&
    //   playerdetails.address2.DistrictId === null
    // ) {
    //   delete playerdetails.address2;
    // }
    delete user.EMail;
    delete user.Password;
    delete user.KycDataId;
    delete user.DistrictId;
    delete user.DesignationId;
    if (userinfo.KycDataId === null) {
      delete userinfo.KycDataId;
    }
    if (userinfo.Password === null) {
      delete userinfo.Password;
    }
    if (userinfo.EMail === null) {
      delete userinfo.EMail;
    }
    playerdetails.userInfo.SystemRole = UserSystemRole.MEMBER.id;
    if (playerdetails.DistrictId) {
      if (playerdetails.userInfo.FirstName) {
        if (playerdetails.userInfo.Gender) {
          if (addressDoc) {
            if (dobDoc) {
              if (MarkSheetDoc) {
                if (AadharDoc) {
                  if (playerdetails?.address1?.AddressDetails) {
                    if (playerdetails.address1.StateId) {
                      if (playerdetails.address1.DistrictId) {
                        if (playerdetails.address1.CityId) {
                          if (playerdetails.PlayerTypeId) {
                            // if (playerdetails.BattingStyleId) {
                            //   if (playerdetails.BowlingStyleId) {
                            // if (playerdetails.TeamId) {
                            if (playerdetails.BattingStyleId === null) {
                              delete playerdetails.BattingStyleId;
                            }
                            if (playerdetails.BowlingStyleId === null) {
                              delete playerdetails.BowlingStyleId;
                            }
                            let resp = await create_player(playerdetails);
                            console.log("resp", resp);
                            let id = "";
                            if (resp.code === 200) {
                              id = resp.data ? resp.data.UserId : "";
                              setMessage("Player Created...!!!");
                              if (profileImg !== "" && id !== "") {
                                console.log(profileImg);
                                try {
                                  const resp = await update_image(
                                    id,
                                    profileImg,
                                    PLAYER_IMG_TITLE
                                  );
                                  console.log(resp);
                                  if (resp.code === 200) {
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                              let addressdoc = await update_user_doc(
                                id,
                                addressDoc,
                                "Address_Doc"
                              );
                              console.log(addressdoc);

                              let dobdoc = await update_user_doc(
                                id,
                                dobDoc,
                                "Dob_Doc"
                              );
                              console.log(dobdoc);

                              let marksheet = await update_user_doc(
                                id,
                                MarkSheetDoc,
                                "Marks_Sheet"
                              );
                              console.log(marksheet);

                              let aadhar = await update_user_doc(
                                id,
                                AadharDoc,
                                "Aadhar_Card"
                              );
                              console.log(aadhar);
                              if (PanCardDoc) {
                                let pancard = await update_user_doc(
                                  id,
                                  PanCardDoc,
                                  "Pan_card"
                                );
                                console.log(pancard);
                              }

                              succespopup();
                              setIsPopupOpen(!isPopupOpen);
                            } else {
                              validationpopupvisible();
                              setMessage(
                                resp.error.message + "fill all the Details"
                              );
                            }
                            // } else {
                            //   validationpopupvisible();
                            //   setMessage("Choose Player Team");
                            // }
                            // } else {
                            //   validationpopupvisible();
                            //   setMessage("Choose Player Bowling Style");
                            // }
                            // } else {
                            //   validationpopupvisible();
                            //   setMessage("Choose Player Batting Style");
                            // }
                          } else {
                            validationpopupvisible();
                            setMessage("Choose Player Type");
                          }
                        } else {
                          validationpopupvisible();
                          setMessage("Select City");
                        }
                      } else {
                        validationpopupvisible();
                        setMessage("Select District");
                      }
                    } else {
                      validationpopupvisible();
                      setMessage("Select State ");
                    }
                  } else {
                    validationpopupvisible();
                    setMessage("Enter Address1");
                  }
                } else {
                  validationpopupvisible();
                  setMessage("Upload Adhar card Document ");
                }
              } else {
                validationpopupvisible();
                setMessage("Upload Marksheet Certificate ");
              }
            } else {
              validationpopupvisible();
              setMessage("Upload Birth Certificate ");
            }
          } else {
            validationpopupvisible();
            setMessage("Upload Address Document ");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Player Gender ");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Player First Name");
      }
    } else {
      validationpopupvisible();
      setMessage("Select District Association");
    }
  };
  const handleinputadd = async () => {
    if (!superadmin) {
      setPlayerDetails({
        ...playerdetails,
        ["DistrictId"]: districtid,
      });
    }
    setPlayerDetails({
      ...playerdetails,
      ["address2"]: address2,

      ["address1"]: address1,
      ["userInfo"]: userinfo,
    });

    console.log("playerdetails", playerdetails);

    if (playerdetails.address2.AddressDetails) {
      if (playerdetails.address2.StateId) {
        if (playerdetails.address2.DistrictId) {
          if (playerdetails.address2.CityId) {
            await playeradd();
          } else {
            validationpopupvisible();
            setMessage("Select Secondary City ");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Secondary District ");
        }
      } else {
        validationpopupvisible();
        setMessage("Select Secondary State ");
      }
    } else {
      delete playerdetails.address2;
      await playeradd();
    }
  };

  let [selectedPlayerImage, setSelectedPlayerImage] = useState(null);

  const handlePalyerImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImg(e.target.files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedPlayerImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("profileImg", profileImg);
  };

  const [selectedPlayerDocument, setSelectedPlayerDocument] = useState(null);

  // const handlePlayerDocument = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type === "application/pdf") {
  //     setSelectedPlayerDocument(URL.createObjectURL(file));
  //   } else {
  //     alert("Please select a PDF file.");
  //   }
  // };

  const removeSelectedImage = () => {
    setSelectedPlayerImage(null);
  };

  const handleeditfirstnamechange = (e) => {
    const { value } = e.target;
    setUserFirstName(value);
  };
  const handleeditlastnamechange = (e) => {
    const { value } = e.target;
    setUserLastName(value);
  };
  const handleeditfathernamechange = (e) => {
    const { value } = e.target;
    setUserFatherName(value);
  };
  const handleeditdobchange = (date) => {
    let dateformat = formatDate(date);

    console.log(dateformat);
    setUserDob(dateformat);
    setShowCalendar1(false);
    console.log(user);
  };
  const handleeditphonechange = (e) => {
    const { value } = e.target;
    setUserPhoneNumber(value);
  };
  const handleeditgenderchange = (e) => {
    const { value } = e.target;
    setUserGender(value);
  };
  const handleeditplayertypechange = (e) => {
    const { value } = e.target;
    setPlayerTypeId(value);
  };
  const handleeditregistrationId = (e) => {
    const { value } = e.target;
    setPlayerRegisterId(value);
  };
  const handleeditDistrict = (e) => {
    const { value } = e.target;
    setPlayerDistrictId(value);
  };
  const handleeditbattingstylechange = (e) => {
    const { value } = e.target;
    setPlayerBattingStyleId(value);
  };

  const handleeditbowlingstylechange = (e) => {
    const { value } = e.target;
    setPlayerBowlingStyleId(value);
  };

  const handleeditbowlinghandechange = (e) => {
    const { value } = e.target;
    setPlayerBowlingHandId(value);
  };

  const handleeditteamchange = (e) => {
    const { value } = e.target;
    setPlayerTeamId(value);
  };

  const playereditinfo = async () => {
    if (playeredit.userInfo.FirstName) {
      if (playeredit.userInfo.Gender) {
        if (playeredit.PlayerTypeId) {
          // if (playeredit.BattingStyleId) {
          //   if (playeredit.BowlingStyleId) {
          // if (playeredit.TeamId) {
          let resp = await update_player(playeredit);
          console.log("resp", resp);
          if (resp.code === 200) {
            if (profileImg !== "") {
              console.log(profileImg);
              try {
                const resp = await update_image(
                  user.UserId,
                  profileImg,
                  PLAYER_IMG_TITLE
                );
                console.log(resp);
                if (resp.code === 200) {
                }
              } catch (error) {
                console.error(error);
              }
            }
            if (editaddressDoc) {
              let addressdoc = await update_user_doc(
                userid,
                editaddressDoc,
                "Address_Doc"
              );
              console.log(addressdoc);
            }
            if (editdobDoc) {
              let dobdoc = await update_user_doc(userid, editdobDoc, "Dob_Doc");
              console.log(dobdoc);
            }
            if (editmarksheetDoc) {
              let marksheet = await update_user_doc(
                userid,
                editmarksheetDoc,
                "Marks_Sheet"
              );
              console.log(marksheet);
            }
            if (editadharDoc) {
              let aadhar = await update_user_doc(
                userid,
                editadharDoc,
                "Aadhar_Card"
              );
              console.log(aadhar);
            }
            if (editpanDoc) {
              let pancard = await update_user_doc(
                userid,
                editpanDoc,
                "Pan_card"
              );
              console.log(pancard);
            }
            succespopup();
            if (isapprove === false) {
              setMessage("Player Details Updated...!!!");
              toggeleditPlayerpopup();
            }
            if (isapprove) {
              setMessage("Player is Approved...!!!");
              handleApprovepopup();
            }
          } else {
            validationpopupvisible();
            setMessage(resp.message);
          }
          // } else {
          //   validationpopupvisible();
          //   setMessage("Choose Player Team");
          // }
          //   } else {
          //     validationpopupvisible();
          //     setMessage("Choose Player Bowling Style");
          //   }
          // } else {
          //   validationpopupvisible();
          //   setMessage("Choose Player Batting Style");
          // }
        } else {
          validationpopupvisible();
          setMessage("Choose Player Type");
        }
      } else {
        validationpopupvisible();
        setMessage("Select Player Gender ");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Player First Name");
    }
  };

  const handleinputedit = async () => {
    delete user.EMail;
    delete user.Password;
    delete user.KycDataId;
    // delete user.DistrictId;
    delete user.DesignationId;

    user.UserId = userid;
    user.FirstName = userfirstname;
    user.LastName = userlastname;
    user.DOB = userdob;
    user.Gender = usergender;
    user.PhoneNumber = userphonenumber;
    user.FatherName = userfathername;
    user.SystemRole = usersystemrole;
    if (isapprove) {
      user.IsApproved = 1;
    }
    if (playerapprove) {
      user.IsApproved = playerapprove;
    }
    editaddress1.AddressDetails = playeraddress1;
    editaddress1.StateId = playerstate1;
    editaddress1.DistrictId = playerdistrict1;
    editaddress1.CityId = playercity1;
    editaddress2.AddressDetails = playeraddress2;
    editaddress2.StateId = playerstate2;
    editaddress2.DistrictId = playerdistrict2;
    editaddress2.CityId = playercity2;

    playeredit.PlayerId = playerid;
    playeredit.PlayerTypeId = playertypeid;
    playeredit.RegistrationId = playerregisterid;
    playeredit.BattingStyleId = playerbattingstyleid;
    playeredit.BowlingStyleId = playerbowlingstyleid;
    playeredit.BowlingHandId = playerbowlinghandid;
    playeredit.BattingHandId = playerbattinghandid;
    playeredit.DistrictId = playerdistrictid;
    playeredit.userInfo = user;
    playeredit.TeamId = playerteamid;
    playeredit.address1 = editaddress1;
    playeredit.address2 = editaddress2;
    if (playeraddress2) {
      if (playerstate2) {
        if (playerdistrict2) {
          if (playercity2) {
            await playereditinfo();
          } else {
            validationpopupvisible();
            setMessage("Select Secondary City ");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Secondary District ");
        }
      } else {
        validationpopupvisible();
        setMessage("Select Secondary State ");
      }
    } else {
      delete playeredit.address2;
      await playereditinfo();
    }
  };

  const editplayerpopup = () => {
    return (
      <div className="overlay">
        <div className="popup_player">
          <div className="pop_up_add_text_container d-flex align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">Edit Player</span>
            <div className="d-flex align-items-center">
              <input
                type="file"
                id="plyImg"
                accept="image/*"
                onChange={handlePalyerImg}
                style={{ display: "none" }}
              />
              <label
                htmlFor="plyImg"
                className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
              >
                {selectedPlayerImage ? (
                  <div className="d-flex gap-2 align-items-center close-doc">
                    <img
                      height={44}
                      width={44}
                      src={selectedPlayerImage}
                      alt="Selected Image"
                      className="profile-img"
                      style={{ borderRadius: 18 }}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <img
                      src={
                        updatePlayerImg
                          ? updatePlayerImg
                          : require("../assets/images/profile-icon.png")
                      }
                      alt=""
                      height={44}
                      width={44}
                      style={{ borderRadius: 20 }}
                      className="profile-img"
                    />
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="RegistrationId"
              placeholder="BCCI Registeration Id"
              value={playerregisterid}
              onChange={handleeditregistrationId}
            />
            <input
              className="player_details_select"
              type="text"
              name="RegistrationId"
              placeholder="Registeration Id"
              // value={playerregisterid}
              // onChange={handleeditregistrationId}
            />
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="status"
              placeholder="Select Registeration type"
              onChange={handleInputChange1}
            />
            {superadmin ? (
              <select
                className="player_details_select"
                name="DistrictId"
                value={playerdistrictid}
                onChange={handleeditDistrict}
              >
                <option>Choose District</option>
                {Array.isArray(districts) &&
                  districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district?.Name}
                    </option>
                  ))}
              </select>
            ) : (
              <span className="player_details_select">
                {getDistrictAdminDistrict(playerdistrictid)}
              </span>
            )}
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="FirstName"
              placeholder="First Name"
              value={userfirstname}
              onChange={handleeditfirstnamechange}
            />
            <div className="d-flex align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="user_doc"
                  accept=".pdf, image/*"
                  onChange={handleAddressDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="user_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    <span> Upload Address Proof </span>
                    <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("address")}
                    >
                      {editaddressDoc != null
                        ? `${editaddressDoc.name}`
                        : selectedAddressDoc != null
                        ? `${addressDoc.name}`
                        : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="FatherName"
              placeholder="Father Name"
              value={userfathername}
              onChange={handleeditfathernamechange}
            />
            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="dob_doc"
                  accept=".pdf, image/*"
                  onChange={handleDobDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="dob_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload DOB Proof <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("DOB")}
                    >
                      {editdobDoc != null
                        ? `${editdobDoc.name}`
                        : selectedDobDoc != null
                        ? `${dobDoc.name}`
                        : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="LastName"
              placeholder="Last Name"
              value={userlastname}
              onChange={handleeditlastnamechange}
            />
            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="marks_doc"
                  accept=".pdf, image/*"
                  onChange={handleMarkSheetDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="marks_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload MarksSheet <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("marksheet")}
                    >
                      {editmarksheetDoc != null
                        ? `${editmarksheetDoc.name}`
                        : selectedMarkSheetDoc != null
                        ? `${MarkSheetDoc.name}`
                        : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="date"
              name="DOB"
              placeholder="select date of birth"
              value={userdob}
              onClick={() => setShowCalendar1(!showCalender1)}
              readOnly
            />
            {showCalender1 && (
              <Calendar
                className="calender"
                value={userdob}
                onChange={handleeditdobchange}
              />
            )}
            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="aadher_doc"
                  accept=".pdf, image/*"
                  onChange={handleAadharDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="aadher_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload Aadhar Card <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("adhar")}
                    >
                      {editadharDoc != null
                        ? `${editadharDoc.name}`
                        : selectedAadharDoc != null
                        ? `${AadharDoc.name}`
                        : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="Gender"
              value={usergender}
              onChange={handleeditgenderchange}
            >
              <option>Gender</option>
              {Array.isArray(gender) &&
                gender.map((Gender) => (
                  <option key={Gender.id} value={Gender.id}>
                    {Gender.Gender}
                  </option>
                ))}
            </select>
            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="pan_doc"
                  accept=".pdf, image/*"
                  onChange={handlePancardDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="pan_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Pancard/Passport/DL{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("pan")}
                    >
                      {editpanDoc != null
                        ? `${editpanDoc.name}`
                        : selectedPancardDoc != null
                        ? `${PanCardDoc.name}`
                        : ""}
                    </span>{" "}
                  </div>

                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>

            {/* <div className="pop_up_inputs-container">
              <select
                className="player_details_select"
                name="BowlingHandId"
                value={playerbowlinghandid}
                onChange={handleeditbowlinghandechange}
              >
                <option>Bowling Hand</option>
                {Array.isArray(playinHand) &&
                  playinHand.map((Hand) => (
                    <option key={Hand.id} value={Hand.id}>
                      {Hand.hand}
                    </option>
                  ))}
              </select>
            </div> */}
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Email"
              onChange={handleInputChange1}
            />
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="number"
              placeholder="Phone number"
              value={userphonenumber}
              onChange={handleeditphonechange}
            />
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="status"
              placeholder="Status"
              onChange={handleInputChange1}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="PlayerTypeId"
              value={playertypeid}
              onChange={handleeditplayertypechange}
            >
              <option>Player type</option>
              {Array.isArray(playertypes) &&
                playertypes.map((Playertype) => (
                  <option key={Playertype.id} value={Playertype.id}>
                    {Playertype.PlayerType}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="BattingStyleId"
              value={playerbattingstyleid}
              onChange={handleeditbattingstylechange}
            >
              <option>Batting Style</option>
              {Array.isArray(playinHand) &&
                playinHand.map((Hand) => (
                  <option key={Hand.id} value={Hand.id}>
                    {Hand.hand}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="BowlingStyleId"
              value={playerbowlingstyleid}
              onChange={handleeditbowlingstylechange}
            >
              <option value="">Bowling Style</option>
              {Array.isArray(bowlingstyle) &&
                bowlingstyle.map((style) => (
                  <option key={style.id} value={style.id}>
                    {style.BowlingStyle}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="TeamId"
              value={playerteamid}
              onChange={handleeditteamchange}
            >
              <option>Team</option>
              {Array.isArray(teams) &&
                teams.map((Teams) => (
                  <option key={Teams.id} value={Teams.id}>
                    {Teams.TeamName}
                  </option>
                ))}
            </select>
          </div>
          <span className="ms-2 pop_up_add_text">Primary Address</span>

          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              placeholder="Address 1"
              value={playeraddress1}
              onChange={handleeditChangeAddress1}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              value={playerstate1}
              onChange={handleeditStateChange1}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              value={playerdistrict1}
              onChange={handleeditstateDistrictChange1}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts) &&
                statedistricts?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              value={playercity1}
              onChange={handleeditCityChange1}
            >
              <option>Select City</option>
              {Array.isArray(districtcities) &&
                districtcities?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="player_details_select"
              type="text"
              placeholder="Zipcode"
              value={zipCode}
            />
          </div>
          <span className="ms-2 pop_up_add_text">Secondary Address</span>

          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              value={playeraddress2}
              placeholder="Address 2"
              onChange={handleeditChangeAddress2}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              value={playerstate2}
              onChange={handleeditStateChange2}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              value={playerdistrict2}
              onChange={handleeditstateDistrictChange2}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts2) &&
                statedistricts2?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              value={playercity2}
              onChange={handleeditCityChange2}
            >
              <option>Select City</option>
              {Array.isArray(districtcities2) &&
                districtcities2?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="player_details_select"
              type="text"
              placeholder="Zipcode"
              value={zipCode2}
            />
          </div>

          <div className="popup_buttons">
            <button
              className="popup_button_cancel"
              onClick={toggeleditPlayerpopup}
            >
              cancel
            </button>

            <button className="popup_button_save" onClick={handleinputedit}>
              Save
            </button>
          </div>
          <button className="close-button" onClick={toggeleditPlayerpopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
          {showdocpopup && showDoc()}
        </div>
      </div>
    );
  };

  const getDistrictAdminDistrict = (districtId) => {
    let dist = districts.find((item) => item.id === districtId);
    if (dist) {
      return dist.Name;
    }
    return "District added by super admin";
  };

  const addplayerpopup = () => {
    return (
      <div className="overlay">
        <div className="popup_player">
          <div className="pop_up_add_text_container d-flex align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">Add Player</span>
            <div className="d-flex align-items-center">
              <input
                type="file"
                id="plyImg"
                accept="image/*"
                onChange={handlePalyerImg}
                style={{ display: "none" }}
              />
              <label
                htmlFor="plyImg"
                className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
              >
                {/* <div className="title">Upload Player Img</div> */}
                {selectedPlayerImage ? (
                  <div className="d-flex gap-2 align-items-center close-doc">
                    <img
                      height={44}
                      width={44}
                      src={selectedPlayerImage}
                      alt="Selected Image"
                      className="profile-img"
                      style={{ borderRadius: 18 }}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <img
                      src={require("../assets/images/profile-icon.png")}
                      alt=""
                      height={44}
                      width={44}
                      className="profile-img"
                    />
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="RegistrationId"
              placeholder="BCCI Registeration Id"
              onChange={handleInputChange2}
            />
            <input
              className="player_details_select"
              type="text"
              name="RegistrationId"
              placeholder="State Registeration Id"
              // value={playerregisterid}
              // onChange={handleeditregistrationId}
            />
            <button className="player_get_button">GET PLAYER</button>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="status"
              placeholder="Select Registeration type"
              onChange={handleInputChange1}
            />
            {superadmin ? (
              <select
                className="player_details_select"
                name="DistrictId"
                onChange={handleInputChange2}
              >
                <option>Choose District Association</option>
                {Array.isArray(districts) &&
                  districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.Name}
                    </option>
                  ))}
              </select>
            ) : (
              <span className="player_details_select">
                {getDistrictAdminDistrict(districtid)}
              </span>
            )}
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="FirstName"
              placeholder="First Name"
              onChange={handleInputChange1}
            />

            <div className="d-flex align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="user_doc"
                  accept=".pdf, image/*"
                  onChange={handleAddressDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="user_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    <span> Upload Address Proof </span>
                    <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 8 }}
                      onClick={() => showDocumnet("address")}
                    >
                      {selectedAddressDoc != null ? `${addressDoc.name}` : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="FatherName"
              placeholder="Father Name"
              onChange={handleInputChange1}
            />

            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="dob_doc"
                  accept=".pdf, image/*"
                  onChange={handleDobDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="dob_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload DOB Proof <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("DOB")}
                    >
                      {selectedDobDoc != null ? `${dobDoc.name}` : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="LastName"
              placeholder="Last Name"
              onChange={handleInputChange1}
            />
            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="marks_doc"
                  accept=".pdf, image/*"
                  onChange={handleMarkSheetDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="marks_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload MarksSheet <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("marksheet")}
                    >
                      {selectedMarkSheetDoc != null
                        ? `${MarkSheetDoc.name}`
                        : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              type="text"
              className="player_details_select"
              value={playerDob ? playerDob.toLocaleDateString() : playerDob}
              placeholder="Select Date of Birth"
              onClick={() => setShowCalendar(!showCalendar)}
              readOnly
            />
            {showCalendar && (
              <Calendar
                className="calender"
                value={playerDob}
                onChange={handleDob}
              />
            )}

            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="aadher_doc"
                  accept=".pdf, image/*"
                  onChange={handleAadharDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="aadher_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Upload Aadhar Card <span style={{ color: "red" }}> * </span>{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("adhar")}
                    >
                      {selectedAadharDoc != null ? `${AadharDoc.name}` : ""}
                    </span>
                  </div>
                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="Gender"
              onChange={handleInputChange1}
            >
              <option>Gender</option>
              {Array.isArray(gender) &&
                gender.map((Gender) => (
                  <option key={Gender.id} value={Gender.id}>
                    {Gender.Gender}
                  </option>
                ))}
            </select>

            <div className="d-flex  align-items-center justify-content-between player_details_select">
              <div className="w-100">
                <input
                  type="file"
                  id="pan_doc"
                  accept=".pdf, image/*"
                  onChange={handlePancardDocument}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="pan_doc"
                  className="d-flex ms-2  align-items-center gap-2 select-doc justify-content-between"
                >
                  <div className="title">
                    Pancard/Passport/DL{" "}
                    <span
                      style={{ fontSize: 10 }}
                      onClick={() => showDocumnet("pan")}
                    >
                      {selectedPancardDoc != null ? `${PanCardDoc.name}` : ""}
                    </span>{" "}
                  </div>

                  <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Email"
              onChange={handleInputChange1}
            />
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="number"
              placeholder="Phone number"
              onChange={handleInputChange1}
            />
          </div>
          {/* <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="status"
              placeholder="Status"
              onChange={handleInputChange1}
            />
          </div> */}
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="PlayerTypeId"
              onChange={handleInputChange2}
            >
              <option>Player type</option>
              {Array.isArray(playertypes) &&
                playertypes.map((Playertype) => (
                  <option key={Playertype.id} value={Playertype.id}>
                    {Playertype.PlayerType}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="BattingStyleId"
              onChange={handleInputChange2}
            >
              <option>Batting Style</option>
              {Array.isArray(playinHand) &&
                playinHand.map((Hand) => (
                  <option key={Hand.id} value={Hand.id}>
                    {Hand.hand}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="BowlingStyleId"
              onChange={handleInputChange2}
            >
              <option value="">Bowling Style</option>
              {Array.isArray(bowlingstyle) &&
                bowlingstyle.map((style) => (
                  <option key={style.id} value={style.id}>
                    {style.BowlingStyle}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="TeamId"
              onChange={handleInputChange2}
            >
              <option>Team</option>
              {Array.isArray(teams) &&
                teams.map((Teams) => (
                  <option key={Teams.id} value={Teams.id}>
                    {Teams.TeamName}
                  </option>
                ))}
            </select>
          </div>
          <span className="ms-2 pop_up_add_text">Primary Address</span>

          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="AddressDetails"
              placeholder="Address 1"
              onChange={handleAddress1}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="TeamId"
              value={selectedstate}
              onChange={handleStateChange1}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="TeamId"
              value={selecteddist}
              onChange={handlestateDistrictChange1}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts) &&
                statedistricts?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="TeamId"
              value={selectedcity}
              onChange={handleCityChange1}
            >
              <option>Select City</option>
              {Array.isArray(districtcities) &&
                districtcities?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Zipcode"
              value={zipCode}
              onChange={handleInputChange1}
            />
          </div>
          <span className="ms-2 pop_up_add_text">Secondary Address</span>

          <div className="pop_up_inputs">
            <input
              className="player_details_select"
              type="text"
              name="AddressDetails"
              placeholder="Address 2"
              onChange={handleAddress2}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="TeamId"
              value={selectedstate2}
              onChange={handleStateChange2}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="player_details_select"
              name="TeamId"
              value={selecteddist2}
              onChange={handlestateDistrictChange2}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts) &&
                statedistricts2?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="player_details_select"
              name="TeamId"
              value={selectedcity2}
              onChange={handleCityChange2}
            >
              <option>Select City</option>
              {Array.isArray(districtcities) &&
                districtcities2?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="player_details_select"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Zipcode"
              value={zipCode2}
              onChange={handleInputChange1}
            />
          </div>

          {/* <div className="d-flex gap-2 mx-2 justify-content-between">
            <div className="d-flex">
              <input
                type="file"
                id="plyDoc"
                accept=".pdf"
                onChange={handlePlayerDocument}
                style={{ display: "none" }}
              />
              <label
                for="plyDoc"
                className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
              >
                <div className="title">Upload Player Documnet</div>
                <img
                    src={require("../assets/images/attach.png")}
                    alt=""
                    height={24}
                    width={24}
                  />
              </label>
              {selectedPlayerDocument && (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <iframe
                    src={selectedPlayerDocument}
                    width="80%"
                    height="200px"
                    title="Selected PDF"
                  ></iframe>
                  <p className="close" onClick={removeSelectedDocument}>
                    X
                  </p>
                </div>
              )}
            </div>
          </div> */}
          <div className="popup_buttons">
            <button
              className="popup_button_cancel"
              onClick={toggleAddplayerPopup}
            >
              cancel
            </button>

            <button className="popup_button_save" onClick={handleinputadd}>
              Create
            </button>
          </div>
          <button className="close-button" onClick={toggleAddplayerPopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
          {showdocpopup && showDoc()}
        </div>
      </div>
    );
  };

  const handleOffset = async () => {
    offset += 50;
    setOffset(offset);
    var players = await get_players(offset, null, districtid);
    setRowData1(players.data.players);
    console.log("payers", players.data.players.length);
    setRowData(players.data.players);
    // await get_api_data();
  };
  const handlePlayer = async (row) => {
    console.log(row);
    setIsPlayerInfo(row);
    if (row.PlayerId) {
      getUserDoc(row.PlayerId);
    }
    console.log("row", row);
    setPlayerIdSelect(row.PlayerId);
    try {
      let resp = await get_player_stats_all(row.PlayerId);
      console.log(resp.data.playerDetails);
      setAllData(resp.data.playerDetails);
      setPlayerStats(resp.data.summary);
      setMatchStats(resp.data.matchStats);
      console.log(allData);
    } catch (error) {
      console.error("Error fetching player stats:", error);
    }
  };

  const handleTournament = async (event) => {
    setLoading(true);

    if (event.target.value !== "all") {
      try {
        let resp = await get_player_stats(event.target.value, playeridSelect);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    } else {
      try {
        let resp = await get_player_stats_all(playeridSelect);
        console.log(resp.data);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    }
    setLoading(false);
  };

  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const [isPlayerInfo, setIsPlayerInfo] = useState(null);
  const closePlayerInfo = () => {
    setIsPlayerInfo(null);
    setAllData(null);
  };
  const [btnbowling, setBtnBowling] = useState(false);
  const [btnbatting, setBtnBatting] = useState(true);
  const togglebattingandbowling = () => {
    setBtnBatting(!btnbatting);
    setBtnBowling(!btnbowling);
  };

  const [showplayerdoc, setPlayerDoc] = useState(false);
  const [uploadDocPoup, setUploadDocPopup] = useState(false);
  const handlePlayerDoc = () => {
    setUploadDocPopup(!uploadDocPoup);
    if (isPlayerInfo) {
      getUserDoc(isPlayerInfo.User.UserId);
    }
  };
  const handlePlayerDocShow = () => {
    setPlayerDoc(!showplayerdoc);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const rowHeight = 10;
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 2;
    let startY = margin;

    // Set text color to white
    doc.setTextColor("#ffffff");

    // Set background color to black for each page
    doc.setFillColor("#000000");
    doc.rect(0, 0, pageWidth, pageHeight, "F");

    doc.setFontSize(9);
    const imgData = isPlayerInfo.User.ProfilePhotoUrl;
    const imgWidth = 50;
    const imgHeight = 50;
    const imgX = pageWidth - imgWidth - margin;
    const imgY = margin;
    doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Name : " +
        isPlayerInfo.User.FirstName +
        " " +
        isPlayerInfo.User.LastName,
      margin,
      startY + 15
    );
    doc.text(
      "Player Type : " + isPlayerInfo.PlayerType.PlayerType,
      margin,
      startY + 21
    );

    const middle = pageWidth / 2;
    let startXLeft = margin;
    let startXRight = middle + margin;
    doc.setTextColor("#ffc52f");
    doc.text("Player Batting Summary", startXLeft, startY + 30);
    doc.setTextColor("#ffffff");
    doc.setFont("helvetica", "normal");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `No of Hundreds : ${playerStats.hundreds}`,
      startXLeft,
      startY + 45
    );
    doc.text(`No of Fifties : ${playerStats.fifties}`, startXLeft, startY + 55);
    doc.text(`Average Runs : ${playerStats.avgRuns}`, startXLeft, startY + 65);

    doc.text(`Total Runs : ${playerStats.totalRuns}`, startXRight, startY + 35);
    doc.text(`No of 6s : ${playerStats.sixes}`, startXRight, startY + 45);
    doc.text(`No of 4s : ${playerStats.fours}`, startXRight, startY + 55);
    doc.text(`Strike rate : ${playerStats.fours}`, startXRight, startY + 65);

    startY += 45;
    doc.setTextColor("#ffc52f");
    doc.setFont("helvetica", "bold");
    doc.text("Player Bowling Summary", startXLeft, startY + 30);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#ffffff");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `Bowling Economy : ${playerStats.bowlingEconomy}`,
      startXLeft,
      startY + 45
    );
    doc.text(
      `Wickets Taken : ${playerStats.wicketsTaken}`,
      startXLeft,
      startY + 55
    );
    doc.text(
      `Overs Bowled: ${playerStats.oversBowled}`,
      startXLeft,
      startY + 65
    );

    doc.text(
      `Total Wickets : ${playerStats.wicketsTaken}`,
      startXRight,
      startY + 35
    );
    doc.text(`Total Maiden : ${playerStats.maidens}`, startXRight, startY + 45);
    doc.text(
      `Wicket5Fors : ${playerStats.wicket5Fors}`,
      startXRight,
      startY + 55
    );
    doc.text(
      `Total RunsGiven : ${playerStats.runsGiven}`,
      startXRight,
      startY + 65
    );

    startY += 70;

    function addDataToPDF(stats, columnPositions, title, playerData) {
      doc.setFontSize(10);
      doc.setTextColor("#ffffff"); // Set text color to white
      doc.setFillColor("#101713"); // Set background color for table header column name
      doc.rect(margin, startY, pageWidth - 2 * margin, rowHeight, "F"); // Draw rectangle for header
      doc.setTextColor("#ffffff"); // Set text color to white for header

      // Render title inside the header with the specified background color and padding
      doc.text(title, margin, startY + 8);

      startY += 10;

      doc.setFontSize(8);
      const headerColumnWidths = columnPositions.map(({ name }) => {
        return (
          (doc.getStringUnitWidth(name) * 8) / doc.internal.scaleFactor + 4
        );
      });
      const dataColumnWidths = columnPositions.map(({ selector }) => {
        const maxWidth = stats.reduce((max, data) => {
          const value = selector(data);
          const textWidth =
            (doc.getStringUnitWidth(String(value)) * 8) /
              doc.internal.scaleFactor +
            4;
          return Math.max(max, textWidth);
        }, 0);
        return maxWidth;
      });
      const columnWidths = headerColumnWidths.map((headerWidth, index) => {
        return Math.max(headerWidth, dataColumnWidths[index], 20);
      });
      const totalWidth = columnWidths.reduce(
        (total, width) => total + width,
        0
      );
      const spaceBetweenColumns =
        (pageWidth - 2 * margin - totalWidth) / (columnPositions.length - 1);
      let startX = margin;
      columnPositions.forEach(({ name, selector }, index) => {
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0); // Remove border
        doc.setFillColor("#101713"); // Set background color for table cells
        const rectWidth = columnWidths[index] + spaceBetweenColumns;
        doc.rect(startX, startY, rectWidth, rowHeight, "F");
        const lines = doc.splitTextToSize(name, columnWidths[index]);
        const textHeight = lines.length * rowHeight;
        doc.setTextColor("#ffffff"); // Set text color to white for table cells
        doc.text(lines, startX + 2, startY + 8, {
          lineHeightFactor: rowHeight / doc.internal.getLineHeight(),
        });
        if (textHeight > rowHeight) {
          startY += textHeight - rowHeight;
        }
        startX += rectWidth;
      });
      startY += rowHeight;
      stats.forEach((data) => {
        let startX = margin;
        columnPositions.forEach(({ selector }, index) => {
          const value = selector(data);
          const rectWidth = columnWidths[index] + spaceBetweenColumns;
          doc.rect(startX, startY, rectWidth, rowHeight, "S");
          doc.text(String(value), startX + 2, startY + 8);
          startX += rectWidth;
        });
        startY += rowHeight;
      });
    }

    addDataToPDF(matchStats, battingcolumn, "Batting Statistics", isPlayerInfo);
    if (startY > pageHeight - margin) {
      doc.addPage();
      startY = margin;
    }
    addDataToPDF(matchStats, bowlingcolumn, "Bowling Statistics", isPlayerInfo);
    doc.save(`player_stats_${isPlayerInfo.User.FirstName}.pdf`);
  };

  const playerInfo = () => {
    return (
      <div id="player-popup-ui" className="team-popup">
        <div className="team-info">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => closePlayerInfo()}
            >
              <div>
                {" "}
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <path
                    fill="#2a8853"
                    d="M14.78 18.362c-.469.469-1.07.703-1.768.703-.698 0-1.299-.234-1.768-.703l-6.364-6.364c-.977-.977-.977-2.561 0-3.535l6.364-6.364c.977-.977 2.561-.977 3.535 0s.977 2.558 0 3.535L9.707 12l5.062 5.062c.977.977.977 2.561 0 3.535z"
                  />
                </svg>{" "}
              </div>
              <div className="title1">Player Info</div>
            </div>
            <div className="close d-flex " onClick={() => closePlayerInfo()}>
              X
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-end me-4">
            <select
              className="combobox_board  w-25 me-4"
              name=""
              id=""
              onChange={handleTournament}
              defaultValue="all"
            >
              <option value="all">All Tounamnet</option>
              {renderTournamentTypeOptions()}
            </select>
            <button
              className={btnbatting ? "btnbatting" : "btnbatting1"}
              onClick={togglebattingandbowling}
            >
              Batting
            </button>
            <button
              className={btnbowling ? "btnbowling" : "btnbowling1"}
              onClick={togglebattingandbowling}
            >
              Bowling
            </button>
          </div>
          <div className="d-flex mt-4 ">
            <div className="d-flex align-item-center w-75 ">
              <div>
                {isPlayerInfo.User.ProfilePhotoUrl ? (
                  <img
                    src={isPlayerInfo.User.ProfilePhotoUrl}
                    alt=""
                    width={200}
                    height={200}
                    style={{ borderRadius: 15 }}
                  />
                ) : (
                  <img
                    src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                    alt=""
                    width={200}
                    height={200}
                    style={{ borderRadius: 15 }}
                  />
                )}

                {/* {showplayerdoc &&
                  ShowPlayerInfo(
                    handlePlayerDocShow,
                    isPlayerInfo,
                    handlePlayerDoc,
                    userDoc
                  )} */}
                {uploadDocPoup && (
                  <UploadDocs
                    onclose={handlePlayerDoc}
                    uid={isPlayerInfo.User.UserId}
                  />
                )}
              </div>
              <div className="">
                <h2 className="title_name_player ">
                  {isPlayerInfo.User.FirstName} {isPlayerInfo.User.LastName}{" "}
                </h2>
                <p className="title_name_player_role">
                  {" "}
                  {isPlayerInfo.PlayerType.PlayerType}
                </p>

                <p className="title_name_player_role">
                  {" "}
                  Batting Style :{" "}
                  {isPlayerInfo.BattingStyle
                    ? isPlayerInfo.BattingStyle.hand
                    : "Na"}
                </p>
                <p className="title_name_player_role">
                  {" "}
                  Bowling Style :{" "}
                  {isPlayerInfo.BowlingStyle
                    ? isPlayerInfo.BowlingStyle.BowlingStyle
                    : "Na"}
                </p>
              </div>
            </div>
            <div className="team-summmery-container" id="ui">
              <div>
                <h1 className="team-summery">Player Summary</h1>
              </div>
              {btnbatting && (
                <div className="d-flex gap-3">
                  <div>
                    <div className="d-flex align-items-center">
                      <h3> Total Matchs Played</h3>
                      <h5>:</h5>
                      <h4> {playerStats.matchCout} </h4>
                    </div>
                    <div className="d-flex  align-items-center">
                      <h3> No of 100s </h3>
                      <h5>:</h5>
                      <h4> {playerStats.hundreds} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3> No of 50s </h3>
                      <h5>:</h5>
                      <h4> {playerStats.fifties} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Avg runs </h3>
                      <h5>:</h5>
                      <h4>{playerStats.avgRuns} </h4>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center">
                      <h3>Total Runs</h3>
                      <h5>:</h5>
                      <h4>{playerStats.totalRuns} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>No of 6s</h3>
                      <h5>:</h5>
                      <h4>{playerStats.sixes} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>No of 4s</h3>
                      <h5>:</h5>
                      <h4>{playerStats.fours} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Strike rate</h3>
                      <h5>:</h5>
                      <h4>{playerStats.sr} </h4>
                    </div>
                  </div>
                </div>
              )}
              {btnbowling && (
                <div className="d-flex">
                  <div>
                    <div className="d-flex align-items-center">
                      <h3> Total Matchs Played </h3>
                      <h5>:</h5>
                      <h4> {playerStats.matchCout} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3> Bowling Economy </h3>
                      <h5>:</h5>
                      <h4> {playerStats.bowlingEconomy} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>3-Fors</h3>
                      <h5>:</h5>
                      <h4> {playerStats.wicket3Fors} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Overs Bowled </h3>
                      <h5>:</h5>
                      <h4>{playerStats.oversBowled} </h4>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center">
                      <h3>Total Wickets </h3>
                      <h5>:</h5>
                      <h4>{playerStats.wicketsTaken} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Total Maiden </h3>
                      <h5>:</h5>
                      <h4>{playerStats.maidens} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Wicket5Fors </h3>
                      <h5>:</h5>
                      <h4>{playerStats.wicket5Fors} </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h3>Total RunsGiven </h3>
                      <h5>:</h5>
                      <h4>{playerStats.runsGiven} </h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex ">
            <button
              className={
                playerActiveTab === "playerinfo"
                  ? "active-front-board"
                  : "front-btn-board"
              }
              onClick={() => handleplayerTabs("playerinfo")}
            >
              {" "}
              Player Info
            </button>
            <button
              className={
                playerActiveTab === "Player Stats"
                  ? "active-back-point"
                  : "back-btn-point"
              }
              onClick={() => handleplayerTabs("Player Stats")}
            >
              {" "}
              Player Stats
            </button>
            <button
              className={
                playerActiveTab === "Player Docs"
                  ? "active-back-board"
                  : "back-btn-board"
              }
              onClick={() => clicktabplayerdoc()}
            >
              {" "}
              Player Docs
            </button>
          </div>

          {/* <div className="d-flex gap-3 mt-2">
            <div
              className="update-btn "
              style={{ width: 120 }}
              onClick={() => setplayerallinfo(false)}
            >
              {" "}
              Player Stats{" "}
            </div>
            <div
              className="update-btn "
              style={{ width: 200 }}
              onClick={generatePDF}
            >
              {" "}
              Download Player Details{" "}
            </div>
            <div
              className="update-btn"
              onClick={handlePlayerDocShow}
              style={{ width: 180 }}
            >
              {" "}
              Player Documents
            </div>
            <div
              className="update-btn"
              onClick={handleplayerinfo}
              style={{ width: 160 }}
            >
              {" "}
              Player Information
            </div>
          </div> */}
          {matchStats.length > 0 && playerActiveTab === "Player Stats" ? (
            <div className="mt-4">
              {btnbatting && (
                <DataTable
                  data={matchStats}
                  columns={battingcolumn}
                  customStyles={customtableStyles}
                  theme="solarized"
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                />
              )}
              {btnbowling && (
                <DataTable
                  data={matchStats}
                  columns={bowlingcolumn}
                  customStyles={customtableStyles}
                  theme="solarized"
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                />
              )}
            </div>
          ) : (
            <div
              style={{ color: "var(--text-color)" }}
              className="d-flex justify-content-center mt-4 "
            >
              {playerActiveTab === "Player Stats" && (
                <div className="">No Data please Select Tournament</div>
              )}
            </div>
          )}
          {loading && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                color: "#fff",
                marginTop: 100,
              }}
            >
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {playerActiveTab === "playerinfo" && (
            <div className="">
              <div className="row">
                <div className="col-md-4 d-flex ms-2 align-items-center">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Name
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo.User.FirstName} {isPlayerInfo.User.LastName}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Father Name
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo.User.FatherName}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Date of Birth
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo?.User.DOB}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Email
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo?.User.EMail}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Gender
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo?.User.Gender === 1 ? "Male" : "Female"}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Phone number
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo?.User.PhoneNumber}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Approved
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    :{" "}
                    {isPlayerInfo.User.IsApproved === 1
                      ? "Done"
                      : isPlayerInfo.User.IsApproved === 2
                      ? "Rejected"
                      : "Not Done"}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Aadhar Kyc
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo.User.IsAadahrKycDone ? "Done" : "Not Done"}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Address 1
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    :{" "}
                    {isPlayerInfo.User?.Address1?.AddressDetails
                      ? isPlayerInfo.User?.Address1?.AddressDetails
                      : ""}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Address 2
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    :{" "}
                    {isPlayerInfo.User?.Address2?.AddressDetails
                      ? isPlayerInfo.User?.Address2?.AddressDetails
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}

          {playerActiveTab === "Player Docs" && (
            <div className="">
              <div
                style={{ width: 200 }}
                className="update-btn  "
                onClick={() => handlePlayerDoc()}
              >
                {" "}
                Upload Documnets
              </div>
              <div className="user-doc-table mt-4">
                {userDoc.map((item, index) => (
                  <div className="user-doc-con" key={index}>
                    <div className="d-flex  align-items-center">
                      <h1 style={{ fontSize: 14 }} className="title">
                        {" "}
                        File Name :{" "}
                      </h1>
                      <h1
                        style={{ fontSize: 14, color: "var(--text-color)" }}
                        className="title ms-1"
                      >
                        {item.name}
                      </h1>
                    </div>
                    <div className="d-flex align-items-center mt-2 gap-3">
                      <iframe
                        className="iframe-content"
                        src={item.fileUrl}
                      ></iframe>
                      <div className="d-flex flex-column  gap-2 ">
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          src={DownloadIcon}
                          alt="Download"
                          // onClick={() =>
                          //   handleDownload(item.fileUrl, item.fileName)
                          // }
                        />
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          src={DeleteIcon}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {userDoc.length === 0 && (
                  <h5
                    className="d-flex justify-content-end"
                    style={{ color: "var(--text-color)" }}
                  >
                    No Documents
                  </h5>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const [showScoreTable, setShowScoreTable] = useState(false);

  let [filterPlayerData, setFilterPlayerData] = useState([]);

  let handlenavigateToScoreTable = (
    row,
    type,
    isFour,
    isSix,
    isWicket,
    IsWideBall,
    isNoBall
  ) => {
   console.log(isWicket);
    console.log(row);
    const {
      match: { id, TournamentOrSeriesId, CurrInnNum },
    } = row;

    const newObject = {
      matchId: id,
      PlayerId: playeridSelect,
      InnId: CurrInnNum,
    };
    console.log(newObject);

    setShowScoreTable(true);
    setFilterPlayerData([
      {
        tournamentid: TournamentOrSeriesId,
        data: newObject,
        type: type,
        isFour: isFour,
        isSix: isSix,
        isWicket: isWicket,
        IsWideBall: IsWideBall,
        isNoBall: isNoBall,
      },
    ]);
  };

  const CloseScoreTable = () => {
    setShowScoreTable(false);
  };

  const battingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "INNS",
      selector: (row) => row.BattingInnsCount,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.totalRuns,
      sortable: true,
      textAlign: "left",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "HS",
      selector: (row) => row.topScore,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.avgRuns,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "SR",
      selector: (row) => row.sr,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "100s",
      selector: (row) => row.hundreds,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "50s",
      selector: (row) => row.fifties,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "4s",
      selector: (row) => (
        <span
        style={{
          cursor: row.fours ? "pointer" : "not-allowed",
        }}
          onClick={() =>
            handlenavigateToScoreTable(
              row,
              "batting",
              true,
              false,
              false,
              false,
              false
            )
          }
        >
          {" "}
          {row.fours}{" "}
        </span>
      ),
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "6s",
      selector: (row) => (
        <span
          style={{
            cursor: row.sixes ? "pointer" : "not-allowed",
          }}
          onClick={() =>
            handlenavigateToScoreTable(
              row,
              "batting",
              false,
              true,
              false,
              false,
              false
            )
          }
        >
          {row.sixes}{" "}
        </span>
      ),
      sortable: true,
      width: "70px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];
  const bowlingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "OV",
      selector: (row) =>( <span
        style={{
          cursor: row.oversBowled ? "pointer" : "not-allowed",
        }}
        onClick={() =>
        handlenavigateToScoreTable(
          row,
          "bowling",
          false,
          false,
          false,
          false,
          false
        )
      }>{row.oversBowled}</span>),
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "MAIDEN",
      selector: (row) => row.maidens,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.runsGiven,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "WKTS",
      selector: (row) =>( <span
        style={{
          cursor: row.wicketsTaken ? "pointer" : "not-allowed",
        }}
        onClick={() =>
        handlenavigateToScoreTable(
          row,
          "bowling",
          false,
          false,
          true,
          false,
          false
        )
      }>{row.wicketsTaken} </span> ),
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "3-FORS",
      selector: (row) => row.wicket3Fors,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "FIFERS",
      selector: (row) => row.wicket5Fors,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.bowlingAverage,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "ECON",
      selector: (row) => row.bowlingEconomy,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "BEST",
      selector: (row) => row.bowlingBest + "/" + row.RunsOnBest,
      sortable: true,
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];

  return (
    <div className="container_player">
      <div>
        <div>
          <div className="btn_layout">
            <div className="search_content">
              <div className="mt-2">
                <span className="search_text ">Player List</span>
              </div>

              <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>

            {/* <div>
              <select
                value={districtid}
                className="search_input"
                onChange={handleDistrictChange}
              >
                <option value="">Filter by District </option>
                {Array.isArray(districts) &&
                  districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.Name}
                    </option>
                  ))}
              </select>
            </div> */}

            <div>
              <select
                // value={districtid}
                className="search_input"
                // onChange={handleDistrictChange}
              >
                <option value="all">All Tounamnet</option>
                {renderTournamentTypeOptions()}
              </select>
              {
                <button className="btn1 me-2" onClick={toggleAddplayerPopup}>
                  Create
                </button>
              }
              {!superadmin &&
                 <button className="btn1" onClick={() => handelAddTeam()}>
                  AddPlayer
                </button>
              }
            </div>
          </div>
          {rowData1 ? (
            <div className="d-flex">
              {superadmin && (
                <div className="district_table_container">
                  <DataTable
                    columns={columnsdist}
                    data={districts}
                    customStyles={customtableStyles}
                    conditionalRowStyles={conditionalRowStylesdis}
                    fixedHeader
                    theme="solarized"
                    onRowClicked={(row) => handleDistrictChange(row)}
                    fixedHeaderScrollHeight="750px"
                  ></DataTable>
                </div>
              )}
              <div className="player_table_container">
                {superadmin && (
                  <div className="team-summmery-container mt-3" id="ui">
                    <div>
                      <h1 className="team-summery">
                        Player Registration Summary
                      </h1>
                    </div>
                    <div className="d-flex">
                      <div>
                        <div className="d-flex  align-items-center">
                          <h3>Total Registered Players</h3>
                          <h5> : </h5>
                          <h4>{rowData1.length}</h4>
                        </div>
                        <div className="d-flex  align-items-center">
                          <h3>Approved Players</h3>
                          <h5> : </h5>
                          <h4>
                            {
                              rowData1.filter(
                                (row) => row.User.IsApproved === 1
                              ).length
                            }
                          </h4>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex   align-items-center">
                          <h3>Non-Approved Players</h3>
                          <h5> : </h5>

                          <h4>
                            {
                              rowData1.filter(
                                (row) => row.User.IsApproved === 0
                              ).length
                            }
                          </h4>
                        </div>
                        <div className="d-flex  align-items-center">
                          <h3>Rejected Players</h3>
                          <h5> : </h5>

                          <h4>
                            {
                              rowData1.filter(
                                (row) => row.User.IsApproved === 2
                              ).length
                            }
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={superadmin ? "player_table" : "player_table2"}>
                  {superadmin && (
                    <div className="player_tabs">
                      <button
                        className={
                          activeTab === "approved"
                            ? "active-front"
                            : "front-btn"
                        }
                        onClick={() => hanbleTabClick("approved", 1)}
                      >
                        {" "}
                        Approved
                      </button>
                      <button
                        className={
                          activeTab === "rejected"
                            ? "active-back-stats"
                            : "back-btn-stats"
                        }
                        onClick={() => hanbleTabClick("rejected", 2)}
                      >
                        {" "}
                        Rejected
                      </button>

                      <button
                        className={
                          activeTab === "nonapproved"
                            ? "active-back"
                            : "back-btn"
                        }
                        onClick={() => hanbleTabClick("nonapproved", 0)}
                      >
                        {" "}
                        Non-Approved
                      </button>
                    </div>
                  )}
                  <div
                  // onScroll={handleOffset}
                  >
                    <DataTable
                      columns={columnsplayer}
                      data={
                        superadmin
                          ? rowData1.filter(
                              (row) => row.User.IsApproved === approval
                            )
                          : rowData1
                      }
                      customStyles={customtableStyles}
                      conditionalRowStyles={conditionalRowStyles}
                      fixedHeader
                      theme="solarized"
                      onRowClicked={(row) => handlePlayer(row)}
                      // onRowClicked={() => handleOffset()}

                      fixedHeaderScrollHeight="700px"
                    ></DataTable>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loading_style ">
              <div class="spinner-border wating-cursor" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        {isPopupOpen && addplayerpopup()}
        {playereditpopup && editplayerpopup()}
        {respsucces && validationpopup(succespopup)}
        {isPlayerInfo && playerInfo()}
        {isapprove && handleApprovePlayer()}
        {deleteplayer && deleteplayerpopup()}
        {deleteplayersuccess && deleteSuccess()}
        {isAddTeamForPlayers && <AddTeamForPlayers rowData1={rowData1} onClose={closeAddTeam} />}
        {showScoreTable && (
          <ShowScoreTable
            filterPlayerData={filterPlayerData}
            CloseScoreTable={CloseScoreTable}
          />
        )}
      </div>
    </div>
  );
}

export default Playercreation;

import React, { useState, useEffect, useRef } from "react";
import matchcreation from "../styles/matchcreation.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable, { createTheme } from "react-data-table-component";
import { tablecreateTheme } from "../assets/globalcolors";
import Match from "../componants/Match";
import MatchStatus from "../componants/MatchStatus";
import {
  custometableStylesmatches,
  customtableStyles,
  conditionalRowStyles,
} from "../assets/tablestyle";
import {
  get_match_type,
  get_tournaments_type,
  get_ground_data,
  get_player_data,
  get_official_data,
  get_teams,
  create_match,
  update_match,
  get_match_data,
  get_matchs_from_server,
  get_match_status,
  copy_match_to_local,
} from "../api/serverWrapper";
import { Alert } from "bootstrap";
import ValidationModal from "./ValidationModal";
import { useNavigate } from "react-router-dom";
import { getData } from "../utils/localstorage_constants";
import { USER_SYSTEM_ROLE } from "../utils/localstorage_variables";
import UserSystemRole from "../componants/UserSystemRole";

function Matchcreation() {
  const navigate = useNavigate();

  let [teams, setTeams] = useState([]);
  const [matchType, setmatchType] = useState([]);
  const [tournamentType, settournamentType] = useState([]);
  const [groundData, setgroundData] = useState([]);
  const [teamAPlayers, setTeamAPlayers] = useState([]);
  const [teamBPlayers, setTeamBPlayers] = useState([]);
  let [selectedTeamA, setSelectedTeamA] = useState(null);
  let [selectedTeamB, setSelectedTeamB] = useState(null);
  const [tossWinningTeamList, setTossWinningTeamList] = useState([]);
  const [umpireList, setUmpireList] = useState([]);
  const [mMatch, setMatch] = useState(new Match());
  const [selectedDate, setSelectedDate] = useState(null);
  const [getMatch, setGetMatch] = useState([]);
  const [getMatch1, setGetMatch1] = useState([]);
  const [overs, setOvers] = useState("");
  const [matchstatus, setMatchStatus] = useState();
  let [isLoading, setIsLoading] = useState(false);
  let [systemRole, setSystemRole] = useState();

  const loadInitialData = async () => {
    isLoading = true;
    setIsLoading(isLoading);
    systemRole = getData(USER_SYSTEM_ROLE);
    setSystemRole(systemRole);
    let teamresp = await get_teams();
    teams = teamresp.data.teams;
    console.log("loadInitialData TEAMS", teams);
    let matchTypes = await get_match_type();
    setmatchType(matchTypes.data);

    let tournamentTyperesp = await get_tournaments_type();
    settournamentType(tournamentTyperesp.data.tournaments);

    let groundresp = await get_ground_data();
    setgroundData(groundresp.data.grounds);

    let matchstatus = await get_match_status();
    setMatchStatus(matchstatus.data);

    // if ( selectedTeamA ){
    //   setSelectedTeamA(teams[1]);
    // }
    // if(selectedTeamB){
    //   setSelectedTeamB(teams[0]);
    // }
    // setTossWinningTeamList([teams[0].id, teams[1].id]);
    setTeams(teams);
    // getPlayerList("A", 1);
    // getPlayerList("B", 2);
    let resp = await get_match_data();
    console.log("Matches ", resp.data.matches);
    setGetMatch(resp.data.matches);
    setGetMatch1(resp.data.matches);

    isLoading = false;
    setIsLoading(isLoading);
    let umpireresp = await get_official_data();
    console.log("umpireresp", umpireresp.data.officials);
    let filteredArray = umpireresp.data.officials.filter(
      (obj) => obj.DesignationId === 2
    );
    setUmpireList(filteredArray);
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const changeHandler = (event) => {
    const filteredData = getMatch1
      .filter((item) =>
        item.MatchNameOrTitle.toLowerCase().includes(
          event.target.value.toLowerCase()
        )
      )
      .sort((a, b) => a.MatchNameOrTitle.localeCompare(b.MatchNameOrTitle));
    setGetMatch(filteredData);
  };

  const getPlayerList = async (team, teamid) => {
    try {
      console.log(teamid);
      let resp = await get_player_data(teamid);
      let plist = resp.data.playersList;
      plist.sort((a, b) =>
        a.Player.User.FirstName.localeCompare(b.Player.User.FirstName)
      );
      if (team == "A") {
        setTeamAPlayers(plist);
      } else {
        setTeamBPlayers(plist);
      }
    } catch (e) {
      console.log("getting player type error", e);
    }
  };

  const PlayerNameCell = ({ row }) => {
    const firstNameLetter = row.Player.User.FirstName.charAt(0);
    const lastNameLetter = row.Player.User.LastName
      ? row.Player.User.LastName.charAt(0)
      : "";

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            color: "#30a161",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          {firstNameLetter}
          {lastNameLetter}
        </div>
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          {row.Player.User.FirstName} {row.Player.User.LastName}
          <span style={{ fontSize: 8 }}>
            {" "}
            {row.Player.PlayerType ? row.Player.PlayerType.PlayerType : ""}
          </span>
        </div>
      </div>
    );
  };

  const Matchstatus = ({ row }) => {
    let resp = matchstatus.find((item) => item.id === row.MatchStatus);
    if (row.MatchStatus === MatchStatus.MATCH_COMPLETED.id) {
      return <div>{row.MatchResultDescription}</div>;
    }
    return resp?.Status;
  };
  const handleScoreCard = (row) => {
    console.log("row", row);
    navigate("/scorecard", { state: { data: row } });
  };

  const columns = [
    {
      name: "Player Name",
      selector: (row) => row.Player.User.FirstName, // Use the first name for sorting
      sortable: true,
      cell: (row) => <PlayerNameCell row={row} />,
    },
  ];

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });
  const matchscolumn = [
    {
      name: "Match Name",
      width: "450px",
      selector: (row) => row.MatchNameOrTitle,
      sortable: true,
    },
    {
      name: "TeamA",
      width: "250px",
      selector: (row) => row.TeamA.TeamName,
      sortable: true,
    },
    {
      name: "TeamB",
      width: "250px",
      selector: (row) => row.TeamB.TeamName,
      sortable: true,
    },
    {
      name: "Match Status",
      width: "350px",
      selector: (row) => <Matchstatus row={row} />,
      sortable: true,
    },
    {
      name: "Current Inn",
      width: "150px",
      selector: (row) => row.CurrInnNum,
      sortable: true,
    },
    {
      name: "Date & Time",
      width: "250px",
      selector: (row) => new Date(row.MatchDateTime).toLocaleString(),
      sortable: true,
    },
    {
      name: "Match Type",
      width: "150px",
      selector: (row) => row.MatchTypeRef.type,
      sortable: true,
    },

    {
      name: "Ground",
      width: "250px",
      selector: (row) => row.Ground.Groundname,
      sortable: true,
    },

    {
      name: "Tournament",
      width: "250px",
      selector: (row) => row.TournamentOrSeries.TournamentName,
      sortable: true,
    },
  ];

  let [teamAselectedPlayerCount, setTeamAselectedPlayerCount] = useState(0);
  let [teamBselectedPlayerCount, setTeamBselectedPlayerCount] = useState(0);
  const [isMatchUpdate, setIsMatchUpdate] = useState(false);
  const [validationData, setValidationData] = useState(null);

  const handleCloseModal = () => {
    setValidationData(null);
  };

  const [matchName, setMatchName] = useState("");
  const [ground, setGround] = useState(false);

  const copyMatchToLocal = async () => {
    alert(selectedMatchId);
    let resp = await copy_match_to_local(selectedMatchId);
    console.log("copyMatchToLocal", resp);
  };
  const SaveMatch = async () => {
    let validation = true;

    if (teamAselectedPlayerCount <= 0) {
      setValidationData("Please select the A  players ");
      validation = false;
      return;
    }
    if (teamBselectedPlayerCount <= 0) {
      setValidationData("Please select the B players ");
      validation = false;
      return;
    }

    if (matchName.length < 2) {
      setValidationData("Please Enter the Match Name ");
      validation = false;
      return;
    } else {
      const val = matchName;
      mMatch.MatchNameOrTitle = val;
      setMatch(mMatch);
    }

    if (selectedDate === null) {
      setValidationData("Please Enter the Date and Time");
      validation = false;
      return;
    }

    if (!ground) {
      setValidationData("Please Select Ground");
      validation = false;
      return;
    }
    if (teamAselectedPlayerCount !== teamBselectedPlayerCount) {
      setValidationData("Both Team Players should Equal");
      validation = false;
      return;
    }
    if (teamAselectedPlayerCount < mMatch.NumberOfPlayers) {
      setValidationData(
        "Team " +
          selectedTeamA.TeamName +
          " should have at least" +
          mMatch.NumberOfPlayers +
          " Players"
      );
      validation = false;
      return;
    }
    if (teamAselectedPlayerCount < mMatch.NumberOfPlayers) {
      setValidationData(
        "Team" +
          selectedTeamB.TeamName +
          " should have at least" +
          mMatch.NumberOfPlayers +
          " Players"
      );
      validation = false;
      return;
    }
    if (
      mMatch.TeamAWicketKeeperId === 0 ||
      // mMatch.TeamAViceCaptainId === 0 ||
      mMatch.TeamACaptainId === 0
    ) {
      setValidationData(
        "Please select " + selectedTeamA.TeamName + " captains and keeper aaaa"
      );
      validation = false;
      return;
    }
    if (
      mMatch.TeamBWicketKeeperId === 0 ||
      // mMatch.TeamBViceCaptainId === 0 ||
      mMatch.TeamBCaptainId === 0
    ) {
      setValidationData(
        "Please select " + selectedTeamB.TeamName + " captains and keeper"
      );
      validation = false;
      return;
    }
    if (
      mMatch.Umpire1Id === 0 ||
      mMatch.Umpire2Id === 0 ||
      mMatch.Umpire3Id === 0
    ) {
      setValidationData("Please select all umpires");
      validation = false;
      return;
    }
    if (mMatch.TossWinningTeamId === 0 || mMatch.ElectedTo == 0) {
      setValidationData("Please select toss");
      validation = false;
      return;
    }

    //console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId);

    mMatch.TeamAId = selectedTeamA.id;
    mMatch.TeamBId = selectedTeamB.id;

    // if (true){
    //   console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId);
    //   return
    // }
    delete mMatch.MatchStatus;

    console.log("isMatchUpdate", isMatchUpdate);

    if (isMatchUpdate === true) {
      //mMatch.id = 24;

      let data = { matchInfo: mMatch };
      let res = await update_match(data);

      console.log("res data", res);
    } else {
      delete mMatch.id;
      let data = { matchInfo: mMatch };
      if (validation) {
        let res = await create_match(data);
        console.log("res data", res);

        if (res.error) {
          setValidationData(res.error.message); // Set validation data to the error message
        }
        if (res.code === 500) {
          let errorMessage =
            "Please select Tournament, Match Type, captains & umpires";
          if (res.message) {
            errorMessage += "\n" + res.message;
          }
          setValidationData(errorMessage);
        }
        if (res.code === 400) {
          setValidationData("Please select Toss Winning Team and Toss Result");
        }

        if (res.code === 200) {
          setValidationData("Match Created successfully");
          console.log("response", res);
          setTimeout(() => {
            setIsPopupOpenMatch(false);
            handleCloseModal();
            window.location.reload();
          }, 2000);
          loadInitialData();
        }
      }
    }
  };
  const [teamASelectedPlayers, setTeamASelectedPlayers] = useState([]);
  const updateTeamAPlayerSelection = (selection) => {
    setTeamASelectedPlayers(selection.selectedRows);
    mMatch.TeamAPlayerList = [];
    selection.selectedRows.forEach((row) => {
      mMatch.TeamAPlayerList.push(row.Player.PlayerId);
    });
    setMatch(mMatch);
    setTeamAselectedPlayerCount(selection.selectedCount);
  };

  const [teamBSelectedPlayers, setTeamBSelectedPlayers] = useState([]);
  const updateTeamBPlayerSelection = (selection) => {
    setTeamBSelectedPlayers(selection.selectedRows);
    mMatch.TeamBPlayerList = [];
    selection.selectedRows.forEach((row) => {
      mMatch.TeamBPlayerList.push(row.Player.PlayerId);
    });
    setMatch(mMatch);
    setTeamBselectedPlayerCount(selection.selectedCount);
  };

  // Handle change when a date is selected

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const newDate = new Date(date);
    const dateString = newDate.toISOString();
    mMatch.MatchDateTime = dateString;
    setMatch(mMatch);
  };

  const customCSS = "";

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#0f0f0f",
      },
    },
    headCells: {
      style: {
        color: "white",
      },
    },
    rows: {
      style: {
        backgroundColor: "#0f0f0f",
        color: "#bbb",
        border: "none",
      },
    },
  };

  const renderTeamOptions = (selectedTeam) => {
    //if (selectedTeam == null ) return;
    //console.log("renderTeamOptions ", selectedTeam);
    let tname = selectedTeam ? selectedTeam.TeamName : "";
    return teams.map((team, index) => (
      <option
        key={index}
        value={team.id}
        style={{
          color: team.TeamName === tname ? "red" : "var(--text-color)",
        }}
        disabled={team.TeamName === tname}
      >
        {team.TeamName}
      </option>
    ));
  };

  const renderMatchTypeOptions = () => {
    return matchType.map((type, index) => (
      <option key={index} value={type.id}>
        {type.type}
      </option>
    ));
  };

  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const renderGroundOptions = () => {
    return groundData.map((item, index) => (
      <option key={index} value={item.id}>
        {item.Groundname}
        {item.Description}
      </option>
    ));
  };

  const renderUmpireOptions = () => {
    return umpireList.map((item, index) => (
      <option key={index} value={item.id}>
        {item.User.FirstName}
        {item.User.LastName}
      </option>
    ));
  };
  const [Umpire2, setUmpire2] = useState([]);
  const renderUmpire2Options = () => {
    return Umpire2.map((item, index) => (
      <option key={index} value={item.id}>
        {item.User.FirstName}
        {item.User.LastName}
      </option>
    ));
  };
  const [Umpire3, setUmpire3] = useState([]);
  const renderUmpire3Options = () => {
    return Umpire3.map((item, index) => (
      <option key={index} value={item.id}>
        {item.User.FirstName}
        {item.User.LastName}
      </option>
    ));
  };

  const [Umpire4, setUmpire4] = useState([]);
  const renderUmpire4Options = () => {
    return Umpire4.map((item, index) => (
      <option key={index} value={item.id}>
        {item.User.FirstName}
        {item.User.LastName}
      </option>
    ));
  };

  const renderTeamAPlayers = () => {
    return teamASelectedPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const [teamAViceCaptainPlayers, setTeamAViceCaptainPlayers] = useState([]);
  const renderTeamAViceCaptainPlayers = () => {
    return teamAViceCaptainPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const [teamBViceCaptainPlayers, setTeamBViceCaptainPlayers] = useState([]);
  const renderTeamBViceCaptainPlayers = () => {
    return teamBViceCaptainPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };
  const renderTeamBPlayers = () => {
    return teamBSelectedPlayers.map((item, index) => (
      <option key={index} value={item.Player.PlayerId}>
        {item.Player.User.FirstName} {item.Player.User.LastName}
      </option>
    ));
  };

  let [selectedMatchId, setSelectedMatchId] = useState(null);

  const handleMatchSelection = (e) => {
    const selectedMatchId = parseInt(e.target.value);
    setSelectedMatchId(selectedMatchId);
  };

  const handleTeamASelection = (event) => {
    const selectedTeamId = parseInt(event.target.value);
    console.log("handleTeamASelection ", selectedTeamId);
    const selectedTeam = teams.find((team) => team.id === selectedTeamId);

    mMatch.TeamAId = selectedTeamId;
    setMatch(mMatch);

    selectedTeamA = selectedTeam;
    setSelectedTeamA(selectedTeamA);

    teamAselectedPlayerCount = 0;
    setTeamAselectedPlayerCount(teamAselectedPlayerCount);

    console.log("team", selectedTeam);
    let list = [];
    list.push(selectedTeam.id);
    if (selectedTeamB) {
      list.push(selectedTeamB.id);
    }
    setTossWinningTeamList(() => list);

    getPlayerList("A", selectedTeam.id);
    updateMatchName(selectedTeam.TeamName);

    //console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId,"selectedTeamA.id",selectedTeamA.id,"selectedTeamB.id",selectedTeamB.id);
  };

  const [firstValueProcessed, setFirstValueProcessed] = useState(false);
  const [accumulatedValues, setAccumulatedValues] = useState([]);
  const updateMatchName = (value) => {
    setAccumulatedValues((prevValues) => [...prevValues, value]);

    if (!firstValueProcessed) {
      const extractedLetters = value
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
      setMatchName(extractedLetters);
      setFirstValueProcessed(true);
    } else {
      if (accumulatedValues.length === 1) {
        setAccumulatedValues([]);
        setFirstValueProcessed(false);
      }
      const extractedLetters = value
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
      setMatchName(
        (prevMatchName) => prevMatchName + " vs " + extractedLetters
      );
    }
  };

  const handleTeamBSelection = (event) => {
    const selectedTeamId = parseInt(event.target.value);
    console.log("handleTeamBSelection ", selectedTeamId);
    const selectedTeam = teams.find((team) => team.id === selectedTeamId);

    mMatch.TeamBId = selectedTeamId;
    setMatch(mMatch);

    selectedTeamB = selectedTeam;
    setSelectedTeamB(selectedTeamB);

    teamBselectedPlayerCount = 0;
    setTeamBselectedPlayerCount(teamBselectedPlayerCount);

    let list = [];
    list.push(selectedTeam.id);
    if (selectedTeamA) {
      list.push(selectedTeamA.id);
    }
    setTossWinningTeamList(() => list);

    getPlayerList("B", selectedTeam.id);
    updateMatchName(selectedTeam.TeamName);

    //console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId,"selectedTeamA.id",selectedTeamA.id,"selectedTeamB.id",selectedTeamB.id);
  };

  const handleElectedChange = (event) => {
    mMatch.ElectedTo = parseInt(event.target.id);
    console.log(event.target);
    setMatch(mMatch);
  };

  const handleTossTeamSelect = (event) => {
    const selectedTeamId = event.target.value;
    mMatch.TossWinningTeamId = parseInt(selectedTeamId);
    setMatch(mMatch);
    if (selectedTeamId) {
      const selectedTeamName = teams.find(
        (team) => team.id === parseInt(selectedTeamId)
      ).TeamName;
      // alert("Selected Team Name: ${selectedTeamName}");
    }
  };

  const handleUmpire1Selection = (event) => {
    const val = parseInt(event.target.value);
    console.log(val);
    mMatch.Umpire1Id = val;
    setMatch(mMatch);
    const availableUmpires = umpireList.filter((umpire) => umpire.id !== val);
    setUmpire2(availableUmpires);
  };
  const handleUmpire2Selection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.Umpire2Id = val;
    setMatch(mMatch);
    const availableUmpires = Umpire2.filter((umpire) => umpire.id !== val);
    setUmpire3(availableUmpires);
  };
  const handleUmpire3Selection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.Umpire3Id = val;
    setMatch(mMatch);
    const availableUmpires = Umpire3.filter((umpire) => umpire.id !== val);
    setUmpire4(availableUmpires);
  };
  const handleUmpire4Selection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.Umpire4Id = val;
    setMatch(mMatch);
  };

  const handleMatchNameChange = (event) => {
    setMatchName(event.target.value);
    let matchName = event.target.value;
    const val = matchName;
    mMatch.MatchNameOrTitle = val;
    setMatch(mMatch);
  };
  const handleGroundSelection = (event) => {
    if (event.target.value) {
      setGround(true);
      const val = parseInt(event.target.value);
      mMatch.GroundId = val;
      setMatch(mMatch);
    }
  };
  const handleMatchTypeSelection = (event) => {
    const selectedMatchTypeId = parseInt(event.target.value);
    const selectedMatchType = matchType.find(
      (type) => type.id === selectedMatchTypeId
    );
    if (selectedMatchType && selectedMatchType.NumberOfOvers) {
      setOvers(selectedMatchType.NumberOfOvers);
      mMatch.NumberOfOvers = selectedMatchType.NumberOfOvers;
    } else {
      mMatch.NumberOfOvers = 5; // invalid case
    }

    mMatch.MatchType = selectedMatchTypeId;
    setMatch(mMatch);
  };
  const handleTournamentSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TournamentOrSeriesId = val;
    setMatch(mMatch);
  };

  const handleNumberofDays = (event) => {
    let { value } = event.target;
    mMatch.NumberOfDays = value;
    setMatch(mMatch);
  };
  const handleTeamACaptainSelection = (event) => {
    const selectedCaptainId = parseInt(event.target.value);
    mMatch.TeamACaptainId = selectedCaptainId;
    setMatch(mMatch);
    console.log(selectedCaptainId);
    const availableViceCaptainPlayers = teamASelectedPlayers.filter(
      (player) => player.Player.PlayerId !== selectedCaptainId
    );
    setTeamAViceCaptainPlayers(availableViceCaptainPlayers);
  };
  const handleTeamAVCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TeamAViceCaptainId = val;
    setMatch(mMatch);
  };

  const handleTeamAKeeperSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TeamAWicketKeeperId = val;
    setMatch(mMatch);
  };
  const handleTeamBCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TeamBCaptainId = val;
    setMatch(mMatch);
    const availableViceCaptainPlayers = teamBSelectedPlayers.filter(
      (player) => player.Player.PlayerId !== val
    );
    setTeamBViceCaptainPlayers(availableViceCaptainPlayers);
  };
  const handleTeamBVCaptainSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TeamBViceCaptainId = val;
    setMatch(mMatch);
  };
  const handleTeamBKeeperSelection = (event) => {
    const val = parseInt(event.target.value);
    mMatch.TeamBWicketKeeperId = val;
    setMatch(mMatch);
  };

  const handleOverChange = (event) => {
    setOvers(event.target.value);
    const val = parseInt(event.target.value);
    mMatch.NumberOfOvers = val;
    setMatch(mMatch);
  };

  const [NoofPlayers, setNoofPlayers] = useState(11);
  const handleNoofPlayers = (event) => {
    console.log(event.target.val);
    setNoofPlayers(event.target.val);
    const val = parseInt(event.target.value);
    mMatch.NumberOfPlayers = val;
    setMatch(mMatch);
    console.log(mMatch);
  };
  const [isOpenMatch, setIsPopupOpenMatch] = useState(false);
  const [isMatchCopyPopup, setIsMatchCopyPopup] = useState(false);

  const fetchMatchForLocal = async () => {
    if (isMatchCopyPopup === false) {
      await getServerMatchList();
    }
    setIsMatchCopyPopup(!isMatchCopyPopup);
  };

  //used for copy match from server DB to local DB
  const [serverMatchList, setServerMatchList] = useState([]);

  const getServerMatchList = async () => {
    try {
      let resp = await get_matchs_from_server();
      setServerMatchList(resp.data.matches);
    } catch (error) {
      console.log("error ", error);
    }
  };
  const renderMatchListOptions = () => {
    return serverMatchList.map((item, index) => (
      <option   key={index} value={item.id}>
        {item.MatchNameOrTitle}
      </option>
    ));
  };

  const openAddMatch = () => {
    setIsPopupOpenMatch(!isOpenMatch);
  };
  return (
    <div>
      <section id="create-match">
        <div className="d-flex align-items-center justify-content-between ms-4">
          <div className="d-flex">
            <h1 className="search_text1">Search Match</h1>
            <input
              className="search_input"
              type="text"
              placeholder="Search Match"
              onChange={changeHandler}
            />
          </div>

          <div className="">
            {/*
            <button
              className="add-match-btn"
              onClick={() => fetchMatchForLocal()}
            >
              {" "}
              Copy Match to Local
            </button> */}
            {systemRole === UserSystemRole.ADMIN.id && (
              <button
                className="add-match-btn ms-4"
                onClick={() => openAddMatch()}
              >
                {" "}
                Create Match
              </button>
            )}
          </div>
        </div>
        <div className="datatable mt-4 mx-2">
          {isLoading === false ? (
            <DataTable
              columns={matchscolumn}
              data={getMatch}
              customStyles={customtableStyles}
              conditionalRowStyles={conditionalRowStyles}
              theme="solarized"
              fixedHeader
              fixedHeaderScrollHeight="750px"
              onRowClicked={(row) => handleScoreCard(row)}
            ></DataTable>
          ) : (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                color: "#fff",
                marginTop: 100,
              }}
            >
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </section>
      {isMatchCopyPopup && (
        <div className="overlay-match">
          <section
            id="create-match-container"
            style={{
              height: 250,
              width: "40%",
              position: "absolute",
              top: "20%",
              left: "30%",
            }}
          >
            <div className="match-title">
              <h1>Copy Match to Local</h1>
              <button
                className="close-button1 me-4"
                onClick={fetchMatchForLocal}
              >
                X
              </button>
            </div>

            <div className="dropdown_manu mt-4">
              <label>Select Match </label>
              <div className="d-flex align-items-center">
                <select
                  name=""
                  // value={selectedTeamA ? selectedTeamA.id : 0}
                  onChange={handleMatchSelection}
                  style={{ width: 400 }}
                >
                  <option>Select Team</option>
                  {renderMatchListOptions()}
                </select>
              </div>
            </div>

            <div className="d-flex bottom-container align-items-center justify-content-center mt-4">
              <div className="d-flex align-items-center gap-3 my-4">
                <button
                  className="cancle-btn"
                  onClick={() => setIsPopupOpenMatch(false)}
                >
                  Cancel
                </button>
                <button
                  className="save-btn"
                  style={{ width: 150 }}
                  onClick={copyMatchToLocal}
                >
                  Copy Match
                </button>
              </div>
            </div>
            {validationData && (
              <ValidationModal
                data={validationData}
                onClose={handleCloseModal}
              />
            )}
          </section>
        </div>
      )}
      {isOpenMatch && (
        <div className="overlay-match">
          <section id="create-match-container">
            <div className="match-title">
              <h1>Create match</h1>
              <button className="close-button1 me-4" onClick={openAddMatch}>
                X
              </button>
            </div>

            <div className="dropdown_manu">
              <div className="d-flex align-items-center">
                <select
                  name=""
                  // value={selectedTeamA ? selectedTeamA.id : 0}
                  onChange={handleTeamASelection}
                >
                  <option>Select Team</option>
                  {renderTeamOptions(selectedTeamB)}
                </select>
                <div className="seleted">
                  <h4>Selected :</h4>
                  <h5> {teamAselectedPlayerCount}</h5>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <select
                  name=""
                  id=""
                  // value={selectedTeamB ? selectedTeamB.id : 0}
                  onChange={handleTeamBSelection}
                >
                  <option value="">Select Team</option>
                  {renderTeamOptions(selectedTeamA)}
                </select>
                <div className="seleted">
                  <h4>Selected :</h4>
                  <h5>{teamBselectedPlayerCount}</h5>
                </div>
              </div>
            </div>
            <div className="middle-container">
              <div className="tables_layout">
                <div className="custom-table">
                  <div className="custom-datatable">
                    {teamAPlayers.length > 0 ? (
                      <DataTable
                        columns={columns}
                        fixedHeader
                        selectableRows
                        data={teamAPlayers}
                        fixedHeaderScrollHeight="500px"
                        theme="solarized"
                        customStyles={customtableStyles}
                        onSelectedRowsChange={updateTeamAPlayerSelection}
                        css={customCSS}
                      ></DataTable>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        {" "}
                        <h1>No players </h1>{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="custom-table">
                  <div className="custom-datatable">
                    {teamBPlayers.length > 0 ? (
                      <DataTable
                        fixedHeader
                        columns={columns}
                        selectableRows
                        data={teamBPlayers}
                        fixedHeaderScrollHeight="500px"
                        theme="solarized"
                        customStyles={customtableStyles}
                        onSelectedRowsChange={updateTeamBPlayerSelection}
                        css={customCSS}
                      ></DataTable>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        {" "}
                        <h1>No players </h1>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="match-cration">
                <div className="match-cration-events">
                  <div className="">
                    <h1>Match</h1>
                    <div className="select-types">
                      <label>Tournament</label>
                      <select onChange={handleTournamentSelection}>
                        <option value="">Select Tournament</option>
                        {renderTournamentTypeOptions()}
                      </select>
                    </div>
                    <div className="select-types">
                      <label>Match type</label>
                      <select onChange={handleMatchTypeSelection}>
                        <option value="">Select MatchType</option>
                        {renderMatchTypeOptions()}
                      </select>
                    </div>
                    <div className="select-types">
                      <label>Ground </label>
                      <select onChange={handleGroundSelection}>
                        <option value="">Select Ground</option>
                        {renderGroundOptions()}
                      </select>
                    </div>
                    <div className="select-types">
                      <label>Number of days</label>
                      <input
                        type="text"
                        className="overs-input"
                        placeholder="Enter number of days"
                        onChange={handleNumberofDays}
                      />
                    </div>
                  </div>
                  <div className="Umpire">
                    <h1>Umpires</h1>
                    <div className="Umpire-types">
                      <label>Umpire 1</label>
                      <select onChange={handleUmpire1Selection}>
                        <option value="">Select Umpire</option>
                        {renderUmpireOptions()}
                      </select>
                    </div>
                    <div className="Umpire-types">
                      <label>Umpire 2</label>
                      <select onChange={handleUmpire2Selection}>
                        <option value="">Select Umpire</option>
                        {renderUmpire2Options()}
                      </select>
                    </div>
                    <div className="Umpire-types">
                      <label>Umpire 3</label>
                      <select onChange={handleUmpire3Selection}>
                        <option value="">Select Umpire</option>
                        {renderUmpire3Options()}
                      </select>
                    </div>
                    <div className="Umpire-types">
                      <label>Umpire 4</label>
                      <select onChange={handleUmpire4Selection}>
                        <option value="">Select Umpire</option>
                        {renderUmpire4Options()}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="toss-wining-result">
                  <div className="select-types">
                    <label>Overs</label>
                    <input
                      type="text"
                      className="overs-input"
                      placeholder="Enter Overs"
                      value={overs}
                      onChange={handleOverChange}
                    />

                    <label className="ms-4">No of Players</label>
                    <input
                      type="text"
                      className="no-of-players ms-4"
                      placeholder="Players"
                      value={NoofPlayers}
                      onChange={handleNoofPlayers}
                    />
                  </div>
                  <div className="select-types"></div>
                  <div className="select-types">
                    <label style={{ width: 116 }}> Match Name </label>
                    <input
                      value={matchName}
                      onChange={handleMatchNameChange}
                      className="match-cration-events-input-name"
                      type="text"
                      placeholder="Enter Match name"
                    />
                  </div>
                  <div className="select-types">
                    <label>Date & Time</label>

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="custom-datetime-picker"
                      placeholderText="Select Date and Time"
                      yearDropdownItemNumber={10}
                    />
                  </div>
                  <h1>Toss result</h1>
                  <div className="toss-wining">
                    <label>Toss winning Team</label>
                    <select name="" id="" onChange={handleTossTeamSelect}>
                      <option value="0">Select Toss Winner</option>
                      {tossWinningTeamList.map((teamId) => (
                        <option key={teamId} value={teamId}>
                          {teams.find((team) => team.id === teamId).TeamName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="Umpire-types">
                    <label>Toss result</label>
                    <div className="select-teams-result">
                      <div className="d-flex align-items-center">
                        <label for="option1">Elected to Bat</label>
                        <input
                          type="radio"
                          id="1"
                          name="toss_winner"
                          onChange={handleElectedChange}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <label for="option2">Elected to Field</label>
                        <input
                          type="radio"
                          id="2"
                          name="toss_winner"
                          onChange={handleElectedChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-container">
              <div className="select-captains">
                <div className="">
                  <div className="select-types">
                    <label>Captain</label>
                    <select onChange={handleTeamACaptainSelection}>
                      <option value="0">Select Captain</option>
                      {renderTeamAPlayers()}
                    </select>
                  </div>
                  <div className="select-types">
                    <label>Vice Captain </label>
                    <select onChange={handleTeamAVCaptainSelection}>
                      <option value="0">Select Vice Captain</option>
                      {renderTeamAViceCaptainPlayers()}
                    </select>
                  </div>
                  <div className="select-types">
                    <label>Wicket keeper</label>
                    <select onChange={handleTeamAKeeperSelection}>
                      <option value="0">Select Wicket keeper </option>
                      {renderTeamAPlayers()}
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="select-types">
                    <label>Captain</label>
                    <select onChange={handleTeamBCaptainSelection}>
                      <option value="0">Select Captain</option>
                      {renderTeamBPlayers()}
                    </select>
                  </div>
                  <div className="select-types">
                    <label>Vice Captain </label>
                    <select onChange={handleTeamBVCaptainSelection}>
                      <option value="0">Select Vice Captain</option>
                      {renderTeamBViceCaptainPlayers()}
                    </select>
                  </div>
                  <div className="select-types">
                    <label>Wicket keeper</label>
                    <select onChange={handleTeamBKeeperSelection}>
                      <option value="0">Wicket keeper</option>
                      {renderTeamBPlayers()}
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 my-4">
                <button
                  className="cancle-btn"
                  onClick={() => setIsPopupOpenMatch(false)}
                >
                  Cancel
                </button>
                <button className="save-btn" onClick={SaveMatch}>
                  Save
                </button>
              </div>
            </div>
            {validationData && (
              <ValidationModal
                data={validationData}
                onClose={handleCloseModal}
              />
            )}
          </section>
        </div>
      )}
      <div></div>
    </div>
  );
}
export default Matchcreation;

import { get_teams, update_match_toss_info } from "../api/serverWrapper";
import updateMatchTossInfo from "../componants/UpdateMatchTossInfo";
import "../styles/addmatchplayers.css";
import React, { useState, useEffect } from "react";
import ValidationModal from "./ValidationModal";

function TossResult({ matchData ,onClose}) {
  console.log(matchData);

  const teamAData = matchData.TeamA;
  const teamBData = matchData.TeamB;
  const matchId=matchData.id;
  let [tossResult,setTossResult] = useState(new updateMatchTossInfo());


  const handleTossResult = (e)=>{
    let seletedTossTeam = e.target.value
    let val = parseInt(seletedTossTeam);
    tossResult.TossWinningTeamId=val;
    setTossResult(tossResult);
  }
 
const [validationData,setValidationData] = useState(null);
const handleElectedChange = (event) => {
  tossResult.ElectedTo = parseInt(event.target.id);
  console.log(event.target.id);
  setTossResult(tossResult);
};
  const AddTossResult = async () =>{
    tossResult.matchId = matchId;
    setTossResult(tossResult);

    if(tossResult.TossWinningTeamId === null){
      setValidationData("Please Select the Toss Winning Team.")
      return;
    }
    if(tossResult.ElectedTo === null){
      setValidationData("Please Select the Toss Winning Team.")
      return;
    }

    console.log("tossResult : ",tossResult);
    let resp= await update_match_toss_info(tossResult);
    console.log(resp);
    if(resp.code === 200){
   
      setValidationData("Toss updated Succesfully");
      setTimeout(() => {
        onClose();
      }, 2000);
    }
    
  }


  const closeValidationModel = ()=>{
    setValidationData(null);
  }

  return (
    <div className="add-match-players-popup">
      <div className="add-match-toss-result-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 "> Toss Result </h1>
          <div className="close" onClick={()=>onClose()} >X</div>
        </div>

        <div className="toss-wining-result">
          <div className="toss-wining">
            <label>Toss winning Team</label>
            <select name="" id="" onChange={handleTossResult} >
              <option value="0">Select Toss Winner</option>
              <option value={teamAData.id}>{teamAData.TeamName}</option>
              <option value={teamBData.id}>{teamBData.TeamName}</option>
            </select> 
          </div>
          <div className="Umpire-types">
            <label>Toss result</label>
            <div className="select-teams-result">
              <div className="d-flex align-items-center">
                <label for="option1">Elected to Bat</label>
                <input type="radio" id="1" name="toss_winner" onChange={handleElectedChange} />
              </div>
              <div className="d-flex align-items-center">
                <label for="option2">Elected to Field</label>
                <input type="radio" id="2" name="toss_winner" onChange={handleElectedChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center me-3" onClick={()=>AddTossResult()} >
          <button className="btn1 ">Save</button>
        </div>
      </div>
      {validationData && <ValidationModal data={validationData} onClose={closeValidationModel} />}
    </div>
  );
}

export default TossResult;

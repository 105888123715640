import React, { useState } from "react";
import DataTable from "react-data-table-component";
import player from "../styles/player.css";
function Player() {
  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Age", selector: (row) => row.age, sortable: true },
    { name: "Bowler type", selector: (row) => row.btype, sortable: true },
    {
      name: "Bowling type",
      selector: (row) => row.bowlingtype,
      sortable: true,
    },
    { name: "Avg speed", selector: (row) => row.avgspeed, sortable: true },
    { name: "Fastest bowl", selector: (row) => row.fbowl, sortable: true },
    { name: "Sowest bowl", selector: (row) => row.sbowl, sortable: true },
    {
      name: "Max deviation",
      selector: (row) => row.mdeviation,
      sortable: true,
    },
    {
      name: "Avg deviation",
      selector: (row) => row.adeviation,
      sortable: true,
    },
    { name: "total", selector: (row) => row.total, sortable: true },
  ];

  const rowData = [
    {
      name: "adi",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "aru mekh",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "vir",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "dho",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "sac",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "rah",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "vin",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "kus",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "dar",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "san",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "vit",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "raj",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "adi",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "sag",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
    {
      name: "kar",
      age: 21,
      btype: "",
      bowlingtype: "",
      avgspeed: 153.2,
      fbowl: 123.1,
      sbowl: 111,
      mdeviation: 5.4,
      adeviation: 0.5,
      total: 176,
    },
  ];
  const [rowData1, setRowData1] = useState(rowData);

  const changeHandler = (event) => {
    const filteredData = rowData
      .filter((item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    setRowData1(filteredData);
  };
  return (
    <div className="table">
      <DataTable
        columns={columns}
        data={rowData1}
        selectableRows
        fixedHeader
        pagination
      ></DataTable>
    </div>
  );
}
export default Player;

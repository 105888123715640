// export const API_BASE_URL = "http://65.2.108.206:3007/sdm/";
// export const API_BASE_URL = "https://api.rnd.3rd-eye.tv/sdm/"; //live api
// export const API_BASE_URL = "http://192.168.1.6:3009/sdm/";
// export const API_BASE_URL = "http://localhost:3009/sdm/";
//export const API_SERVER_BASE_URL = "http://65.2.108.206:3007/sdm/";
export const API_BASE_URL = "https://api90.rnd.3rd-eye.tv/sdm/"; //test api 3007

export const OFFICIAL_CATEGORIES =
  API_BASE_URL + "meta-data/official-categories";
export const PLAYING_HAND = API_BASE_URL + "meta-data/playing-hand";
export const GENDER = API_BASE_URL + "meta-data/user-genders";
export const BOWLING_STYLE = API_BASE_URL + "meta-data/player-bowling-styles";
export const MATCHTYPES = API_BASE_URL + "meta-data/match-types";
export const BATTINGSHOT = API_BASE_URL + "meta-data/battingShots";
export const FIELDING_TYPE = API_BASE_URL + "meta-data/fielding-types";
export const BOWLING_ACTION = API_BASE_URL + "meta-data/bowling-actions";
export const APPEALTTYPES = API_BASE_URL + "meta-data/appealtTypes";
export const DELIVERY_RESULT = API_BASE_URL + "meta-data/delivery-result";
export const PLAYER_TYPE = API_BASE_URL + "meta-data/player-types";
export const BOWLING_LENGTH = API_BASE_URL + "meta-data/bowling-lengths";
export const BOWLING_SIDE = API_BASE_URL + "meta-data/bowling-side";
export const DESIGNATIONS = API_BASE_URL + "meta-data/designations";
export const BATTING_WW_POSITION =
  API_BASE_URL + "meta-data/batting-ww-positions";
export const TEAM_TYPES = API_BASE_URL + "meta-data/team-types";
export const TOURNAMENT_TYPES = API_BASE_URL + "meta-data/tournament-types";

export const OFFICIALS = API_BASE_URL + "officials";
export const TOURNAMENTS = API_BASE_URL + "tournaments";
export const CREATE_TOURNAMET = API_BASE_URL + "tournaments/createtournament";
export const UPDATE_TOURNAMENT = API_BASE_URL + "tournaments/edittournament";
export const GROUNDS = API_BASE_URL + "grounds";
export const TEAM_PLAYERS = API_BASE_URL + "teams/teamInfo";
export const PLAYERS = API_BASE_URL + "players";
export const CREATE_PLAYER = API_BASE_URL + "players/createplayer";
export const UPDATE_PLAYER = API_BASE_URL + "players/editplayer";
export const CREATE_OFFICIAL = API_BASE_URL + "officials/create-official";
export const TEAMS = API_BASE_URL + "teams";
export const CREATE_TEAM = API_BASE_URL + "teams/createteam";
export const CREATE_GROUND = API_BASE_URL + "grounds/createground";
export const UPDATE_GROUND = API_BASE_URL + "grounds/editground";
export const UPDATE_OFFICIAL = API_BASE_URL + "officials/edit-official";
export const CREATE_MATCH = API_BASE_URL + "matches/creatematch";
export const UPDATE_MATCH = API_BASE_URL + "matches/editmatch";
export const UPDATE_MATCH_STAUS = API_BASE_URL + "matches/update-match-status";
export const GET_MATCH_INFO = API_BASE_URL + "matches/getMatchInfo";
export const GET_MATCH_INN_INFO = API_BASE_URL + "matches/getMatchInnInfo";
export const GET_PLAYERS_RUNNING_DATA = API_BASE_URL + "/score/players-running-data"
export const COPY_MATCH_TO_LOCAL = API_BASE_URL + "matches/copyMatchToLocal";
export const CREATE_MATCH_SCORE = API_BASE_URL + "score/create-match-score";
export const UPDATE_MATCH_SCORE = API_BASE_URL + "score/update-match-score";
export const GET_MATCH_INN_RUNNING_INFO =
  API_BASE_URL + "score/inn-running-details";
export const UPDATE_INN_START_DATA =
  API_BASE_URL + "score/update-start-inn-data";

export const GET_MATCH = API_BASE_URL + "matches";
export const GET_MATCHES_FROM_SERVER = API_BASE_URL + "matches";
export const UPDATE_TEAM = API_BASE_URL + "teams/editteam";
export const GET_SCORE = API_BASE_URL + "score";
export const GET_MATCH_PLAYERSDATA =
  API_BASE_URL + "matches/getMatchPlayersData";
export const WICKET_TYPES = API_BASE_URL + "meta-data/wicket-types";
export const GET_BALL_DETAILS_FOR_IDS =
  API_BASE_URL + "score/ball-details-for-ids";
export const GET_MATCH_INN_PARTNERSHIP =
  API_BASE_URL + "score/match-inn-partnership";
export const GET_MATCH_INN_OVERS = API_BASE_URL + "score/match-inn-overs-stats";
export const GET_TOURNAMENT_LEADER_BOARD =
  API_BASE_URL + "tournaments/get-tournament-leaderboard";

export const CREATE_USER = API_BASE_URL + "users/register";
export const USER_LOGIN = API_BASE_URL + "users/login-with-p";
export const UPDATE_IMAGE = API_BASE_URL + "users/update-user-pic";
export const DELETE_BALL = API_BASE_URL + "score/deleteBall";
export const SET_MATCH_FOLLOW_ON = API_BASE_URL + "score/match-follow-on";
export const DISTRICTS = API_BASE_URL + "meta-data/districts";
export const SEND_ADHAR_OTP = API_BASE_URL + "kyc/otp-aadhar";
export const ADHAR_VERIFICATION = API_BASE_URL + "kyc/otp-aadhar-verify";
export const PLAYER_REGISTRATION_CHECK =
  API_BASE_URL + "players/player-by-reg-id";
export const VIDEO_URLS = API_BASE_URL + "score/match-video-url";
export const USER_PHONENUMBER_VALIDATION =
  API_BASE_URL + "users/user-for-phone";
export const MATCH_STATUS = API_BASE_URL + "meta-data/match-status";
export const TOURNAMNET_TEAM_POINTS =
  API_BASE_URL + "tournaments/get-tournament-points";
export const PLAYER_STATS = API_BASE_URL + "players/player-stats";

export const TEAM_STATS = API_BASE_URL + "teams/team-stats";
export const HOME_VIDEOS = API_BASE_URL + "meta-data/home-videos";
export const HOME_CELB_IMGS = API_BASE_URL + "meta-data/home-celebrities";

export const UMPIRE_STATS = API_BASE_URL + "officials/umpire-stats";
export const GET_USER_BY_ID = API_BASE_URL + "users/user-by-id";
export const LIVE_MATCH_HIGHLIGHTS =
  API_BASE_URL + "score/get-match-highlights";
export const GET_USER_DOC = API_BASE_URL + "users/user-docs";
export const UPDATE_USER_DOC = API_BASE_URL + "users/update-user-doc";
export const MATCHES_ALL_INNINGS = API_BASE_URL + "matches/match-innings";
export const DELETE_PLAYER = API_BASE_URL + "players/delete-player";
export const COUNTRY_STATES = API_BASE_URL + "meta-data/country-states";
export const STATE_DISTRICTS = API_BASE_URL + "meta-data/state-districts";
export const DISTRICT_CITIES = API_BASE_URL + "meta-data/district-cities";
export const CREATE_FIXTURE = API_BASE_URL + "matches/createFixture";
export const ADD_PLAYER_TO_MATCH = API_BASE_URL + "matches/addPlayerToMatch";
export const ADD_OFFICALS_TO_MATCH =
  API_BASE_URL + "matches/addOfficialsToMatch";
export const UPDATE_MATCH_TOSS_INFO =
  API_BASE_URL + "matches/updateMatchTossInfo";
export const APPROVE_USER = API_BASE_URL + "users/approve-user";
export const RESET_PASSWORD = API_BASE_URL + "users/changePassword";
export const FORGOT_PASSWORD = API_BASE_URL + "users/resetPassword";

class MatchStatus {
    static get MATCH_SCHEDULED() {
      return { id: 1, status: 'Match Scheduled' };
    }
  
    static get MATCH_TOSS_COMPLETED() {
      return { id: 2, status: 'Match Toss Completed' };
    }
  
    static get MATCH_STARTED() {
      return { id: 3, status: 'Match Started' };
    }
  
    static get MATCH_STOPPED() {
      return { id: 4, status: 'Match Stopped' };
    }
  
    static get MATCH_COMPLETED() {
      return { id: 5, status: 'Match Completed' };
    }
  
    static get MATCH_POSTPONED() {
      return { id: 6, status: 'Match Postponed' };
    }
  
    static get MATCH_DELAYED() {
      return { id: 7, status: 'Match Delayed' };
    }
  
    static get MATCH_ABANDONED() {
      return { id: 8, status: 'Match Abandoned' };
    }
  
    static get MATCH_CANCELED() {
      return { id: 9, status: 'Match Canceled' };
    }

    static get MATCH_PLAYERS_ADDED() {
      return { id: 10, status: 'Players Added' };
    }

    static get MATCH_OFFICIALS_ADDED() {
      return { id: 11, status: 'Officials Added' };
    }
    static get MATCH_READY_TOSS() {
      return { id: 12, status: 'Officials & Players Added' };
    }
 
  }
  
  module.exports = MatchStatus;
  
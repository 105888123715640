class Tournament {
  constructor() {
    this.id = null;
    this.TournamentName = null;
    this.TournamentType = null;
    this.HostTeamId = null;
    this.GroupCount = null;
    this.StartDate = null;
    this.EndDate = null;
    this.TournamentDescription = null;
    this.LogoUrl = null;
  }
}

export default Tournament;

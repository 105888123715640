class UserSystemRole {
    static get ADMIN() {
      return { id: 1, Status: 'Admin' };
    }
  
    static get MEMBER() {
      return { id: 2, Status: 'Member' };
    }
  
    static get FAN() {
      return { id: 3, Status: 'Fan' };
    }
    static get DISTRICT_ADMIN() {
        return { id: 4, Status: 'District Admin' };
      }
    static get Types(){
      return[
        UserSystemRole.ADMIN,
        UserSystemRole.MEMBER,
        UserSystemRole.FAN,
        UserSystemRole.DISTRICT_ADMIN
      ]
    }
  
    
  }
  
module.exports = UserSystemRole;
  
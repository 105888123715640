class AddPlayerToMatch {
    constructor() {
        this.matchId=null;
        this.TeamAPlayerList =[];
        this.TeamBPlayerList =[];
        this.NumberOfPlayers  = null;
        this.TeamACaptainId=null;
        this.TeamBCaptainId=null;
        this.TeamAViceCaptainId=null;
        this.TeamBViceCaptainId=null;
        this.TeamAWicketKeeperId=null;
        this.TeamBWicketKeeperId=null;
    }
}

export default AddPlayerToMatch
// export const backgroundcolor = "#101713";
// export const rowColor = "#0a0a0a";
// export const txt_color = "#bbb";
// export const tablecreateTheme = "#0f0f0f";
// export const activeColor ="#2b8a54"
// export const imageFill ="black"
// export const StrokeColor ="#0f0f0f"


// export const backgroundcolor = "#385a47";
// export const rowColor = "#F2F2F2";
// export const txt_color = "#191919";
// export const tablecreateTheme = "#D9D9D9";
// export const activeColor ="#2b8a54"
// export const imageFill ="#FFFFFF"
// export const StrokeColor ="#B8B8B8"


// export const backgroundcolor = "#561C24";
// export const rowColor ="#C7B7A3";
// export const txt_color = "#000";
// export const tablecreateTheme = "#e8d8c4";
// export const activeColor ="#561c24"


export const darkThemeColors = {
    backgroundcolor: "#101713",
    rowColor: "#0a0a0a",
    txt_color: "#bbb",
    tablecreateTheme: "#0f0f0f",
    activeColor: "#2b8a54",
    imageFill: "black",
    StrokeColor: "#0f0f0f"
  };
  
  export const lightThemeColors = {
    backgroundcolor: "#385a47",
    rowColor: "#F2F2F2",
    txt_color: "#191919",
    tablecreateTheme: "#D9D9D9",
    activeColor: "#2b8a54",
    imageFill: "#FFFFFF",
    StrokeColor: "#B8B8B8"
  };

class Match {
  constructor() {
    this.id = -1;
    this.TeamAId = 0;
    this.TeamBId = 1;
    this.TeamACaptainId = 0;
    this.TeamBCaptainId = 0;
    this.TeamAViceCaptainId = 0;
    this.TeamBViceCaptainId = 0;
    this.MatchDateTime = null;
    this.MatchType = 0;
    this.NumberOfOvers = 0;
    this.NumberOfPlayers = 11;
    this.NumberOfDays = 0;
    this.MatchNameOrTitle = null;
    this.GroundId = 0;
    this.TossWinningTeamId = 0;
    this.ElectedTo = 0;
    this.Umpire1Id = 0;
    this.TournamentOrSeriesId = 0;
    this.Umpire2Id = 0;
    this.Umpire3Id = 0;
    this.Umpire4Id = 0;
    this.TeamAWicketKeeperId = 0;
    this.TeamBWicketKeeperId = 0;
    this.TeamAPlayerList = [];
    this.TeamBPlayerList = [];
  }
}

export default Match;

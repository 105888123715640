import React, { useState, useEffect, useRef } from "react";
import "../styles/addmatch.css";
import DatePicker from "react-datepicker";
import CreateFixture from "../componants/CreateFixture"
import {
  get_match_type,
  get_tournaments_type,
  get_ground_data,
  get_player_data,
  get_official_data,
  get_teams,
  create_match,
  update_match,
  get_match_data,
  get_matchs_from_server,
  get_match_status,
  copy_match_to_local,
  create_fixture,
} from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";

function AddMatch({ onClose ,receivedData}) {

  console.log(receivedData);

  let [selectedTeamA, setSelectedTeamA] = useState(null);
  let [selectedTeamB, setSelectedTeamB] = useState(null);

  let [teams, setTeams] = useState([]);
  const [matchType, setmatchType] = useState([]);
  const [groundData, setgroundData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [matchName, setMatchName] = useState("");
  const [TournamentOrSeriesId,setTournamentOrSeriesId]=useState(receivedData.id);
  let tournamentName = receivedData.TournamentName
  const [overs, setOvers] = useState("");
  let [createFix,setCreateFix] = useState(new CreateFixture());
  const loadInitialData = async () => {
    let teamresp = await get_teams();
    teams = teamresp.data.teams;
    console.log("loadInitialData TEAMS", teams);
    let matchTypes = await get_match_type();
    setmatchType(matchTypes.data);

    let groundresp = await get_ground_data();
    setgroundData(groundresp.data.grounds);

    // if ( selectedTeamA ){
    //   setSelectedTeamA(teams[1]);
    // }
    // if(selectedTeamB){
    //   setSelectedTeamB(teams[0]);
    // }
    // setTossWinningTeamList([teams[0].id, teams[1].id]);
    setTeams(teams);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const newDate = new Date(date);
    const dateString = newDate.toISOString();
    createFix.MatchDateTime=dateString;
    setCreateFix(createFix);
  };

  const handleTeamASelection = (event) => {
    const selectedTeamId = parseInt(event.target.value);
    createFix.TeamAId = selectedTeamId;
    setCreateFix(createFix);
    console.log("handleTeamASelection ", selectedTeamId);
    const selectedTeam = teams.find((team) => team.id === selectedTeamId);

    selectedTeamA = selectedTeam;
    setSelectedTeamA(selectedTeamA);

    console.log("team", selectedTeam);
    let list = [];
    list.push(selectedTeam.id);
    if (selectedTeamB) {
      list.push(selectedTeamB.id);
    }
    

    updateMatchName(selectedTeam.TeamName);

    //console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId,"selectedTeamA.id",selectedTeamA.id,"selectedTeamB.id",selectedTeamB.id);
  };

  const handleTeamBSelection = (event) => {
    const selectedTeamId = parseInt(event.target.value);
    createFix.TeamBId = selectedTeamId;
    setCreateFix(createFix);
    console.log("handleTeamBSelection ", selectedTeamId);
    const selectedTeam = teams.find((team) => team.id === selectedTeamId);

    selectedTeamB = selectedTeam;
    setSelectedTeamB(selectedTeamB);

    let list = [];
    list.push(selectedTeam.id);
    if (selectedTeamA) {
      list.push(selectedTeamA.id);
    }
    

    updateMatchName(selectedTeam.TeamName);

    //console.log("mMatch.TeamAId",mMatch.TeamAId,"mMatch.TeamBId",mMatch.TeamBId,"selectedTeamA.id",selectedTeamA.id,"selectedTeamB.id",selectedTeamB.id);
  };

  const [firstValueProcessed, setFirstValueProcessed] = useState(false);
  const [accumulatedValues, setAccumulatedValues] = useState([]);
  const updateMatchName = (value) => {
    setAccumulatedValues((prevValues) => [...prevValues, value]);

    if (!firstValueProcessed) {
      const extractedLetters = value
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
      setMatchName(extractedLetters);
      setFirstValueProcessed(true);
    } else {
      if (accumulatedValues.length === 1) {
        setAccumulatedValues([]);
        setFirstValueProcessed(false);
      }
      const extractedLetters = value
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
      setMatchName(
        (prevMatchName) => prevMatchName + " vs " + extractedLetters
      );
    }
  };

  const renderMatchTypeOptions = () => {
    return matchType.map((type, index) => (
      <option key={index} value={type.id}>
        {type.type}
      </option>
    ));
  };

  const renderGroundOptions = () => {
    return groundData.map((item, index) => (
      <option key={index} value={item.id}>
        {item.Groundname}
        {item.Description}
      </option>
    ));
  };

  const handleGroundSelection = (event) => {
    if (event.target.value) {
      const val = parseInt(event.target.value);
      createFix.GroundId=val;
      setCreateFix(createFix);
    }
  };

  const handleMatchTypeSelection = (event) => {
    const selectedMatchTypeId = parseInt(event.target.value);
    console.log(selectedMatchTypeId);
    const selectedMatchType = matchType.find(
      (type) => type.id === selectedMatchTypeId
    );
    if (selectedMatchType && selectedMatchType.NumberOfOvers) {
      setOvers(selectedMatchType.NumberOfOvers);
      createFix.NumberOfOvers = selectedMatchType.NumberOfOvers;
    } else {
      createFix.NumberOfOvers = 5; // invalid case
    }

    createFix.MatchType = selectedMatchTypeId;
    setCreateFix(createFix);
  };

  const renderTeamOptions = (selectedTeam) => {
    //if (selectedTeam == null ) return;
    //console.log("renderTeamOptions ", selectedTeam);
    let tname = selectedTeam ? selectedTeam.TeamName : "";
    return teams.map((team, index) => (
      <option
        key={index}
        value={team.id}
        style={{
          color: team.TeamName === tname ? "red" : "var(--text-color)",
        }}
        disabled={team.TeamName === tname}
      >
        {team.TeamName}
      </option>
    ));
  };

  const handleMatchNameChange = (event) => {
    setMatchName(event.target.value);
    let matchName = event.target.value;
    createFix.MatchNameOrTitle=matchName
    setCreateFix(createFix);
  };


  const handleOverChange = (event) => {
    setOvers(event.target.value);
    const val = parseInt(event.target.value);
    createFix.NumberOfOvers = val;
    setCreateFix(createFix);
  };

  const careteFixture = async () =>{
    console.log(receivedData);
    createFix.TournamentOrSeriesId=TournamentOrSeriesId;
    createFix.MatchNameOrTitle=matchName;
    setCreateFix(createFix);



    if(createFix.TeamAId   === null && createFix.TeamBId   === null){
      setValidationData("Please Select the Both Teams");
      return;
    }
    if(createFix.TeamAId   === null ){
      setValidationData("Please Select the Both Teams");
      return;
    }
    if( createFix.TeamBId   === null){
      setValidationData("Please Select the Both Teams");
      return;
    }
    if(createFix.MatchType == null){
      setValidationData("Please Select the Match Type");
      return;
    }

    if(createFix.MatchDateTime === null){
      setValidationData("Please Select the Match Date & Time");
      return;
    }

    if(createFix.GroundId === null){
      setValidationData("Please Select the Ground");
      return;
    }

    console.log("createFix  : ",createFix);

     try {
        let resp= await create_fixture(createFix);
        console.log(resp);
        if(resp.code === 200 ){
          setValidationData("Match created successfully");
          setTimeout(() => {
            onClose();
          }, 2000);
        }
     } catch (error) {
        console.log("error in create fixture",error);
     }

  }

  const [validationData,setValidationData] = useState(null);

  const closevaliadation = () =>{
    setValidationData(null);
     
  }

  return (
    <div className="add-match-popup">
      <div className="add-match-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Create Match </h1>
          <div className="close" onClick={() => onClose()}>
            X
          </div>
        </div>
        <div className="d-flex  align-items-center mx-3 my-2">
          <div className="title2"> Tournament Name </div>
          <div className="t-name ms-2"> :  {tournamentName} </div>
        </div>
        <div className="">
          <div className="dropdown_manu_match mx-2 d-flex gap-3">
            <select className="w-50" onChange={handleTeamASelection}>
              <option value="">select Team</option>
              {renderTeamOptions(selectedTeamB)}
            </select>
            <select className="w-50" onChange={handleTeamBSelection}>
              <option value="">select Team</option>
              {renderTeamOptions(selectedTeamA)}
            </select>
          </div>

          <div className="dropdown_manu_match mt-4 mx-3 d-flex gap-3">
            <select className="w-50" onChange={handleMatchTypeSelection}>
              <option value="" >
                Select Match Type
              </option>
              {renderMatchTypeOptions()}
            </select>
            <select className="w-50" onChange={handleGroundSelection}>
              <option value="">Ground</option>
              {renderGroundOptions()}
            </select>
          </div>
          <div className="dropdown_manu_match mt-4 mx-3 d-flex gap-3">
            <input
              className="w-50"
              type="text"
              placeholder="Enter Match Name"
              value={matchName}
              onChange={handleMatchNameChange}
            />
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="custom-datetime-picker-3"
              placeholderText="Select Date and Time"
              yearDropdownItemNumber={10}
            />
          </div>
          <div className="dropdown_manu_match mt-4 mx-3 d-flex gap-3">
          <input
                      type="text"
                      className="custom-datetime-picker-3"
                      placeholder="Enter Overs"
                      value={overs}
                      onChange={handleOverChange}
                    />
          </div>
        </div>
        <div className="d-flex justify-content-center me-3" onClick={()=>careteFixture()} >
          <button className="btn1 mt-4">Save</button>
        </div>
      </div>
      {validationData &&<ValidationModal data={validationData} onClose={closevaliadation} />}
    </div>
  );
}

export default AddMatch;

class UserInfo {
  constructor(data) {
    if (data == null) {
      this.UserId = -1;
      this.FirstName = null;
      this.LastName = null;
      this.DOB = null;
      this.Gender = 1;
      this.PhoneNumber = null;
      this.FatherName = null;
      this.EMail = null;
      this.Password = null;
      this.KycDataId = null;
      this.SystemRole = null;
      this.IsApproved = false;
      // this.Address1 = null;
      // this.State1 = null;
      // this.District1 = null;
      // this.City1 = null;
      // this.Zipcode1 = null;
      // this.Address2 = null;
      // this.State2 = null;
      // this.District2 = null;
      // this.City2 = null;
      // this.Zipcode2 = null;
    } else {
      this.UserId = data.UserId;
      this.FirstName = data.FirstName;
      this.LastName = data.LastName;
      this.DOB = data.DOB;
      this.Gender = data.Gender;
      this.PhoneNumber = data.PhoneNumber;
      this.FatherName = data.FatherName;
      this.EMail = data.EMail;
      this.ProfilePhotoUrl = data.ProfilePhotoUrl;
      this.SystemRole = data.SystemRole;
      this.IsApproved = data.IsApproved;
    }
  }
}

export default UserInfo;

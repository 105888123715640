const LOG_ENABLED = true;

const DEBUG_LOG_ENABLED = false;

const ERROR_TAG = "SDM-API-E-";
const INFO_TAG = "SDM-API-I-";
const DEBUG_TAG = "SDM-API-D-";

export const log_info = (message, ...optionalParams) => {
  if (LOG_ENABLED) {
    if (optionalParams === null || optionalParams.length === 0) {
      console.log(INFO_TAG + message);
    } else {
      console.log(INFO_TAG + message, optionalParams);
    }
  }
};

export const log_error = (message, ...optionalParams) => {
  if (LOG_ENABLED) {
    if (optionalParams === null || optionalParams.length === 0) {
      console.log(ERROR_TAG + message);
    } else {
      console.log(ERROR_TAG + message, optionalParams);
    }
  }
};

export const log_debug = (message, ...optionalParams) => {
  if (LOG_ENABLED & DEBUG_LOG_ENABLED) {
    if (optionalParams === null || optionalParams.length === 0) {
      console.log(DEBUG_TAG + message);
    } else {
      console.log(DEBUG_TAG + message, optionalParams);
    }
  }
};

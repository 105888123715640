import {
  doGetRequest,
  doPostRequest,
  doPostRequestImageFile,
  doDeleteRequest,
} from "./fetchcalls";
import {
  OFFICIAL_CATEGORIES,
  PLAYING_HAND,
  GENDER,
  BOWLING_STYLE,
  TEAMS,
  MATCHTYPES,
  PLAYERS,
  CREATE_PLAYER,
  UPDATE_PLAYER,
  CREATE_OFFICIAL,
  CREATE_TEAM,
  OFFICIALS,
  MATCHTYPE,
  GET_MATCH_INFO,
  TOURNAMENTS,
  GROUNDS,
  TEAM_PLAYERS,
  UPDATE_OFFICIAL,
  CREATE_GROUND,
  UPDATE_GROUND,
  BATTINGSHOT,
  FIELDING_TYPE,
  BOWLING_ACTION,
  APPEALTTYPES,
  PLAYER_TYPE,
  DELIVERY_RESULT,
  BOWLING_LENGTH,
  BOWLING_SIDE,
  DESIGNATIONS,
  BATTING_WW_POSITION,
  GET_MATCH,
  UPDATE_TEAM,
  TEAM_TYPES,
  CREATE_TOURNAMET,
  TOURNAMENT_TYPES,
  UPDATE_TOURNAMENT,
  CREATE_MATCH,
  UPDATE_MATCH,
  GET_MATCH_INN_INFO,
  CREATE_MATCH_SCORE,
  GET_MATCH_INN_RUNNING_INFO,
  UPDATE_INN_START_DATA,
  GET_SCORE,
  GET_MATCH_PLAYERSDATA,
  WICKET_TYPES,
  GET_BALL_DETAILS_FOR_IDS,
  GET_MATCH_INN_PARTNERSHIP,
  GET_MATCH_INN_OVERS,
  CREATE_USER,
  GET_TOURNAMENT_LEADER_BOARD,
  USER_LOGIN,
  UPDATE_IMAGE,
  DELETE_BALL,
  SET_MATCH_FOLLOW_ON,
  DISTRICTS,
  SEND_ADHAR_OTP,
  ADHAR_VERIFICATION,
  PLAYER_REGISTRATION_CHECK,
  VIDEO_URLS,
  GET_MATCHES_FROM_SERVER,
  USER_PHONENUMBER_VALIDATION,
  MATCH_STATUS,
  PLAYER_STATS,
  TEAM_STATS,
  TOURNAMNET_TEAM_POINTS,
  HOME_VIDEOS,
  UPDATE_MATCH_STAUS,
  UMPIRE_STATS,
  UPDATE_MATCH_SCORE,
  COPY_MATCH_TO_LOCAL,
  GET_USER_BY_ID,
  LIVE_MATCH_HIGHLIGHTS,
  HOME_CELB_IMGS,
  GET_USER_DOC,
  UPDATE_USER_DOC,
  MATCHES_ALL_INNINGS,
  CREATE_FIXTURE,
  ADD_PLAYER_TO_MATCH,
  DELETE_PLAYER,
  COUNTRY_STATES,
  STATE_DISTRICTS,
  DISTRICT_CITIES,
  ADD_OFFICALS_TO_MATCH,
  UPDATE_MATCH_TOSS_INFO,
  APPROVE_USER,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_PLAYERS_RUNNING_DATA,
} from "./app_urls";
import { USER_ID } from "../utils/localstorage_variables";
export const get_official_data = async (offset, districtId, offtype) => {
  let url = OFFICIALS + "?";
  if (offset) {
    url += "offset=" + offset + "&";
  }
  if (districtId) {
    url += "districtId=" + districtId + "&";
  }
  if (offtype) {
    url += "designationId=" + offtype;
  }
  console.log(url);
  let response = await doGetRequest(url);
  return response;
};

export const get_official_category = async () => {
  let response = await doGetRequest(OFFICIAL_CATEGORIES);
  return response;
};

export const get_playing_hand = async () => {
  let response = await doGetRequest(PLAYING_HAND);
  return response;
};

export const get_gender = async () => {
  let response = await doGetRequest(GENDER);
  return response;
};

export const get_bowling_style = async () => {
  let response = await doGetRequest(BOWLING_STYLE);
  return response;
};

export const get_batting_shot_data = async () => {
  let response = await doGetRequest(BATTINGSHOT);
  return response;
};

export const get_teams = async () => {
  let uid = localStorage.getItem(USER_ID);
  let response = await doGetRequest(TEAMS + "?userId=" + uid);
  return response;
};

export const get_fielding_type = async () => {
  let response = await doGetRequest(FIELDING_TYPE);
  return response;
};

export const get_tournaments_type = async () => {
  let response = await doGetRequest(TOURNAMENTS);
  return response;
};

export const get_match_type = async () => {
  let response = await doGetRequest(MATCHTYPES);
  return response;
};
export const get_ground_data = async () => {
  let response = await doGetRequest(GROUNDS);
  return response;
};

export const get_player_data = async (teamId) => {
  let response = await doGetRequest(TEAM_PLAYERS + "?teamId=" + teamId);
  return response;
};

export const get_player_type = async () => {
  let response = await doGetRequest(PLAYER_TYPE);
  return response;
};

export const get_players = async (offset, name, districtId) => {
  let url = PLAYERS + "?";
  if (offset) {
    url += "offset=" + offset + "&";
  }
  if (name) {
    url += "name=" + name + "&";
  }
  if (districtId) {
    url += "districtId=" + districtId;
  }
  console.log(url);
  let response = await doGetRequest(url);
  return response;
};

export const create_player = async (player) => {
  let response = await doPostRequest(CREATE_PLAYER, player);
  return response;
};

export const update_player = async (player) => {
  let response = await doPostRequest(UPDATE_PLAYER, player);
  return response;
};

export const create_official = async (umpire) => {
  let response = await doPostRequest(CREATE_OFFICIAL, umpire);
  return response;
};

export const create_match = async (match) => {
  let response = await doPostRequest(CREATE_MATCH, match);
  return response;
};
export const update_match = async (match) => {
  let response = await doPostRequest(UPDATE_MATCH, match);
  return response;
};
export const get_team_data = async () => {
  let response = await doGetRequest(TEAMS);
  return response;
};

export const update_official = async (umpire) => {
  let response = await doPostRequest(UPDATE_OFFICIAL, umpire);
  return response;
};

export const create_ground = async (ground) => {
  let response = await doPostRequest(CREATE_GROUND, ground);
  return response;
};

export const update_inn_start_data = async (data) => {
  let response = await doPostRequest(UPDATE_INN_START_DATA, data);
  return response;
};

export const update_match_status = async (matchId, status) => {
  let response = await doPostRequest(
    UPDATE_MATCH_STAUS + "?matchId=" + matchId + "&status=" + status
  );
  return response;
};

export const get_match_info = async (matchId) => {
  console.log(GET_MATCH_INFO + "?matchId=" + matchId);
  let response = await doGetRequest(GET_MATCH_INFO + "?matchId=" + matchId);
  return response;
};
export const get_match_inn_info = async (matchId, innNum) => {
  console.log(GET_MATCH_INN_INFO + "?matchId=" + matchId + "&innNum=" + innNum);
  let response = await doGetRequest(
    GET_MATCH_INN_INFO + "?matchId=" + matchId + "&innNum=" + innNum
  );
  return response;
};

export const get_player_ruuning_data = async (
  matchId,
  innNum,
  striker,
  nonStriker,
  bowler
) => {
  let response = await doGetRequest(
    GET_PLAYERS_RUNNING_DATA +
      "?matchId=" +
      matchId +
      "&innNum=" +
      innNum +
      "&striker=" +
      striker +
      "&nonStriker=" +
      nonStriker +
      "&bowler=" +
      bowler
  );
  return response;
};

export const get_match_inn_running_info = async (
  matchId,
  innNum,
  currOverId
) => {
  let response = await doGetRequest(
    GET_MATCH_INN_RUNNING_INFO +
      "?matchId=" +
      matchId +
      "&innNum=" +
      innNum +
      "&currOver=" +
      currOverId
  );
  return response;
};

export const get_match_data = async (tournamentId, isFanloing) => {
  let url = GET_MATCH;
  let uid = 0;
  if (isFanloing) {
    uid = -1;
  } else {
    uid = localStorage.getItem(USER_ID);
  }
  console.log("uid : " + uid);
  if (tournamentId) {
    if (uid) {
      url += "?tournamentId=" + tournamentId + "&userId=" + uid;
    } else {
      url += "?tournamentId=" + tournamentId;
    }
  } else {
    if (uid) {
      url += "?userId=" + uid;
    }
  }
  console.log(url);
  let response = await doGetRequest(url);
  return response;
};
export const get_matchs_from_server = async () => {
  //let url = GET_MATCHES_FROM_SERVER;
  // if (tournamentId) {
  //   url += "?tournamentId=" + tournamentId;
  // }
  let response = await doGetRequest(GET_MATCHES_FROM_SERVER);
  //console.log("url", url);
  return response;
};

export const update_ground = async (ground) => {
  let response = await doPostRequest(UPDATE_GROUND, ground);
  return response;
};

export const get_bowling_action = async () => {
  let response = await doGetRequest(BOWLING_ACTION);
  return response;
};

export const get_appealt_types = async () => {
  let response = await doGetRequest(APPEALTTYPES);
  return response;
};

export const get_delivery_result = async () => {
  let response = await doGetRequest(DELIVERY_RESULT);
  return response;
};

export const get_bowling_length = async () => {
  let response = await doGetRequest(BOWLING_LENGTH);
  return response;
};

export const get_bowling_side = async () => {
  let response = await doGetRequest(BOWLING_SIDE);
  return response;
};

export const get_designations = async () => {
  let response = await doGetRequest(DESIGNATIONS);
  return response;
};

export const get_batting_ww_position = async () => {
  let response = await doGetRequest(BATTING_WW_POSITION);
  return response;
};

export const get_team_types = async () => {
  let response = await doGetRequest(TEAM_TYPES);
  return response;
};

export const create_team = async (team) => {
  let response = await doPostRequest(CREATE_TEAM, team);
  return response;
};

export const update_team = async (team) => {
  let response = await doPostRequest(UPDATE_TEAM, team);
  return response;
};

export const tournament_types = async () => {
  let response = await doGetRequest(TOURNAMENT_TYPES);
  return response;
};

export const create_tournament = async (tournament) => {
  let response = await doPostRequest(CREATE_TOURNAMET, tournament);
  return response;
};

export const update_tournament = async (tournament) => {
  let response = await doPostRequest(UPDATE_TOURNAMENT, tournament);
  return response;
};
export const create_score = async (score) => {
  let response = await doPostRequest(CREATE_MATCH_SCORE, score);
  return response;
};

export const update_score = async (score) => {
  let response = await doPostRequest(UPDATE_MATCH_SCORE, score);
  return response;
};

export const copy_match_to_local = async (matchId) => {
  let url = COPY_MATCH_TO_LOCAL + "?matchId=" + matchId;
  let response = await doGetRequest(url);
  return response;
};
export const get_score = async (
  matchId,
  innNum,
  overNum,
  batsmanId,
  bowlerID,
  apealedTypeId,
  umpireId,
  offset,
  isFour,
  isSix,
  isWicket,
  IsWideBall,
  isNoBall
) => {
  let url = GET_SCORE + "?matchId=" + matchId + "&innNum=" + innNum;

  if (batsmanId) {
    url += "&batsmanId=" + batsmanId;
  }
  if (bowlerID) {
    url += "&bowlerId=" + bowlerID;
  }
  if (apealedTypeId) {
    url += "&apealedTypeId=" + apealedTypeId;
  }
  // if (innNum) {
  //   url += "&innNum=" + innNum;
  // }
  if (umpireId) {
    url += "&umpireId=" + umpireId;
  }
  if (offset) {
    url += "&offset=" + offset;
  }
  if (overNum) {
    url += "&overNum=" + overNum;
  }
  if (isFour) {
    url += "&isFour=" + isFour;
  }
  if (isSix) {
    url += "&isSix=" + isSix;
  }
  if (isWicket) {
    url += "&isWicket=" + isWicket;
  }
  if (IsWideBall) {
    url += "&isWide=" + IsWideBall;
  }
  if (isNoBall) {
    url += "&isNoBall=" + isNoBall;
  }
  // console.log(url);
  let response = await doGetRequest(url);
  return response;
};

export const get_match_playersdata = async (matchId, innNum) => {
  let response = await doGetRequest(
    GET_MATCH_PLAYERSDATA + "?matchId=" + matchId + "&innNum=" + innNum
  );
  return response;
};
export const get_wicket_types = async () => {
  let response = await doGetRequest(WICKET_TYPES);
  return response;
};

export const get_balls_details_for_ids = async (ballIds) => {
  let response = await doPostRequest(GET_BALL_DETAILS_FOR_IDS, ballIds);
  return response;
};

export const get_match_inn_partnership = async (matchId, innNum) => {
  let response = await doGetRequest(
    GET_MATCH_INN_PARTNERSHIP + "?matchId=" + matchId + "&innNum=" + innNum
  );
  return response;
};

export const get_match_inn_overs = async (matchId, innNum) => {
  let response = await doGetRequest(
    GET_MATCH_INN_OVERS + "?matchId=" + matchId + "&innNum=" + innNum
  );
  return response;
};

export const create_user = async (user) => {
  let response = await doPostRequest(CREATE_USER, user);
  return response;
};

export const get_tournament_leaderboard = async (tournamentId) => {
  let response = await doGetRequest(
    GET_TOURNAMENT_LEADER_BOARD + "?tournamentId=" + tournamentId
  );
  return response;
};

export const login_user = async (user) => {
  let response = await doPostRequest(USER_LOGIN, user);
  return response;
};

export const update_image = async (id, file, title) => {
  let url = UPDATE_IMAGE + "?id=" + id + "&title=" + title;
  const formData = new FormData();
  formData.append("profile_pic", file);
  let response = await doPostRequestImageFile(url, formData);
  return response;
};

export const delete_last_ball = async (PreStateId, ballId) => {
  let response = await doPostRequest(
    DELETE_BALL + "?scoreId=" + ballId + "&undoScoreId=" + PreStateId
  );
  return response;
};

export const set_match_follow_on = async (matchId) => {
  let response = await doPostRequest(
    SET_MATCH_FOLLOW_ON + "?matchId=" + matchId
  );
  return response;
};

export const get_districts = async () => {
  let response = await doGetRequest(DISTRICTS);
  return response;
};

export const send_adhar_otp = async (adharnumber) => {
  let response = await doPostRequest(SEND_ADHAR_OTP, adharnumber);
  return response;
};

export const adhar_verification = async (adhardetails) => {
  let response = await doPostRequest(ADHAR_VERIFICATION, adhardetails);
  return response;
};

export const player_registration_check = async (regId) => {
  let response = await doGetRequest(
    PLAYER_REGISTRATION_CHECK + "?regId=" + regId
  );
  return response;
};

export const get_video_urls = async (
  matchId,
  innNum,
  overNum,
  ballNum,
  extraIndex
) => {
  let response = await doGetRequest(
    VIDEO_URLS +
      "?matchId=" +
      matchId +
      "&innNum=" +
      innNum +
      "&overNum=" +
      overNum +
      "&ballNum=" +
      ballNum +
      "&extraIndex=" +
      extraIndex
  );
  return response;
};

export const user_phonenumber_validation = async (phonenumber) => {
  let response = await doGetRequest(
    USER_PHONENUMBER_VALIDATION + "?PhoneNumber=" + phonenumber
  );
  return response;
};

export const get_match_status = async () => {
  let response = await doGetRequest(MATCH_STATUS);
  return response;
};

export const get_player_stats = async (tournamentId, playerId) => {
  let response = await doGetRequest(
    PLAYER_STATS + "?tournamentId=" + tournamentId + "&playerId=" + playerId
  );
  return response;
};
export const get_player_stats_all = async (playerId) => {
  let response = await doGetRequest(PLAYER_STATS + "?playerId=" + playerId);
  return response;
};

export const get_team_stats = async (teamId) => {
  let response = await doGetRequest(TEAM_STATS + "?teamId=" + teamId);
  return response;
};

export const get_tournament_team_points = async (tournamentId) => {
  let response = await doGetRequest(
    TOURNAMNET_TEAM_POINTS + "?tournamentId=" + tournamentId
  );
  return response;
};

export const get_home_videos = async () => {
  let response = await doGetRequest(HOME_VIDEOS);
  return response;
};

export const get_celb_images = async () => {
  let response = await doGetRequest(HOME_CELB_IMGS);
  return response;
};
export const get_umpire_stats = async (umpireId) => {
  let response = await doGetRequest(UMPIRE_STATS + "?umpireId=" + umpireId);
  return response;
};

export const get_tornament_umpire_stats = async (tournamentId, umpireId) => {
  let response = await doGetRequest(
    UMPIRE_STATS + "?tournamentId=" + tournamentId + "&umpireId=" + umpireId
  );
  return response;
};

export const get_user_by_id = async (userId) => {
  console.log("userid", userId);
  let response = await doGetRequest(GET_USER_BY_ID + "?userId=" + userId);
  return response;
};
export const get_live_match_highlights = async (matchId, innNum) => {
  let response = await doGetRequest(
    LIVE_MATCH_HIGHLIGHTS + "?matchId=" + matchId + "&innNum=" + innNum
  );
  return response;
};

export const get_user_documnets = async (userId) => {
  let response = await doGetRequest(GET_USER_DOC + "?userId=" + userId);
  return response;
};

export const update_user_doc = async (id, file, name) => {
  let url = UPDATE_USER_DOC + "?userId=" + id + "&docName=" + name;
  console.log(url);
  console.log(file);
  const formData = new FormData();
  formData.append("doc", file);
  let response = await doPostRequestImageFile(url, formData);
  return response;
};
export const get_match_all_innings = async (matchId) => {
  let response = await doGetRequest(
    MATCHES_ALL_INNINGS + "?matchId=" + matchId
  );
  return response;
};

export const delete_player = async (playerId) => {
  let response = await doDeleteRequest(DELETE_PLAYER + "?playerId=" + playerId);
  return response;
};
export const get_country_states = async () => {
  let response = await doGetRequest(COUNTRY_STATES);
  return response;
};
export const get_state_districts = async (stateId) => {
  let response = await doGetRequest(STATE_DISTRICTS + "?stateId=" + stateId);
  return response;
};
export const get_district_cities = async (stateId, districtId) => {
  let response = await doGetRequest(
    DISTRICT_CITIES + "?stateId=" + stateId + "&districtId=" + districtId
  );
  return response;
};

export const create_fixture = async (data) => {
  const fixturedata = { matchInfo: data };
  let response = await doPostRequest(CREATE_FIXTURE, fixturedata);
  return response;
};

export const add_player_to_match = async (data) => {
  let response = await doPostRequest(ADD_PLAYER_TO_MATCH, data);
  return response;
};
export const add_offical_to_match = async (data) => {
  let response = await doPostRequest(ADD_OFFICALS_TO_MATCH, data);
  return response;
};
export const update_match_toss_info = async (data) => {
  let response = await doPostRequest(UPDATE_MATCH_TOSS_INFO, data);
  return response;
};
export const approve_user = async (data) => {
  let response = await doPostRequest(APPROVE_USER, data);
  return response;
};
export const reset_password = async (user) => {
  let response = await doPostRequest(RESET_PASSWORD, user);
  return response;
};

export const forgot_password = async (mobile) => {
  let response = await doPostRequest(FORGOT_PASSWORD, mobile);
  return response;
};

import {
  backgroundcolor,
  rowColor,
  txt_color,
  activeColor,
} from "./globalcolors";
export const customtableStyles = {
  headRow: {
    style: {
      borderRadius: "30px",
      backgroundColor: "var(--table-header)",
      color: "var(--text-color)",
      fontWeight: "bold",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      color: "white",
      margin: "0px",
    },
  },

  rows: {
    style: {
      borderRadius: "30px",
      color: "var(--text-color)",
      height: "60px",
      "&:nth-child(2n)": {
        backgroundColor: "var(--row-color)",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
};

export const customdocStyles = {
  headRow: {
    style: {
      borderRadius: "30px",
      backgroundColor: "var(--table-header)",
      color: "var(--text-color)",
      fontWeight: "bold",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      color: "white",
      margin: "0px",
    },
  },

  rows: {
    style: {
      borderRadius: "30px",
      color: "var(--text-color)",
      height: "100px",
      "&:nth-child(2n)": {
        backgroundColor: "var(--row-color)",
      },
    },
  },
};
export const custometableStylesmatches = {
  headRow: {
    style: {
      borderRadius: "30px",
      backgroundColor: "#101713",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      color: "white",
      center: true,
    },
  },
  rows: {
    style: {
      borderRadius: "30px",
      color: "#bbb",
    },
  },
};
export const custometableStylesdashboard = {
  headRow: {
    style: {
      borderRadius: "30px",
      backgroundColor: "#101713",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      color: "white",
      width: "200px",
      center: true,
    },
  },
  rows: {
    style: {
      borderRadius: "30px",
      color: "#bbb",
    },
  },
};

export const conditionalRowStyles = [
  {
    when: (row) => row,
    style: {
      "&:hover": {
        cursor: "pointer",
        color: "var( --active-border)",
      },
    },
  },
];

export const conditionalRowStylesTournament = [
  {
    when: (row) => row,
    style: {
      borderRadius: "30px",
      backgroundColor: "#0a0a0a",
      color: "#bbb",
    },
  },
];

export const conditionalRowStylesPlayer = [
  {
    when: (row) => row.PlayerId % 2 === 0,
    style: {
      borderRadius: "30px",
      backgroundColor: "#0a0a0a",
      color: "#bbb",
    },
  },
];

export const conditionalRowStylesMatches = [
  {
    when: (row) => row.id % 2 == 0,
    style: {
      borderRadius: "30px",
      backgroundColor: "#0a0a0a",
      color: "#bbb",
    },
  },
];
export const conditionalRowStylesUmpire = [
  {
    when: (row) => row.id % 2 === 0,
    style: {
      borderRadius: "30px",
      backgroundColor: "#0a0a0a",
      color: "#bbb",
    },
  },
];

export const conditionalRowStylesGround = [
  {
    when: (row) => row.id % 2 === 0,
    style: {
      borderRadius: "30px",
      backgroundColor: "#0a0a0a",
      color: "#bbb",
    },
  },
];

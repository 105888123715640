import React, { useEffect,useState } from 'react';
//import data from '../componants/data.json';
import {create_player} from '../api/serverWrapper'
let data = [];

function CricketBoards() {
    const [userData,setUserData] = useState({});

    let teamnames = [
 
     'Kangra District Cricket Association'  ,
     'District Cricket Association L&S' ,
     'District Cricket Association Una'  ,
     'Hamirpur Cricket Association'   ,
     'Cricket Association of Kinnaur'  ,
     'Cricket Association of Bilaspur'  ,
     'Cricket Association of Mandi'     , 
     'District Cricket Association Shimla' ,  
     'Solan District Cricket Association'  , 
     'Sirmour District Cricket Association'  , 
     'Kullu District Cricket Association'  ,   
     'Adhoc Committee Chamba'    ,              
     'Team Green'         ,                    
     'Team Red' 
    ];
 
     let startId = 25;
 
     useEffect(() => {
         const uniqueCenterNames = {};
 
           
         data.data.forEach(entry => {
             
             if (!uniqueCenterNames[entry.centerName]) {
                 uniqueCenterNames[entry.centerName] = true;
             }
         });
 
         
          
         console.log("data.data",data.data.length);
         
 
     }, []);
 
 
         
         
        
     const click = async ()=>{

        
         let matchingEntries =  data.data;
         
         for( let i =0 ; i <matchingEntries.length; i++ ){
            let entry = matchingEntries[i];
            let index = teamnames.indexOf(entry.centerName); 

            console.log("index",index);
             let u  = {
                 UserId: -1,
                 FirstName: entry.name,
                 DOB: entry.dob,
                 Gender: 1,
                 FatherName: entry.fathername,
             }
             if ( entry.surname !== ''){
                u.LastName = entry.surname;

             }
             let p = {
 
                 PlayerTypeId: 3,
                 BattingStyleId: 2,
                 BowlingStyleId: 2,
                 BowlingHandId: 1,
                 BattingHandId: 1,
                 userInfo: u,
                 TeamId: index+startId,
             }
             if (entry.regno === null) { p.RegistrationId =  ""+entry.regno};
            //  console.log(p);
             

             let resp =await create_player(p);
             console.log(resp);
            //  break;
            
         }

         
        
        }
    
  
    return (
        <div style={{marginTop:300}} >
            <div style={{width:100,backgroundColor:'red'}} className="" onClick={()=>click()} >
                click
            </div>
        </div>
    );
}

export default CricketBoards;

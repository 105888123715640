import React, { useEffect, useState } from "react";
import "../styles/fixtures.css";
import { useNavigate } from "react-router-dom";
import {
  get_player_data,
  get_player_stats,
  get_player_stats_all,
  get_team_stats,
  get_tournaments_type,
  get_user_documnets
} from "../api/serverWrapper";
import jsPDF from "jspdf";

import { playerInfo, showTeam } from "../componants/CommonFuncations";
import { getData } from "../utils/localstorage_constants";
import { USER_SYSTEM_ROLE } from "../utils/localstorage_variables";
import UserSystemRole from "../componants/UserSystemRole";
import AddMatchPlayers from "./AddMatchPlayers";
import MatchStatus from "../componants/MatchStatus";
import AddMatchOffical from "./AddMatchOffical";
import TossResult from "./TossResult";

function Fixtures(props) {
  const navigate = useNavigate();
  // let [matchstatus, setMatchStatus] = useState();
  let [isLoading, setIsLoading] = useState(false);
  let [isShowTeam, setIsShowTeam] = useState(false);
  const [teamStats, setTeamStats] = useState({});
  const [Players, setPalyers] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [playerStats, setPlayerStats] = useState([]);
  const [allData, setAllData] = useState(null);
  const [matchStats, setMatchStats] = useState([]);
  const [isPlayerInfo, setIsPlayerInfo] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnbowling, setBtnBowling] = useState(false);
  const [btnbatting, setBtnBatting] = useState(true);
  const [tournamentType, settournamentType] = useState([]);
  let [systemRole, setSystemRole] = useState();
  useEffect(() => {
    loadinitialData();
  }, []);

  const loadinitialData = async () => {
    isLoading = true;
    setIsLoading(isLoading);
    let tournamentTyperesp = await get_tournaments_type();
    settournamentType(tournamentTyperesp.data.tournaments);
    systemRole = getData(USER_SYSTEM_ROLE);
    setSystemRole(systemRole);
    // console.log("resp", resp.data);
    isLoading = false;
    setIsLoading(isLoading);
  };

  let { tournamnetmatches, matchstatus } = props;

  let data = tournamnetmatches?.sort((a, b) =>
    b.MatchDateTime.localeCompare(a.MatchDateTime)
  );

  const battingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "INNS",
      selector: (row) => row.BattingInnsCount,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.totalRuns,
      sortable: true,
      textAlign: "left",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "HS",
      selector: (row) => row.topScore,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.avgRuns,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "SR",
      selector: (row) => row.sr,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "100s",
      selector: (row) => row.hundreds,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "50s",
      selector: (row) => row.fifties,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      width: "70px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];
  const bowlingcolumn = [
    {
      name: "MATCH NAME",
      selector: (row) => row.match.MatchNameOrTitle,
      sortable: true,
      width: "30%",
    },
    {
      name: "OV",
      selector: (row) => row.oversBowled,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "MAIDEN",
      selector: (row) => row.maidens,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.runsGiven,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "WKTS",
      selector: (row) => row.wicketsTaken,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "3-FORS",
      selector: (row) => row.wicket3Fors,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "FIFERS",
      selector: (row) => row.wicket5Fors,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.bowlingAverage,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "ECON",
      selector: (row) => row.bowlingEconomy,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "BEST",
      selector: (row) => row.bowlingBest + "/" + row.RunsOnBest,
      sortable: true,
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];

  const closeTeamModel = () => {
    isShowTeam = false;
    setIsShowTeam(isShowTeam);
  };

  const handlePlayerInfo = async (player) => {
    setIsPlayerInfo(player);
    console.log(player);
    console.log(player.PlayerId);
    handlePlayerDoc(player.PlayerId);
    setPlayerId(player.PlayerId);
    try {
      let resp = await get_player_stats_all(player.PlayerId);
      console.log(resp.data.playerDetails);
      setAllData(resp.data.playerDetails);
      setPlayerStats(resp.data.summary);
      setMatchStats(resp.data.matchStats);
      console.log(allData);
    } catch (error) {
      console.error("Error fetching player stats:", error);
    }
  };

  const closePlayerInfo = () => {
    setIsPlayerInfo(null);
  };

  const handleTournament = async (event) => {
    setLoading(true);

    if (event.target.value !== "all") {
      try {
        let resp = await get_player_stats(event.target.value, playerId);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    } else {
      try {
        let resp = await get_player_stats_all(playerId);
        console.log(resp.data);
        setAllData(resp.data.playerDetails);
        setPlayerStats(resp.data.summary);
        setMatchStats(resp.data.matchStats);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    }
    setLoading(false);
  };
  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const togglebattingandbowling = () => {
    setBtnBatting(!btnbatting);
    setBtnBowling(!btnbowling);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const rowHeight = 10;
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 2;
    let startY = margin;

    function setBackground() {
      // Set text color to white
      doc.setTextColor("#ffffff");

      // Set background color to black for each page
      doc.setFillColor("#000000");
      doc.rect(0, 0, pageWidth, pageHeight, "F");
    }

    setBackground();
    doc.setFontSize(9);
    const imgData = isPlayerInfo.Player.User.ProfilePhotoUrl;
    const imgWidth = 50;
    const imgHeight = 50;
    const imgX = pageWidth - imgWidth - margin;
    const imgY = margin;
    doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Name : " +
        isPlayerInfo.Player.User.FirstName +
        " " +
        isPlayerInfo.Player.User.LastName,
      margin,
      startY + 15
    );
    doc.text(
      "Player Type : " + isPlayerInfo.Player.PlayerType.PlayerType,
      margin,
      startY + 21
    );

    const middle = pageWidth / 2;
    let startXLeft = margin;
    let startXRight = middle + margin;
    doc.setTextColor("#ffc52f");
    doc.text("Player Batting Summary", startXLeft, startY + 30);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#ffffff");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `No of Hundreds : ${playerStats.hundreds}`,
      startXLeft,
      startY + 45
    );
    doc.text(`No of Fifties : ${playerStats.fifties}`, startXLeft, startY + 55);
    doc.text(`Average Runs : ${playerStats.avgRuns}`, startXLeft, startY + 65);

    doc.text(`Total Runs : ${playerStats.totalRuns}`, startXRight, startY + 35);
    doc.text(`No of 6s : ${playerStats.sixes}`, startXRight, startY + 45);
    doc.text(`No of 4s : ${playerStats.fours}`, startXRight, startY + 55);
    doc.text(`Strike rate : ${playerStats.fours}`, startXRight, startY + 65);

    startY += 45;
    doc.setTextColor("#ffc52f");
    doc.text("Player Bowling Summary", startXLeft, startY + 30);
    doc.setTextColor("#ffffff");
    doc.text(
      `Matches Played : ${playerStats.matchCout}`,
      startXLeft,
      startY + 35
    );
    doc.text(
      `Bowling Economy : ${playerStats.bowlingEconomy}`,
      startXLeft,
      startY + 45
    );
    doc.text(
      `Wickets Taken : ${playerStats.wicketsTaken}`,
      startXLeft,
      startY + 55
    );
    doc.text(
      `Overs Bowled: ${playerStats.oversBowled}`,
      startXLeft,
      startY + 65
    );

    doc.text(
      `Total Wickets : ${playerStats.wicketsTaken}`,
      startXRight,
      startY + 35
    );
    doc.text(`Total Maiden : ${playerStats.maidens}`, startXRight, startY + 45);
    doc.text(
      `Wicket5Fors : ${playerStats.wicket5Fors}`,
      startXRight,
      startY + 55
    );
    doc.text(
      `Total RunsGiven : ${playerStats.runsGiven}`,
      startXRight,
      startY + 65
    );

    startY += 70;

    function addDataToPDF(stats, columnPositions, title) {
      startY += 10;
      doc.setFontSize(10);
      doc.setTextColor("#ffffff"); // Set text color to white
      doc.setFillColor("#101713"); // Set background color for table header column name
      doc.rect(margin, startY, pageWidth - 2 * margin, rowHeight, "F"); // Draw rectangle for header
      doc.setTextColor("#ffffff"); // Set text color to white for header

      // Render title inside the header with the specified background color and padding
      doc.text(title, margin, startY + 8);

      startY += 10;

      doc.setFontSize(8);
      const headerColumnWidths = columnPositions.map(({ name }) => {
        return (
          (doc.getStringUnitWidth(name) * 8) / doc.internal.scaleFactor + 4
        );
      });

      const totalWidth = headerColumnWidths.reduce(
        (total, width) => total + width,
        0
      );
      const spaceBetweenColumns =
        (pageWidth - 2 * margin - totalWidth) / (columnPositions.length - 1);
      let startX = margin;
      columnPositions.forEach(({ name }, index) => {
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0); // Remove border
        doc.setFillColor("#101713"); // Set background color for table cells
        const rectWidth = headerColumnWidths[index] + spaceBetweenColumns;
        doc.rect(startX, startY, rectWidth, rowHeight, "F");
        const lines = doc.splitTextToSize(name, headerColumnWidths[index]);
        doc.setTextColor("#ffffff"); // Set text color to white for table cells
        doc.text(lines, startX + 2, startY + 8, {
          lineHeightFactor: rowHeight / doc.internal.getLineHeight(),
        });

        startX += rectWidth;
      });
      startY += rowHeight;
      stats.forEach((data) => {
        let startX = margin;
        columnPositions.forEach(({ selector }, index) => {
          const value = selector(data);
          const rectWidth = headerColumnWidths[index] + spaceBetweenColumns;
          doc.rect(startX, startY, rectWidth, rowHeight, "S");
          doc.text(String(value), startX + 2, startY + 8);
          startX += rectWidth;
        });
        startY += rowHeight;
      });
    }

    addDataToPDF(matchStats, battingcolumn, "Batting Statistics");
    if (startY > pageHeight - margin) {
      doc.addPage();
      setBackground(); // Set background color for the new page
      startY = margin;
    }
    addDataToPDF(matchStats, bowlingcolumn, "Bowling Statistics");
    doc.save(`player_stats_${isPlayerInfo.Player.User.FirstName}.pdf`);
  };
  const handleTeam = async (row) => {
    console.log(row);
    setTeamData(row);
    let resp = await get_player_data(row.id);
    console.log(resp.data.playersList);
    setPalyers(resp.data.playersList);
    // console.log(resp.data.playersList);
    isShowTeam = true;
    setIsShowTeam(isShowTeam);

    let teamstats = await get_team_stats(row.id);
    // console.log(teamstats.data);
    setTeamStats(teamstats.data);
    // console.log(teamStats.testMatchCount);
  };

  const formatDate = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
  };

  const formatTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const options = { hour: "numeric", minute: "2-digit", hour12: true };
    return dateObj.toLocaleTimeString("en-US", options);
  };

  const handleMatchDetails = (item) => {
    // console.log("item", item);
    navigate("/scorecard", { state: { data: item } });
  };

  const matchStatus = (item) => {
    let resp = matchstatus.find((status) => status.id === item.MatchStatus);
    console.log(resp);
    return item.MatchResultDescription !== null ? (
      <div className="match_status">
        <span>{item.MatchResultDescription}</span>
      </div>
    ) : (
      <div className="match_status">
        <span>{resp?.Status}</span>
      </div>
    );
  };

  const [isSelectPlayersPopup, setISPlayerPopup] = useState(false);
  const [matchData, setMatchData] = useState(null);

  const handleSelectPlayerPopup = (item) => {
    setISPlayerPopup(true);
    setMatchData(item);
  };
  const handleSelectPlayerClose = () => {
    setISPlayerPopup(false);
    window.location.reload();
  };

  const [isOfficialPopup, setIsOfficialPopup] = useState(false);
  const handleOfficialPopup = (item) => {
    setIsOfficialPopup(true);
    setMatchData(item);
  };

  const handleOfficialclose = () => {
    setIsOfficialPopup(false);
    loadinitialData();
    window.location.reload();
  };

  const [isTossResultPopup, setIsTossResultPopup] = useState(false);
  const handleTossResultPopup = (item) => {
    setIsTossResultPopup(true);
    setMatchData(item);
  };
  const handleTossResultClose = () => {
    setIsTossResultPopup(false);
    loadinitialData();
    window.location.reload();
  };

  const addPlayerAndOfficilas = (item) => {
    return (
      <div className="d-flex flex-column ">
        <button
          className="add-player-btn"
          onClick={() => handleSelectPlayerPopup(item)}
        >
          Add Players
        </button>

        <button
          className="add-player-btn"
          onClick={() => handleOfficialPopup(item)}
        >
          Add Officals
        </button>
      </div>
    );
  };


  const [playerActiveTab, setPlayerActiveTab] = useState("playerinfo");
  const handleplayerTabs = (tab) => {
    setPlayerActiveTab(tab);
  };
  const [userDoc, setUserDoc] = useState([]);
  const [uploadDocPoup, setUploadDocPopup] = useState(false);
  const handlePlayerDoc = () => {
    setUploadDocPopup(!uploadDocPoup);
    if (isPlayerInfo) {
      getUserDoc(isPlayerInfo.Player.User.UserId);
    }
  };

  const getUserDoc = async (id) => {
    let docData = await get_user_documnets(id);
    console.log("docData", docData);

    let d = convertDataToArray(docData.data);
    setUserDoc(d);
  };

  const convertDataToArray = (data) => {
    return Object.keys(data).map((key) => ({
      fileName: key,
      fileUrl: data[key].fileUrl,
    }));
  };

  return (
    <div className="conatiner_fixtures">
      <div>
        {tournamnetmatches ? (
          <div className="matches_conatiner">
            <div className="header">
              <div className="match_date_time">
                <span>Date</span>
              </div>
              <div className="match_vs_logo_header">
                <span>Fixtures</span>
              </div>
              <div className="match_status_header">
                <span>Match Status</span>
              </div>
              <div className="btn_container_empty"></div>
            </div>
            {data.length !== 0 ? (
              data.map((item) => (
                <div className="match">
                  <div className="match_date_time">
                    <span className="date">
                      {formatDate(item.MatchDateTime)}
                    </span>
                    {/* <span className="time">
                    {formatTime(item.MatchDateTime)}
                  </span>{" "} */}
                  </div>
                  <div className="match_vs_logo">
                    <div
                      className="team"
                      onClick={() =>
                        systemRole === UserSystemRole.ADMIN.id &&
                        handleTeam(item.TeamA)
                      }
                    >
                      {item.TeamA.TeamLogoUrl ? (
                        <img
                          className="logo_size"
                          src={item.TeamA.TeamLogoUrl}
                          alt=""
                        />
                      ) : (
                        <img
                          className="logo_size"
                          src={require("../assets/images/team_logo.png")}
                          alt=""
                        />
                      )}
                      <span className="teamname">{item.TeamA.TeamName}</span>
                    </div>
                    <div className="vs">
                      <span>V/S</span>
                    </div>
                    <div
                      className="team"
                      onClick={() =>
                        systemRole === UserSystemRole.ADMIN.id &&
                        handleTeam(item.TeamB)
                      }
                    >
                      {item.TeamB.TeamLogoUrl ? (
                        <img
                          className="logo_size"
                          src={item.TeamB.TeamLogoUrl}
                          alt=""
                        />
                      ) : (
                        <img
                          className="logo_size"
                          src={require("../assets/images/team_logo.png")}
                          alt=""
                        />
                      )}
                      <span className="teamname">{item.TeamB.TeamName}</span>
                    </div>
                  </div>

                  {matchStatus(item)}

                  {(item.MatchStatus === MatchStatus.MATCH_STARTED.id ||
                    item.MatchStatus === MatchStatus.MATCH_COMPLETED.id ||
                    item.MatchStatus ===
                      MatchStatus.MATCH_TOSS_COMPLETED.id) && (
                    <div className="btn_container">
                      <button
                        className="btn"
                        onClick={() => handleMatchDetails(item)}
                      >
                         
                        Match Details
                      </button>
                    </div>
                  )}

                  {item.MatchStatus === MatchStatus.MATCH_SCHEDULED.id && (
                    <div className=""> {addPlayerAndOfficilas(item)}</div>
                  )}
                  {item.MatchStatus === MatchStatus.MATCH_PLAYERS_ADDED.id && (
                    <div className=""> {addPlayerAndOfficilas(item)}</div>
                  )}
                  {item.MatchStatus ===
                    MatchStatus.MATCH_OFFICIALS_ADDED.id && (
                    <div className=""> {addPlayerAndOfficilas(item)}</div>
                  )}
                  {item.MatchStatus === MatchStatus.MATCH_READY_TOSS.id && (
                    <div className="btn_container">
                      <button
                        className="btn"
                        onClick={() => handleTossResultPopup(item)}
                      >
                        Add Toss Result
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="empty">
                No fixtures select any other tournamnet
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              color: "#fff",
              marginTop: 100,
            }}
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {isShowTeam &&
          showTeam(
            teamData,
            closeTeamModel,
            teamStats,
            Players,
            handlePlayerInfo
          )}
        {isPlayerInfo &&
          playerInfo(
            playerStats,
            closePlayerInfo,
            handleTournament,
            renderTournamentTypeOptions,
            btnbatting,
            togglebattingandbowling,
            btnbowling,
            isPlayerInfo,
            generatePDF,
            allData,
            matchStats,
            battingcolumn,
            bowlingcolumn,
            loading,
            handleplayerTabs,
            playerActiveTab,
            userDoc,
            handlePlayerDoc,
          )}
      </div>
      {isSelectPlayersPopup && (
        <AddMatchPlayers
          onClose={handleSelectPlayerClose}
          matchData={matchData}
        />
      )}
      {isOfficialPopup && (
        <AddMatchOffical onClose={handleOfficialclose} matchData={matchData} />
      )}

      {isTossResultPopup && (
        <TossResult onClose={handleTossResultClose} matchData={matchData} />
      )}
    </div>
  );
}
export default Fixtures;

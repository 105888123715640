// sdm_match_inn_status_constants.js

class MatchInnStatus {
    static get NOT_STARTED() {
      return { id: 1, Status: 'Not Started' };
    }
  
    static get IN_PROGRESS() {
      return { id: 2, Status: 'In Progress' };
    }
  
    static get SUSPENDED() {
      return { id: 3, Status: 'Suspended' };
    }
  
    static get COMPLETED() {
      return { id: 4, Status: 'Completed' };
    }
  
    static get DECLARED() {
      return { id: 5, Status: 'Declared' };
    }
  
    static get FORFEITED() {
      return { id: 6, Status: 'Forfeited' };
    }
  
    static get ABANDONED() {
      return { id: 7, Status: 'Abandoned' };
    }
  
    static get IN_POWER_PLAY() {
      return { id: 8, Status: 'In Power Play' };
    }
  }
  
module.exports = MatchInnStatus;
  
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  customtableStyles,
  conditionalRowStylesTournament,
  conditionalRowStyles,
} from "../assets/tablestyle";
import "../styles/tournament.css";
import { tablecreateTheme } from "../assets/globalcolors";
import { EditIcon, DeleteIcon } from "../assets/SvgIcons";
import {
  get_tournaments_type,
  create_tournament,
  get_teams,
  tournament_types,
  update_tournament,
  update_image,
} from "../api/serverWrapper";
import Calendar from "react-calendar";
import ValidationModal from "./ValidationModal";
import Tournament from "../componants/Tournamentcreation";
import { useNavigate } from "react-router-dom";
import { TOURNAMENT_IMG_TITLE } from "../utils/constants";

function Tournaments() {
  const navigate = useNavigate();

  const [ispopupvisible, setIsPopupVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tournaments, setTournamnets] = useState([]);
  const [tournaments1, setTournamnets1] = useState([]);

  const [tournamenttypes, setTournamentTypes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tournament, setTournament] = useState(new Tournament());
  const [tournamentname, setTournamnetName] = useState();
  const [tournamentdescription, setTournamentDescription] = useState();
  const [tournamenttype, setTournamentType] = useState();
  const [tournamentgroupcount, setTournamnetGroupCount] = useState();
  const [tournamenthostteam, setTournamentHostTeam] = useState();
  const [tournamentstartdate, setTournamentStartDate] = useState(null);
  const [tournamentenddate, setTournamentEndDate] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState();
  let [isLoading, setIsLoading] = useState(false);
  const [respsucces, setRespSucces] = useState(false);
  const [showcalendarstart, setShowCalendarStart] = useState(false);
  const [showcalendarend, setShowCalendarEnd] = useState(false);
  let [selectedtournamentimg, setSelectedTournamentImg] = useState(null);
  let [tournamentimg, setTournamentImg] = useState("");
  createTheme("solarized", {
    background: {
      default: 'var(--main-background-color)',
    },
  });

  const get_api_data = async () => {
    isLoading = true;
    setIsLoading(isLoading);
    let tournamnets = await get_tournaments_type();
    setTournamnets(tournamnets.data.tournaments);
    setTournamnets1(tournamnets.data.tournaments);
    let teams = await get_teams();
    setTeams(teams.data.teams);
    let tournamenttypes = await tournament_types();
    setTournamentTypes(tournamenttypes.data);
    isLoading = false;
    setIsLoading(isLoading);
  };
  useEffect(() => {
    get_api_data();
  }, []);

  useEffect(() => {
    setTournamnetName(tournament?.TournamentName);
    setTournamentType(tournament?.TournamentType);
    setTournamentDescription(tournament?.TournamentDescription);
    setTournamnetGroupCount(tournament?.GroupCount);
    setTournamentHostTeam(tournament?.HostTeamId);
    setTournamentStartDate(tournament?.StartDate);
    setTournamentEndDate(tournament?.EndDate);
    setSelectedTournamentImg(tournament?.LogoUrl);
  }, [tournament]);

  const TournamentImage = ({ row }) => {
    console.log(row.LogoUrl);
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.LogoUrl ? (
          <img
            width={50}
            height={50}
            style={{
              borderRadius: "50%",
              marginRight: "15px",
            }}
            src={row.LogoUrl}
            alt=""
          />
        ) : (
          <div className="">
            <img
              width={50}
              height={50}
              style={{
                borderRadius: "50%",
                marginRight: "15px",
              }}
              src={require("../assets/images/team_logo.png")}
              alt=""
            />
          </div>
        )}
      </div>
    );
  };
  const columns = [
    {
      selector: (row) => <TournamentImage row={row} />,
      width: "90px",
    },
    {
      name: "Tournament Name",
      selector: (row) => row.TournamentName,
      sortable: true,
    },
    // {
    //   name: "Number of Groups",
    //   selector: (row) => row.GroupCount,
    //   sortable: true,
    // },
    {
      name: "Start ",
      selector: (row) => new Date(row.StartDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "End ",
      selector: (row) => new Date(row.EndDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Edit",
      selector: (row) =>
        row.id === tournament.id ? (
          <img
            style={{ height: "25px", width: "25px", marginRight: "25px" }}
            src={EditIcon}
            onClick={() => editTournament(row)}
          />
        ) : (
          <img
            style={{ height: "25px", width: "25px", marginRight: "25px" }}
            src={EditIcon}
            onClick={() => editTournament(row)}
          />
        ),
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "right",
        };
      },
    },
    {
      name: "Delete",
      selector: (row) => (
        <img style={{ height: "25px", width: "25px" }} src={DeleteIcon} />
      ),
      width: "100px",
    },
  ];

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      window.location.reload();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };

  const editTournament = (row) => {
    console.log("edit", row);
    setIsEdit(!isEdit);
    setTournament(row);
    togglepopup();
  };

  const togglepopup = () => {
    setIsPopupVisible(!ispopupvisible);
    if (isEdit) {
      setIsEdit(!isEdit);
      setTournament(new Tournament());
    }
    if (selectedtournamentimg) {
      setSelectedTournamentImg(null);
    }
  };

  const handleImg = (e) => {
    let file = e.target.files[0];
    if (file) {
      tournamentimg = file;
      setTournamentImg(tournamentimg);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedTournamentImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("selectedtournamentimg", selectedtournamentimg);
  };

  const handleBoard = (row) => {
    console.log("row", row);
    navigate("/leaderboard", { state: { data: row } });
  };

  const handleTournamentDetails = async (event) => {
    const { name, value } = event.target;
    if (name === "TournamentName") {
      setTournamnetName(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    } else if (name === "TournamentType") {
      setTournamentType(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    } else if (name === "TournamentDescription") {
      setTournamentDescription(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    } else if (name === "GroupCount") {
      setTournamnetGroupCount(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    } else if (name === "HostTeamId") {
      setTournamentHostTeam(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    } else if (name === "EndDate") {
      setTournamentEndDate(value);
      setTournament({
        ...tournament,
        [name]: value,
      });
    }
  };

  const handlestartDate = (date) => {
    setTournamentStartDate(date);
    let formateDate = formatDate(date);
    setTournament({
      ...tournament,
      ["StartDate"]: formateDate,
    });
    setShowCalendarStart(false);
  };
  const handleendDate = (date) => {
    setTournamentEndDate(date);
    let formateDate = formatDate(date);
    setTournament({
      ...tournament,
      ["EndDate"]: formateDate,
    });
    setShowCalendarEnd(false);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const createTournamnet = async () => {
    delete tournament.LogoUrl;
    delete tournament.id;
    delete tournament.GroupCount;
    delete tournament.HostTeamId;
    if (tournament.TournamentName) {
      if (tournament.TournamentDescription) {
        if (tournament.StartDate) {
          if (tournament.EndDate) {
            // if (tournament.GroupCount) {
            if (tournament.TournamentType) {
              // if (tournament.HostTeamId) {
              let resp = await create_tournament(tournament);
              let id = resp.data ? resp.data.id : "";
              if (resp.code === 200) {
                if (tournamentimg !== "" && id !== "") {
                  console.log("teamlogo", tournamentimg);
                  try {
                    const resp = await update_image(
                      id,
                      tournamentimg,
                      TOURNAMENT_IMG_TITLE
                    );
                    console.log(resp);
                    if (resp.code === 200) {
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
                succespopup();
                setMessage("Tournament Created...!!!");
                togglepopup();
              } else {
                validationpopupvisible();
                setMessage(resp.message);
              }
              // } else {
              //   validationpopupvisible();
              //   setMessage("Choose Host Team");
              // }
            } else {
              validationpopupvisible();
              setMessage("Choose Tournament Type");
            }
            // }
            // else {
            //   validationpopupvisible();
            //   setMessage("Enter Group Count");
            // }
          } else {
            validationpopupvisible();
            setMessage("Select Tournament End Date");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Tournament Start Date");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Tournament Description");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Tournamnet Name");
    }
  };

  const updateTournament = async () => {
    delete tournament.LogoUrl;
    delete tournament.GroupCount;
    delete tournament.HostTeamId;
    if (tournament.TournamentName) {
      if (tournament.TournamentDescription) {
        if (tournament.StartDate) {
          if (tournament.EndDate) {
            // if (tournament.GroupCount) {
            if (tournament.TournamentType) {
              // if (tournament.HostTeamId) {
              let resp = await update_tournament(tournament);
              console.log(resp);
              if (resp.code === 200) {
                if (tournamentimg !== "") {
                  console.log("teamlogo", tournamentimg);
                  try {
                    const resp = await update_image(
                      tournament.id,
                      tournamentimg,
                      TOURNAMENT_IMG_TITLE
                    );
                    console.log(resp);
                    if (resp.code === 200) {
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
                succespopup();
                setMessage("Tournament Updated...!!!");
                togglepopup();
              } else {
                validationpopupvisible();
                setMessage(resp.message);
              }
              // } else {
              //   validationpopupvisible();
              //   setMessage("Choose Host Team");
              // }
            } else {
              validationpopupvisible();
              setMessage("Choose Tournament Type");
            }
            // } else {
            //   validationpopupvisible();
            //   setMessage("Enter Group Count");
            // }
          } else {
            validationpopupvisible();
            setMessage("Select Tournament End Date");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Tournament Start Date");
        }
      } else {
        validationpopupvisible();
        setMessage("Enter Tournament Description");
      }
    } else {
      validationpopupvisible();
      setMessage("Enter Tournamnet Name");
    }
  };

  const changeHandler = (event) => {
    const filteredData = tournaments
      .filter((item) =>
        item.TournamentName.toLowerCase().includes(
          event.target.value.toLowerCase()
        )
      )
      .sort((a, b) => a.TournamentName.localeCompare(b.TournamentName));
    setTournamnets1(filteredData);
  };

  const addandeditTournamentpopup = () => {
    return (
      <div className="overlay">
        <div className="tournament_popup">
          <div className="pop_up_add_text_container_tournament d-flex me-5">
            <span className="pop_up_add_text">
              {isEdit ? "Edit Tournament" : "Add Tournament"}
            </span>
            <input
              type="file"
              id="tournamentimgUrl"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="tournamentimgUrl"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Team Logo</div> */}
              {selectedtournamentimg ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedtournamentimg}
                    alt="Selected Image"
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/team_logo.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="pop_up_inputs">
            <input
              className="tournament_details_select"
              type="text"
              name="TournamentName"
              placeholder="Tournament Name"
              value={tournamentname}
              onChange={handleTournamentDetails}
            />
            <select
              className="tournament_details_select1"
              name="TournamentType"
              value={tournamenttype}
              onChange={handleTournamentDetails}
            >
              <option>Choose Tournament Type</option>
              {Array.isArray(tournamenttypes) &&
                tournamenttypes.map((Tournamenttypes) => (
                  <option key={Tournamenttypes.id} value={Tournamenttypes.id}>
                    {Tournamenttypes.Type}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <input
              className="tournament_details_select"
              name="TournamentDescription"
              placeholder="Description"
              value={tournamentdescription}
              onChange={handleTournamentDetails}
            />
            {/* <input
              className="tournament_details_select1"
              name="GroupCount"
              placeholder="Group Count"
              value={tournamentgroupcount}
              onChange={handleTournamentDetails}
            /> */}
          </div>
          <div className="pop_up_inputs">
            {/* <select
              className="tournament_details_select"
              name="HostTeamId"
              value={tournamenthostteam}
              onChange={handleTournamentDetails}
            >
              <option>Choose Host Team</option>
              {Array.isArray(teams) &&
                teams.map((Team) => (
                  <option key={Team.id} value={Team.id}>
                    {Team.TeamName}
                  </option>
                ))}
            </select> */}
            <input
              className="tournament_details_select"
              placeholder="Select Start Date"
              value={
                tournamentstartdate
                  ? new Date(tournamentstartdate).toLocaleDateString()
                  : tournamentstartdate
              }
              onClick={() => setShowCalendarStart(!showcalendarstart)}
              readOnly
            />
            {showcalendarstart && (
              <Calendar
                className="calender"
                value={tournamentstartdate}
                onChange={handlestartDate}
              />
            )}
            <input
              className="tournament_details_select"
              placeholder="End Date"
              value={
                tournamentenddate
                  ? new Date(tournamentenddate).toLocaleDateString()
                  : tournamentenddate
              }
              onClick={() => setShowCalendarEnd(!showcalendarend)}
              readOnly
            />
            {showcalendarend && (
              <Calendar
                className="calender"
                value={tournamentenddate}
                onChange={handleendDate}
              />
            )}
          </div>
          <div className="popup_buttons">
            <button className="popup_button_cancel" onClick={togglepopup}>
              cancel
            </button>

            <button
              className="popup_button_save"
              onClick={isEdit ? updateTournament : createTournamnet}
            >
              {isEdit ? "Save" : "Create"}
            </button>
          </div>
          <button className="close-button" onClick={togglepopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      </div>
    );
  };
  return (
    <div className="container_tournamnet">
      <div className="layout_conatiner">
        <div>
          <div className="btn_layout">
            <div className="search_content">
              <div className="mt-2">
                <span className="search_text">Tournament List</span>
              </div>
              <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search"
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div>
              <button className="btn1" onClick={() => togglepopup()}>
                Create
              </button>
            </div>
          </div>
          <div className="tournament_table">
            {isLoading === false ? (
              <DataTable
                columns={columns}
                data={tournaments1}
                customStyles={customtableStyles}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeader
                theme="solarized"
                fixedHeaderScrollHeight="750px"
                onRowClicked={(row) => handleBoard(row)}
              ></DataTable>
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  marginTop: 100,
                }}
              >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {ispopupvisible && addandeditTournamentpopup()}
        {respsucces && validationpopup(succespopup)}
      </div>
    </div>
  );
}
export default Tournaments;

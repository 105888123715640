import React, { useEffect, useState } from "react";
import "../styles/home.css";
import ReactPlayer from "react-player";
import {
  get_batting_shot_data,
  get_fielding_type,
  get_home_videos,
  get_live_match_highlights,
  get_match_data,
  get_match_info,
  get_match_inn_info,
  get_match_inn_running_info,
  get_celb_images,
  get_match_all_innings,
} from "../api/serverWrapper";
import { BiLike } from "react-icons/bi";
import { BiComment } from "react-icons/bi";
import { CiShare2 } from "react-icons/ci";

import { format } from "date-fns";
import MatchTypes from "../componants/MatchTypes";
import Pusher from "pusher-js";
import { useNavigate } from "react-router-dom";
import {
  generateCommentary,
  getBowlerStats,
  getPlayerList,
  getWicketkeeperName,
} from "../componants/CommonFuncations";
import MatchStatus from "../componants/MatchStatus";
import { getData } from "../utils/localstorage_constants";
import { IS_LOGGED } from "../utils/localstorage_variables";
import Videothumbnail from "./Videothumbnail";

export default function Home() {
  const navigate = useNavigate();

  let [videoId, setVideoId] = useState(0);
  let [matches, setMatches] = useState(new Map());
  let [videourl, setVideoURL] = useState();
  let [videos, setVideos] = useState(null);
  let [celbsData, setCelbsData] = useState([]);
  let [innInfo, setInnInfo] = useState(new Map());
  let [currentinn, setCurrentInn] = useState(new Map());
  let [isclicked, setIsclicked] = useState(false);
  let [commentry, setCommentry] = useState(new Map());
  let [commentryteam, setCommentryTeam] = useState(new Map());
  let [isloaded, setIsLoaded] = useState(false);
  let [playersData, setPlayersData] = useState(new Map());
  let [wicketkeeper, setWicketKeeper] = useState(new Map());
  let [matchallinnings, setMatchAllInnings] = useState(new Map());
  // let [innInfo, setInnInfo] = useState(null);
  const [currOverScore, setCurrOverScore] = useState([]);
  const [preOverScore, preCurrOverScore] = useState([]);
  let [matchesdata, setMatchesdata] = useState([]);
  let [logged, setLogged] = useState(false);
  let [matchIds, setMatchIds] = useState(new Map());
  let [shottypes, setShotTypes] = useState();
  let [fieldingtypes, setFieldingTypes] = useState();

  let isloaded_data = false;

  useEffect(() => {
    logged = getData(IS_LOGGED);
    setLogged(logged);
    loadinitialdata();
  }, []);

  const get_ss = async (id) => {
    // console.log("getss", id);
    const pusher = new Pusher("c3050db1a9b68d16f530", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("sdm-" + id);

    channel.bind("scoring", async function (data) {
      isloaded_data = false;
      await load(data.matchId, data.innI);
    });

    return () => {
      pusher.disconnect();
    };
  };
  const loadinitialdata = async () => {
    if (isloaded_data) {
      return;
    }
    isloaded_data = true;
    isloaded = true;
    setIsLoaded(isloaded);
    let shotTypes = await get_batting_shot_data();
    shottypes = shotTypes.data;
    setShotTypes(shottypes);
    let fieldingTypes = await get_fielding_type();
    fieldingtypes = fieldingTypes.data;
    setFieldingTypes(fieldingtypes);
    let respm = await get_match_data(null, true);
    matchesdata = respm.data.matches.filter(
      (m) => m.MatchStatus === MatchStatus.MATCH_STARTED.id
    );
    setMatchesdata(matchesdata);

    if (matchesdata.length <= 5) {
      let next = 5 - matchesdata.length;
      // console.log("next", next);
      for (let i = 0; i < next; i++) {
        if (
          respm.data.matches[i].MatchStatus === MatchStatus.MATCH_COMPLETED.id
        ) {
          matchesdata.push(respm.data.matches[i]);
          setMatchesdata(matchesdata);
          // next++;
        }
      }
    }

    console.log("matchesdata", matchesdata);
    let videoresp = await get_home_videos();

    videos = Object.values(videoresp.data);
    setVideos(videos);

    videourl = videos[videoId]?.fileUrl;
    setVideoURL(videourl);

    let celbImages = await get_celb_images();
    celbsData = Object.values(celbImages.data.info);
    setCelbsData(celbsData);

    for (let i = 0; i < matchesdata.length; i++) {
      // let minfo = await get_match_info(matchesdata[i].id);
      matches.set(matchesdata[i].id, matchesdata[i]);
      await get_ss(matchesdata[i].id);
      await load(matchesdata[i].id, matchesdata[i].CurrInnNum);
    }
    setMatches(matches);
    isloaded = false;
    setIsLoaded(isloaded);
  };

  let [var1, setvar1] = useState(0);

  const load = async (matchId, innId) => {
    let m = matches.get(matchId);
    if (m === null) {
      return;
    }

    let matchinfo = await get_match_info(matchId);
    playersData.set(matchId, getPlayerList(matchinfo.data));
    setPlayersData(playersData);
    let matchinn = await get_match_inn_info(matchId, m.CurrInnNum);
    innInfo.set(matchId, matchinn.data);
    let allinning = await get_match_all_innings(matchId);
    matchallinnings.set(matchId, allinning.data);

    let bowlingplayersdata = await bowlingPlayer(matchinn.data, matchinfo.data);

    let wk = getWicketkeeperName(matchinfo.data, bowlingplayersdata);
    wicketkeeper.set(matchId, wk);
    setWicketKeeper(wicketkeeper);
    // bowlingPlayers.set(matchId, bowlingplayersdata);
    // setBowlingPlayers(bowlingPlayers);

    let live = await get_live_match_highlights(matchId, m.CurrInnNum);
    let runnData = await get_match_inn_running_info(
      m.id,
      m.CurrInnNum,
      matchinn.data?.CurrOverNum - 1
    );
    currentinn.set(matchId, runnData.data);
    // runnData.data.oversData.currOver;
    live?.data?.map((item) =>
      item.IsSix || item.IsFour || item.IsWicket
        ? (commentry.set(item.id, item),
          setCommentry(commentry),
          matchinn.data.BattingTeam.id === m.TeamA.id
            ? (commentryteam.set(item.id, m.TeamA),
              setCommentryTeam(commentryteam),
              matchIds.set(item.id, matchId),
              setMatchIds(matchIds))
            : (commentryteam.set(item.id, m.TeamB),
              setCommentryTeam(commentryteam),
              matchIds.set(item.id, matchId),
              setMatchIds(matchIds)))
        : ""
    );

    // console.log("commentry", commentry);
    // console.log("commentryteam", commentryteam);

    let new_matches = new Map(matches);
    let new_currentinn = new Map(currentinn);
    let new_innInfo = new Map(innInfo);

    matches = new_matches;
    currentinn = new_currentinn;
    innInfo = new_innInfo;

    setCurrentInn(currentinn);
    setMatches(matches);
    setInnInfo(innInfo);
    setMatchAllInnings(matchallinnings);
    console.log("matches", matches);
    console.log("innInfo", innInfo);
    // console.log("commentry", commentry);
    // console.log("commentryteam", commentryteam);
  };

  const bowlingPlayer = (inningInfo, matchInfo) => {
    const players =
      inningInfo?.BowlingTeamId === matchInfo.TeamAId
        ? matchInfo.TeamAPlayerList
        : matchInfo.TeamBPlayerList;
    let d = players.filter((item) => item.InnId === inningInfo.InningNumber);
    return d;
  };

  const previousVideo = () => {
    if (videoId > 0) {
      // console.log("previous");
      videoId -= 1;
      setVideoId(videoId);
      setVideoURL(videos[videoId]);
    } else {
      // console.log("initial");
      videoId = videos.length - 1;
      setVideoId(videoId);
      setVideoURL(videos[videoId]);
    }
  };
  const nextVideo = () => {
    if (videoId < videos.length - 1) {
      // console.log("next", videoId, videos.length);

      videoId += 1;
      setVideoId(videoId);
      setVideoURL(videos[videoId]);
    } else {
      // console.log("initial");

      videoId = 0;
      setVideoId(videoId);
      setVideoURL(videos[videoId]);
    }
  };

  const toScorecard = (row) => {
    isclicked = false;
    setIsclicked(isclicked);
    setTimeout(() => {
      navigate("/scorecard", { state: { data: row } });
    }, 100);
  };
  const handleScoreCard = (row) => {
    isclicked = true;
    setIsclicked(isclicked);

    // console.log("row", row);
    setTimeout(() => {
      toScorecard(row);
    }, 100);
  };

  const matchDateandTime = (dateandtime) => {
    let formattedDateTime = format(
      new Date(dateandtime),
      "d MMMM',' yyyy h:mm a"
    );
    return formattedDateTime;
  };

  const getTargetDisplayStr = (innInfo, match) => {
    let str = "";
    if (match.MatchType === MatchTypes.TEST.id) {
      if (innInfo?.InningNumber === 4) {
        str =
          innInfo?.TargetRuns > 0
            ? "Target : " + (innInfo?.TargetRuns + 1)
            : "";
      } else if (innInfo?.InningNumber === 2 || innInfo?.InningNumber === 3) {
        let val = 0;
        if (innInfo?.InningNumber === 2) {
          val =
            innInfo?.TargetRuns > 0
              ? -innInfo?.TargetRuns
              : innInfo?.TargetRuns;
        } else {
          if (match.IsMatchFollowOn) {
            val =
              innInfo?.TargetRuns > 0
                ? -innInfo?.TargetRuns
                : innInfo?.TargetRuns;
          } else {
            val =
              innInfo?.TargetRuns > 0
                ? innInfo?.TargetRuns
                : innInfo?.TargetRuns;
          }
        }
        //alert(val)
        let diff = val + innInfo?.InnTotalRuns;

        str = diff < 0 ? "Trail by : " + -diff : "Leading by : " + diff;
      }
    } else {
      if (innInfo?.InningNumber === 2) {
        str =
          innInfo?.TargetRuns > 0
            ? "Target : " + (innInfo?.TargetRuns + 1)
            : "";
      }
    }
    return <div>{str}</div>;
  };

  const matchTeamScores = (item, index) => {
    return matchallinnings.get(item.id)?.map((innings) => (
      <div className="match_scoring_board">
        <div className="d-flex align-item-center gap-1">
          <h3>
            {innings.BattingTeamId === innInfo.get(item.id)?.BattingTeamId
              ? innInfo.get(item.id)?.BattingTeam
                ? innInfo.get(item.id)?.BattingTeam.TeamShortName
                : ""
              : innInfo.get(item.id)?.BowlingTeam
              ? innInfo.get(item.id)?.BowlingTeam.TeamShortName
              : ""}
            :
          </h3>
          <h4>
            {innings.InnTotalRuns}/{innings.TotalWickets}
          </h4>
          <h5>
            ({innings.CurrOverNum - 1}.
            {innings.CurrBallNum != 0 ? innings.CurrBallNum - 1 : 0})(
            {innings.InningNumber} inn)
          </h5>
        </div>
      </div>
    ));
  };

  const matchScoreCard = (item, index) => {
    return (
      <div className="match_scoring_board">
        <div className="d-flex align-item-center justify-content-between  ">
          <div className="d-flex align-item-center gap-1">
            <h3>
              {innInfo.get(item.id)?.BattingTeam
                ? innInfo.get(item.id)?.BattingTeam.TeamShortName
                : ""}{" "}
              :{" "}
            </h3>
            <h4>
              {innInfo.get(item.id)?.InnTotalRuns}/
              {innInfo.get(item.id)?.TotalWickets}
            </h4>
            <h5>
              ({innInfo.get(item.id)?.CurrOverNum - 1}.
              {innInfo.get(item.id)?.CurrBallNum != 0
                ? innInfo.get(item.id)?.CurrBallNum - 1
                : 0}
              )
            </h5>
          </div>
          <div>
            {innInfo.get(item.id)?.InnTotalRuns !== 0 ? (
              <h5 className="">
                CRR :
                {(
                  innInfo.get(item.id)?.InnTotalRuns /
                  (innInfo.get(item.id)?.CurrOverNum -
                    1 +
                    (innInfo.get(item.id)?.CurrBallNum - 1) / 6)
                ).toFixed(2)}
              </h5>
            ) : (
              <h5>CRR : 0.0</h5>
            )}
          </div>
        </div>
        <div>
          <h5>{getTargetDisplayStr(innInfo.get(item.id), item)}</h5>
        </div>
        <div className="match_striker">
          <div className=" d-flex gap-2">
            <span className="match_nonstriker_striker_name ">
              {currentinn.get(item.id)?.striker?.Player.User.FirstName}
            </span>
            <img
              style={{ height: "16px", width: "16px" }}
              src={require("../assets/images/bat.png")}
            />
          </div>
          <div className="d-flex gap-2  ">
            <span className="match_nonstriker_striker_runs">
              {currentinn.get(item.id)?.striker?.RunsScored}
            </span>
            <span className="match_nonstriker_striker_balls">
              ({currentinn.get(item.id)?.striker?.BallsPlayed})
            </span>
          </div>
        </div>
        <div className="match_nonstriker ">
          <span className="match_nonstriker_striker_name">
            {currentinn.get(item.id)?.nonStriker?.Player.User.FirstName}
          </span>

          <div className="d-flex gap-2 ">
            <span className="match_nonstriker_striker_runs">
              {" "}
              {currentinn.get(item.id)?.nonStriker?.RunsScored}{" "}
            </span>
            <span className="match_nonstriker_striker_balls">
              ({currentinn.get(item.id)?.nonStriker?.BallsPlayed})
            </span>
          </div>
        </div>

        <div className="match_bowler">
          <div className=" d-flex gap-2">
            <span className="match_bowler_name">
              {currentinn.get(item.id)?.bowler?.Player.User.FirstName}
            </span>
            <img
              style={{ height: "16px", width: "16px" }}
              src={require("../assets/images/ball.png")}
            />
          </div>

          <div className="d-flex gap-2">
            <span className="match_bowler_stats">
              {currentinn.get(item.id)?.bowler?.WicketsTaken}-
              {currentinn.get(item.id)?.bowler?.RunsGiven}
            </span>
            {currentinn.get(item.id)?.bowler ? (
              <span className="match_bowler_stats ">
                ({getBowlerStats(currentinn.get(item.id)?.bowler).v})
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {item.MatchStatus === MatchStatus.MATCH_STARTED.id && (
          <div className=" d-flex my-2">
            <span className="mt-2 over-text">Over : </span>
            <div className="gap-2 d-flex ms-2">
              {currentinn
                .get(item.id)
                ?.oversData?.currOver.map((score, index) => (
                  <div
                    className="home_ball"
                    style={
                      score.IsWicket
                        ? { backgroundColor: "#380a11", color: "#fff" }
                        : null
                    }
                  >
                    <div>
                      {score.TotalRun}
                      <div style={{ fontSize: 8 }}>
                        {score.IsWideBall ? "WD" : ""}
                        {score.IsNoBall ? "NB" : ""}
                        {score.IsWicket ? "W" : ""}
                        {score.IsBye ? "b" : ""}
                        {score.IsLegbye ? "lb" : ""}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const matchCards = () => {
    return matchesdata?.map((item, index) => (
      <div
        onClick={() => (logged ? handleScoreCard(item) : "")}
        key={index}
        className={isclicked ? "card_home_clicked" : "card_home"}
      >
        <div className="card_header">
          <span className="tournament_name">
            {/* {item.TournamentOrSeries.TournamentName} */}
            {item.MatchNameOrTitle} - {item.MatchTypeRef.type}
          </span>

          {item.MatchStatus === MatchStatus.MATCH_STARTED.id && (
            <span className="live"> 🔴LIVE</span>
          )}
        </div>
        <div className="card_vs mx-3">
          <div className="team_home">
            <img
              className="team_img"
              src={
                item.TeamA.TeamLogoUrl
                  ? item.TeamA.TeamLogoUrl
                  : require("../assets/images/team_logo.png")
              }
            />
            <span className="team_name">{item.TeamA.TeamShortName}</span>
          </div>
          <div className="team_home">
            <img
              src={
                item.TeamA.TeamLogoUrl
                  ? item.TeamB.TeamLogoUrl
                  : require("../assets/images/team_logo.png")
              }
              alt=""
              className="team_img"
            />
            <span className="team_name">{item.TeamB.TeamShortName}</span>
          </div>
        </div>
        {item.MatchStatus === MatchStatus.MATCH_STARTED.id &&
          matchScoreCard(item, index)}

        {item.MatchStatus === MatchStatus.MATCH_COMPLETED.id &&
          matchTeamScores(item, index)}
        {item.MatchStatus === MatchStatus.MATCH_COMPLETED.id && (
          <div className="defeat ms-1">
            <span>{item.MatchResultDescription}</span>
          </div>
        )}
        <div className="match_details_conatiner">
          {/* <span className="match_time">
            {matchDateandTime(item.MatchDateTime)}
          </span> */}
          <div className="match_venue_container ms-1">
            <span className="match_venue">Ground,{item.Ground.Groundname}</span>
            <button
              className="match_more_info_container"
              onClick={() => (logged ? handleScoreCard(item) : "")}
            >
              <span className="match_more_info">More Info</span>
            </button>
          </div>
        </div>
      </div>
      //     <div key={index}>
      //     Key: {index}, Value:  {JSON.stringify(item)}
      // </div>
    ));
  };

  const liveMatchesHighlights = () => {
    // Convert the commentry Map to an array, sort it by timestamp, and then reverse it
    const sortedCommentryArray = Array.from(commentry)
      .sort(([keyA, valueA], [keyB, valueB]) => {
        const dateA = new Date(valueA.BallDateTime);
        const dateB = new Date(valueB.BallDateTime);
        return dateA - dateB;
      })
      .reverse();

    return sortedCommentryArray.map(([key, value]) => (
      <div className="highligts" key={key}>
        <div className="highlights_padding">
          <div className="highligts_match_header">
            {commentryteam.get(key).TeamLogoUrl ? (
              <img
                src={commentryteam.get(key).TeamLogoUrl}
                alt=""
                className="highligts_match_header_img"
              />
            ) : (
              <img
                src={require("../assets/images/team_logo.png")}
                alt=""
                className="highligts_match_header_img"
              />
            )}

            <div className="highligts_match_header_teamname_container">
              <span className="highligts_match_header_teamname">
                {commentryteam.get(key).TeamName}
              </span>
              <span className="date_time">
                {" "}
                {new Date(value.BallDateTime).toLocaleString()}
              </span>
            </div>
          </div>
          <div className="highligts_match_commentry_container">
            <span className="highligts_match_commentry">
              {generateCommentary(
                playersData.get(matchIds.get(key)),
                commentry.get(key),
                shottypes,
                fieldingtypes,
                wicketkeeper.get(matchIds.get(key))
              )}
            </span>
          </div>
        </div>
        <ReactPlayer
          controls={true}
          url={value.videoUrls.c4}
          width="100%"
          height="100%"
        />
        <div className="highligts_match_like_share_comment_conatiner">
          <div>
            <BiLike />
            <span style={{ fontSize: "14px" }}>Like</span>
          </div>
          <div>
            <BiComment />
            <span style={{ fontSize: "14px" }}>Comment</span>
          </div>
          <div>
            <CiShare2 />
            <span style={{ fontSize: "14px" }}>Share</span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="app-container">
      {/* {isloaded ? (
        <div className="loading_style ">
          <div class="spinner-border wating-cursor" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : ( */}
      <div>
        <div className="video-container">
          <div className="video">
            <ReactPlayer
              url={videourl}
              height="100%"
              width="100%"
              controls
              playing
              muted
            />

            {/* <div className="video_container_video">
              <h1 className="video_container_video_text">Captains Corner</h1>
              <span className="video_container_video_text">
                Inspiring greatness,definning Indian Cricket history
              </span>
              {videos.map((item, index) => {
              <span
                className={videoId ? "active_video_bar" : "inactive_video_bar"}
              >
                {"  "}
              </span>
              })}
            </div> */}
            {/* <div className="video_navigation_buttons_container">
              <button
                onClick={previousVideo}
                className="video_navigation_buttons"
              >
                Previous
              </button>
              <button onClick={nextVideo} className="video_navigation_buttons">
                Next
              </button>
            </div> */}
            {/* </div> */}
          </div>
          {/* {innInfo.length !== 0 ? ( */}
          <div className="card-container">{matchCards()}</div>
          {/* ) : (
          <div className="card-container">Loading...</div>
        )} */}
        </div>
        <div className="live_match_text_container">
          <span className="live_match_text">Highlights</span>
        </div>
        <div className="live_matches_container">
          {/* <div>
              <span>Live Matches</span>
            </div> */}
          <div className="present_match_higlights">
            {liveMatchesHighlights()}
          </div>
          {/* Celebs Photos */}
          <div className="celebs_container">
            {celbsData.map((item) => (
              // <div className="advertisment_container">
              <div className="advertisment_details_container">
                <img
                  className="celebs_img"
                  height={250}
                  width={250}
                  src={item?.fileUrl}
                />
                <div className="celbs_name_details">
                  <h3>{item.Name}</h3>
                  <p>{item.title}</p>
                  <p>{item.Description}</p>
                </div>
                {/* </div> */}
              </div>
            ))}
          </div>
          {/* <div className="advertisment_container">
              <div className="advertisment_details_container">
                <img
                  style={{
                    width: "90%",
                    alignSelf: "center",
                    margin: "2%",
                  }}
                  src={require("../assets/navbar_icons/sdm_logo.png")}
                  alt=""
                />
                <div
                  style={{
                    width: "90%",
                    color: "#d9d9d9",
                    marginInline: "2%",
                    alignSelf: "center",
                    fontSize: "14px",
                  }}
                >
                  <h3 style={{ fontSize: "16px" }}>About HPCA</h3>
                  <p>
                    The Himachal Pradesh Cricket Association was formed with the
                    objective of augmentation of game of Cricket in the state of
                    Himachal, in the year 1960.
                  </p>
                </div>
              </div>
            </div> */}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "../styles/register.css";
import {
  get_gender,
  create_user,
  get_designations,
  create_official,
  update_image,
  get_districts,
  send_adhar_otp,
  adhar_verification,
  get_player_type,
  create_player,
  player_registration_check,
  user_phonenumber_validation,
} from "../api/serverWrapper";
import {
  IS_LOGGED,
  USER_DATA,
  USER_ID,
  USER_SYSTEM_ROLE,
} from "../utils/localstorage_variables";

import Calendar from "react-calendar";

import UserInfo from "../componants/UserInfo";
import { useNavigate } from "react-router-dom";
import Playerdetails from "../componants/Playerdetails";
import OfficialDetails from "../componants/Official";
import ValidationModal from "./ValidationModal";
import { OFFICIAL_IMG_TITLE, PLAYER_IMG_TITLE } from "../utils/constants";
import { saveData } from "../utils/localstorage_constants";
import UserSystemRole from "../componants/UserSystemRole";
function Register() {
  const navigate = useNavigate();
  let [activeTab, setActiveTab] = useState("hpcamember");

  const [errorMessage, setErrorMessage] = useState("");
  const [usererroemessage, setUserErrorMessage] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  let [genders, setGenders] = useState([]);
  let [player, setPlayer] = useState(new Playerdetails());
  let [officialData, setOfficialData] = useState(new OfficialDetails());

  let [user, setUser] = useState(new UserInfo());
  let [selectedGender, setSelectedGender] = useState(1);
  let [playertypes, setPlayerTypes] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState();
  let [designations, setDesignations] = useState();
  let [selectedImage, setSelectedImage] = useState(null);
  let [profileImg, setProfileImg] = useState("");
  let [districts, setDistricts] = useState();
  let [adharnumber, setAdharNumber] = useState();
  let [enteredotp, setEnteredOTP] = useState();
  let [requestid, setRequestId] = useState();
  let [selecteddesignation, setSelectedDesigantion] = useState();
  const [progressprocess, setProgressProcess] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState();
  let [adharphoto, setAdharPhoto] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [Dob, setDob] = useState(null);

  useEffect(() => {
    loadInitialdata();
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  const loadInitialdata = async () => {
    let gender = await get_gender();
    genders = gender.data;
    setGenders(genders);
    let district = await get_districts();
    districts = district.data;
    setDistricts(districts);
    let desRes = await get_designations();
    let newdesRes =desRes.data.filter((id)=> id.id !== 1);
    designations =  newdesRes;
    console.log(designations);
    setDesignations(designations);

    let playertype = await get_player_type();
    playertypes = playertype.data;
    setPlayerTypes(playertypes);
    console.log(playertypes);
  };

  const handleImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImg(e.target.files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("profileImg", profileImg);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const handleRegistrationId = async (e) => {
  //   let { name, value } = e.target;
  //   setUserErrorMessage(null);
  //   setProgressProcess(false);
  //   if (value) {
  //     let resp = await player_registration_check(value);
  //     if (resp.data.player === null) {
  //       setPlayer((prevPlayer) => ({
  //         ...prevPlayer,
  //         [name]: value,
  //       }));
  //       setProgressProcess(true);
  //     } else {
  //       setUserErrorMessage("User already Registered Please Go to Login");
  //     }
  //   }
  // };
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) || value === "") {
      setUser({ ...user, [name]: value });
      setErrorMessage("");
    } else {
      setErrorMessage("Mobile number should contain only numbers.");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleTabClick = (active) => {
    user = new UserInfo();
    setErrorMessage("");
    setUser(user);
    activeTab = active;
    setActiveTab(activeTab);
  };
  const validateExistUserPhonenumber = async () => {
    let exist = false;
    let resp = await user_phonenumber_validation(user.PhoneNumber);
    console.log("respph", resp.data);
    if (resp.data) {
      exist = false;
      console.log("out");
    } else {
      exist = true;
      console.log("in");
    }
    console.log("exist", exist);
    return exist;
  };

  const handleInputDetails = (e) => {
    let { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    console.log(user);
  };

  const handleDob = (date) => {
    setDob(date);
    let dateformat = formatDate(date);

    console.log(dateformat);
    setUser((prevUser) => ({
      ...prevUser,
      ["DOB"]: dateformat,
    }));
    setShowCalendar(false);
    console.log(user);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDesignation = (e) => {
    let { value } = e.target;
    selecteddesignation = value;
    setSelectedDesigantion(selecteddesignation);
    if (selecteddesignation !== 1) {
      officialData.DesignationId = selecteddesignation;
    }
    console.log(value);
  };

  const handleDistrict = (e) => {
    let { value } = e.target;
    player.DistrictId = value;
    setPlayer(player);

    officialData.DistrictId = value;
    setOfficialData(officialData);
  };
  const handleAdhar = (e) => {
    let { value } = e.target;
    adharnumber = value;
    setAdharNumber(adharnumber);
  };

  const handlSendOTP = async () => {
    let resp = await send_adhar_otp({ aadhaarNo: adharnumber });
    if (resp.code === 200) {
      requestid = resp.data.request_id;
      setRequestId(requestid);
      setMessage("Otp sent to your registered mobile number");
      setIsValid(!isValid);
      // alert("Otp sent to your registered mobile number");
      console.log(resp);
    }
  };

  const handleEnterOTP = (e) => {
    let { value } = e.target;
    enteredotp = value;
    setEnteredOTP(enteredotp);
  };

  const handleAdharVerification = async () => {
    let resp = await adhar_verification({
      requestId: requestid,
      otp: enteredotp,
      aadhaarNo: adharnumber,
    });

    if (resp.code === 200) {
      user.KycDataId = resp.data.id;
      setMessage("Adhar verified succesfully");
      setIsValid(!isValid);
      // alert("adhar verified succesfully");
      // let decodedImageData = atob(resp.data.AadharPhoto);
      adharphoto = resp.data.AadharPhoto;
      setAdharPhoto(adharphoto);
      console.log(adharphoto);
    }
    console.log(resp);
  };

  const handleGender = (e) => {
    let { name, value } = e.target;
    selectedGender = value;
    setSelectedGender(value);
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePlayer = (e) => {
    let { name, value } = e.target;
    // setPlayer((prevPlayer) => ({
    //   ...prevPlayer,
    //   [name]: value,
    // }));
    player.PlayerTypeId = value;
    console.log(player);
  };

  const handleRegistrationId = (e) => {
    let { name, value } = e.target;
    setPlayer({
      ...player,
      [name]: value,
    });

    setOfficialData({
      ...officialData,
      [name]: value,
    });
  };

  const registerFan = async () => {
    delete user.UserId;
    delete user.DOB;
    delete user.FatherName;
    delete user.EMail;
    delete user.Gender;
    delete user.KycDataId;
    delete user.LastName;
    // delete user.SystemRole;
    user.SystemRole = UserSystemRole.FAN.id;
    if (user.FirstName) {
      if (user.PhoneNumber) {
        if (validatePhoneNumber(user.PhoneNumber)) {
          if (await validateExistUserPhonenumber()) {
            if (user.Password) {
              if (user.Password === confirmpassword) {
                let resp = await create_user(user);
                console.log("resp", resp);
                if (resp.code === 200) {
                  setMessage("Fan registerd successfully");
                  setIsValid(!isValid);
                  saveData(IS_LOGGED, true);
                  saveData(USER_ID, resp.data.UserId);
                  saveData(USER_SYSTEM_ROLE, resp.data.SystemRole);
                  setUser(new UserInfo());
                  setTimeout(() => navigate("/", { replace: true }), [3000]);
                } else {
                  setMessage("Fan registeration Unsuccessfull");
                  setIsValid(!isValid);
                }
              } else {
                setMessage("password and confirm password dont match");
                setIsValid(!isValid);
              }
            } else {
              setMessage("Set Password");
              setIsValid(!isValid);
            }
          } else {
            setMessage(
              "The provided mobile number already registered try with any other mobile number"
            );
            setIsValid(!isValid);
          }
        } else {
          setMessage("Enter valid phone number");
          setIsValid(!isValid);
        }
      } else {
        setMessage("Please Enter Phone number");
        setIsValid(!isValid);
      }
    } else {
      setMessage("Please Enter Name");
      setIsValid(!isValid);
    }
  };

  const registerOfficial = async () => {
    if (selecteddesignation === "1") {
      player.userInfo = user;
      // delete user.DistrictId;
      user.SystemRole = UserSystemRole.MEMBER.id;
      if (user.KycDataId === null) {
        delete user.KycDataId;
        console.log("player", player);
      }
      if (user.EMail === null) {
        delete user.EMail;
      }
      delete player.TeamId;
      delete player.BattingStyleId;
      delete player.BowlingStyleId;
      delete player.playerId;
      console.log("user", user);
    } else {
      officialData.User = user;
      delete officialData.id;
      if (officialData.RegistrationId === null) {
        delete officialData.RegistrationId;
      }
      user.SystemRole = UserSystemRole.MEMBER.id;
      officialData.CategoryId = 1;
      officialData.Country = "india";
      if (user.KycDataId === null) {
        delete user.KycDataId;
      }
      if (user.LastName === null) {
        delete user.LastName;
      }
      if (user.DOB === null) {
        delete user.DOB;
      }
      if (user.EMail === null) {
        delete user.EMail;
      }
      delete officialData.User.FatherName;
      console.log("officialData", officialData);
    }
    if (player.DistrictId || officialData.DistrictId) {
      if (officialData.DesignationId) {
        if (user.FirstName) {
          if (validatePhoneNumber(user.PhoneNumber)) {
            if (await validateExistUserPhonenumber()) {
              if (user.Password) {
                if (user.Password === confirmpassword) {
                  let resp;
                  if (selecteddesignation === "1") {
                    if (player.PlayerTypeId) {
                      console.log("play");
                      resp = await create_player(player);
                    } else {
                      setMessage("Please Select Player Type");
                      setIsValid(!isValid);
                    }
                  } else {
                    console.log("off");
                    resp = await create_official(officialData);
                  }
                  console.log(resp?.data);
                  if (resp?.code === 200) {
                    let userresp = resp.data;
                    let imgresp;
                    console.log("userresp", userresp, userresp.SystemRole);
                    if (profileImg) {
                      if (selecteddesignation === "1") {
                        imgresp = update_image(
                          userresp.UserId,
                          profileImg,
                          OFFICIAL_IMG_TITLE
                        );
                      } else {
                        imgresp = update_image(
                          userresp.UserId,
                          profileImg,
                          PLAYER_IMG_TITLE
                        );
                      }
                    }
                    console.log(imgresp);
                    if (resp.data.User.IsApproved) {
                      saveData(IS_LOGGED, true);
                      saveData(USER_ID, userresp.UserId);
                      saveData(USER_SYSTEM_ROLE, resp.data.User.SystemRole);
                      setUser(new UserInfo());
                      setTimeout(
                        () => navigate("/", { replace: true }),
                        [3000]
                      );
                    }

                    setMessage(
                      "User Registered Succesfully,We will proced with further procedure and notify you"
                    );
                    setIsValid(!isValid);
                  } else {
                    setMessage("User Registeration Unsuccesfull..!");
                    setIsValid(!isValid);
                    console.log(resp);
                  }
                } else {
                  setMessage("password and confirm password dont match");
                  setIsValid(!isValid);
                }
              } else {
                setMessage("Set Password");
                setIsValid(!isValid);
              }
            } else {
              setMessage(
                "The provided mobile number already registered try with any other mobile number"
              );
              setIsValid(!isValid);
            }
          } else {
            setMessage("Enter valid phone number");
            setIsValid(!isValid);
          }
        } else {
          setMessage("Please Enter First Name");
          setIsValid(!isValid);
        }
      } else {
        setMessage("Please select Designation");
        setIsValid(!isValid);
      }
    } else {
      setMessage("Please select District");
      setIsValid(!isValid);
    }
  };

  const handleNavigation = () => {
    navigate("/login", { replace: true });
  };

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };
  return (
    <div className="register_tabs">
      <div className="tabs">
        <div
          className={`tab ${
            activeTab === "hpcamember" ? "activetab1" : "inactivetab1"
          }`}
          onClick={() => handleTabClick("hpcamember")}
        >
          HPCA member
        </div>
        <div
          className={`tab ${
            activeTab === "fans" ? "activetab2" : "inactivetab2"
          }`}
          onClick={() => handleTabClick("fans")}
        >
          Fans
        </div>
      </div>{" "}
      {activeTab === "hpcamember" && (
        <div className="register_popup">
          <div className="pop_up_text_container d-flex ">
            <span className="pop_up_text_register">Register HPCA Member</span>
            <input
              type="file"
              id="plyImg"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="plyImg"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Player Img</div> */}
              {selectedImage ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedImage}
                    alt="Selected Image"
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/profile-icon.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}

              {/* {adharphoto ? (
            <div className="d-flex gap-2 align-items-center close-doc">
              <img
                height={60}
                width={60}
                src={"data:image/png;base64," + adharphoto}
                // alt="Selected Image"
                className="profile-img"
                style={{ borderRadius: 18 }}
              />
            </div>
          ) : (
            <div>
              {" "}
              <img
                src={require("../assets/images/profile-icon.png")}
                alt=""
                height={44}
                width={44}
                className="profile-img"
              />
            </div>
          )} */}
            </label>
          </div>
          <div className="register_pop_up_inputs">
            <input
              className="user_details"
              type="text"
              name="RegistrationId"
              placeholder="Enter Registartion Id"
              onChange={handleRegistrationId}
            />
          </div>
          {usererroemessage && (
            <span style={{ color: "red" }}>{usererroemessage}</span>
          )}
          <div>
            <div className="register_pop_up_inputs">
              <select
                className="user_details"
                name="DistrictId"
                onChange={handleDistrict}
              >
                <option>Choose District</option>
                {Array.isArray(districts) &&
                  districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.Name}
                    </option>
                  ))}
              </select>
              <select
                className="user_details"
                name="DesignationId"
                onChange={handleDesignation}
              >
                <option>Choose Designation</option>
                {Array.isArray(designations) &&
                  designations.map((designations) => (
                    <option key={designations.id} value={designations.id}>
                      {designations.Title}
                    </option>
                  ))}
              </select>
            </div>
            {selecteddesignation === "1" && (
              <div className="register_pop_up_inputs">
                <select
                  className="user_details"
                  name="PlayerTypeId"
                  onChange={handlePlayer}
                >
                  <option>Choose Player Type</option>
                  {Array.isArray(playertypes) &&
                    playertypes.map((playerType) => (
                      <option key={playerType.id} value={playerType.id}>
                        {playerType.PlayerType}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="register_pop_up_inputs">
              <input
                className="user_details"
                type="text"
                name="FirstName"
                placeholder="Enter Adhar Number"
                onChange={handleAdhar}
              />
              {user.KycDataId ? (
                ""
              ) : (
                <button className="send_verify_otp" onClick={handlSendOTP}>
                  Send OTP
                </button>
              )}
              {user.KycDataId ? (
                ""
              ) : (
                <input
                  className="user_details"
                  type="text"
                  name="FirstName"
                  placeholder="Enter Adhar OTP"
                  onChange={handleEnterOTP}
                />
              )}
              {user.KycDataId ? (
                ""
              ) : (
                <button
                  className="send_verify_otp"
                  onClick={handleAdharVerification}
                >
                  Verify OTP
                </button>
              )}
            </div>

            <div className="register_pop_up_inputs">
              <input
                className="user_details"
                type="text"
                name="FirstName"
                placeholder="First Name"
                onChange={handleInputDetails}
              />
              <input
                className="user_details"
                type="text"
                name="LastName"
                placeholder="Last Name"
                onChange={handleInputDetails}
              />
            </div>
            <div className="register_pop_up_inputs">
              <input
                className="user_details"
                type="text"
                name="FatherName"
                placeholder="Father Name"
                onChange={handleInputDetails}
              />
              <select
                className="user_details"
                name="Gender"
                value={selectedGender}
                onChange={handleGender}
              >
                <option disabled>Select Gender</option>
                {Array.isArray(genders) &&
                  genders.map((Gender) => (
                    <option key={Gender.id} value={Gender.id}>
                      {Gender.Gender}
                    </option>
                  ))}
              </select>
            </div>
            <div className="register_pop_up_inputs">
              <input
                type="text"
                className="player_details_select"
                value={Dob ? Dob.toLocaleDateString() : Dob}
                placeholder="Select Date of Birth"
                onClick={() => setShowCalendar(!showCalendar)}
                readOnly
              />

              {showCalendar && (
                <Calendar
                  className="calender"
                  value={Dob}
                  onChange={handleDob}
                />
              )}
              <input
                className="user_details"
                name="PhoneNumber"
                placeholder="Phone number"
                maxLength={10}
                minLength={10}
                value={user.PhoneNumber}
                onChange={handlePhoneChange}
              />
            </div>
            {errorMessage && (
              <span style={{ color: "red" }}>{errorMessage}</span>
            )}

            <div className="register_pop_up_inputs">
              <input
                className="user_details"
                type="password"
                name="Password"
                id="password"
                placeholder="set Password"
                value={user.Password}
                onChange={handleInputDetails}
              />
              {/* <button onClick={togglePasswordVisibility}>
          {showPassword ? "Hide" : "Show"} Password
        </button> */}
              <input
                className="user_details"
                type="password"
                placeholder="Confirm Password"
                onChange={handleConfirmPassword}
              />
              {/* <button onClick={togglePasswordVisibility}>
          {showPassword ? "Hide" : "Show"} Password
        </button> */}
            </div>
            <input
              className="user_details"
              type="email"
              name="EMail"
              placeholder="Mail"
              onChange={handleInputDetails}
            />
          </div>

          <div className="button">
            <button
              type="submit"
              className="registerbtn"
              onClick={registerOfficial}
            >
              Register
            </button>
          </div>
          <div className="hpca_member">
            <span>Only HPCA members are allowed to register</span>
          </div>
          <div className="button">
            <span>HPCA member already have account?</span>
            <button className="nav_login" onClick={handleNavigation}>
              Go to Login
            </button>
          </div>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      )}
      {activeTab === "fans" && (
        <div className="register_popup">
          <div className="pop_up_text_container d-flex ">
            <span className="pop_up_text_register">Register Fans</span>
          </div>
          <div className="register_pop_up_inputs">
            <input
              className="user_details"
              type="text"
              name="FirstName"
              placeholder="Name"
              onChange={handleInputDetails}
            />
          </div>
          <div className="register_pop_up_inputs">
            <input
              className="user_details"
              name="PhoneNumber"
              placeholder="Phone number"
              maxLength={10}
              minLength={10}
              value={user.PhoneNumber}
              onChange={handlePhoneChange}
            />
            {/* <button className="registerbtn">send otp</button> */}
          </div>
          {errorMessage && activeTab === "fans" && (
            <span style={{ color: "red" }}>{errorMessage}</span>
          )}
          <div className="register_pop_up_inputs">
            {/* <input
              className="user_details"
              type="text"
              placeholder="Enter OTP"
            /> */}
            <input
              className="user_details"
              type="password"
              name="Password"
              id="password"
              placeholder="set Password"
              value={user.Password}
              onChange={handleInputDetails}
            />
            <input
              className="user_details"
              type="password"
              placeholder="Confirm Password"
              onChange={handleConfirmPassword}
            />
          </div>

          <div className="button">
            <button type="submit" className="registerbtn" onClick={registerFan}>
              Register
            </button>
          </div>
          <div className="button">
            <span> already have account?</span>
            <button className="nav_login" onClick={handleNavigation}>
              Go to Login
            </button>
          </div>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      )}
    </div>
  );
}

export default Register;

import React, { useState, useEffect } from "react";
import "../styles/board.css";
import Leaderboard from "../screen/Leaderboard";
import PointsTable from "../screen/PointsTable";
import { useLocation } from "react-router-dom";

import {
  get_match_data,
  get_players,
  get_tournament_leaderboard,
  get_tournament_team_points,
  get_tournaments_type,
  get_match_status,
  get_teams,
} from "../api/serverWrapper";
import Fixtures from "./Fixtures";
import AddMatch from "./AddMatch";

function Board() {
  const { state } = useLocation();
  const receivedData = state?.data || null;
  const [activeTab, setActiveTab] = useState("Fixtures");
  let [tournamnetdetails, setTournamentDetails] = useState([]);
  let [tournamnetmatches, setTournamentMatches] = useState();
  let [tournamentteampoints, setTournamentTeamPoints] = useState(null);
  let [tournamentType, setTournamentType] = useState(0);
  let [tournamentid, setTournamentId] = useState();
  let [tournaments, setTournaments] = useState([]);
  let [matchstatus, setMatchStatus] = useState();
  let [isloading, setIsLoading] = useState(false);
  const [tournametData,setTournametData] = useState(receivedData || null);
  const get_api_data = async () => {
    let tournamentsdata = await get_tournaments_type();
    tournaments = tournamentsdata.data.tournaments;
    setTournaments(tournaments);
    // console.log("tournaments", tournaments);
    let resp = await get_match_status();
    matchstatus = resp.data;
    setMatchStatus(matchstatus);
    if (receivedData) {
      // console.log("receivedData", receivedData);
      tournamentid = receivedData.id;
      setTournamentId(tournamentid);
      await handleApis(receivedData.id);
    } else {
      tournamentid = tournaments[0].id;
      setTournamentId(tournamentid);
      await handleApis(tournamentid);
    }
  };
  useEffect(() => {
    get_api_data();
  }, []);
  const hanbleTabClick = (tab) => {
    console.log("tab", tab);
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };
  const handleTournament = async (event) => {
    const selectedTournamentId = event.target.value;
    let tId = parseInt(event.target.value)
    console.log("selectedTournamentId", selectedTournamentId);

    let selectedTournament = tournaments.find((t)=>t.id == tId);
    setTournametData(selectedTournament);

    if (selectedTournamentId !== "") {
      tournamentid = selectedTournamentId;
      setTournamentId(tournamentid);
      await handleApis(selectedTournamentId);
    }
  };

  const handleApis = async (tournamentId) => {
    isloading = true;
    setIsLoading(isloading);
    let leadboard = await get_tournament_leaderboard(tournamentId);
    tournamnetdetails = leadboard.data;
    setTournamentDetails(tournamnetdetails);
    let matches = await get_match_data(tournamentId, true);
    tournamnetmatches = matches.data.matches;
    setTournamentMatches(tournamnetmatches);
    let teampoints = await get_tournament_team_points(tournamentId);
    tournamentteampoints = teampoints.data.poitsData;
    tournamentType = teampoints.data.type;
    setTournamentType(tournamentType);
    setTournamentTeamPoints(tournamentteampoints);
    // console.log("tournamentteampoints", tournamentteampoints);
    console.log("teampoints", teampoints);
    // console.log("tournamnetmatches", tournamnetmatches);
    isloading = false;
    setIsLoading(isloading);
  };
  const [isTeamPopup, setISTeamPopup] = useState(false);
  const openAddTeam = () => {
    setISTeamPopup(true);
  };
  const openAddTeamclose = () => {
    setISTeamPopup(false);
    window.location.reload();
  };

  return (
    <section className="conatiner_board">
      <div className="tab_container">
        <div className="d-flex ">
          <button
            className={
              activeTab === "Fixtures"
                ? "active-front-board"
                : "front-btn-board"
            }
            onClick={() => hanbleTabClick("Fixtures")}
          >
            {" "}
            Fixtures
          </button>
          <button
            className={
              activeTab === "Pointstable"
                ? "active-back-point"
                : "back-btn-point"
            }
            onClick={() => hanbleTabClick("Pointstable")}
          >
            {" "}
            Points Table{" "}
          </button>
          <button
            className={
              activeTab === "Leaderboard"
                ? "active-back-board"
                : "back-btn-board"
            }
            onClick={() => hanbleTabClick("Leaderboard")}
          >
            {" "}
            Leader Board
          </button>
        </div>
        <div className="search_content_board">
          {receivedData && (
            <div
              style={{ width: 200 }}
              className="update-btn me-2"
              onClick={openAddTeam}
            >
              Add Fixtures
            </div>
          )}
          {isTeamPopup && (
            <AddMatch onClose={openAddTeamclose} receivedData={tournametData} />
          )}
          <div>
            <span className="search_text_board">Select Tournament</span>
          </div>
          <div>
            <select
              className="combobox_board"
              name="TeamId"
              value={tournamentid}
              onChange={handleTournament}
            >
              <option value="">Select Tournament</option>
              {Array.isArray(tournaments) &&
                tournaments.map((Tournamnet) => (
                  <option key={Tournamnet.id} value={Tournamnet.id}>
                    {Tournamnet.TournamentName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      {isloading ? (
        <div className="loading_style ">
          <div class="spinner-border wating-cursor" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {activeTab === "Leaderboard" && (
            <Leaderboard tournamnetdetails={tournamnetdetails} />
          )}
          {activeTab === "Pointstable" && (
            <PointsTable
              tournamentteampoints={tournamentteampoints}
              tournamentType={tournamentType}
            />
          )}
          {activeTab === "Fixtures" && (
            <Fixtures
              tournamnetmatches={tournamnetmatches}
              matchstatus={matchstatus}
            />
          )}
        </div>
      )}
    </section>
  );
}
export default Board;

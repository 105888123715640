import React from "react";
import '../styles/analyseotherbtn.css'

function AnalyseOtherBtn({handleDeclareOrEndInning,handleOtherBtn,handleAddPlayerPopup}) {

  const Impact_Player ="Impact Player";
  const Replaced_Player ="Replaced Player";
  const Concussion_Substitute ="Concussion Substitute";
    
  return (
    <div className="other-popup">
      <div className="other-btn-popup">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 "> Other Functionality </h1>
          <div className="close" onClick={()=>handleOtherBtn()} >X</div>
        </div>
        <div className="all-btn-container mx-4">
          <button className="update-btn  mx-2" onClick={()=>handleDeclareOrEndInning()} >End / Declare Inn</button>
          <button className="update-btn  mx-2"  >Draw</button>
          <button className="update-btn  mx-2">Tie</button>
          <button className="update-btn  mx-2" onClick={()=>handleAddPlayerPopup(Impact_Player)} > Add Impact Player </button>
          <button className="update-btn  mx-2" onClick={()=>handleAddPlayerPopup(Replaced_Player)} >Add Replaced Player</button>
          <button className="update-btn  mx-2" onClick={()=>handleAddPlayerPopup(Concussion_Substitute)} >Add Concussion Substitute</button>
          <button className="update-btn  mx-2"> Retired hurt</button>
          <button className="update-btn  mx-2">Retired out</button>
          <button className="update-btn  mx-2">Timed out</button>
          <button className="update-btn  mx-2">Penalty runs</button>
          <button className="update-btn  mx-2">End over</button>
          <button className="update-btn  mx-2">Revert Inning </button>
        </div>
      </div>
    </div>
  );
 }

export default AnalyseOtherBtn;

import "../styles/addmatchplayers.css";
import React, { useState, useEffect, useRef } from "react";
import matchcreation from "../styles/matchcreation.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable, { createTheme } from "react-data-table-component";
import { EditIcon, DeleteIcon } from "../assets/SvgIcons";
import {
  customtableStyles,
  conditionalRowStylesUmpire,
  conditionalRowStyles,
} from "../assets/tablestyle";

import {
  get_official_category,
  get_official_data,
  get_designations,
  add_offical_to_match,
} from "../api/serverWrapper";
import AddOfficialsToMatch from "../componants/AddOfficialsToMatch";
import ValidationModal from "./ValidationModal";

function AddMatchOffical({ onClose, matchData }) {
  console.log("matchData={matchData} : ", matchData);
  let matchName = matchData.MatchNameOrTitle;
  let matchId = matchData.id;
  const [OfficialDesiganation, SetOfficialDesigantion] = useState([]);
  const [officialData, setofficialData] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [filteredOfficials, setFilteredOfficials] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedOfficial, setSelectedOfficial] = useState("");
  const [error, setError] = useState("");
  let [matchOfficals, setMatchOfficals] = useState(new AddOfficialsToMatch());

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  //{officialType:"player",officalName:"kiran"}

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let resp = await get_official_data();
    console.log(resp);
    let off=resp.data.officials.filter((item)=> item.User.IsApproved === 1);
    setofficialData(off);

    let desigination = await get_designations();
    let desiData = desigination.data.filter((id) => id.id !== 1 && id.id !== 3);
    SetOfficialDesigantion(desiData);
    console.log(desigination);
  };

  const handleDesignationChange = (e) => {
    const selectedDesignationId = e.target.value;
    setSelectedDesignation(selectedDesignationId);
    const filtered = officialData.filter(
      (official) => official.DesignationId === parseInt(selectedDesignationId)
    );
    setFilteredOfficials(filtered);
  };

  const handleOfficialChange = (e) => {
    setSelectedOfficial(e.target.value);
  };

  const renderOfficials = () => {
    return filteredOfficials.map((item) => (
      <option key={item.UserId} value={item.UserId}>
        {item.User.FirstName} {item.User.LastName}
      </option>
    ));
  };
  const renderDesigantionOptions = () => {
    return OfficialDesiganation.map((item) => (
      <option value={item.id}>{item.Title}</option>
    ));
  };

  const handleDelete = (row) => {
    setTableData(tableData.filter((item) => item !== row));
  };

  const handleAdd = () => {
    if (selectedDesignation && selectedOfficial) {
      const official = filteredOfficials.find(
        (item) => item.UserId === parseInt(selectedOfficial)
      );
      console.log(official);
      const designation = OfficialDesiganation.find(
        (item) => item.id === parseInt(selectedDesignation)
      );

      const newEntry = {
        officialType: designation.Title,
        officialName: `${official.User.FirstName} ${official.User.LastName}`,
        officialid: official.id,
        officialRoleId: official.DesignationId,
      };

      const isDuplicate = tableData.some(
        (entry) => entry.officialid === newEntry.officialid
      );

      if (isDuplicate) {
        setValidationData("don't add duplicate umpire and officials.");
        return;
      } else {
        setTableData([...tableData, newEntry]);
        // Clear any previous error message
      }
    }
  };

  const officalcolumn = [
    {
      name: "Official Type",
      selector: (row) => row.officialType,
      center: true,
      width: "150px",
    },
    {
      name: "Official Name",
      selector: (row) => row.officialName,
      center: true,
    },
    {
      name: "Delete",
      selector: (row) => (
        <img style={{ height: "25px", width: "25px" }} src={DeleteIcon} />
      ),
      width: "100px",
      cell: (row) => (
        <img
          style={{ height: "25px", width: "25px", cursor: "pointer" }}
          src={DeleteIcon}
          alt="delete"
          onClick={() => handleDelete(row)}
        />
      ),
    },
  ];

  const handleSave = async () => {
    matchOfficals.matchId = matchId;
    setMatchOfficals(matchOfficals);

    if (selectedDesignation === "2") {
      const officialsOfSelectedDesignation = tableData.filter(
        (entry) =>
          entry.officialType ===
          OfficialDesiganation.find((d) => d.id === 2).Title
      );
      if (officialsOfSelectedDesignation.length < 3) {
        setError("You must select  3 upmires");
        return;
      } else {
        setError("");
      }
    }
    // proceed with save logic
    console.log("Saving data:", tableData);
    if (tableData.length < 1) {
      setValidationData("Add Upmires & Officials ");
      return;
    }
    let otrOfficials = [];
    tableData.forEach((off) => {
      if (off.officialType === "Umpire") {
        matchOfficals.Umpire1Id = tableData[0].officialid;
        matchOfficals.Umpire2Id = tableData[1].officialid;
        matchOfficals.Umpire3Id = tableData[2].officialid;
        // matchOfficals.Umpire4Id = tableData[3].officialid;
        setMatchOfficals(matchOfficals);
      }

      if (off.officialType !== "Umpire") {
        console.log(off.officialid);

        otrOfficials.push({
          officialRoleId: off.officialRoleId,
          officialId: off.officialid,
        });

        matchOfficals.otherOfficials = otrOfficials;
        setMatchOfficals(matchOfficals);
      }
    });

    console.log("matchOfficals : ", matchOfficals);
    try {
      let resp = await add_offical_to_match(matchOfficals);
      console.log(resp);
      if (resp.code === 200) {
        setValidationData("umpries and Officals Added Scceesfully.");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setValidationData(`${resp.message}`);
      }
    } catch (error) {
      console.log(error);
    }
     
  };

  const [validationData, setValidationData] = useState(null);

  const closeValidationModel = () => {
    setValidationData(null);
  };
  return (
    <div className="add-match-players-popup">
      <div className="add-match-players-offical-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 "> Select Officials </h1>
          <div className="close" onClick={() => onClose()}>
            X
          </div>
        </div>
        <div className="d-flex  justify-content-between me-4">
          {/* <div className="d-flex align-items-center">
            <h3 className="tournament-name"> Tournament Name </h3>
            <h3 className="type-name"> : Ipl</h3>
          </div> */}
          <div className="d-flex align-items-center">
            <h3 className="tournament-name"> Match Name </h3>
            <h3 className="type-name">: {matchName}</h3>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center me-4 mt-2">
          <div className="d-flex align-items-center ms-3">
            <div className="d-flex align-items-center">
              <label style={{ color: "var(--title-color)" }}>
                Official Type{" "}
              </label>
              <select
                className="select-comobo"
                id="officialType"
                onChange={handleDesignationChange}
                value={selectedDesignation}
              >
                <option value=""> Select Official Type </option>
                {renderDesigantionOptions()}
              </select>
            </div>
            <div className="d-flex align-items-center">
              <label style={{ color: "var(--title-color)" }}>Official </label>
              <select
                className="select-comobo"
                id="official"
                onChange={handleOfficialChange}
                value={selectedOfficial}
              >
                <option value=""> Select Official </option>
                {renderOfficials()}
              </select>
            </div>
          </div>
          <div
            style={{ width: 100 }}
            className="update-btn"
            onClick={handleAdd}
          >
            Add
          </div>
        </div>
        {tableData.length > 0 ? (
          <div className="mx-4 my-4">
            <DataTable
              columns={officalcolumn}
              data={tableData}
              customStyles={customtableStyles}
              fixedHeader
              theme="solarized"
              fixedHeaderScrollHeight="400px"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <h6 style={{ color: "var(--text-color)", alignSelf: "center" }}>
              Select Official Type and Official
            </h6>
          </div>
        )}

        {error && (
          <div className="d-flex justify-content-center mt-3">
            <h6 style={{ color: "red", alignSelf: "center" }}>{error}</h6>
          </div>
        )}

        <div className="d-flex justify-content-center me-3">
          <button className="update-btn mt-4 w-25" onClick={() => handleSave()}>
            Add To Match
          </button>
        </div>
      </div>
      {validationData && (
        <ValidationModal data={validationData} onClose={closeValidationModel} />
      )}
    </div>
  );
}

export default AddMatchOffical;

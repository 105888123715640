import React, { useEffect } from "react";
import "../styles/pointstable.css";
import DataTable, { createTheme } from "react-data-table-component";
import { customtableStyles } from "../assets/tablestyle";
import { tablecreateTheme } from "../assets/globalcolors";
import MatchTypes from "../componants/MatchTypes";
function PointsTable(props) {
  const { tournamentteampoints, tournamentType } = props;
  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  useEffect(() => {
    if (tournamentType === (MatchTypes.ODI.id || MatchTypes.T20.id)) {
      column.splice(6, 0, {
        name: "NRR",
        width: "120px",
        center: true,
        selector: (row) => row.nrr,
      });
    }
  }, []);

  const RecentForm = ({ matchPerformance }) => {
    let resp = [];

    const newArray = Array.from({ length: 5 }, (_, index) =>
      index < matchPerformance.length ? matchPerformance[index] : null
    );
    if (Array.isArray(matchPerformance)) {
      newArray.map((item) => {
        console.log("item", item);
        if (item === 1) {
          resp.push(<div className="win">W</div>);
        } else if (item === 0) {
          resp.push(<div className="loss">L</div>);
        } else {
          resp.push(<div className="win"></div>);
        }
      });
    }
    return <div style={{ display: "flex" }}>{resp}</div>;
  };

  const TeamName = ({ row }) => {
    console.log("row", row);
    return (
      <div className="team_name_logo">
        {row.team.TeamLogoUrl !== null ? (
          <img className="logo_team" src={row.team.TeamLogoUrl} alt="" />
        ) : (
          <img
            className="logo_team"
            src={require("../assets/images/team_logo.png")}
            alt=""
          />
        )}
        <span style={{ marginLeft: "10px" }}>{row.team.TeamName}</span>
      </div>
    );
  };
  const column = [
    {
      width: "50px",
      selector: (row, index) => index + 1,
    },
    {
      name: "TEAM",
      width: "400px",
      selector: (row) => <TeamName row={row} />,
    },
    {
      name: "P",
      width: "60px",
      center: true,
      selector: (row) => row.played,
    },
    {
      name: "W",
      width: "60px",
      center: true,
      selector: (row) => row.matchesWin,
    },
    {
      name: "L",
      width: "60px",
      center: true,
      selector: (row) => row.matchesLost,
    },
    // {
    //   name: "NR",
    //   width: "120px",
    //   selector: (row) => row.NR,
    // },
    // tournamentType === (MatchTypes.ODI.id || MatchTypes.T20.id) && {
    //   name: "NRR",
    //   width: "120px",
    //   center: true,
    //   selector: (row) => row.nrr,
    // },
    // {
    //   name: "FOR",
    //   width: "120px",
    //   selector: (row) => row.for,
    // },
    // {
    //   name: "AGAINST",
    //   width: "120px",
    //   selector: (row) => row.against,
    // },
    {
      name: "PTS",
      width: "100px",
      center: true,
      selector: (row) => row.points,
    },
    {
      name: "RECENT FORM",
      center: true,
      selector: (row) => <RecentForm matchPerformance={row.matchPerformance} />,
    },
  ];

  return (
    <div className="container_pointtable">
      {tournamentteampoints !== null ? (
        <DataTable
          columns={column}
          //data={tournamentteampoints?.sort((a, b) => b.points - a.points)}
          data={tournamentteampoints}
          customStyles={customtableStyles}
          theme="solarized"
          fixedHeader
          fixedHeaderScrollHeight="700px"
        ></DataTable>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", color: "#fff" }}
        >
          Select Tournament
        </div>
      )}
    </div>
  );
}

export default PointsTable;

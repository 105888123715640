import React, { useEffect, useState, useRef } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { tablecreateTheme } from "../assets/globalcolors";
import ReactPlayer from "react-player";
import {
  get_appealt_types,
  get_batting_shot_data,
  get_batting_ww_position,
  get_bowling_action,
  get_bowling_length,
  get_bowling_side,
  get_delivery_result,
  get_designations,
  get_fielding_type,
  get_match_info,
  get_match_type,
  get_match_data,
  get_match_inn_info,
  get_tournaments_type,
  get_score,
  get_video_urls,
} from "../api/serverWrapper";
import { customtableStyles } from "../assets/tablestyle";
import ValidationModal from "./ValidationModal";
import "../styles/dashboard.css";
import { VideoIcon } from "../assets/SvgIcons";
function Dashboard({ filterPlayerData }) {
  let data = filterPlayerData;
  console.log("recived data from home : ", data);
  let [tournamentid, Tournamentid] = useState(data[0]?.tournamentid || null);
  let isFour = data[0]?.isFour || null;
  let isSix = data[0]?.isSix || null;
  let isWicket = data[0]?.isWicket || null;
  let IsWideBall = data[0]?.IsWideBall || null;
  let isNoBall  = data[0]?.isNoBall  || null;
  const [appealtypes, setAppealTypes] = useState();
  const [battingshots, setBattingShots] = useState();
  const [bowlingactions, setBowlingActions] = useState();
  const [bowlinglengths, setBowlingLengths] = useState();
  const [bowlingside, setBowlingSide] = useState();
  const [deliveryresult, setDeliveryResult] = useState();
  const [designations, setDesignations] = useState();
  const [fieldingtypes, setFieldingTypes] = useState();
  const [matchtypes, setMatchTypes] = useState();
  const [battingwwpositions, setBattingWWPositions] = useState();
  const [match, setMatch] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [score, setScore] = useState(null);
  let [score1, setScore1] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOkclicked, setIsOkclicked] = useState(false);

  const [tournamentType, settournamentType] = useState([]);
  const [matchList, setMatchList] = useState([]);
  let [matchList1, setMatchList1] = useState([]);
  let [matchInfo, setMatchInfo] = useState(null);
  let [inningInfo, setInningInfo] = useState(null);
  let [battingPlayerList, setBattingPlayerList] = useState();
  const [bowingPlayerList, setBowlingPlayerList] = useState();
  const [umpire1, setUmpire1] = useState(null);
  const [umpire2, setUmpire2] = useState(null);
  const [umpire3, setUmpire3] = useState(null);
  const [appealtTypes, setAppealtTypes] = useState([]);
  const [message, setMeassage] = useState("");
  let [offset, setOffset] = useState(0);
  let [overNum, setOverNum] = useState(null);

  const [ballType, setBallType] = useState([
    { id: 13, AppealType: "W" },
    { id: 14, AppealType: "NB" },
    { id: 15, AppealType: "B" },
    { id: 16, AppealType: "LB" },
  ]);

  const [iningId1, setInningId1] = useState([1, 2]);
  const [iningId2, setInningId2] = useState([1, 2, 3, 4]);

  const [allAppealType, setAllAppealType] = useState([]);
  let [ballurls, setBallUrls] = useState();
  let [cameraview, setCameraView] = useState();
  const [singlecam, setSingleCam] = useState(true);
  const [fourcam, setFourCam] = useState(false);
  const [sixcam, setSixCam] = useState(false);
  const [isVisible, setPopup] = useState(false);
  const [isValid, setIsValid] = useState(false);
  let [isfourcamactive, setIsFourCamActive] = useState(false);
  const [issixcamactive, setIsSixCamActive] = useState(false);

  // get score details
  let [matchid, setMatchId] = useState(data[0]?.data.matchId || null);
  const [batsmanid, setBatsmanId] = useState(
    data[0]?.type === "batting" ? data[0]?.data.PlayerId : null
  );
  const [bowlerid, setBowlerId] = useState(
    data[0]?.type === "bowling" ? data[0]?.data.PlayerId : null
  );
  console.log(bowlerid);
  const [appealid, setAppealId] = useState(null);
  let [inningid, setInningId] = useState(data[0]?.data.InnId || null);
  const [umpireid, setUmpireId] = useState(null);

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const get_data = async () => {
    let appealtypes = await get_appealt_types();
    setAppealTypes(appealtypes.data);
    let battingshots = await get_batting_shot_data();
    setBattingShots(battingshots.data);
    let bowlingactions = await get_bowling_action();
    setBowlingActions(bowlingactions.data);
    let bowlinglengths = await get_bowling_length();
    setBowlingLengths(bowlinglengths.data);
    let bowlingside = await get_bowling_side();
    setBowlingSide(bowlingside.data);
    let deliveryresult = await get_delivery_result();
    setDeliveryResult(deliveryresult.data);
    let designations = await get_designations();
    setDesignations(designations.data);
    let fieldingtypes = await get_fielding_type();
    setFieldingTypes(fieldingtypes.data);
    let matchtypes = await get_match_type();
    setMatchTypes(matchtypes.data);
    let battingwwpositions = await get_batting_ww_position();
    setBattingWWPositions(battingwwpositions.data);
    // let match = await get_match_info(26);
    // setMatch(match.data);

    let tournamentsResp = await get_tournaments_type();
    console.log("tournamentsResp", tournamentsResp);
    settournamentType(tournamentsResp.data.tournaments);

    getMatchList();

    let resp = await get_appealt_types();
    setAppealtTypes(resp.data);

    const concatenatedArray = [...resp.data, ...ballType];

    setAllAppealType(concatenatedArray);
    if (data.length > 0) {
      await getMatchInfo(matchid);
      await get_info();
    }
  };

  const getMatchList = async () => {
    try {
      let resp = await get_match_data();
      setMatchList(resp.data.matches);
      setMatchList1(resp.data.matches);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const [showWWPostion, setWWPostion] = useState(false);

  useEffect(() => {
    get_data();
  }, []);

  useEffect(() => {
    drawLine();
  }, [score1, showWWPostion]);

  const drawLine = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = lineColor;
    ctx.lineCap = "round";
    ctx.lineWidth = 2; // Set line thickness to 5 pixels

    score1.forEach((b) => {
      const startX = 150; // Starting X coordinate
      const startY = 136; // Starting Y coordinate
      const endX = b.BattingWWX2; // Ending X coordinate
      const endY = b.BattingWWY2; // Ending Y coordinate
      ctx.beginPath();
      if (b.IsFour) {
        ctx.strokeStyle = "yellow";
        ctx.arc(endX, endY, 2, 0, Math.PI * 2);
        ctx.fillStyle = "yellow";
      } else if (b.IsSix) {
        ctx.strokeStyle = "red";
      }
      // else if(b.IsBye){
      //   ctx.strokeStyle = 'black';
      // }
      // else if(b.IsLegbye){
      //   ctx.strokeStyle = 'pink';
      // }
      // else if(b.ISWideBall){
      //   ctx.strokeStyle = 'white';
      // }
      else if (b.IsOut) {
        ctx.strokeStyle = "#a84e32";
      } else {
        ctx.strokeStyle = lineColor;
        ctx.arc(endX, endY, 2, 0, Math.PI * 2);
        ctx.fillStyle = lineColor;
      }

      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();

      ctx.beginPath();

      ctx.fill();
    });
  };

  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const handleTournament = async (event) => {
    const selectedTournamentId = event.target.value;
    let respmatches = await get_match_data(selectedTournamentId, true);
    // const filteredMatches = matchList.filter(
    //   (item) => item.TournamentOrSeriesId === parseInt(id)
    // );
    matchList1 = respmatches.data.matches;

    setMatchList1(matchList1);
  };

  const renderMatchListOptions = () => {
    return matchList1.map((item, index) => (
      <option key={index} value={item.id}>
        {item.MatchNameOrTitle} ({item.id})
      </option>
    ));
  };

  const getMatchInfo = async (matchId) => {
    let resp = await get_match_info(matchId);
    matchInfo = resp.data;
    console.log("matchdata", matchInfo);
    // setBattingPlayerList(matchData.TeamBPlayerList);
    // setBowlingPlayerList(matchData.TeamAPlayerList);
    setMatchInfo(matchInfo);

    if (matchInfo && inningid) {
      await get_info();
    }
  };

  const handleMatch = async (event) => {
    matchid = event.target.value;
    setMatchId(matchid);
    getMatchInfo(matchid);
  };

  const renderInning = () => {
    return (matchInfo?.MatchType === 3 ? iningId2 : iningId1).map(
      (item, index) => <option value={item}>{item}</option>
    );
  };

  const handleInning = async (event) => {
    inningid = event.target.value;
    setInningId(inningid);
    console.log(event.target.value);

    if (matchid) {
      await get_info();
    } else {
      setMeassage("select  match");
      validationpopupvisible();
    }
  };

  const get_info = async () => {
    let resp = await get_match_inn_info(matchid, inningid);
    console.log("inn", resp.data);
    console.log("match", matchInfo);
    inningInfo = resp.data;
    setInningInfo(inningInfo);

    battingPlayerList = await battingPlayer();
    setBattingPlayerList(battingPlayerList);

    let bowlingplayer = await bowlingPlayer();
    setBowlingPlayerList(bowlingplayer);
    console.log("bowlingplayer", bowlingplayer);

    setUmpire1(matchInfo.Umpire1);
    setUmpire2(matchInfo.Umpire2);
    setUmpire3(matchInfo.Umpire3);

    await fetchScore();
  };

  const battingPlayer = () => {
    console.log(inningInfo.BattingTeamId, matchInfo.TeamBId);
    const players =
      inningInfo?.BattingTeamId === matchInfo.TeamBId
        ? matchInfo.TeamBPlayerList
        : matchInfo.TeamAPlayerList;

    let d = players.filter((item) => item.InnId === inningInfo.InningNumber);

    console.log("battingaaaaaaaa", d);
    return d;
  };

  const handleBattingPlayer = (event) => {
    setBatsmanId(event.target.value);
  };

  const bowlingPlayer = () => {
    const players =
      inningInfo?.BowlingTeamId === matchInfo.TeamAId
        ? matchInfo.TeamAPlayerList
        : matchInfo.TeamBPlayerList;
    let d = players.filter((item) => item.InnId === inningInfo.InningNumber);

    console.log("bowling", d);
    return d;
  };

  const handleBowlingPlayer = (event) => {
    setBowlerId(event.target.value);
  };

  const renderBattingPlayer = () => {
    return battingPlayerList?.map((item, index) =>
      item.InnId === inningInfo.InningNumber ? (
        <option value={item.Player.PlayerId}>
          {item.Player.User.FirstName}
        </option>
      ) : (
        ""
      )
    );
  };

  const renderBowlingPlayer = () => {
    return bowingPlayerList?.map((item, index) =>
      item.InnId === inningInfo.InningNumber ? (
        <option value={item.Player.PlayerId}>
          {item.Player.User.FirstName}
        </option>
      ) : (
        ""
      )
    );
  };

  const renderAppealTypes = () => {
    return appealtTypes.map((item, index) => (
      <option key={item.id} value={item.id}>
        {item.AppealType}
      </option>
    ));
  };

  const handleAllAppealType = (event) => {
    setAppealId(event.target.value);
  };

  const renderUmpire = () => {
    return (
      <div>
        <option key={umpire1?.id} value={umpire1}>
          {umpire1?.User.FirstName}
        </option>
        <option key={umpire2?.id} value={umpire2}>
          {umpire2?.User.FirstName}
        </option>
        <option key={umpire3?.id} value={umpire3}>
          {umpire3?.User.FirstName}
        </option>
      </div>
    );
  };

  const handleUmpire = (event) => {
    setUmpireId(event.target.value);
  };

  const fetchScore = async () => {
    if (matchid && inningid) {
      setScore(null);
      setScore1(null);
      offset = 0;
      setOffset(offset);
      try {
        let score = await get_score(
          matchid,
          inningid,
          overNum,
          batsmanid,
          bowlerid,
          appealid,
          umpireid,
          offset,
          isFour,
          isSix,
          isWicket,
          IsWideBall,
          isNoBall,
        );
        score1 = score.data.scores;

        setScore(score1);
        setScore1(score1);
        console.log(score1);
        console.log(score1.length);
        setIsOkclicked(true);
      } catch (error) {
        console.error("Error fetching score:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setMeassage("Please select Match and Inning");
      validationpopupvisible();
    }
  };

  const Overcell = ({ row }) => {
    return <div className="cell">{row.OverNum + "." + row.BallNum}</div>;
  };

  function getPlayerName(list, id) {
    const p = list.find((item) => item.PlayerId === id);
    // console.log("Id", id, "user", p);
    if (p) return p.Player.User.FirstName;
    else return "NA";
  }

  const Strikername = ({ row }) => {
    return getPlayerName(battingPlayerList, row.BattingStrikerId);
  };

  const Bowlername = ({ row }) => {
    return getPlayerName(bowingPlayerList, row.BowlerId);
  };

  const Nonstrikername = ({ row }) => {
    return getPlayerName(battingPlayerList, row.BattingNonStrikerId);
  };

  const LegUmpire = ({ row }) => {
    let legumpire = "";
    if (umpire1.id === row.LegUmpireId) {
      legumpire = umpire1.User.FirstName;
    }
    if (umpire2.id === row.LegUmpireId) {
      legumpire = umpire2.User.FirstName;
    }
    if (umpire3.id === row.LegUmpireId) {
      legumpire = umpire3.User.FirstName;
    }
    return legumpire;
  };

  const MainUmpire = ({ row }) => {
    let mainumpire = "";
    if (umpire1.id === row.MainUmpireId) {
      mainumpire = umpire1.User.FirstName;
    }
    if (umpire2.id === row.MainUmpireId) {
      mainumpire = umpire2.User.FirstName;
    }
    if (umpire3.id === row.MainUmpireId) {
      mainumpire = umpire3.User.FirstName;
    }
    return mainumpire;
  };

  const Fielder = ({ row }) => {
    return getPlayerName(bowingPlayerList, row.FielderId);
  };

  const Supportfielder = ({ row }) => {
    return getPlayerName(bowingPlayerList, row.SupportingFielderId);
  };

  const Wicketbyplayer = ({ row }) => {
    return getPlayerName(bowingPlayerList, row.OutByPlayerId);
  };

  const AppealedTeam = ({ row }) => {
    let appealedteam = "";
    if (matchInfo.TeamAId === row.ApealedTeamId) {
      appealedteam = matchInfo.TeamA.TeamName;
    } else {
      appealedteam = matchInfo.TeamB.TeamName;
    }
    return appealedteam;
  };

  const Appealedtoumpire = ({ row }) => {
    let appealedtoumpire = "";
    if (umpire1.id === row.ApealedToUmpireId) {
      appealedtoumpire = umpire1.User.FirstName;
    }
    if (umpire2.id === row.ApealedToUmpireId) {
      appealedtoumpire = umpire2.User.FirstName;
    }
    if (umpire3.id === row.ApealedToUmpireId) {
      appealedtoumpire = umpire3.User.FirstName;
    }
    return appealedtoumpire;
  };

  const PenaltyTeam = ({ row }) => {
    let penaltyteam = "";
    if (matchInfo.TeamAId === row.PenaltyTeamId) {
      penaltyteam = matchInfo.TeamA.TeamName;
    } else {
      penaltyteam = matchInfo.TeamB.TeamName;
    }
    return penaltyteam;
  };
  const handlevideopopup = async (row) => {
    console.log("row", row);
    let extraIndex = 0;
    for (let i = 0; i < score1.length; i++) {
      if (
        score1[i].OverNum === row.OverNum &&
        score1[i].BallNum == row.BallNum
      ) {
        if (score1[i].id === row.id) {
          break;
        } else {
          extraIndex++;
        }
      }
    }

    let resp = await get_video_urls(
      row.MatchId,
      row.InnNum,
      row.OverNum,
      row.BallNum,
      extraIndex
    );

    ballurls = resp.data;
    console.log("ballurls", ballurls);
    setBallUrls(ballurls);
    cameraview = ballurls?.c1;
    setCameraView(cameraview);
    console.log("extraIndex", extraIndex);
    console.log("ballurls", ballurls);
    handleVisibility();
  };

  const handleVisibility = () => {
    setPopup(!isVisible);
    setActiveCamTab("cam1");
    setFourCam(false);
    setSixCam(false);
    setSingleCam(true);
  };

  const handleSingleCamChange = () => {
    setSingleCam(true);
    setFourCam(false);
    setSixCam(false);
    setIsSixCamActive(false);
    setIsFourCamActive(false);
  };

  const handleFourCamChange = () => {
    setSingleCam(false);
    setFourCam(true);
    setSixCam(false);
    setIsSixCamActive(false);
    setIsFourCamActive(true);
  };

  const handleSixCamChange = () => {
    setSingleCam(false);
    setFourCam(false);
    setSixCam(true);
    setIsSixCamActive(true);
    setIsFourCamActive(false);
  };
  const columns = [
    {
      name: "Overs",
      sortable: true,
      selector: (row) => <Overcell row={row} />,
      width: "100px",
      center: true,
    },
    {
      name: "Video",
      selector: (row) => (
        <img
          onClick={() => handlevideopopup(row)}
          style={{ height: "30px" }}
          src={VideoIcon}
        />
      ),
      width: "80px",
      center: true,
    },
    // {
    //   name: "Match",
    //   sortable: true,
    //   selector: (row) => row.MatchId,
    //   width: "100px",
    //   center: true,
    // },
    {
      name: "Inning",
      sortable: true,
      selector: (row) => row.InnNum,
      width: "100px",
      center: true,
    },
    {
      name: "Total Run",
      sortable: true,
      selector: (row) => row.TotalRun,
      width: "120px",
      center: true,
    },
    {
      name: "Striker",
      sortable: true,
      selector: (row) => <Strikername row={row} />,
      width: "200px",
      center: true,
    },
    {
      name: "Bowler",
      sortable: true,
      selector: (row) => <Bowlername row={row} />,
      width: "200px",
      center: true,
    },
    {
      name: "Non Striker",
      sortable: true,
      selector: (row) => <Nonstrikername row={row} />,
      width: "200px",
      center: true,
    },
    {
      name: "Leg Umpire",
      sortable: true,
      selector: (row) => <LegUmpire row={row} />,
      width: "140px",
      center: true,
    },
    {
      name: "Main Umpire",
      sortable: true,
      selector: (row) => <MainUmpire row={row} />,
      width: "150px",
      center: true,
    },
    {
      name: "Wide",
      sortable: true,
      selector: (row) => (row.IsWideBall === true ? "YES" : "NO"),
      width: "90px",
      center: true,
    },
    {
      name: "No Ball",
      sortable: true,
      selector: (row) => (row.IsNoBall === true ? "YES" : "NO"),
      width: "100px",
      center: true,
    },
    {
      name: "Four",
      sortable: true,
      selector: (row) => (row.IsFour === true ? "YES" : "NO"),
      width: "90px",
      center: true,
    },
    {
      name: "Six",
      sortable: true,
      selector: (row) => (row.IsSix === true ? "YES" : "NO"),
      width: "80px",
      center: true,
    },
    {
      name: "Out",
      sortable: true,
      selector: (row) => (row.IsOut === true ? "YES" : "NO"),
      width: "80px",
      center: true,
    },
    {
      name: "Bye",
      sortable: true,
      selector: (row) => (row.IsBye === true ? "YES" : "NO"),
      width: "80px",
      center: true,
    },
    {
      name: "Leg Bye",
      sortable: true,
      selector: (row) => (row.IsLegbye === true ? "YES" : "NO"),
      width: "110px",
      center: true,
    },
    {
      name: "Wicket",
      sortable: true,
      selector: (row) => (row.IsWicket === true ? "YES" : "NO"),
      width: "110px",
      center: true,
    },
    {
      name: "Fielder",
      sortable: true,
      selector: (row) => <Fielder row={row} />,
      width: "100px",
      center: true,
    },
    {
      name: "Playing Position",
      sortable: true,
      selector: (row) => row.BattingShotGroupsId,
      width: "170px",
      center: true,
    },
    // {
    //   name: "Shot",
    //   sortable: true,
    //   selector: (row) =>
    //     battingshots ? battingshots[row.BattingShotTypeId - 1].ShotName : "",
    //   width: "150px",
    //   center: true,
    // },
    {
      name: "Fielding Type",
      sortable: true,
      selector: (row) =>
        fieldingtypes && row.FieldingTypeId
          ? fieldingtypes[row.FieldingTypeId - 1].Type
          : "",
      width: "150px",
      center: true,
    },
    {
      name: "CatchDrop",
      sortable: true,
      selector: (row) => (row.isCatchDrop === true ? "YES" : "NO"),
      width: "130px",
      center: true,
    },
    {
      name: "Supporting Fielder",
      sortable: true,
      selector: (row) => <Supportfielder row={row} />,
      width: "190px",
      center: true,
    },
    // {
    //   name: "Bowling Action",
    //   sortable: true,
    //   selector: (row) =>
    //     bowlingactions ? bowlingactions[row.BowlingAction - 1].actionType : "",
    //   width: "160px",
    //   center: true,
    // },
    {
      name: "Bowling Length",
      sortable: true,
      selector: (row) =>
        bowlinglengths
          ? bowlinglengths[row.BowlingLengthId - 1].description
          : "",
      width: "160px",
      center: true,
    },
    {
      name: "Bowled PositionX",
      sortable: true,
      selector: (row) => row.BowledPositionX,
      width: "180px",
      center: true,
    },
    {
      name: "Bowled PositionY",
      sortable: true,
      selector: (row) => row.BowledPositionY,
      width: "180px",
      center: true,
    },
    // {
    //   name: "BattingWWX1",
    //   sortable: true,
    //   selector: (row) => row.BattingWWX1,
    //   width: "150px",
    //   center: true,
    // },
    // {
    //   name: "BattingWWY1",
    //   sortable: true,
    //   selector: (row) => row.BattingWWY1,
    //   width: "150px",
    //   center: true,
    // },
    // {
    //   name: "BattingWWX2",
    //   sortable: true,
    //   selector: (row) => row.BattingWWX1,
    //   width: "160px",
    //   center: true,
    // },
    // {
    //   name: "BattingWWY2",
    //   sortable: true,
    //   selector: (row) => row.BattingWWY2,
    //   width: "150px",
    //   center: true,
    // },
    // {
    //   name: "BattingWW Position",
    //   sortable: true,
    //   selector: (row) =>
    //     battingwwpositions && row.BattingWWPositionId
    //       ? battingwwpositions[row.BattingWWPositionId - 1].wwPositionName
    //       : "",
    //   width: "200px",
    //   center: true,
    // },
    {
      name: "Extra Runs",
      sortable: true,
      selector: (row) => row.ExtraRuns,
      width: "130px",
      center: true,
    },
    {
      name: "Striker Runs",
      sortable: true,
      selector: (row) => row.BattingStrikerRuns,
      width: "140px",
      center: true,
    },
    {
      name: "Wicket By",
      sortable: true,
      selector: (row) => <Wicketbyplayer row={row} />,
      width: "130px",
      center: true,
    },
    {
      name: "Wicket Type",
      sortable: true,
      selector: (row) => row.WicketType,
      width: "140px",
      center: true,
    },
    {
      name: "Appeal Result",
      sortable: true,
      selector: (row) => (row.AppealResult === true ? "YES" : "NO"),
      width: "150px",
      center: true,
    },
    {
      name: "Apealed Team",
      sortable: true,
      selector: (row) => <AppealedTeam row={row} />,
      width: "220px",
      center: true,
    },
    {
      name: "Apealed To Umpire",
      sortable: true,
      selector: (row) => <Appealedtoumpire row={row} />,
      width: "190px",
      center: true,
    },

    {
      name: "Batsman StepOut",
      sortable: true,
      selector: (row) => (row.BattingIsStepOut === true ? "YES" : "NO"),
      width: "180px",
      center: true,
    },
    {
      name: "Apealed Type",
      sortable: true,
      selector: (row) =>
        appealtypes && row.ApealedTypeId
          ? appealtypes[row.ApealedTypeId - 1].AppealType
          : "",
      width: "150px",
      center: true,
    },

    {
      name: "Referred To3rd Umpire",
      sortable: true,
      selector: (row) => (row.IsRefrredTo3RdUmpire === true ? "YES" : "NO"),
      width: "220px",
      center: true,
    },
    {
      name: "Runs Given By Fielder",
      sortable: true,
      selector: (row) => row.RunsGivenByFielder,
      width: "210px",
      center: true,
    },
    {
      name: "Run Saved By Fielder",
      sortable: true,
      selector: (row) => row.RunSavedByFileder,
      width: "200px",
      center: true,
    },
    {
      name: "Penalty Runs",
      sortable: true,
      selector: (row) => row.PenaltyRuns,
      width: "150px",
      center: true,
    },
    {
      name: "Penalty Team",
      sortable: true,
      selector: (row) => <PenaltyTeam row={row} />,
      width: "220px",
      center: true,
    },
    {
      name: "Short Runs",
      sortable: true,
      selector: (row) => row.ShortRuns,
      width: "130px",
      center: true,
    },
    {
      name: "Short Run End",
      sortable: true,
      selector: (row) => row.ShortRunEnd,
      width: "150px",
      center: true,
    },
    {
      name: "Bowling Side",
      sortable: true,
      selector: (row) =>
        bowlingside && row.BowlingSideId
          ? bowlingside[row.BowlingSideId - 1].SideName
          : "",
      width: "150px",
      center: true,
    },
    {
      name: "Batting Striker End",
      sortable: true,
      selector: (row) => row.BattingStrikerEndId,
      width: "190px",
      center: true,
    },
    // {
    //   name: "Delivery Result",
    //   sortable: true,
    //   selector: (row) =>
    //     deliveryresult
    //       ? deliveryresult[row.DeliveryResultId - 1].resultType
    //       : "",
    //   center: true,
    //   width: "160px",
    // },
  ];

  const [activeCamTab, setActiveCamTab] = useState("cam1");
  const handleCamTab = (tab, activecam) => {
    cameraview = activecam;
    setCameraView(cameraview);
    setActiveCamTab(tab);
    handleSingleCamChange();
  };

  const validationpopupvisible = () => {
    setIsValid(!isValid);
  };

  const validationpopup = () => {
    return (
      <div>
        <ValidationModal data={message} onClose={validationpopupvisible} />
      </div>
    );
  };

  const popupVideo = () => {
    return (
      <div className="overlay">
        <div className="popup_scoretable">
          {singlecam && (
            <ReactPlayer
              controls={true}
              url={cameraview}
              height="95%"
              width="100%"
              playing={true}
            />
          )}
          {fourcam && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c1}
                  height="40%"
                  width="40%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c2}
                  height="40%"
                  width="40%"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c3}
                  height="40%"
                  width="40%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c4}
                  height="40%"
                  width="40%"
                />
              </div>
            </div>
          )}
          {sixcam && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c1}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <ReactPlayer
                    controls={true}
                    playing={true}
                    url={ballurls?.c2}
                    height="50%"
                    width="45%"
                  />
                  <ReactPlayer
                    controls={true}
                    playing={true}
                    url={ballurls?.c3}
                    height="50%"
                    width="45%"
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c4}
                  height="100%"
                  width="30%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c5}
                  height="100%"
                  width="30%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c6}
                  height="100%"
                  width="30%"
                />
              </div>
            </div>
          )}
          <div className="cam_operatior_container">
            <div className="d-flex  justify-content-center ">
              <button
                className={
                  activeCamTab === "cam1" ? "active-front" : "front-btn"
                }
                onClick={() => handleCamTab("cam1", ballurls?.c1)}
              >
                {" "}
                Cam1
              </button>
              <button
                className={
                  activeCamTab === "cam2" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam2", ballurls?.c2)}
              >
                {" "}
                Cam2
              </button>
              <button
                className={
                  activeCamTab === "cam3" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam3", ballurls?.c3)}
              >
                {" "}
                Cam3
              </button>
              <button
                className={
                  activeCamTab === "cam4" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam4", ballurls?.c4)}
              >
                {" "}
                Cam4
              </button>
              <button
                className={
                  activeCamTab === "cam5" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam5", ballurls?.c5)}
              >
                {" "}
                Cam5
              </button>
              <button
                className={activeCamTab === "cam6" ? "active-back" : "back-btn"}
                onClick={() => handleCamTab("cam6", ballurls?.c6)}
              >
                {" "}
                Cam6
              </button>
            </div>
          </div>
          {/* <img
            className="cam_frames4"
            src={require("../assets/images/four_icon.png")}
            onClick={() => handleFourCamChange()}
          /> */}
          <div
            className="cam_frames4"
            style={{ boxShadow: isfourcamactive ? "0 0 16px 0 #2a8853" : "" }}
            onClick={() => handleFourCamChange()}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
          </div>
          <div
            className="cam_frames6"
            style={{ boxShadow: issixcamactive ? "0 0 16px 0 #2a8853" : "" }}
            onClick={() => handleSixCamChange()}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
          </div>
          {/* <img
            className="cam_frames6"
            src={require("../assets/images/six_icon.png")}
            onClick={() => handleSixCamChange()}
          />{" "} */}
          <button className="close-button" onClick={() => handleVisibility()}>
            X
          </button>
        </div>
      </div>
    );
  };

  const handleSearch = (event) => {
    offset = 0;
    setOffset(offset);
    overNum = event.target.value;
    setOverNum(overNum);
    fetchScore();
    // const filteredData = score
    //   .filter((item) =>
    //     (item.OverNum + "." + item.BallNum)
    //       .toLowerCase()
    //       .includes(event.target.value.toLowerCase())
    //   )
    //   .sort((a, b) =>
    //     (a.OverNum + "." + a.BallNum).localeCompare(b.OverNum + "." + b.BallNum)
    //   );
    // setScore1(filteredData);
  };

  //drag Line
  const canvasRef = useRef(null);

  const [lineColor, setLineColor] = useState("#83324F");

  //drag line ends

  // ball pitch starts
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [ballPitchArea, setBallPitchArea] = useState(null);
  const handlePitchClick = (e) => {
    const pitchRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - pitchRect.left;
    const offsetY = e.clientY - pitchRect.top;
    const pitchWidth = pitchRect.width;
    const pitchHeight = pitchRect.height;
    const ballX = (offsetX / pitchWidth) * 280;
    const ballY = (offsetY / pitchHeight) * 300;
    if (
      (ballX >= 87 && ballX <= 210 && ballY >= 5 && ballY <= 22) ||
      (ballX >= 92 && ballX <= 210 && ballY >= 20 && ballY <= 22)
    ) {
      setBallPitchArea("Stumps");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 88 && ballX <= 212 && ballY >= 20 && ballY <= 24) ||
      (ballX >= 83 && ballX <= 221 && ballY >= 27 && ballY <= 50)
    ) {
      setBallPitchArea("2M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 74 && ballX <= 225 && ballY >= 48 && ballY <= 81) ||
      (ballX >= 81 && ballX <= 216 && ballY >= 50 && ballY <= 78)
    ) {
      setBallPitchArea("4M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 66 && ballX <= 223 && ballY >= 93 && ballY <= 113) ||
      (ballX >= 70 && ballX <= 222 && ballY >= 91 && ballY <= 113)
    ) {
      setBallPitchArea("8M");
      setBallPosition({ x: ballX, y: ballY });
    }
    if (
      (ballX >= 24 && ballX <= 272 && ballY >= 122 && ballY <= 290) ||
      (ballX >= 64 && ballX <= 232 && ballY >= 124 && ballY <= 293)
    ) {
      setBallPitchArea("HalfWay");
      setBallPosition({ x: ballX, y: ballY });
    }
  };

  //ball pitch ends

  const handleShowPitch = () => {
    setWWPostion(!showWWPostion);
    drawLine();
    if (!matchid) {
      setMeassage("Select Tournament  Match & inning");
      validationpopupvisible();
    }
  };

  const handleScroll = async () => {
    console.log("scrolling");
    // const scrollY = event.target.scrollTop;
    // console.log("scrollY", scrollY);
    if (offset !== 0) {
      offset -= 10;
    } else {
      setMeassage("No More Records");
      validationpopupvisible();
    }
    setOffset(offset);
    console.log("offset", offset);
    await fetchScore();
  };

  return (
    <div className="">
      <div className="mt-3">
        <div>
          <div className="d-flex justify-content-between">
            <div className="search_content">
              <div>
                <span className="text">Score Table</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search by Overs"
                  onChange={handleSearch}
                />
                <div
                  className="update-btn"
                  onClick={() => handleShowPitch()}
                  style={{ width: "50%" }}
                >
                  Show Wagon Wheel & Pitch
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "20%",
              }}
            >
              <span className="text ms-4">Filters</span>
              <button className="btn1" onClick={fetchScore}>
                OK
              </button>
            </div>
          </div>
          <div className="mt-2  d-flex gap-3 ">
            <div className={`table_layout ${score1 ? "" : "round-loading"}`}>
              {isOkclicked ? (
                <div>
                  {score1 ? (
                    <div className="">
                      <DataTable
                        columns={columns}
                        data={score1}
                        customStyles={customtableStyles}
                        theme="solarized"
                        fixedHeader
                        fixedHeaderScrollHeight="700px"
                        progressPending={loading}
                        onRowClicked={handleScroll}
                      ></DataTable>
                      {showWWPostion && (
                        <div className="d-flex wegon-wheel">
                          <div
                            className="close d-flex justify-content-end"
                            onClick={() => handleShowPitch()}
                          >
                            X
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex mx-4 mt-4">
                              <canvas
                                ref={canvasRef}
                                width={300}
                                height={300}
                                className="cricket_ground"
                              />
                            </div>

                            <div
                              style={{
                                marginTop: 250,
                                marginRight: 100,
                                marginLeft: 50,
                              }}
                              className=""
                            >
                              <div className="d-flex gap-3 align-items-center">
                                <div className="title2">Six</div>
                                <div
                                  style={{ backgroundColor: "red" }}
                                  className="color-show ms-1"
                                ></div>
                              </div>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="title2">Four</div>
                                <div
                                  style={{ backgroundColor: "yellow" }}
                                  className="color-show"
                                ></div>
                              </div>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="title2">Out</div>
                                <div
                                  style={{
                                    backgroundColor: "#a84e32",
                                    marginLeft: 6,
                                  }}
                                  className="color-show"
                                ></div>
                              </div>
                            </div>

                            <div className="mt-4 ms-5">
                              <div className="pitch-section ">
                                <div className="cricket-pitch">
                                  {score1.map(
                                    (score, index) =>
                                      score.BowledPositionX !== 0 &&
                                      score.BowledPositionY !== 0 && (
                                        <div
                                          key={index} // Make sure to provide a unique key
                                          className="ballC"
                                          style={{
                                            left: score.BowledPositionX,
                                            top: score.BowledPositionY,
                                            backgroundColor: score.IsFour
                                              ? "yellow"
                                              : score.IsSix
                                              ? "red"
                                              : score.IsOut
                                              ? "#a84e32"
                                              : lineColor,
                                            // height:20,
                                            // width:20,
                                          }}
                                        />
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        color: "var(--text-color)",
                      }}
                    >
                      Loading..
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    color: "var(--text-color)",
                  }}
                >
                  {data.length > 0 ? (
                    <div className="">Loading...</div>
                  ) : (
                    <div className="">Select the Tournament and Match </div>
                  )}
                </div>
              )}
            </div>

            <div className="filter-panel">
              <div className="ms-3 py-2">
                <h2>Select Tournament </h2>

                <select
                  className="select-box "
                  value={tournamentid}
                  onChange={handleTournament}
                >
                  <option value="">Select Tournament</option>
                  {renderTournamentTypeOptions()}
                </select>

                <h2 className="mt-2">Select Match</h2>
                <select
                  className="select-box mt-1"
                  value={matchid}
                  onChange={handleMatch}
                >
                  <option value="">Select Match</option>
                  {renderMatchListOptions()}
                </select>
                <h2 className="mt-2">Select Inning</h2>
                <select
                  className="select-box mt-1"
                  value={inningid}
                  onChange={handleInning}
                >
                  <option value="">Select Inning</option>
                  {renderInning()}
                </select>
                <h2 className="mt-2">Select Batting Player</h2>
                <select
                  className="select-box mt-1"
                  value={batsmanid}
                  onChange={handleBattingPlayer}
                >
                  <option value="">Select Batting Player</option>
                  {renderBattingPlayer()}
                </select>
                <h2 className="mt-2">Select Bowling Player</h2>
                <select
                  className="select-box mt-1"
                  value={bowlerid}
                  onChange={handleBowlingPlayer}
                >
                  <option value="">Select Bowling Player</option>
                  {renderBowlingPlayer()}
                </select>
                <h2 className="mt-2">Select Appeal Type</h2>
                <select
                  className="select-box mt-1"
                  name=""
                  id=""
                  onChange={handleAllAppealType}
                >
                  <option value="">Select Appeal Type</option>
                  {renderAppealTypes()}
                </select>
                <h2 className="mt-2">Select Umpire</h2>
                <select
                  className="select-box mt-1"
                  name=""
                  id=""
                  onChange={handleUmpire}
                >
                  <option value="">Select Umpire</option>
                  <option key={umpire1?.id} value={umpire1?.id}>
                    {umpire1?.User.FirstName}
                  </option>
                  <option key={umpire2?.id} value={umpire2?.id}>
                    {umpire2?.User.FirstName}
                  </option>
                  <option key={umpire3?.id} value={umpire3?.id}>
                    {umpire3?.User.FirstName}
                  </option>
                  {/* {renderUmpire()} */}
                </select>
              </div>
            </div>
          </div>
        </div>
        {isVisible && popupVideo()}
        {isValid && validationpopup()}
      </div>
    </div>
  );
}

export default Dashboard;

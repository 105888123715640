import React, { useEffect, useState } from "react";
import "../styles/scoreticker.css";
import {
  get_match_inn_running_info,
  get_match_inn_info,
  get_match_info,
  get_team_stats,
  get_team_data,
  get_player_data,
} from "../api/serverWrapper";
import Pusher from "pusher-js";
import { useParams } from "react-router-dom";
import { getBowlerStats } from "../componants/CommonFuncations";
import MatchTypes from "../componants/MatchTypes";

function Scoreticker() {
  let [match, setMatch] = useState();
  let [teamA, setTeamA] = useState();
  let [teamB, setTeamB] = useState();
  let [matchinfo, setMatchInfo] = useState();
  let [innInfo, setInnInfo] = useState(null);
  let { matchid } = useParams();
  useEffect(() => {
    get_ss();
    console.log(matchid);
    get_match(matchid);
  }, []);

  const get_match = async (id) => {
    let resp = await get_match_info(id);
    match = resp.data;
    setMatch(match);
    console.log("resp", match);
    let teamAresp = await get_player_data(match.TeamA.id);
    console.log("teamAresp", teamAresp);
    teamA = teamAresp.data;
    setTeamA(teamA);
    let teamBresp = await get_player_data(match.TeamB.id);
    console.log("teamBresp", teamBresp);
    teamB = teamBresp.data;
    setTeamB(teamB);
    await get_initialdata(matchid);
  };

  const get_ss = async () => {
    const pusher = new Pusher("c3050db1a9b68d16f530", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("sdm-" + matchid);
    channel.bind("scoring", function (data) {
      console.log("data", data);
      get_initialdata(data.matchId, data.innId);
    });

    return () => {
      pusher.disconnect();
    };
  };
  const get_initialdata = async (matchid) => {
    console.log("get_initialdata", matchid, match.CurrInnNum);
    let innres = await get_match_inn_info(matchid, match.CurrInnNum);
    matchinfo = innres.data;
    setMatchInfo(matchinfo);
    console.log("innres", innres.data);

    let resp = await get_match_inn_running_info(
      matchid,
      match.CurrInnNum,
      matchinfo.CurrOverNum - 1
    );
    innInfo = resp.data;
    setInnInfo(innInfo);
    console.log("innInfo", innInfo);
    // await get_ss();
  };

  const getTargetDisplayStr = (innInfo, match) => {
    let str = "";
    if (match.MatchType === MatchTypes.TEST.id) {
      if (innInfo.InningNumber === 4) {
        str =
          innInfo.TargetRuns > 0 ? "Target : " + (innInfo.TargetRuns + 1) : "";
      } else if (innInfo.InningNumber === 2 || innInfo.InningNumber === 3) {
        let val = 0;
        if (innInfo.InningNumber === 2) {
          val =
            innInfo.TargetRuns > 0 ? -innInfo.TargetRuns : innInfo.TargetRuns;
        } else {
          if (match.IsMatchFollowOn) {
            val =
              innInfo.TargetRuns > 0 ? -innInfo.TargetRuns : innInfo.TargetRuns;
          } else {
            val =
              innInfo.TargetRuns > 0 ? innInfo.TargetRuns : innInfo.TargetRuns;
          }
        }
        //alert(val)
        let diff = val + innInfo.InnTotalRuns;

        str = diff < 0 ? "Trail by : " + -diff : "Leading by : " + diff;
      }
    } else {
      if (innInfo.InningNumber === 2) {
        str =
          innInfo.TargetRuns > 0 ? "Target : " + (innInfo.TargetRuns + 1) : "";
      }
    }
    return (
      <div className="target">
        {/* <span style={{ fontSize: "18px", fontWeight: "700", color: "#ffffff" }}>
          TARGET
        </span> */}
        <span className="target_score"> {str}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="score-ticker-container">
        <div className="batting_bowling_team_container">
          {teamA?.TeamLogoUrl && teamB?.TeamLogoUrl ? (
            <img
              className="team_logo_scoreticker"
              src={
                teamA?.id === matchinfo?.BattingTeam.id
                  ? teamA?.TeamLogoUrl
                  : teamB?.TeamLogoUrl
              }
              alt=""
            />
          ) : (
            <img
              className="team_logo_scoreticker"
              src={require("../assets/images/team_logo.png")}
              alt=""
            />
          )}
          <span>{matchinfo?.BattingTeam.TeamShortName}</span>
        </div>

        <div className="batsmans">
          <div className="striker_st">
            <div>
              <span className="name d-flex gap-2">
                {innInfo?.striker.Player.User.FirstName}
                <img
                  style={{ height: "18px", width: "16px" }}
                  src={require("../assets/images/bat.png")}
                />
              </span>
            </div>
            <div className="d-flex gap-3">
              <span className="player_runs">{innInfo?.striker.RunsScored}</span>
              <span className="ballsplayed">
                ({innInfo?.striker.BallsPlayed})
              </span>
            </div>
          </div>
          <div className="nonstriker_st">
            <div>
              <span className="name">
                {innInfo?.nonStriker.Player.User.FirstName}
              </span>
            </div>
            <div className="d-flex gap-3 ">
              <span className="player_runs">
                {innInfo?.nonStriker.RunsScored}
              </span>
              <span className="ballsplayed">
                ({innInfo?.nonStriker.BallsPlayed})
              </span>
            </div>
          </div>
        </div>
        <div className="hexagon">
          <div className="team-score-over">
            <span className="team-score">
              {matchinfo?.InnTotalRuns} - {matchinfo?.TotalWickets}
            </span>
            <span className="team-over">
              [{matchinfo?.CurrOverNum - 1}.{matchinfo?.CurrBallNum - 1}]
            </span>
          </div>
          <div className="team-name">
            <span className="playingteam">
              {matchinfo?.BattingTeam.TeamShortName}{" "}
            </span>
            <span className="nonplayingteam">
              v {matchinfo?.BowlingTeam.TeamShortName}
            </span>
          </div>
          {/* <div
          className={
            "match_type " +
            (matchinfo?.InningNumber === 2 ? "inning2" : "inning1")
          }
          
        >
          <span className="match_crr">
            CRR{" "}
            {(
              matchinfo?.InnTotalRuns /
              (matchinfo?.CurrOverNum - 1 + (matchinfo?.CurrBallNum - 1) / 6)
            ).toFixed(2)}
          </span>
          {matchinfo?.InningNumber === 2 && (
            <span className="match_crr">
              RR{" "}
              {(
                (matchinfo?.TargetRuns - matchinfo?.InnTotalRuns) /
                (20.0 -
                  (matchinfo?.CurrOverNum -
                    1 +
                    (matchinfo?.CurrBallNum - 1) / 6))
              ).toFixed(2)}
            </span>
          )}
        </div> */}
        </div>
        <div className="bowler_container">
          <div className="ball_container">
            {innInfo?.oversData.currOver.length !== 0 ? (
              <div className="gap-2 d-flex">
                {innInfo?.oversData.currOver.map((score, index) => (
                  <div
                    className="scoreticker_ball"
                    style={
                      score.IsWicket
                        ? { backgroundColor: "#380a11", color: "#fff" }
                        : null
                    }
                  >
                    <div>
                      {score.TotalRun}
                      <div style={{ fontSize: 8 }}>
                        {score.IsWideBall ? "WD" : ""}
                        {score.IsNoBall ? "NB" : ""}
                        {score.IsWicket ? "W" : ""}
                        {score.IsBye ? "b" : ""}
                        {score.IsLegbye ? "lb" : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="gap-2 d-flex">
                {innInfo?.oversData.preOver.map((score, index) => (
                  <div
                    className="scoreticker_ball"
                    style={
                      score.IsWicket
                        ? { backgroundColor: "#380a11", color: "#fff" }
                        : null
                    }
                  >
                    <div>
                      {score.TotalRun}
                      <div style={{ fontSize: 8 }}>
                        {score.IsWideBall ? "WD" : ""}
                        {score.IsNoBall ? "NB" : ""}
                        {score.IsWicket ? "W" : ""}
                        {score.IsBye ? "b" : ""}
                        {score.IsLegbye ? "lb" : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="bowler">
            <div>
              <span className="name">
                {innInfo?.bowler.Player.User.FirstName}
              </span>
            </div>
            <div className="d-flex gap-2">
              <span className="bowler_stats">
                {innInfo?.bowler.WicketsTaken}-{innInfo?.bowler.RunsGiven}
              </span>
              {/* {innInfo?.bowler.OverAboveBallsBowled < 6 &&
              innInfo?.bowler.OverAboveBallsBowled > 0 ? ( */}
              {innInfo ? (
                <span className="bowler_stats ">
                  [{getBowlerStats(innInfo?.bowler).v}]
                </span>
              ) : (
                ""
              )}
              {/* ) : (
                <span className="bowler_stats ">
                  [{innInfo?.bowler.OversBowled}]
                </span>
              )} */}
            </div>
          </div>
        </div>
        {matchinfo?.TargetRuns ? getTargetDisplayStr(matchinfo, match) : ""}
        <div className="batting_bowling_team_container">
          {teamA?.TeamLogoUrl && teamB?.TeamLogoUrl ? (
            <img
              className="team_logo_scoreticker"
              src={
                teamB?.id === matchinfo?.BowlingTeam.id
                  ? teamB?.TeamLogoUrl
                  : teamA?.TeamLogoUrl
              }
              alt=""
            />
          ) : (
            <img
              className="team_logo_scoreticker"
              src={require("../assets/images/team_logo.png")}
              alt=""
            />
          )}
          <span>{matchinfo?.BowlingTeam.TeamShortName}</span>
        </div>
      </div>
    </div>
  );
}

export default Scoreticker;

// import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./screen/Home";
import Matchcreation from "./screen/Matchcreation";
import Menubars from "./screen/Menubars";
import Player from "./screen/Player";
import Score from "./screen/Score";
import Playercreation from "./screen/Playercreation";
import Session from "./screen/Session";
import Teamcreation from "./screen/Teamcreation";
import Ground from "./screen/Ground";
import NewTable from "./screen/NewTable";
import User from "./screen/User";
import Umpire from "./screen/Officials";
import Dashboard from "./screen/Dashboard";
import ScoreCard from "./screen/ScoreCard";
import Tournaments from "./screen/Tournaments";
import Leaderboard from "./screen/Leaderboard";
import Register from "./screen/Register";
import Login from "./screen/Login";
import Scoreticker from "./screen/Scoreticker";
import CricetB from "./screen/CricketBoards";
import { useEffect, useState } from "react";
import Board from "./screen/Board";
import { THEME_COLOR } from "./utils/localstorage_variables";
import "../src/styles/darkTheme.css";
import Terms from "./screen/Terms";

function App() {
  const location = useLocation();
  let [loggedin, setLoggedIn] = useState();
  useEffect(() => {
    let resp = localStorage.getItem("islogged");
    loggedin = resp;
    setLoggedIn(loggedin);
    console.log(localStorage.getItem(THEME_COLOR));
    let th = localStorage.getItem(THEME_COLOR);
    if (th) {
      const theme = th;
      document.documentElement.className = theme;
    } else {
      const theme = "dark-theme";
      document.documentElement.className = theme;
    }
  });

  const shouldExcludeMenubars = () => {
    if (
      location.pathname.startsWith("/match/scoreticker/") ||
      location.pathname === "/scoring" ||
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/privacypolicy"
    ) {
      return false;
    }
    return true;
  };
  return (
    <div>
      {shouldExcludeMenubars() && <Menubars />}
      <div className="my-4">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/players" element={<Playercreation />} />
          <Route path="/teams" element={<Teamcreation />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/matches" element={<Matchcreation />} />
          <Route path="/officials" element={<Umpire />} />
          <Route path="/grounds" element={<Ground />} />
          <Route path="/scorecard" element={<ScoreCard />} />
          <Route path="/scoring" element={<Score />} />
          <Route path="/leaderboard" element={<Board />} />
          <Route path="/entry" element={<CricetB />} />
          <Route path="/match/scoreticker/:matchid" element={<Scoreticker />} />
          <Route path="/privacypolicy" element={<Terms />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

class DismissalTypes {
  static get BOWLED() {
    return { id: 1, status: "Bowled" };
  }

  static get CAUGHT() {
    return { id: 2, status: "Caught" };
  }

  static get LBW() {
    return { id: 3, status: "LBW" };
  }

  static get STUMPED() {
    return { id: 4, status: "Stumped" };
  }

  static get HITTING_TWICE() {
    return { id: 5, status: "Hitting Twice" };
  }

  static get HIT_WICKET() {
    return { id: 6, status: "Hit Wicket" };
  }

  static get RUN_OUT() {
    return { id: 7, status: "Run Out" };
  }

  static get TIMED_OUT() {
    return { id: 8, status: "Timed Out" };
  }

  static get OBSTRUCTING_FIELD() {
    return { id: 9, status: "Obstructing Field" };
  }

  static get OTHER() {
    return { id: 10, status: "Other" };
  }

  static get RETIRED_OUT() {
    return { id: 11, status: "Retired Out" };
  }

  static get RETIRED_NOT_OUT() {
    return { id: 12, status: "Retired Not Out" };
  }

  static get RETIRED_ABSENT() {
    return { id: 13, status: "Retired Absent" };
  }

  static get CAUGHT_AND_BOWLED() {
    return { id: 14, status: "Caught and Bowled" };
  }

  static get CAUGHT_BEHIND() {
    return { id: 15, status: "Caught Behind" };
  }

  static get Types() {
    return [
      DismissalTypes.BOWLED,
      DismissalTypes.CAUGHT,
      DismissalTypes.LBW,
      DismissalTypes.STUMPED,
      DismissalTypes.HITTING_TWICE,
      DismissalTypes.HIT_WICKET,
      DismissalTypes.RUN_OUT,
      DismissalTypes.TIMED_OUT,
      DismissalTypes.OBSTRUCTING_FIELD,
      DismissalTypes.OTHER,
      DismissalTypes.RETIRED_OUT,
      DismissalTypes.RETIRED_NOT_OUT,
      DismissalTypes.RETIRED_ABSENT,
      DismissalTypes.CAUGHT_AND_BOWLED,
      DismissalTypes.CAUGHT_BEHIND,
    ];
  }
}

module.exports = DismissalTypes;

import React from "react";
import "../styles/validationmodal.css";

function ValidationModal({ data, onClose }) {
  return (
    <div className="validation-popup">
      <div className="validation-success-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Info</h1>
          <div onClick={onClose} className="close">
            X
          </div>
        </div>
        <div className="inner-layout">
          <div className="mx-4">
            <p className="success"> {data}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end me-3">
          <button className="btn1 mt-4" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValidationModal;

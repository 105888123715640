import React from "react";
import "../styles/validationmodal.css";

function DeleteBall({ data, onClose ,deleteConfirm }) {
  return (
    <div className="validation-popup">
      <div className="validation-success-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Delete  Ball</h1>
          <div onClick={onClose} className="close">
            X
          </div>
        </div>
        <div className="inner-layout">
          <div className="mx-4">
            <h5 style={{color:'var(--text-color)'}} className="success">Do you want Delete the  Ball  {data}</h5>
          </div>
        </div>
        <div className="d-flex  gap-3 justify-content-end me-3">
          <button className="btn1 mt-4" onClick={onClose}>
          NO
          </button>
          <button className="btn1 mt-4" onClick={()=>deleteConfirm()} >
            YES
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteBall;

import React from "react";
import "../styles/uploaddoc.css";
import { useEffect, useState } from "react";
import { update_user_doc } from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";

function UploadDocs({ onclose, uid }) {
  const [selectedPlayerDocument, setSelectedPlayerDocument] = useState(null);
  const [validationData, setValidationData] = useState(null);
  const [document, setDocument] = useState("");
  const [fileName, setFileName] = useState("");

  const handleFileName = (e) => {
    setFileName(e.target.value);
  };

  useEffect(() => {}, [document]);

  const handlePlayerDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type.startsWith("image/")) {
        setDocument(e.target.files[0]);
        setSelectedPlayerDocument(URL.createObjectURL(file));
      } else {
        alert("Please select a PDF file or an image.");
      }
    }
    console.log(document);
  };

  const uploadDocment = async () => {
    if (fileName === "") {
      alert("Please Fill the Document Name.");
      return;
    }
    console.log(document);
    if (document !== "") {
      let resp = await update_user_doc(uid, document, fileName);
      console.log(resp);
      if (resp.success) {
        setValidationData("Document Uploaded Successfully.");
      }
    } else {
      alert("Please select a PDF file or an image.");
    }
  };

  const handlevalidationData = async () => {
    setValidationData(null);
    onclose();
  };

  const removeSelectedDocument = () => {
    setSelectedPlayerDocument(null);
  };
  return (
    <div className="upload-doc-popup">
      <div className="upload-doc-popup-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Upload documnet </h1>
          <div onClick={onclose} className="close">
            X
          </div>
        </div>
        <div className="mx-4">
          <input
            type="text"
            className="player_details_select"
            placeholder="Document Name"
            onChange={handleFileName}
          />
        </div>
        <div className="d-flex gap-2 ms-4 justify-content-between">
          <div className="d-flex">
            <input
              type="file"
              id="user_doc"
              accept=".pdf, image/*"
              onChange={handlePlayerDocument}
              style={{ display: "none" }}
            />
            <label
              htmlFor="user_doc"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              <div className="title">Upload Player Documnet</div>
              <img
                src={require("../assets/images/attach.png")}
                alt=""
                height={24}
                width={24}
              />
            </label>
            {selectedPlayerDocument && (
              <div className="d-flex gap-2 align-items-center close-doc">
                <iframe
                  src={selectedPlayerDocument}
                  width="80%"
                  height="200px"
                  title="Selected PDF"
                ></iframe>
                <p className="close" onClick={removeSelectedDocument}>
                  X
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end me-3">
          <button className="btn1 mt-4 me-3" onClick={onclose}>
            Cancle
          </button>
          <button className="btn1 mt-4" onClick={uploadDocment}>
            OK
          </button>
        </div>
      </div>
      {validationData && (
        <ValidationModal data={validationData} onClose={handlevalidationData} />
      )}
    </div>
  );
}

export default UploadDocs;

import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "../styles/leaderboard.css";
import { customtableStyles } from "../assets/tablestyle";
import { tablecreateTheme } from "../assets/globalcolors";
import {
  get_players,
  get_tournament_leaderboard,
  get_tournaments_type,
} from "../api/serverWrapper";

function Leaderboard(props) {
  const [btnbowling, setBtnBowling] = useState(false);
  const [btnbatting, setBtnBatting] = useState(true);
  // const [tournaments, setTournaments] = useState([]);
  const [players, setPlayers] = useState([]);
  // let [tournamnetdetails, setTournamentDetails] = useState(props);

  let { tournamnetdetails } = props;
  const get_api_data = async () => {
    // let tournaments = await get_tournaments_type();
    // setTournaments(tournaments.data.tournaments);

    let players = await get_players();
    setPlayers(players.data);
  };

  useEffect(() => {
    get_api_data();
  }, []);

  createTheme("solarized", {
    background: {
      default: 'var(--main-background-color)',
    },
  });

  const bowlingcolumn = [
    {
      selector: (row, index) => index + 1,
      width: "60px",
      sortable: true,
    },
    {
      name: "Player Name",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "16px" }}>{row.playerName}</span>
          <span style={{ fontSize: "12px" }}>{row.teamName}</span>
        </div>
      ),
      sortable: true,
      width: "300px",
      headerStyle: (selector, id) => {
        return {
          textAlign: "right",
        };
      },
    },
    {
      name: "MAT",
      selector: (row) => row.matchCout,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "OV",
      selector: (row) => row.oversBowled,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "MAIDEN",
      selector: (row) => row.maidens,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.runsGiven,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "WKTS",
      selector: (row) => row.wicketsTaken,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "3-FORS",
      selector: (row) => row.wicket3Fors,
      sortable: true,
      width: "120px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "FIFERS",
      selector: (row) => row.wicket5Fors,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.bowlingAverage,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "ECON",
      selector: (row) => row.bowlingEconomy,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "BEST",
      selector: (row) => row.bowlingBest + "/" + row.RunsOnBest,
      sortable: true,
      width: "100px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];

  const getPlayerName = (playerid) => {
    const p = players.find((item) => item.PlayerId === playerid);
    if (p) return p.Player.User.FirstName;
    else return "NA";
  };

  const getTeamName = (teamid) => {
    //  const p = list.find(
    //    (item) => item.PlayerId === id && item.OversBowled !== 0
    //  );
    //  if (p) return p.Player.User.FirstName;
    //  else return "NA";
  };

  const PlayerNameandTeam = ({ row }) => {
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ fontSize: "16px" }}>{getPlayerName(row.playerId)}</span>
      <span style={{ fontWeight: "600" }}>{row.teamname}</span>
    </div>;
  };
  const battingcolumn = [
    {
      selector: (row, index) => index + 1,
      width: "60px",
      sortable: true,
    },
    {
      name: "Player Name",
      selector: (row) => (
        // <PlayerNameandTeam row={row} />,
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "16px" }}>{row.playerName}</span>
          <span style={{ fontSize: "12px" }}>{row.teamName}</span>
        </div>
      ),
      sortable: true,
      width: "280px",
      textAlign: "left",
      headerStyle: (selector, id) => {
        return {
          textAlign: "right",
        };
      },
    },
    {
      name: "MAT",
      selector: (row) => row.matchCout,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "INNS",
      selector: (row) => row.BattingInnsCount,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "center",
        };
      },
    },
    {
      name: "RUNS",
      selector: (row) => row.totalRuns,
      sortable: true,
      textAlign: "left",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "HS",
      selector: (row) => row.topScore,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "AVG",
      selector: (row) => row.avgRuns,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "SR",
      selector: (row) => row.sr,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "100s",
      selector: (row) => row.hundreds,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "50s",
      selector: (row) => row.fifties,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      width: "70px",
      headerStyle: () => {
        return {
          textAlign: "left",
        };
      },
    },
  ];

  const togglebattingandbowling = () => {
    setBtnBatting(!btnbatting);
    setBtnBowling(!btnbowling);
  };

  // const handleTournament = async (event) => {
  //   const selectedTournamentId = event.target.value;
  //   let resp = await get_tournament_leaderboard(selectedTournamentId);
  //   console.log(resp.data);
  //   tournamnetdetails = resp.data;
  //   setTournamentDetails(tournamnetdetails);
  // };

  // const handleMatchesRender = () => {
  //   if (matches1.length !== 0) {
  //     return (
  //       Array.isArray(matches1) &&
  //       matches1.map((Match) => (
  //         <option key={Match.id} value={Match.id}>
  //           {Match.MatchNameOrTitle}
  //         </option>
  //       ))
  //     );
  //   } else {
  //     return <option>No Matches</option>;
  //   }
  // };

  return (
    <div className="container1">
      <div className="layout_conatiner">
        <div>
          <div className="select_bat_bowl">
            {/* <div className="search_content">
              <div>
                <span className="search_text">Leader Board</span>
              </div>
              <div>
                <select
                  className="combobox"
                  name="TeamId"
                  onChange={handleTournament}
                >
                  <option>Select Tournament</option>
                  {Array.isArray(tournaments) &&
                    tournaments.map((Tournamnet) => (
                      <option key={Tournamnet.id} value={Tournamnet.id}>
                        {Tournamnet.TournamentName}
                      </option>
                    ))}
                </select>
              </div>
            </div> */}

            <div>
              <button
                className={btnbatting ? "btnbatting" : "btnbatting1"}
                onClick={togglebattingandbowling}
              >
                Batting
              </button>
              <button
                className={btnbowling ? "btnbowling" : "btnbowling1"}
                onClick={togglebattingandbowling}
              >
                Bowling
              </button>
            </div>
          </div>
          <div className="leader_table">
            {btnbatting ? (
              <DataTable
                columns={battingcolumn}
                data={tournamnetdetails?.sort(
                  (a, b) => b.totalRuns - a.totalRuns
                )}
                customStyles={customtableStyles}
                theme="solarized"
                fixedHeader
                fixedHeaderScrollHeight="700px"
              ></DataTable>
            ) : (
              <DataTable
                columns={bowlingcolumn}
                data={tournamnetdetails?.sort(
                  (a, b) => b.wicketsTaken - a.wicketsTaken
                )}
                customStyles={customtableStyles}
                theme="solarized"
                fixedHeader
                fixedHeaderScrollHeight="700px"
              ></DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Leaderboard;

// Define the MatchTypes class
class MatchTypes {
    
    static get ODI() {
        return { id: 1, name: 'ODI', NumberOfOvers : 50, NumberOfInnings : 2 };
    }

    static get T20() {
      return { id: 2, name: 'T20', NumberOfOvers : 20, NumberOfInnings : 2 };
    }
    
    static get TEST() {
        return { id: 3, name: 'Test', NumberOfOvers : 1000, NumberOfInnings : 4};
    }
    static get CUSTOM() {
        return { id: 4, name: 'Test', NumberOfOvers : 10, NumberOfInnings : 2};
    }
    static get Types(){
        return [MatchTypes.ODI,MatchTypes.T20,MatchTypes.TEST,MatchTypes.CUSTOM];
    }
  }
  
  // Export the MatchTypes class
module.exports = MatchTypes;
class Address {
  constructor(data) {
    if (data) {
      console.log("data", data);
      this.CountryId = 105;
      this.CityId = data.CityId;
      this.StateId = data.StateId;
      this.DistrictId = data.DistrictId;
      this.AddressDetails = data.AddressDetails;
    } else {
      this.CountryId = 105;
      this.CityId = null;
      this.StateId = null;
      this.DistrictId = null;
      this.AddressDetails = null;
    }
  }
}

export default Address;

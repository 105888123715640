import React, { useEffect, useState } from "react";
import "../styles/addteamforplayers.css";
import { get_players, get_teams } from "../api/serverWrapper";
import DataTable, { createTheme } from "react-data-table-component";
import UserSystemRole from "../componants/UserSystemRole";

import {
  conditionalRowStylesPlayer,
  customtableStyles,
  conditionalRowStyles,
} from "../assets/tablestyle";
import {
  LOGIN_USER_DISTRICT_ID,
  USER_SYSTEM_ROLE,
} from "../utils/localstorage_variables";
import { getData } from "../utils/localstorage_constants";
import { AddIcon, DeleteIcon } from "../assets/SvgIcons";

function AddTeamForPlayers({ rowData1, onClose }) {
  let [playerData, setPlayerData] = useState(rowData1);

  const [teams, setTeams] = useState([]);
  let [superadmin, setSuperadmin] = useState(false);
  let [systemrole, setSystemRole] = useState();
  let [offset, setOffset] = useState(0);
  let [districtid, setDistrictId] = useState(null);
  const [addedPlayers, setAddedPlayers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    LoadData();
  }, []);

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const handleSearch = async (event) => {
    console.log(event.target.value);
    offset = 0;
    setOffset(offset);
    var players = await get_players(offset, event.target.value, districtid);
    setPlayerData(players.data.players);
    console.log("payers", players.data.players.length);
    setPlayerData(players.data.players);
    // const filteredData = rowData
    //   .filter((item) =>
    //     item.User.FirstName.toLowerCase().includes(
    //       event.target.value.toLowerCase()
    //     )
    //   )
    //   .sort((a, b) => a.User.FirstName.localeCompare(b.User.FirstName));
    // setRowData1(filteredData);
  };

  const LoadData = async () => {
    let team = await get_teams();
    console.log(team.data.teams);
    setTeams(team.data.teams);

    let districtId = getData(LOGIN_USER_DISTRICT_ID);
    let systemRole = getData(USER_SYSTEM_ROLE);
    systemrole = systemRole;
    setSystemRole(systemrole);

    if (systemRole === UserSystemRole.ADMIN.id) {
      setSuperadmin(true);
    } else if (systemRole === UserSystemRole.DISTRICT_ADMIN.id) {
      districtid = districtId;
      setDistrictId(districtid);
      setSuperadmin(false);
    } else {
      setSuperadmin(false);
    }
  };

  const PlayerNameCell = ({ row }) => {
    const firstNameLetter = row.User.FirstName
      ? row.User.FirstName.charAt(0)
      : "";
    const lastNameLetter = row.User.LastName ? row.User.LastName.charAt(0) : "";
    return (
      <div className="batsman_bowler_container">
        {row.User.ProfilePhotoUrl ? (
          <img
            className="batsman_bowler_img"
            src={row.User.ProfilePhotoUrl}
            alt=""
          />
        ) : (
          <div className="name_cell">
            {firstNameLetter}
            {lastNameLetter}
          </div>
        )}
        <div>
          {row.User.FirstName +
            "   " +
            (row.User.LastName ? row.User.LastName : "")}
        </div>{" "}
      </div>
    );
  };

  const columnsplayer = [
    {
      name: "Name",
      selector: (row) => <PlayerNameCell row={row} />,
      sortable: true,
      width: "300px",
    },
    {
      name: "Add Player",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px" }}
          src={AddIcon}
          onClick={() => handleAddTeam(row)}
        />
      ),
      center: true,
      width: "120px",
    },
  ];
  const columnsnewplayer = [
    {
      name: "Name",
      selector: (row) => <PlayerNameCell row={row} />,
      sortable: true,
      width: "300px",
    },
    {
      name: "Delete",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px" }}
          src={DeleteIcon}
          onClick={() => handleDelete(row)}
        />
      ),
      center: true,
      width: "120px",
    },
  ];

  const renderTeams = () => {
    return teams?.map((item, index) => (
      <option key={index} value={item.id}>
        {" "}
        {item.TeamName} - ({item.TeamShortName}){" "}
      </option>
    ));
  };

  const handleAddTeam = (player) => {
    console.log(player);
    if (!selectedTeam) {
      alert("Please select a team first.");
      return;
    }
    setAddedPlayers([...addedPlayers, player]);
    setPlayerData(playerData.filter((p) => p.PlayerId !== player.PlayerId));
  };

  const handleDelete = (player) => {
    setAddedPlayers(addedPlayers.filter((p) => p.PlayerId !== player.PlayerId));
    setPlayerData([...playerData, player]);
  };


  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  return (
    <div className="add-team-for-players-popup">
      <div className="add-team-for-players-popup-inner ">
        <div className="d-flex justify-content-between align-items-center">
          <h1>Add Team</h1>
          <div className="close" onClick={() => onClose()}>
            x
          </div>
        </div>
        <div className="">
          <div className="d-flex align-items-center">
            <div>
              <input
                className="search_input"
                type="text"
                placeholder="Search player"
                onChange={handleSearch}
              />
            </div>
            <div className="pop_up_inputs">
              <select className="player_details_select" onChange={handleTeamChange}>
                <option> Select Team </option>
                {renderTeams()}
              </select>
            </div>
          </div>
          <div className="d-flex  mx-4 gap-2">
            <div style={{ borderRadius: 20 }} className="">
              <DataTable
                columns={columnsplayer}
                data={superadmin ? [] : playerData}
                customStyles={customtableStyles}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeader
                theme="solarized"
                fixedHeaderScrollHeight="500px"
              ></DataTable>
            </div>
           {addedPlayers.length > 0  ? (<div style={{ borderRadius: 20 }} className="">
              <DataTable
                columns={columnsnewplayer}
                data={addedPlayers}
                customStyles={customtableStyles}
                conditionalRowStyles={conditionalRowStyles}
                fixedHeader
                theme="solarized"
                fixedHeaderScrollHeight="500px"
              ></DataTable>
            </div>) :( <div  className="title2 d-flex align-items-center"> <h1>Please add Players To the Team  </h1> <img   style={{ height: "25px", width: "25px" }} src={AddIcon} alt="" /> </div> )}
          </div>
        </div>
        <div className="d-flex justify-content-center gap-3 mx-4 my-4">
          <div className="update-btn w-25" onClick={() => onClose()}>
            cancle
          </div>
          <div className="update-btn w-25">Add Players To Team</div>
        </div>
      </div>
    </div>
  );
}

export default AddTeamForPlayers;

class CreateFixture  {
    
    constructor(fixtureData = {}) {

        this.TournamentOrSeriesId=null;
        this.TeamAId=null;
        this.TeamBId=null;
        this.MatchDateTime=null;
        this.MatchType=null;
        this.NumberOfOvers =null;
        this.MatchNameOrTitle =null;
        this.GroundId =null;
         
        Object.assign(this, fixtureData);
      }
}

export  default CreateFixture;
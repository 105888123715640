import React, { useState } from "react";
import "../styles/wicketmodal.css";

function WicketModal({ data, onClose,setSelectedWicketTypeName ,setWicketType,setStrikerOrNonStriker,setIsWicketForStriker,cancle}) {
  const [wicket, setWicket] = useState(null);
  const [error, setError] = useState(false); 



  const [currentBatting, setCurrentBatting] = useState([
    { id: 1, type: "Striker " },
    { id: 2, type: "Non striker" },
  ]);

  const handleWicketType = (id,Type) => {
    setWicket(id);
    setSelectedWicketTypeName(Type);
    setWicketType(id);
    if ( id <= 6 || id == 14 || id == 15 ){
      setIsWicketForStriker(true);
      setSelectedStrikerOrNonStriker(1);
      setStrikerOrNonStriker("Striker ");
    }
    else{
      setIsWicketForStriker(false);
      setSelectedStrikerOrNonStriker(null);
      setStrikerOrNonStriker("");
    }
  };

  const [selectedStrikerOrNonStriker, setSelectedStrikerOrNonStriker] = useState(null);
  const handleBattingType = (id,type) => {
    id === 1 ? setIsWicketForStriker(true): setIsWicketForStriker(false);
    setSelectedStrikerOrNonStriker(id);
    setStrikerOrNonStriker(type);
  };
  const handleOKClick = () => {
    if (!wicket || !selectedStrikerOrNonStriker) {
      setError(true);
    } else {
      setError(false); // Reset error state
      onClose(); // Close the modal
    }
  };

  return (
    <div className="appeal-popup">
      <div className="appeal-container">
        <div className="d-flex justify-content-between align-items-center">
          <h1>Select Wicket Type</h1>
          <div className="close" onClick={onClose}>
            X{" "}
          </div>
        </div>

        <div className="wicket-grid ">
          {data.map((item, index) => (
            <div
              className="d-flex align-item-center gap-2"
              key={index}
              onClick={() => handleWicketType(item.id,item.Type)}
            >
              <input
                type="radio"
                checked={wicket === item.id}
                id={`appealresult-${item.id}`}
                style={{ visibility: "hidden", position: "absolute" }}
              />
              <div
                className={
                  item.id === wicket
                    ? "check-box-style selected-item"
                    : "check-box-style"
                }
              >
                 ✔
              </div>
              <label htmlFor={`wicket-${item.id}`}>{item.Type}</label>
            </div>
          ))}
        </div>
        {error && !wicket && (
          <div className="error-message mt-2">Please select a wicket type</div>
        )}
        <div className="d-flex ms-3 mt-4 align-items-center">
          <h1>Select Wicket</h1>
          <div className="d-flex ms-4 gap-3">
            {currentBatting.map((item, index) => (
              <div
                className="d-flex align-item-center gap-2"
                onClick={() => handleBattingType(item.id,item.type)}
                key={index}
              >
                <input
                  type="radio"
                  checked={selectedStrikerOrNonStriker === item.id}
                  id={`strikerornonstriker-${item.id}`}
                  style={{ visibility: "hidden", position: "absolute" }}
                />
                <div
                  className={
                    item.id === selectedStrikerOrNonStriker
                      ? "check-box-style selected-item"
                      : "check-box-style"
            }
                >
                   ✔
           </div>
                <label htmlFor={`strikerornonstriker-${item.id}`}>
                  {item.type}
                </label>
              </div>
            ))}
          </div>
        </div>
        {error && !selectedStrikerOrNonStriker && (
          <div className="error-message ">Please select a Striker Or Non Striker</div>
        )}
        <div
          className="d-flex justify-content-end  cursor-pointer me-4 mb-2"
        >
          <button onClick={cancle}  className="update-btn w-25 d-flex mt-3 "  >Cancel</button>
          <button  onClick={handleOKClick} className="update-btn w-25 d-flex mt-3 ms-2"  >OK</button>
        </div>
      </div>
    </div>
  );
}

export default WicketModal;

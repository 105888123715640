class Team {
  constructor() {
    this.id = null;
    this.TeamName = null;
    this.TeamShortName = null;
    this.Description = null;
    this.CaptainId = null;
    this.TeamLogoUrl = null;
    this.ViceCaptainId = null;
    this.TeamType = null;
  }
}

export default Team;

import React ,{useState} from 'react'
import '../styles/showScoreTable.css'
import Dashboard from './Dashboard'

function ShowScoreTable({filterPlayerData,CloseScoreTable}) {

    
  return (
     <div className="score-table-team-for-players-popup">
        <div className="score-table-team-for-players-popup-inner">
            <div className="title2 d-flex justify-content-end" style={{cursor:'pointer'}} onClick={()=>CloseScoreTable()} >X</div>
             <Dashboard filterPlayerData={filterPlayerData} />
        </div>
     </div>
  )
}

export default ShowScoreTable
import React, { useState, useEffect, useRef } from "react";
import "../styles/scorecard.css";
import ScoreStats from "./ScoreStats";
import Squad from "./Squad";
import Dashboard from "./Dashboard";
import ValidationModal from "./ValidationModal";
import ReactPlayer from "react-player";
import { backgroundcolor, tablecreateTheme } from "../assets/globalcolors";
import classNames from "classnames";
import {
  get_match_type,
  get_tournaments_type,
  get_ground_data,
  get_player_data,
  get_official_data,
  get_teams,
  create_match,
  update_match,
  get_appealt_types,
  get_match_data,
  get_match_info,
  get_match_inn_info,
  get_match_playersdata,
  get_score,
  get_balls_details_for_ids,
  get_wicket_types,
  get_match_inn_partnership,
  get_video_urls,
  get_batting_shot_data,
  get_fielding_type,
} from "../api/serverWrapper";
import DataTable, { createTheme } from "react-data-table-component";
import { customtableStyles } from "../assets/tablestyle";
import { Await, useLocation } from "react-router-dom";
import {
  generateCommentary,
  getBowlerStats,
  getPlayerList,
  getTypeById,
  getWicketkeeperName,
} from "../componants/CommonFuncations";
import DismissalTypes from "../componants/DismissalTypes";
import MatchStatus from "../componants/MatchStatus";
import { VideoIcon } from "../assets/SvgIcons";

const ScoreCard = () => {
  const { state } = useLocation();
  const receivedData = state?.data || null;

  console.log(receivedData);

  let senddataToScoreTable = [
    {
      tournamentid: receivedData?.TournamentOrSeriesId,
      data: {
        matchId: receivedData?.id,
        PlayerId: null,
        InnId: 1,
      },
      type: "",
    },
  ];

  const [tournamentType, settournamentType] = useState([]);
  const [wickettypes, setWicketTypes] = useState([]);
  const [matchList, setMatchList] = useState([]);
  let [matchList1, setMatchList1] = useState([]);
  let [tournamentid, setTournamentId] = useState(null);
  let [matchid, setMatchId] = useState(null);
  let [matchInfo, setMatchInfo] = useState(null);
  let [matchtypes, setMatchTypes] = useState(null);

  let [inningInfo, setInningInfo] = useState(null);
  const [battingPlayerList, setBattingPlayerList] = useState();
  const [bowingPlayerList, setBowlingPlayerList] = useState();
  const [appealtTypes, setAppealtTypes] = useState([]);
  let [matchplayersdata, setMatchPlayerData] = useState();
  let [inningid, setInningId] = useState(1);
  let [fallofwickets, setFallofWickets] = useState(null);
  const [battingplayers, setBattingPlayers] = useState([]);
  const [bowlingplayers, setBowlingPlayers] = useState([]);
  const [notbattedplayers, setNotBattedPlayers] = useState([]);
  const [batsmanid, setBatsmanId] = useState(null);
  const [bowlerid, setBowlerId] = useState(null);
  const [appealid, setAppealId] = useState(null);
  const [umpireid, setUmpireId] = useState(null);
  let [wicketsids, setWicketsIds] = useState([]);
  const [outPlayers, setOutPlayers] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [message, setMeassage] = useState("");
  const [ballType, setBallType] = useState([
    { id: 13, AppealType: "W" },
    { id: 14, AppealType: "NB" },
    { id: 15, AppealType: "B" },
    { id: 16, AppealType: "LB" },
  ]);

  const [isVisible, setPopup] = useState(false);
  let [ballurls, setBallUrls] = useState();
  let [cameraview, setCameraView] = useState();
  const [singlecam, setSingleCam] = useState(true);
  const [fourcam, setFourCam] = useState(false);
  const [sixcam, setSixCam] = useState(false);
  let [isfourcamactive, setIsFourCamActive] = useState(false);
  const [issixcamactive, setIsSixCamActive] = useState(false);
  const [activeCamTab, setActiveCamTab] = useState("cam1");

  const [allAppealType, setAllAppealType] = useState([]);
  const [iningId1, setInningId1] = useState([1, 2]);
  const [iningId2, setInningId2] = useState([1, 2, 3, 4]);
  let [isLoading, setIsLoading] = useState(false);

  let [teamAplayers, setTeamAPlayers] = useState();
  let [teamBplayers, setTeamBPlayers] = useState();
  let [playersData, setPlayersData] = useState();
  let [shottypes, setShotTypes] = useState();
  let [fieldingtypes, setFieldingTypes] = useState();
  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const loadInitialData = async () => {
    isLoading = true;
    setIsLoading(isLoading);
    try {
      let tournamentsResp = await get_tournaments_type();
      settournamentType(tournamentsResp.data.tournaments);
      let shotTypes = await get_batting_shot_data();
      shottypes = shotTypes.data;
      setShotTypes(shottypes);

      let fieldingTypes = await get_fielding_type();
      fieldingtypes = fieldingTypes.data;
      setFieldingTypes(fieldingtypes);
      let wickettypes = await get_wicket_types();
      setWicketTypes(wickettypes.data);
      // console.log("wickettypes", wickettypes);
      console.log("recivedData", receivedData);
      let matchtype = await get_match_type();
      matchtypes = matchtype.data;
      setMatchTypes(matchtypes);
      getMatchList();

      let resp = await get_appealt_types();
      setAppealtTypes(resp.data);

      const concatenatedArray = [...resp.data, ...ballType];

      setAllAppealType(concatenatedArray);
      if (receivedData) {
        tournamentid = receivedData.TournamentOrSeriesId;
        setTournamentId(tournamentid);
        matchid = receivedData.id;
        setMatchId(matchid);
        await getMatchInfo(matchid);

        inningid = 1;
        setInningId(inningid);
        await get_info();
        await fetchScore();
        await fetchTeamPlayers(receivedData.TeamAId, receivedData.TeamBId);
      }
    } catch (error) {
      console.log("Error:", error);
    }
    isLoading = false;
    setIsLoading(isLoading);
  };

  const fetchTeamPlayers = async (Ateam, Bteam) => {
    let teamA = await get_player_data(Ateam);
    teamAplayers = teamA.data;
    setTeamAPlayers(teamAplayers);
    setBtnTeamA(true);
    setBtnTeamB(false);
    players = teamAplayers;
    setPlayers(players);
    let teamB = await get_player_data(Bteam);
    teamBplayers = teamB.data;
    setTeamBPlayers(teamBplayers);

    // console.log("players", teamAplayers, teamBplayers);
  };

  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const renderMatchListOptions = () => {
    return matchList1.map((item, index) => (
      <option key={index} value={item.id}>
        {item.MatchNameOrTitle} ({item.id})
      </option>
    ));
  };
  const renderAppealTypes = () => {
    return allAppealType.map((item, index) => (
      <option key={item.id} value={item.id}>
        {item.AppealType}
      </option>
    ));
  };
  let loadeddata = false;

  useEffect(() => {
    if (loadeddata) {
      return;
    }
    loadeddata = true;
    loadInitialData();
    // const storedTab = localStorage.getItem("activeTab");
    // if (storedTab) {
    //   setActiveTab(storedTab);
    // }
  }, [loadeddata]);

  const getMatchList = async () => {
    try {
      let resp = await get_match_data(null, true);
      setMatchList(resp.data.matches);
      setMatchList1(resp.data.matches);
    } catch (error) {
      console.log("error ", error);
    }
  };
  const [activeTab, setActiveTab] = useState("commentry");
  let [players, setPlayers] = useState();

  const [btnteamB, setBtnTeamB] = useState(false);
  const [btnteamA, setBtnTeamA] = useState(true);

  const toggleteamsquad = (team, playing11) => {
    if (team === "teamA") {
      setBtnTeamA(true);
      setBtnTeamB(false);
    }
    if (team === "teamB") {
      setBtnTeamA(false);
      setBtnTeamB(true);
    }
    players = playing11;
    setPlayers(playing11);
  };
  const getPlaying11 = () => {
    return players?.playersList.map((item) => {
      const matchPlayer = matchplayersdata?.find(
        (m) => item.PlayerId === m.PlayerId
      );
      if (matchPlayer) {
        return (
          <div className="card">
            {item.Player.User.ProfilePhotoUrl ? (
              <img
                src={item.Player.User.ProfilePhotoUrl}
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            ) : (
              <img
                src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            )}

            <div className="player-name pt-2">
              <h4>
                {item.Player.User.FirstName} {item.Player.User.LastName}
              </h4>
              <h5>{item.Player.PlayerType.PlayerType}</h5>
            </div>
          </div>
        );
      }
    });
  };

  const getRestSquad = () => {
    return players?.playersList.map((item) => {
      const matchPlayer = matchplayersdata?.find(
        (m) => item.PlayerId === m.PlayerId
      );
      if (!matchPlayer) {
        return (
          <div className="card">
            {item.Player.User.ProfilePhotoUrl ? (
              <img
                src={item.Player.User.ProfilePhotoUrl}
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            ) : (
              <img
                src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            )}

            <div className="player-name pt-2">
              <h4>
                {item.Player.User.FirstName} {item.Player.User.LastName}
              </h4>
              <h5>{item.Player.PlayerType.PlayerType}</h5>
            </div>
          </div>
        );
      }
    });
  };
  const hanbleTabClick = (tab) => {
    setActiveTab(tab);
    // localStorage.setItem("activeTab", tab);
  };

  const validationpopupvisible = () => {
    setIsValid(!isValid);
    if (message) {
      setMeassage("");
    }
  };
  let [score1, setScore1] = useState(null);
  let [scoreAll, setScoreAll] = useState(null);
  const [commentryData, setCommnetryData] = useState();
  const [commentryDataAll, setCommnetryDataAll] = useState();
  const handlevideo = async (row) => {
    // console.log("row", row);
    let extraIndex = 0;
    for (let i = 0; i < score1.length; i++) {
      if (
        score1[i].OverNum === row.OverNum &&
        score1[i].BallNum == row.BallNum
      ) {
        if (score1[i].id === row.id) {
          break;
        } else {
          extraIndex++;
        }
      }
    }
    let resp = await get_video_urls(
      row.MatchId,
      row.InnNum,
      row.OverNum,
      row.BallNum,
      extraIndex
    );
    ballurls = resp.data;
    // console.log("ballurls", ballurls);
    setBallUrls(ballurls);
    cameraview = ballurls?.c1;
    setCameraView(cameraview);
    // console.log("extraIndex", extraIndex);
    // console.log("ballurls", ballurls);
    handleVisibility();
  };
  const Overcell = ({ row }) => {
    return <div className="cell">{row.OverNum + "." + row.BallNum}</div>;
  };
  const columns = [
    {
      name: "Overs",
      sortable: true,
      selector: (row) => <Overcell row={row} />,
      width: "100px",
      center: true,
    },
    {
      sortable: true,
      selector: (row) => row.Description,
      width: "350px",
    },
    {
      selector: (row) => (
        <img
          onClick={() => handlevideo(row)}
          style={{ height: "30px" }}
          src={VideoIcon}
        />
      ),
      width: "80px",
      center: true,
    },
  ];

  const validationpopup = () => {
    return (
      <div>
        <ValidationModal data={message} onClose={validationpopupvisible} />
      </div>
    );
  };
  const popupVideo = () => {
    return (
      <div className="overlay">
        <div className="popup_scoretable">
          {singlecam && (
            <ReactPlayer
              controls={true}
              url={cameraview}
              height="95%"
              width="100%"
              playing={true}
            />
          )}
          {fourcam && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c1}
                  height="40%"
                  width="40%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c2}
                  height="40%"
                  width="40%"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c3}
                  height="40%"
                  width="40%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c4}
                  height="40%"
                  width="40%"
                />
              </div>
            </div>
          )}
          {sixcam && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c1}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <ReactPlayer
                    controls={true}
                    playing={true}
                    url={ballurls?.c2}
                    height="50%"
                    width="45%"
                  />
                  <ReactPlayer
                    controls={true}
                    playing={true}
                    url={ballurls?.c3}
                    height="50%"
                    width="45%"
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c4}
                  height="100%"
                  width="30%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c5}
                  height="100%"
                  width="30%"
                />
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={ballurls?.c6}
                  height="100%"
                  width="30%"
                />
              </div>
            </div>
          )}
          <div className="cam_operatior_container">
            <div className="d-flex  justify-content-center ">
              <button
                className={
                  activeCamTab === "cam1" ? "active-front" : "front-btn"
                }
                onClick={() => handleCamTab("cam1", ballurls?.c1)}
              >
                {" "}
                Cam1
              </button>
              <button
                className={
                  activeCamTab === "cam2" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam2", ballurls?.c2)}
              >
                {" "}
                Cam2
              </button>
              <button
                className={
                  activeCamTab === "cam3" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam3", ballurls?.c3)}
              >
                {" "}
                Cam3
              </button>
              <button
                className={
                  activeCamTab === "cam4" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam4", ballurls?.c4)}
              >
                {" "}
                Cam4
              </button>
              <button
                className={
                  activeCamTab === "cam5" ? "active-back-cam" : "back-btn-cam"
                }
                onClick={() => handleCamTab("cam5", ballurls?.c5)}
              >
                {" "}
                Cam5
              </button>
              <button
                className={activeCamTab === "cam6" ? "active-back" : "back-btn"}
                onClick={() => handleCamTab("cam6", ballurls?.c6)}
              >
                {" "}
                Cam6
              </button>
            </div>
          </div>
          {/* <img
            className="cam_frames4"
            src={require("../assets/images/four_icon.png")}
            onClick={() => handleFourCamChange()}
          /> */}
          <div
            className="cam_frames4"
            style={{ boxShadow: isfourcamactive ? "0 0 16px 0 #2a8853" : "" }}
            onClick={() => handleFourCamChange()}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
          </div>
          <div
            className="cam_frames6"
            style={{ boxShadow: issixcamactive ? "0 0 16px 0 #2a8853" : "" }}
            onClick={() => handleSixCamChange()}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
              <div className="smallBoxStyle"></div>
            </div>
          </div>
          {/* <img
            className="cam_frames6"
            src={require("../assets/images/six_icon.png")}
            onClick={() => handleSixCamChange()}
          />{" "} */}
          <button className="close-button" onClick={() => handleVisibility()}>
            X
          </button>
        </div>
      </div>
    );
  };
  const [score, setScore] = useState(null);
  let [offset, setOffset] = useState(0);
  let [overNum, setOverNum] = useState(null);
  const [playerData, setPlayerData] = useState([]);
  const fetchScore = async () => {
    if (matchid && inningid) {
      setScore(null);
      setScore1(null);
      offset = 0;
      setOffset(offset);
      try {
        let score = await get_score(
          matchid,
          inningid,
          overNum,
          batsmanid,
          bowlerid,
          appealid,
          umpireid,
          offset
        );
        score1 = score.data.scores;
        setScore(score1);
        setScore1(score1);
        const oversgroups = groupByOver(score1);
        oversgroups.sort((a, b) => b.OverNum - a.OverNum);
        setCommnetryData(oversgroups);
        let matchinfo = await get_match_info(score.data.scores[0].MatchId);
        // console.log(matchinfo.data.TeamAPlayerList);
        setPlayerData(matchinfo.data.TeamAPlayerList);
        // console.log("score1", score1);
      } catch (error) {
        console.error("Error fetching score:", error);
      } finally {
      }
    } else {
      setMeassage("Please select Match and Inning");
    }
  };
  const groupByOver = (deliveries) => {
    const grouped = {};
    deliveries.forEach((delivery) => {
      const overNum = delivery.OverNum;
      if (!grouped[overNum]) {
        grouped[overNum] = {
          OverNum: overNum,
          deliveries: [],
        };
      }
      grouped[overNum].deliveries.push(delivery);
    });
    return Object.values(grouped);
  };
  const playerNameMap = playerData.reduce((map, playerObj) => {
    const id = playerObj.Player.PlayerId;
    const name = `${playerObj.Player.User.FirstName} ${playerObj.Player.User.LastName}`;
    map[id] = name;
    return map;
  }, {});
  const playerName = (id) => {
    console.log(id);
    const name = playerNameMap[id];
    if (name) {
      return name;
    } else {
      console.log("Player not found");
      return null;
    }
  };

  const fetchMoreData = async () => {
    try {
      const score = await get_score(
        matchid,
        inningid,
        overNum,
        batsmanid,
        bowlerid,
        appealid,
        umpireid,
        offset
      );
      scoreAll = score.data.scores;
      const oversgroups = groupByOver(scoreAll);
      oversgroups.sort((a, b) => b.OverNum - a.OverNum);

      setScore1((prevScoreAll) => [...prevScoreAll, ...scoreAll]);
      setCommnetryData((prevData) => [...prevData, ...oversgroups]);
      setOffset((prevOffset) => prevOffset + 80);
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  const fetchscorecard = async () => {
    if (matchid && inningid) {
      isLoading = true;
      setIsLoading(isLoading);
      setBattingPlayers([]);
      setBowlingPlayers([]);
      setWicketsIds([]);
      setNotBattedPlayers([]);
      let fallwickets = await get_match_inn_partnership(matchid, inningid);
      fallofwickets = fallwickets.data;
      setFallofWickets(fallofwickets);
      let resp = await get_match_playersdata(matchid, inningid);
      matchplayersdata = resp.data;
      setMatchPlayerData(matchplayersdata);
      // console.log("matchplayersdata", matchplayersdata);
      await seperateTeams();
      let wicket = await get_balls_details_for_ids({
        ballIds: wicketsids,
      });
      // console.log(wicket.data);
      setOutPlayers(wicket.data);
      isLoading = false;
      setIsLoading(isLoading);
    } else {
      setMeassage("Please select Match and Inning");
      validationpopupvisible();
    }
  };

  const seperateTeams = async () => {
    if (matchplayersdata) {
      for (let i = 0; i < matchplayersdata.length; i++) {
        if (matchplayersdata[i].TeamId === inningInfo.BattingTeamId) {
          if (matchplayersdata[i].IsOut) {
            wicketsids.push(matchplayersdata[i].OutBallId);
            setWicketsIds(wicketsids);
          }
          if (matchplayersdata[i].IsBatted) {
            setBattingPlayers((prevBattingPlayers) => [
              ...prevBattingPlayers,
              matchplayersdata[i],
            ]);

            console.log("battingplayers", battingplayers);
          } else {
            setNotBattedPlayers((prevNotBattedPlayers) => [
              ...prevNotBattedPlayers,
              matchplayersdata[i],
            ]);
          }
        }
        if (
          matchplayersdata[i].TeamId === inningInfo.BowlingTeamId &&
          (matchplayersdata[i].BallsBowled !== 0 ||
            matchplayersdata[i].IsDidBowling)
        ) {
          setBowlingPlayers((prevBowlingPlayers) => [
            ...prevBowlingPlayers,
            matchplayersdata[i],
          ]);
        }
      }
    }
  };

  function getPlayerName(list, id) {
    const p = list.find((item) => item.PlayerId === id);
    if (p) return p.Player.User.FirstName;
    else return "";
  }

  // function getWicketkeeperName(list) {
  //   const p = list.find(
  //     (item) =>
  //       item.PlayerId === matchInfo.TeamAWicketKeeperId ||
  //       item.PlayerId === matchInfo.TeamBWicketKeeperId
  //   );
  //   if (p) return p.Player.User.FirstName + "  " + p.Player.User.LastName;
  //   else return "";
  // }

  function getBatsmanName(list, id) {
    const p = list.find((item) => item.PlayerId === id);
    const firstNameLetter = p?.Player.User.FirstName
      ? p?.Player.User.FirstName.charAt(0)
      : "";
    const lastNameLetter = p?.Player.User.LastName
      ? p?.Player.User.LastName.charAt(0)
      : "";
    if (p) {
      if (
        (p.PlayerId === matchInfo.TeamACaptainId ||
          p.PlayerId === matchInfo.TeamBCaptainId) &&
        (p.PlayerId === matchInfo.TeamAWicketKeeperId ||
          p.PlayerId === matchInfo.TeamBWicketKeeperId)
      ) {
        return (
          <div className="batsman_bowler_container">
            {" "}
            {p.Player.User.ProfilePhotoUrl ? (
              <img
                className="batsman_bowler_img"
                src={p.Player.User.ProfilePhotoUrl}
                alt=""
              />
            ) : (
              <div className="name_cell">
                {firstNameLetter}
                {lastNameLetter}
              </div>
            )}
            <span> {p.Player.User.FirstName + " (C) " + " (WK) "}</span>{" "}
          </div>
        );
      }
      if (
        p.PlayerId === matchInfo.TeamACaptainId ||
        p.PlayerId === matchInfo.TeamBCaptainId
      ) {
        return (
          <div className="batsman_bowler_container">
            {" "}
            {p.Player.User.ProfilePhotoUrl ? (
              <img
                className="batsman_bowler_img"
                src={p.Player.User.ProfilePhotoUrl}
                alt=""
              />
            ) : (
              <div className="name_cell">
                {firstNameLetter}
                {lastNameLetter}
              </div>
            )}
            <span> {p.Player.User.FirstName + " (C)"}</span>{" "}
          </div>
        );
      }
      if (
        p.PlayerId === matchInfo.TeamAWicketKeeperId ||
        p.PlayerId === matchInfo.TeamBWicketKeeperId
      ) {
        return (
          <div className="batsman_bowler_container">
            {" "}
            {p.Player.User.ProfilePhotoUrl ? (
              <img
                className="batsman_bowler_img"
                src={p.Player.User.ProfilePhotoUrl}
                alt=""
              />
            ) : (
              <div className="name_cell">
                {firstNameLetter}
                {lastNameLetter}
              </div>
            )}
            <span> {p.Player.User.FirstName + " (WK)"}</span>{" "}
          </div>
        );
      }
      return (
        <div className="batsman_bowler_container ">
          {" "}
          {p.Player.User.ProfilePhotoUrl ? (
            <img
              className="batsman_bowler_img"
              src={p.Player.User.ProfilePhotoUrl}
              alt=""
            />
          ) : (
            <div className="name_cell">
              {firstNameLetter}
              {lastNameLetter}
            </div>
          )}
          <span>{p.Player.User.FirstName}</span>
        </div>
      );
    } else {
      return "";
    }
  }

  function getBowlerName(list, id) {
    const p = list.find(
      (item) => item.PlayerId === id && item.BallsBowled !== 0
    );
    const firstNameLetter = p?.Player.User.FirstName
      ? p?.Player.User.FirstName.charAt(0)
      : "";
    const lastNameLetter = p?.Player.User.LastName
      ? p?.Player.User.LastName.charAt(0)
      : "";
    if (p) {
      if (
        p.PlayerId === matchInfo.TeamACaptainId ||
        p.PlayerId === matchInfo.TeamBCaptainId
      ) {
        return (
          <div className="batsman_bowler_container ">
            {" "}
            {p.Player.User.ProfilePhotoUrl ? (
              <img
                className="batsman_bowler_img"
                src={p.Player.User.ProfilePhotoUrl}
                alt=""
              />
            ) : (
              <div className="name_cell">
                {firstNameLetter}
                {lastNameLetter}
              </div>
            )}
            <span>{p.Player.User.FirstName + " (C)"}</span>
          </div>
        );
      }
      return (
        <div className="batsman_bowler_container ">
          {" "}
          {p.Player.User.ProfilePhotoUrl ? (
            <img
              className="batsman_bowler_img"
              src={p.Player.User.ProfilePhotoUrl}
              alt=""
            />
          ) : (
            <div className="name_cell">
              {firstNameLetter}
              {lastNameLetter}
            </div>
          )}
          <span>{p.Player.User.FirstName}</span>
        </div>
      );
    } else {
      return "";
    }
  }

  const Batsman = ({ row }) => {
    return getBatsmanName(battingPlayerList, row.PlayerId);
  };

  const Bowler = ({ row }) => {
    return getBowlerName(bowingPlayerList, row.PlayerId);
  };

  const BowlerEconomy = ({ row }) => {
    let ballCount = row.BallsBowled % 6;
    let overs = Math.floor(row.BallsBowled / 6);
    let totalOvers = ballCount > 0 ? overs + ballCount / 10 : overs;
    console.log("totalOvers", totalOvers);
    let e = totalOvers > 0 ? (row.RunsGiven / totalOvers).toFixed(2) : 0;
    console.log("ecomony", e);
    return e;
  };

  const WicketBy = ({ row }) => {
    if (outPlayers) {
      for (let i = 0; i < outPlayers.length; i++) {
        if (outPlayers[i].OutPlayerId === row.PlayerId) {
          for (let j = 0; j < wickettypes.length; j++) {
            if (wickettypes[j].id === outPlayers[i].WicketType) {
              if (wickettypes[j].id === DismissalTypes.CAUGHT.id) {
                return (
                  "c    " +
                  getPlayerName(bowingPlayerList, outPlayers[i].OutByPlayerId) +
                  "     b     " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (
                wickettypes[j].id === DismissalTypes.HITTING_TWICE.id
              ) {
                return (
                  // wickettypes[j].Type +
                  "  hit the ball twice  "
                  // getPlayerName(bowingPlayerList, outPlayers[i].OutByPlayerId) +
                  // "     b     " +
                  // getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.BOWLED.id) {
                return (
                  "  b        " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.STUMPED.id) {
                return (
                  // wickettypes[j].Type +
                  "   ST ✝   " +
                  getWicketkeeperName(matchInfo, bowingPlayerList) +
                  "     b     " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.RUN_OUT.id) {
                return (
                  // wickettypes[j].Type +
                  "   run out    " +
                  getPlayerName(bowingPlayerList, outPlayers[i].OutByPlayerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.RETIRED_OUT.id) {
                return (
                  // wickettypes[j].Type +
                  "  retired out   "
                  // getPlayerName(bowingPlayerList, outPlayers[i].OutByPlayerId)
                );
              } else if (
                wickettypes[j].id === DismissalTypes.CAUGHT_BEHIND.id
              ) {
                return (
                  // wickettypes[j].Type +
                  "  C ✝  " +
                  getWicketkeeperName(matchInfo, bowingPlayerList) +
                  "  b   " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (
                wickettypes[j].id === DismissalTypes.CAUGHT_AND_BOWLED.id
              ) {
                return (
                  // wickettypes[j].Type +
                  "  c & b   " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.LBW.id) {
                return (
                  // wickettypes[j].Type +
                  "  lbw   " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (
                wickettypes[j].id === DismissalTypes.RETIRED_ABSENT.id
              ) {
                return (
                  // wickettypes[j].Type +
                  "  Absent hurt   "
                  // getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (
                wickettypes[j].id === DismissalTypes.OBSTRUCTING_FIELD.id
              ) {
                return (
                  // wickettypes[j].Type +
                  "  obstructing field   "
                  // getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.TIMED_OUT.id) {
                return (
                  // wickettypes[j].Type +
                  "  timed out   "
                  // getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.OTHER.id) {
                return (
                  // wickettypes[j].Type +
                  " other   "
                  // getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else if (wickettypes[j].id === DismissalTypes.HIT_WICKET.id) {
                return (
                  // wickettypes[j].Type +
                  " hit wicket   " +
                  "b  " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              } else {
                return (
                  wickettypes[j].Type +
                  "    " +
                  getPlayerName(bowingPlayerList, outPlayers[i].OutByPlayerId) +
                  "     b     " +
                  getPlayerName(bowingPlayerList, outPlayers[i].BowlerId)
                );
              }
            }
          }
        }
      }
    }
  };

  const handlevideopopup = async (row) => {
    // console.log("row", row);
    let resp;
    for (let i = 0; i < outPlayers.length; i++) {
      if (row.OutBallId === outPlayers[i].id) {
        // console.log(
        //   "outball",
        //   row.OutBallId,
        //   outPlayers[i].MatchId,
        //   outPlayers[i].InnNum,
        //   outPlayers[i].OverNum,
        //   outPlayers[i].BallNum
        // );
        resp = await get_video_urls(
          outPlayers[i].MatchId,
          outPlayers[i].InnNum,
          outPlayers[i].OverNum,
          outPlayers[i].BallNum,
          0
        );
      }
    }
    // console.log("resp", resp);
    ballurls = resp?.data;
    setBallUrls(ballurls);
    cameraview = ballurls?.c1;
    setCameraView(cameraview);
    handleVisibility();
  };

  const handleCamTab = (tab, activecam) => {
    cameraview = activecam;
    setCameraView(cameraview);
    setActiveCamTab(tab);
    handleSingleCamChange();
  };

  const handleVisibility = () => {
    setPopup(!isVisible);
    setActiveCamTab("cam1");
    setFourCam(false);
    setSixCam(false);
    setSingleCam(true);
  };

  const handleSingleCamChange = () => {
    setSingleCam(true);
    setFourCam(false);
    setSixCam(false);
    setIsSixCamActive(false);
    setIsFourCamActive(false);
  };

  const handleFourCamChange = () => {
    setSingleCam(false);
    setFourCam(true);
    setSixCam(false);
    setIsSixCamActive(false);
    setIsFourCamActive(true);
  };

  const handleSixCamChange = () => {
    setSingleCam(false);
    setFourCam(false);
    setSixCam(true);
    setIsSixCamActive(true);
    setIsFourCamActive(false);
  };

  let [filterPlayerData, setFilterPlayerData] = useState(
    receivedData ? senddataToScoreTable : []
  );
  const handleDashboardNavigation = (row, type, isFour, isSix, isWicket,IsWideBall,isNoBall) => {
    setFilterPlayerData([
      {
        tournamentid: tournamentid,
        data: row,
        type: type,
        isFour: isFour,
        isSix: isSix,
        isWicket: isWicket,
        IsWideBall:IsWideBall,
        isNoBall :isNoBall,
      },
    ]);
    console.log(row);
    hanbleTabClick("scoretable");
  };

  const columnbatting = [
    {
      name: "Batting",
      selector: (row) => <Batsman row={row} />,
      width: "300px",
    },
    {
      selector: (row) =>
        row.IsOut === true ? (
          <div className="" onClick={() => handlevideopopup(row)}>
            <img style={{ height: "30px", marginRight: 10 }} src={VideoIcon} />
            <WicketBy row={row} />
          </div>
        ) : (
          <div className="" style={{ height: "30px", marginLeft: 40 }}>
            not out
          </div>
        ),
      width: "350px",
    },
    // {
    //   name: "Video",
    //   selector: (row) => (
    //     <img
    //       onClick={() => handlevideopopup(row)}
    //       style={{ height: "30px" }}
    //       src={VideoIcon}
    //     />
    //   ),
    //   width: "80px",
    //   center: true,
    // },
    {
      name: "R",
      selector: (row) => row.RunsScored,
    },
    {
      name: "B",
      selector: (row) => (
        <span onClick={() => handleDashboardNavigation(row, "batting")}>
          {row.BallsPlayed}
        </span>
      ),
    },
    {
      name: "4s",
      selector: (row) => (
        <span
          style={{
            cursor: row.Boundary4s ? "pointer" : "not-allowed",
          }}
          onClick={
            row.Boundary4s
              ? () =>
                  handleDashboardNavigation(row, "batting", true, false, false,false,false)
              : null
          }
        >
          {row.Boundary4s}
        </span>
      ),
    },
    {
      name: "6s",
      selector: (row) => (
        <span
          style={{
            cursor: row.Boundary6s ? "pointer" : "not-allowed",
          }}
          onClick={
            row.Boundary6s
              ? () =>
                  handleDashboardNavigation(row, "batting", false, true, false,false,false)
              : null
          }
        >
          {row.Boundary6s}
        </span>
      ),
    },
    {
      name: "SR",
      selector: (row) =>
        (row.RunsScored !== 0
          ? (row.RunsScored / row.BallsPlayed) * 100
          : 0.0
        ).toFixed(2),
    },
  ];

  const columnbowling = [
    {
      name: "Bowling",
      selector: (row) => <Bowler row={row} />,
      width: "300px",
    },
    {
      name: "Over",
      selector: (row) => (
        <span
          style={{
            cursor: getBowlerStats(row).v ? "pointer" : "not-allowed",
          }}
          onClick={
            getBowlerStats(row).v
              ? () =>
                  handleDashboardNavigation(row, "bowling", false, false, false,false,false)
              : null
          }
        >
          {getBowlerStats(row).v}
        </span>
      ),
      // row.OverAboveBallsBowled > 0 && row.OverAboveBallsBowled < 6
      //   ? row.OversBowled + "." + row.OverAboveBallsBowled
      //   : row.OversBowled,
    },
    {
      name: "M",
      selector: (row) => row.MaidenOvers,
    },
    {
      name: "R",
      selector: (row) => row.RunsGiven,
    },
    {
      name: "W",
      selector: (row) => (
        <span
          style={{
            cursor: row.WicketsTaken ? "pointer" : "not-allowed",
          }}
          onClick={
            row.WicketsTaken
              ? () =>
                  handleDashboardNavigation(row, "bowling", false, false, true,false,false)
              : null
          }
        >
          {row.WicketsTaken}{" "}
        </span>
      ),
    },
    {
      name: "ECON",
      selector: (row) => getBowlerStats(row).e,
    },
    {
      name: "WD",
      selector: (row) => (
        <span
          style={{
            cursor: row.WideBallBowled ? "pointer" : "not-allowed",
          }}
          onClick={
            row.WideBallBowled
              ? () =>
                  handleDashboardNavigation(row, "bowling", false, false, false,true,false)
              : null
          }
        >
          {row.WideBallBowled}{" "}
        </span>
      ),
    },
    {
      name: "NB",
      selector: (row) => (
        <span
          style={{
            cursor: row.NoBallsBowled ? "pointer" : "not-allowed",
          }}
          onClick={
            row.NoBallsBowled
              ? () =>
                  handleDashboardNavigation(row, "bowling", false, false, false,false,true)
              : null
          }
        >
           {row.NoBallsBowled}
        </span>
      ),
       
    },
  ];

  const handleTournament = (event) => {
    const selectedTournamentId = event.target.value;
    setTournamentId(selectedTournamentId);
    handletour(selectedTournamentId);
  };

  const handletour = async (id) => {
    let respmatches = await get_match_data(id, true);
    // const filteredMatches = matchList.filter(
    //   (item) => item.TournamentOrSeriesId === parseInt(id)
    // );
    matchList1 = respmatches.data.matches;

    setMatchList1(matchList1);
  };

  const renderInning = () => {
    return (matchInfo?.MatchType === 3 ? iningId2 : iningId1).map(
      (item, index) => <option value={item}>{item}</option>
    );
  };

  const handleMatch = async (event) => {
    matchid = event.target.value;
    handlematch(matchid);
  };

  const handlematch = async (id) => {
    setMatchId(id);
    await getMatchInfo(id);
    await fetchScore();
    await fetchTeamPlayers(matchInfo.TeamAId, matchInfo.TeamBId);
  };

  const handleInning = async (inn) => {
    // console.log("event", event.target.value);
    // inningid = event.target.value;
    inningid = inn;
    await handleinning(inningid);
  };

  const handleinning = async (id) => {
    setInningId(id);

    if (matchid) {
      await get_info();
      await fetchScore();
      await fetchTeamPlayers(matchInfo.TeamAId, matchInfo.TeamBId);
    } else {
      setMeassage("select  match");
      validationpopupvisible();
    }
  };

  const get_info = async () => {
    if (matchid && inningid) {
      playersData = getPlayerList(matchInfo);
      setPlayersData(playersData);
      // console.log("playersData", playersData);

      let resp = await get_match_inn_info(matchid, inningid);
      // console.log("data0", resp.data);
      inningInfo = resp.data;
      setInningInfo(inningInfo);

      let battingplayer = await battingPlayer();
      setBattingPlayerList(battingplayer);
      // console.log("battingplayer", battingplayer);
      let bowlingplayer = await bowlingPlayer();
      setBowlingPlayerList(bowlingplayer);
      // console.log("bowlingplayer", bowlingplayer);

      await fetchscorecard();
    } else {
      setMeassage("Please select Match and Inning");
      validationpopupvisible();
    }
  };

  const battingPlayer = () => {
    const players =
      inningInfo?.BattingTeamId === matchInfo.TeamBId
        ? matchInfo.TeamBPlayerList
        : matchInfo.TeamAPlayerList;
    let d = players.filter((item) => item.InnId === inningInfo.InningNumber);
    return d;
  };

  const bowlingPlayer = () => {
    const players =
      inningInfo?.BowlingTeamId === matchInfo.TeamAId
        ? matchInfo.TeamAPlayerList
        : matchInfo.TeamBPlayerList;
    let d = players.filter((item) => item.InnId === inningInfo.InningNumber);
    return d;
  };

  const handleAllAppealType = (event) => {};

  const getMatchInfo = async (matchId) => {
    let resp = await get_match_info(matchId);
    matchInfo = resp.data;
    setMatchInfo(matchInfo);
    // console.log("matchInfo", matchInfo);
    if (matchInfo) {
      if (inningid) {
        await get_info();
      }
    }
  };
  const renderBattingPlayer = () => {
    return battingPlayerList?.map((item, index) =>
      item.InnId === inningInfo.InningNumber ? (
        <option value={item}>{item.Player.User.FirstName}</option>
      ) : (
        ""
      )
    );
  };

  const renderBowlingPlayer = () => {
    return bowingPlayerList?.map((item, index) =>
      item.InnId === inningInfo.InningNumber ? (
        <option value={item}>{item.Player.User.FirstName}</option>
      ) : (
        ""
      )
    );
  };

  const handleSearch = (event) => {
    offset = 0;
    setOffset(offset);
    overNum = event.target.value;
    setOverNum(overNum);
    fetchScore();
    // const filteredData = score
    //   .filter((item) =>
    //     (item.OverNum + "." + item.BallNum)
    //       .toLowerCase()
    //       .includes(event.target.value.toLowerCase())
    //   )
    //   .sort((a, b) =>
    //     (a.OverNum + "." + a.BallNum).localeCompare(b.OverNum + "." + b.BallNum)
    //   );
    // setScore1(filteredData);
  };

  const fallofWickets = () => {
    let wicketElements = "";

    for (let i = 0; i < fallofwickets.length; i++) {
      wicketElements =
        wicketElements +
        fallofwickets[i].runsWithExtra +
        "-" +
        fallofwickets[i].wickNumber +
        "(" +
        getPlayerName(battingPlayerList, fallofwickets[i].outPlayerId) +
        ", " +
        fallofwickets[i].overNum +
        "." +
        fallofwickets[i].ballNum +
        "),   ";
    }

    return wicketElements;
  };

  const extraRuns = () => {
    return (
      <p>
        {inningInfo.InngExtraRuns}
        {"   "}({"wd  "}
        {inningInfo.WidesCount}
        {", b  "}
        {inningInfo.ByesCount}
        {", lb  "}
        {inningInfo.LegByesCount}
        {", nb  "}
        {inningInfo.NoBallCount}
        {",p  "}
        {inningInfo.FieldingTeamPenaltyRuns})
      </p>
    );
  };

  const notBattedPlayers = () => {
    const notPlayed = [];

    for (let i = 0; i < notbattedplayers.length; i++) {
      notPlayed.push(
        <p>{getPlayerName(battingPlayerList, notbattedplayers[i].PlayerId)},</p>
      );
    }
    return notPlayed;
  };

  const inningFilters = () => {
    return (
      <div className="inn_filter_container ">
        {matchInfo?.MatchType === 3 ? (
          <div className="test_inning_container">
            {iningId2.map((item, index) => (
              <div
                className={item === inningid ? "test_inn_active" : "test_inn"}
                onClick={() => handleInning(item)}
                key={item}
                value={item}
              >
                {item} inns
              </div>
            ))}
          </div>
        ) : (
          <div className="oneday_t20_container">
            {iningId1.map((item, index) => (
              <div
                className={
                  item === inningid ? "oneday_t20_inn_active" : "oneday_t20_inn"
                }
                onClick={() => handleInning(item)}
                key={item}
                value={item}
              >
                {item} inns
              </div>
            ))}
          </div>
        )}{" "}
      </div>
    );
  };

  const filters = () => {
    return (
      <div className="filter_container ">
        <div className="d-flex">
          <span className="text">Filters</span>
          <button className="btn_ok" onClick={fetchscorecard}>
            OK
          </button>
        </div>
        <h1> Tournament </h1>

        <select
          className="select-box "
          name=""
          value={tournamentid}
          onChange={handleTournament}
        >
          <option value="">Select Tournament</option>
          {renderTournamentTypeOptions()}
        </select>

        <h1 className="mt-2">Select Match</h1>
        <select
          className="select-box mt-1"
          name=""
          id=""
          value={matchid}
          onChange={handleMatch}
        >
          <option value=""> Select Match</option>
          {renderMatchListOptions()}
        </select>
        {/* <h1 className="mt-2"> Inning</h1>
        <select
          className="select-box mt-1"
          name=""
          id=""
          value={inningid}
          onChange={handleInning}
        >
          <option value="">Select Inning</option>
          {renderInning()}
        </select> */}
      </div>
    );
  };
  return (
    <section className="match-details">
      <div className="d-flex ">
        <button
          className={activeTab === "commentry" ? "active-front" : "front-btn"}
          onClick={() => hanbleTabClick("commentry")}
        >
          {" "}
          Commentry
        </button>
        <button
          className={
            activeTab === "scorecard" ? "active-back-stats" : "back-btn-stats"
          }
          onClick={() => hanbleTabClick("scorecard")}
        >
          {" "}
          ScoreCard
        </button>
        <button
          className={
            activeTab === "squad" ? "active-back-stats" : "back-btn-stats"
          }
          onClick={() => hanbleTabClick("squad")}
        >
          {" "}
          Squad{" "}
        </button>
        <button
          className={
            activeTab === "stats" ? "active-back-stats" : "back-btn-stats"
          }
          onClick={() => hanbleTabClick("stats")}
        >
          {" "}
          Stats{" "}
        </button>
        <button
          className={activeTab === "scoretable" ? "active-back" : "back-btn"}
          onClick={() => hanbleTabClick("scoretable")}
        >
          {" "}
          Score Table
        </button>
      </div>
      {isLoading === false &&
        activeTab !== "stats" &&
        activeTab !== "scoretable" &&
        activeTab !== "squad" && <div>{inningFilters()}</div>}

      {activeTab === "scorecard" && (
        <div className="">
          {isLoading === false ? (
            <div className="scorecard-container ">
              <div className="score-table">
                {matchplayersdata && inningInfo ? (
                  <div>
                    <div className="team_name_bat_bowl d-flex align-items-center">
                      <h1> Batting Team : </h1>
                      <p> {inningInfo.BattingTeam.TeamName}</p>
                    </div>{" "}
                    <DataTable
                      columns={columnbatting}
                      data={battingplayers?.sort(
                        (a, b) => a.BattingOrder - b.BattingOrder
                      )}
                      // data={battingplayers}
                      customStyles={customtableStyles}
                      theme="solarized"
                    ></DataTable>
                    <div className="d-flex align-items-center justify-content-between total-runs">
                      <div className="d-flex ">
                        <span className="scoredetails">Total</span>
                        <span className="total-over scoredetails">
                          {inningInfo.CurrOverNum - 1}.
                          {inningInfo.CurrBallNum - 1} Overs (
                          {inningInfo.InnTotalRuns !== 0
                            ? (
                                inningInfo.InnTotalRuns /
                                (inningInfo.CurrOverNum -
                                  1 +
                                  (inningInfo.CurrBallNum - 1) / 10)
                              ).toFixed(2)
                            : 0.0}{" "}
                          Run Rate )
                        </span>
                      </div>
                      <span className="scoredetails">
                        {inningInfo.InnTotalRuns +
                          " / " +
                          inningInfo.TotalWickets}
                      </span>
                    </div>
                    <div className="pending-batsman d-flex align-items-center">
                      <h1>Extras : </h1>
                      {extraRuns()}
                    </div>
                    <div className="pending-batsman d-flex align-items-center">
                      <h1>Did not bat : </h1>
                      {notbattedplayers && notBattedPlayers()}
                    </div>
                    <div className="pending-batsman d-flex align-items-center">
                      <h1>fall of wickets: </h1>
                      {outPlayers && <p>{fallofWickets()}</p>}
                    </div>
                    <div className="pending-batsman d-flex align-items-center">
                      <h1>Match Result: </h1>
                      {matchInfo.MatchStatus ===
                        MatchStatus.MATCH_COMPLETED.id && (
                        <p>{matchInfo.MatchResultDescription}</p>
                      )}
                      {/* {outPlayers && <p>{fallofWickets()}</p>} */}
                    </div>
                    <div className=" mt-4">
                      <div className="team_name_bat_bowl d-flex align-items-center">
                        <h1> Bowling Team : </h1>
                        <p> {inningInfo.BowlingTeam.TeamName}</p>
                      </div>
                      {matchplayersdata && (
                        <DataTable
                          columns={columnbowling}
                          data={bowlingplayers?.sort(
                            (a, b) => a.BowlingOrder - b.BowlingOrder
                          )}
                          customStyles={customtableStyles}
                          theme="solarized"
                        ></DataTable>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="select_match_hint">
                    Select the Tournament and Match
                  </div>
                )}
              </div>

              {/* {matchInfo && inningInfo ? (
                <div className="card-container">
                  <div className="card_home">
                    <div className="card_header">
                      <span className="tournament_name">
                        {matchInfo.MatchNameOrTitle} -{" "}
                        {matchInfo.MatchTypeRef.type}
                      </span>
                      {matchInfo.MatchStatus ===
                        MatchStatus.MATCH_STARTED.id && (
                        <span className="live"> 🔴LIVE</span>
                      )}
                    </div>
                    <div className="card_vs mx-3">
                      <div className="team_home">
                        <img
                          className="team_img"
                          src={
                            matchInfo.TeamA.TeamLogoUrl
                              ? matchInfo.TeamA.TeamLogoUrl
                              : require("../assets/images/team_logo.png")
                          }
                        />
                        <span className="team_name">
                          {matchInfo.TeamA.TeamShortName}
                        </span>
                      </div>
                      <div className="team_home">
                        <img
                          src={
                            matchInfo.TeamA.TeamLogoUrl
                              ? matchInfo.TeamB.TeamLogoUrl
                              : require("../assets/images/team_logo.png")
                          }
                          alt=""
                          className="team_img"
                        />
                        <span className="team_name">
                          {matchInfo.TeamB.TeamShortName}
                        </span>
                      </div>
                    </div>
                    {matchInfo.MatchStatus ===
                      MatchStatus.MATCH_COMPLETED.id && (
                      <span
                        style={{
                          color: "#bbb",
                          fontSize: "14px",
                          paddingInline: "2%",
                        }}
                      >
                        {matchInfo.MatchResultDescription}
                      </span>
                    )}
                  </div>
                </div>
              ) : ( */}

              {filters()}
              {isValid && validationpopup()}
              {isVisible && popupVideo()}
            </div>
          ) : (
            <div className="loading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === "commentry" && (
        <div>
          {isLoading === false ? (
            <div>
              <div className="search_content">
                <div>
                  <span className="text">Score over</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <input
                    className="search_input"
                    type="text"
                    placeholder="Search by Overs"
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="d-flex">
                {commentryData ? (
                  <div className=" mt-4 commentry-card-conatiner">
                    {commentryData &&
                      commentryData.map((item, index) => (
                        <div key={index} className="commentry-card">
                          <div className="commentry-header">
                            <div className="d-flex gap-3 align-items-center">
                              <p>Over {item.OverNum + 1} </p>
                              {item.deliveries
                                .slice()
                                .reverse()
                                .map((delivery, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                    className={classNames("commentry-ball", {
                                      wicket: delivery.IsWicket,
                                    })}
                                  >
                                    {" "}
                                    {delivery.TotalRun}
                                    <div style={{ fontSize: 8 }}>
                                      {delivery.IsWideBall ? "WD" : ""}
                                      {delivery.IsNoBall ? "NB" : ""}
                                      {delivery.IsWicket ? "W" : ""}
                                      {delivery.IsBye ? "b" : ""}
                                      {delivery.IsLegbye ? "lb" : ""}
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div className=""></div>
                          </div>
                          <div className="commentry-desc">
                            {item.deliveries.map((delivery, index) => (
                              <div className="d-flex ms-3  my-2 each-ball">
                                <div className="over-num">
                                  {delivery.OverNum}.{delivery.BallNum}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                    className={classNames(
                                      "commentry-ball-show",
                                      {
                                        wicket: delivery.IsWicket,
                                      }
                                    )}
                                  >
                                    {delivery.TotalRun}
                                    <div style={{ fontSize: 8 }}>
                                      {delivery.IsWideBall ? "WD" : ""}
                                      {delivery.IsNoBall ? "NB" : ""}
                                      {delivery.IsWicket ? "W" : ""}
                                      {delivery.IsBye ? "b" : ""}
                                      {delivery.IsLegbye ? "lb" : ""}
                                    </div>
                                  </div>
                                </div>
                                <div className="ball-desc-">
                                  <div
                                    style={
                                      delivery.IsWicket
                                        ? { color: "red" }
                                        : null
                                    }
                                    className="ball-desc-txt"
                                  >
                                    {/* {generateCommentary(playerName(delivery.BattingStrikerId),1,1,delivery,getTypeById(delivery.WicketType))} */}
                                    {/* {delivery.Description} */}
                                    {generateCommentary(
                                      playersData,
                                      delivery,
                                      shottypes,
                                      fieldingtypes,
                                      getWicketkeeperName(
                                        matchInfo,
                                        bowingPlayerList
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="mt-4 commentry-card-conatiner select_match_hint">
                    Select the Tournament and Match
                  </div>
                )}
                {filters()}
                {isValid && validationpopup()}
              </div>
            </div>
          ) : (
            <div className="loading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === "stats" && (
        <ScoreStats filterPlayerData={filterPlayerData} />
      )}
      {activeTab === "scoretable" && (
        <div className="">
          <Dashboard filterPlayerData={filterPlayerData} />
        </div>
      )}
      {activeTab === "squad" && (
        <div className="">
          {isLoading === false ? (
            <div className="d-flex">
              {teamAplayers && teamBplayers && matchplayersdata ? (
                <div className="squad_container_container">
                  <div className="squad_container">
                    <div className="togglebtn">
                      <button
                        className={btnteamA ? "btnteamA" : "btnteamA1"}
                        onClick={() => toggleteamsquad("teamA", teamAplayers)}
                      >
                        {teamAplayers?.TeamName}{" "}
                      </button>
                      <button
                        className={btnteamB ? "btnteamB" : "btnteamB1"}
                        onClick={() => toggleteamsquad("teamB", teamBplayers)}
                      >
                        {teamBplayers?.TeamName}{" "}
                      </button>
                    </div>
                  </div>

                  <div>
                    <h4 className="heading_color ms-3">Playing 11</h4>
                    <div className="d-flex player-container">
                      {getPlaying11()}
                    </div>
                  </div>
                  <div>
                    <h4 className="heading_color ms-3">Rest of Squad</h4>
                    <div className="d-flex player-container">
                      {getRestSquad()}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="select_match_hint squad_container_container">
                  <span>Select the Tournament and Match</span>
                </div>
              )}
              {filters()}
              {isValid && validationpopup}
            </div>
          ) : (
            <div className="loading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ScoreCard;

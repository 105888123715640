import { customdocStyles, customtableStyles } from "../assets/tablestyle";
import DataTable, { createTheme } from "react-data-table-component";
import { activeColor, tablecreateTheme } from "../assets/globalcolors";
import DismissalTypes from "../componants/DismissalTypes";
import UserSystemRole from "../componants/UserSystemRole";
import { DeleteIcon, DownloadIcon } from "../assets/SvgIcons";
import "../styles/analyseaddplayer.css";

export function getBowlerStats(b) {
  let ballCount = b.BallsBowled % 6;
  let overs = Math.floor(b.BallsBowled / 6);

  let totalOvers = overs + ballCount / 6;

  let e = totalOvers > 0 ? (b.RunsGiven / totalOvers).toFixed(2) : 0;

  let v = overs + (ballCount > 0 ? "." + ballCount : "");

  return {
    e: e,
    v: v,
  };
}

export function getPlayerList(matchInfo) {
  const aMap = new Map(
    matchInfo.TeamAPlayerList.map((item) => [item.PlayerId, item])
  );
  const bMap = new Map(
    matchInfo.TeamBPlayerList.map((item) => [item.PlayerId, item])
  );
  const combinedMap = new Map([...aMap, ...bMap]);
  return combinedMap;
}

export function getWicketkeeperName(matchInfo, list) {
  const p = list.find(
    (item) =>
      item.PlayerId === matchInfo.TeamAWicketKeeperId ||
      item.PlayerId === matchInfo.TeamBWicketKeeperId
  );
  if (p) return p.Player.User.FirstName + "  " + p.Player.User.LastName;
  else return "";
}

export function generateCommentary(
  pdata,
  ball,
  shottypes,
  fieldingtypes,
  wicketkepper
) {
  // console.log("pdata", pdata);
  // console.log("shottypes", shottypes);
  // console.log("fieldingtypes", fieldingtypes);
  let o;
  let outplayer;
  let s;
  let batsmanName;
  if (ball.BattingStrikerId) {
    s = pdata.get(ball.BattingStrikerId);
    batsmanName = `${s?.Player.User.FirstName} ${
      s?.Player.User.LastName ? s?.Player.User.LastName : ""
    }`;
  }

  if (ball.OutPlayerId) {
    o = pdata.get(ball.OutPlayerId);
    outplayer = `${o?.Player.User.FirstName} ${o?.Player.User.LastName}`;
  }
  let b = pdata.get(ball.BowlerId);
  let bowlerName = `${b?.Player.User.FirstName} ${
    b?.Player.User.LastName ? b?.Player.User.LastName : ""
  }`;

  let fielderName = null;

  if (ball.FielderId) {
    let f = pdata.get(ball.FielderId);
    fielderName = `${f?.Player.User.FirstName} ${
      f?.Player.User.LastName ? f?.Player.User.LastName : ""
    }`;
  }

  let commentary = "";

  if (ball.IsWicket) {
    let dt = DismissalTypes.Types;
    if (dt[ball.WicketType - 1].id === DismissalTypes.CAUGHT.id) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", OUT Caught out" +
        (ball.BattingShotTypeId
          ? shottypes[ball.BattingShotTypeId - 1]?.ShotName + ","
          : "");

      if (fielderName) {
        commentary += " ,Caught by " + fielderName;
      }
    } else if (dt[ball.WicketType - 1].id === DismissalTypes.RUN_OUT.id) {
      commentary += bowlerName + " to " + batsmanName + ",OUT Run out";
      if (fielderName) {
        commentary += ",Throw by " + fielderName;
      }
    } else if (dt[ball.WicketType - 1].id === DismissalTypes.BOWLED.id) {
      commentary += bowlerName + " to " + batsmanName + ", OUT Bowled ";
    } else if (dt[ball.WicketType - 1].id === DismissalTypes.LBW.id) {
      commentary += bowlerName + " to " + batsmanName + ", OUT LBW ";
    } else if (
      dt[ball.WicketType - 1].id === DismissalTypes.CAUGHT_AND_BOWLED.id
    ) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", OUT Caught and bowled " +
        (ball.BattingShotTypeId
          ? shottypes[ball.BattingShotTypeId - 1]?.ShotName + ","
          : "") +
        " Caught by " +
        bowlerName;
    } else if (
      dt[ball.WicketType - 1].id === DismissalTypes.RETIRED_ABSENT.id
    ) {
      commentary +=
        outplayer + "  ( " + dt[ball.WicketType - 1].status + " ), ";
    } else if (dt[ball.WicketType - 1].id === DismissalTypes.CAUGHT_BEHIND.id) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", OUT Caught behind, Caught behind by " +
        (wicketkepper ? wicketkepper : "");
    } else if (dt[ball.WicketType - 1].id === DismissalTypes.STUMPED.id) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", OUT Stumed, Stumped by " +
        (wicketkepper ? wicketkepper : "");
    } else {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", OUT " +
        dt[ball.WicketType - 1].status;
    }
  } else {
    if (ball.IsWideBall) {
      commentary += bowlerName + " to " + batsmanName + ", wide ";
    } else if (ball.IsNoBall) {
      commentary += bowlerName + " to " + batsmanName + ",(no ball) ";
    } else if (ball.IsBye) {
      commentary +=
        bowlerName + " to " + batsmanName + ", bye, " + ball.ExtraRuns + " run";
    } else if (ball.IsLegbye) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", leg bye, " +
        ball.ExtraRuns +
        " run";
    } else if (ball.IsFour) {
      commentary += commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", FOUR, " +
        (ball.BattingShotTypeId
          ? shottypes[ball.BattingShotTypeId - 1]?.ShotName
          : "");
    } else if (ball.IsSix) {
      commentary +=
        bowlerName +
        " to " +
        batsmanName +
        ", SIX, " +
        (ball.BattingShotTypeId
          ? shottypes[ball.BattingShotTypeId - 1]?.ShotName
          : "");
    } else {
      commentary +=
        bowlerName + " to " + batsmanName + ", " + ball.TotalRun + " run";
    }
  }

  return commentary;
}

export const showTeam = (
  teamData,
  closeTeamModel,
  teamStats,
  Players,
  handlePlayerInfo
) => {
  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });
  return (
    <div className="team-popup">
      <div className="team-info">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title1">Team Info</div>
          <div className="close d-flex " onClick={() => closeTeamModel()}>
            X
          </div>
        </div>
        <div className="d-flex mt-2">
          <div className="d-flex align-item-center w-75">
            {teamData.TeamLogoUrl ? (
              <img
                src={teamData.TeamLogoUrl}
                width={200}
                height={200}
                alt=""
                style={{ borderRadius: 30 }}
              />
            ) : (
              <img
                src={require("../assets/images/teamLogo.jpeg")}
                width={200}
                height={200}
                alt=""
                style={{ borderRadius: 20 }}
              />
            )}
            <h2 className="title_name "> {teamData.TeamName} </h2>
          </div>
          <div className="team-summmery-container">
            <div>
              <h1 className="team-summery">Team Summary</h1>
            </div>
            <div className="d-flex">
              <div>
                <div className="d-flex align-items-center">
                  <h3> Total Test Matches</h3>
                  <h5>:</h5>
                  <h4> {teamStats.testMatchCount} </h4>
                </div>
                <div className="d-flex align-items-center">
                  <h3> One-day Matches</h3>
                  <h5>:</h5>
                  <h4> {teamStats.oneDayMatchCount} </h4>
                </div>
                <div className="d-flex align-items-center">
                  <h3> Total T20 Matches</h3>
                  <h5>:</h5>
                  <h4> {teamStats.t20MatchCount} </h4>
                </div>
              </div>
              <div className="">
                <div className="d-flex align-items-center">
                  <h3>Test Match Own</h3>
                  <h5>:</h5>
                  <h4> {teamStats.testMatchOwn} </h4>
                </div>
                <div className="d-flex align-items-center">
                  <h3>One-day Match Own</h3>
                  <h5>:</h5>
                  <h4> {teamStats.oneDayMatchOwn} </h4>
                </div>
                <div className="d-flex align-items-center">
                  <h3>T20 Match Own</h3>
                  <h5>:</h5>
                  <h4> {teamStats.t20MatchOwn} </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="title1 mt-4">Players</div>
        <div className="d-flex player-container">
          {Players.map((item) => (
            <div className="player-card" onClick={() => handlePlayerInfo(item)}>
              {item.Player.User.ProfilePhotoUrl ? (
                <img
                  src={item.Player.User.ProfilePhotoUrl}
                  style={{ borderRadius: 20 }}
                  width={"100%"}
                  height={175}
                  alt=""
                />
              ) : (
                <img
                  src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                  style={{ borderRadius: 20 }}
                  width={"100%"}
                  height={175}
                  alt=""
                />
              )}

              <div className="player-name pt-2">
                <h4>
                  {item.Player.User.FirstName} {item.Player.User.LastName} (
                  {item.Player.PlayerId})
                </h4>
                <h5>{item.Player.PlayerType.PlayerType}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const playerInfo = (
  playerStats,
  closePlayerInfo,
  handleTournament,
  renderTournamentTypeOptions,
  btnbatting,
  togglebattingandbowling,
  btnbowling,
  isPlayerInfo,
  generatePDF,
  allData,
  matchStats,
  battingcolumn,
  bowlingcolumn,
  loading,
  handleplayerTabs,
  playerActiveTab, 
  userDoc,
  handlePlayerDoc,

) => {
  console.log("isPlayerInfo" ,isPlayerInfo);
  return (
    <div className="team-popup">
      <div className="team-info">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex"
            style={{ cursor: "pointer" }}
            onClick={() => closePlayerInfo()}
          >
            <div>
              {" "}
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path
                  fill="#2a8853"
                  d="M14.78 18.362c-.469.469-1.07.703-1.768.703-.698 0-1.299-.234-1.768-.703l-6.364-6.364c-.977-.977-.977-2.561 0-3.535l6.364-6.364c.977-.977 2.561-.977 3.535 0s.977 2.558 0 3.535L9.707 12l5.062 5.062c.977.977.977 2.561 0 3.535z"
                />
              </svg>{" "}
            </div>
            <div className="title1">Player Info</div>
          </div>
          <div className="close d-flex " onClick={() => closePlayerInfo()}>
            X
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-end me-4">
          <select
            className="combobox_board  w-25 me-4"
            name=""
            id=""
            onChange={handleTournament}
            defaultValue="all"
          >
            <option value="all">All Tounamnet</option>
            {renderTournamentTypeOptions()}
          </select>
          <button
            className={btnbatting ? "btnbatting" : "btnbatting1"}
            onClick={togglebattingandbowling}
          >
            Batting
          </button>
          <button
            className={btnbowling ? "btnbowling" : "btnbowling1"}
            onClick={togglebattingandbowling}
          >
            Bowling
          </button>
        </div>
        <div className="d-flex mt-4 ">
          <div className="d-flex align-item-center w-75">
            <div>
              {isPlayerInfo.Player.User.ProfilePhotoUrl ? (
                <img
                  src={isPlayerInfo.Player.User.ProfilePhotoUrl}
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              ) : (
                <img
                  src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              )}
            </div>

            <div className="">
              <h2 className="title_name_player ">
                {isPlayerInfo.Player.User.FirstName}{" "}
                {isPlayerInfo.Player.User.LastName}{" "}
              </h2>
              <p className="title_name_player_role">
                {" "}
                {isPlayerInfo.Player.PlayerType.PlayerType}
              </p>

              <p className="title_name_player_role">
                {" "}
                Batting Style : {allData ? allData.BattingHand.hand : ""}
              </p>
              <p className="title_name_player_role">
                {" "}
                Bowling Style : {allData ? allData.BowlingHand.hand : ""}
              </p>
            </div>
          </div>
          <div className="team-summmery-container">
            <div style={{ backgroundColor: "#101713" }}>
              <h1 className="team-summery">Player Summary</h1>
            </div>
            {btnbatting && (
              <div className="d-flex gap-3">
                <div>
                  <div className="d-flex align-items-center">
                    <h3> Total Matchs Played</h3>
                    <h5>:</h5>
                    <h4> {playerStats.matchCout} </h4>
                  </div>
                  <div className="d-flex  align-items-center">
                    <h3> No of 100s </h3>
                    <h5>:</h5>
                    <h4> {playerStats.hundreds} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3> No of 50s </h3>
                    <h5>:</h5>
                    <h4> {playerStats.fifties} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Avg runs </h3>
                    <h5>:</h5>
                    <h4>{playerStats.avgRuns} </h4>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex align-items-center">
                    <h3>Total Runs</h3>
                    <h5>:</h5>
                    <h4>{playerStats.totalRuns} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>No of 6s</h3>
                    <h5>:</h5>
                    <h4>{playerStats.sixes} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>No of 4s</h3>
                    <h5>:</h5>
                    <h4>{playerStats.fours} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Strike rate</h3>
                    <h5>:</h5>
                    <h4>{playerStats.sr} </h4>
                  </div>
                </div>
              </div>
            )}
            {btnbowling && (
              <div className="d-flex">
                <div>
                  <div className="d-flex align-items-center">
                    <h3> Total Matchs Played </h3>
                    <h5>:</h5>
                    <h4> {playerStats.matchCout} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3> Bowling Economy </h3>
                    <h5>:</h5>
                    <h4> {playerStats.bowlingEconomy} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Wickets Taken </h3>
                    <h5>:</h5>
                    <h4> {playerStats.wicketsTaken} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Overs Bowled </h3>
                    <h5>:</h5>
                    <h4>{playerStats.oversBowled} </h4>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex align-items-center">
                    <h3>Total Wickets </h3>
                    <h5>:</h5>
                    <h4>{playerStats.wicketsTaken} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Total Maiden </h3>
                    <h5>:</h5>
                    <h4>{playerStats.maidens} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Wicket5Fors </h3>
                    <h5>:</h5>
                    <h4>{playerStats.wicket5Fors} </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3>Total RunsGiven </h3>
                    <h5>:</h5>
                    <h4>{playerStats.runsGiven} </h4>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex ">
          <button
            className={
              playerActiveTab === "playerinfo"
                ? "active-front-board"
                : "front-btn-board"
            }
            onClick={() => handleplayerTabs("playerinfo")}
          >
            {" "}
            Player Info
          </button>
          <button
            className={
              playerActiveTab === "Player Stats"
                ? "active-back-point"
                : "back-btn-point"
            }
            onClick={() => handleplayerTabs("Player Stats")}
          >
            {" "}
            Player Stats
          </button>
          <button
            className={
              playerActiveTab === "Player Docs"
                ? "active-back-board"
                : "back-btn-board"
            }
            onClick={() => handleplayerTabs("Player Docs")}
          >
            {" "}
            Player Docs
          </button>
        </div>

        {matchStats.length > 0 && playerActiveTab === "Player Stats" ? (
          <div className="mt-4">
            {btnbatting && (
              <DataTable
                data={matchStats}
                columns={battingcolumn}
                customStyles={customtableStyles}
                theme="solarized"
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
            )}
            {btnbowling && (
              <DataTable
                data={matchStats}
                columns={bowlingcolumn}
                customStyles={customtableStyles}
                theme="solarized"
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
            )}
          </div>
        ) : (
          <div
          style={{ color: "var(--text-color)" }}
          className="d-flex justify-content-center mt-4 "
        >
          {playerActiveTab === "Player Stats" && (
            <div className="">No Data please Select Tournament</div>
          )}
        </div>
        )}
        {loading && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              color: "#fff",
              marginTop: 100,
            }}
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {playerActiveTab === "playerinfo" && (
          <div className="">
            <div className="row">
              <div className="col-md-4 d-flex ms-2 align-items-center">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Name
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player.User.FirstName} {isPlayerInfo.Player.User.LastName}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Father Name
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player.User.FatherName}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Date of Birth
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player?.User.DOB}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Email
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player?.User.EMail}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Gender
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  {" "}
                  : Male
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Phone number
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player?.User.PhoneNumber}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Approved
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player.User.IsApproved ? "Done" : "Not Done"}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                <div style={{ color: "var(--title-color)", width: 120 }}>
                  Aadhar Kyc
                </div>
                <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player.User.IsAadahrKycDone ? "Done" : "Not Done"}
                </span>
              </div>
              <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Address 1
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                    : {isPlayerInfo.Player.User?.Address1?.AddressDetails ?  isPlayerInfo.User?.Address1?.AddressDetails  :""}
                  </span>
                </div>
                <div className="col-md-4 d-flex ms-2 align-items-center  mt-2">
                  <div style={{ color: "var(--title-color)", width: 120 }}>
                    Address 2
                  </div>
                  <span style={{ fontSize: 14, color: "var(--text-color)" }}>
                  : {isPlayerInfo.Player.User?.Address2?.AddressDetails ?  isPlayerInfo.User?.Address2?.AddressDetails  :""}
                  </span>
                </div>
            </div>
          </div>
        )}

{playerActiveTab === "Player Docs" && (
            <div className="">
              <div
                style={{ width: 200 }}
                className="update-btn  "
                onClick={() => handlePlayerDoc()}
              >
                {" "}
                Upload Documnets
              </div>
              <div className="user-doc-table mt-4">
                {userDoc.map((item, index) => (
                  <div className="user-doc-con" key={index}>
                    <div className="d-flex  align-items-center">
                      <h1 style={{ fontSize: 14 }} className="title">
                        {" "}
                        File Name :{" "}
                      </h1>
                      <h1
                        style={{ fontSize: 14, color: "var(--text-color)" }}
                        className="title ms-1"
                      >
                        {item.fileName}
                      </h1>
                    </div>
                    <div className="d-flex align-items-center mt-2 gap-3">
                      <iframe
                        className="iframe-content"
                        src={item.fileUrl}
                      ></iframe>
                      <div className="d-flex flex-column  gap-2 ">
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          src={DownloadIcon}
                          alt="Download"
                          // onClick={() =>
                          //   handleDownload(item.fileUrl, item.fileName)
                          // }
                        />
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          src={DeleteIcon}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {userDoc.length === 0 && (
                  <h5
                    className="d-flex justify-content-end"
                    style={{ color: "var(--text-color)" }}
                  >
                    No Documents
                  </h5>
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export function getTypeById(id) {
  const data = [
    {
      id: 1,
      Type: "Bowled ",
      ShortName: "",
    },
    {
      id: 2,
      Type: "Caught ",
      ShortName: "",
    },
    {
      id: 3,
      Type: "LBW ",
      ShortName: "",
    },
    {
      id: 4,
      Type: "Stumped",
      ShortName: "",
    },
    {
      id: 5,
      Type: "Hitting Twice",
      ShortName: "",
    },
    {
      id: 6,
      Type: "Hit Wicket",
      ShortName: "",
    },
    {
      id: 7,
      Type: "Run Out",
      ShortName: "",
    },
    {
      id: 8,
      Type: "Timed Out ",
      ShortName: "",
    },
    {
      id: 9,
      Type: "Obstructing Field",
      ShortName: "",
    },
    {
      id: 10,
      Type: "Other",
      ShortName: "",
    },
    {
      id: 11,
      Type: "Retired Out",
      ShortName: "",
    },
    {
      id: 12,
      Type: "Retired Not Out",
      ShortName: "",
    },
    {
      id: 13,
      Type: "Retired obsent",
      ShortName: "",
    },
    {
      id: 14,
      Type: "Caught and Bowled",
      ShortName: "",
    },
    {
      id: 15,
      Type: "Caught Behind",
      ShortName: "",
    },
  ];
  const item = data.find((item) => item.id === id);
  return item ? item.Type : null;
}

const handleDownload = async (url, filename) => {
  window.open(url, "_blank");
};

export const ShowUserInfo = (
  handleVisible,
  handleNavigation,
  profile,
  systemRole,
  handleUploadDoc,
  userDoc,
  handleChangePassword
) => {
  async function fetchFileMetadata(url) {
    console.log("url", url);
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (response.ok) {
        const contentType = response.headers.get("Content-Type");
        return contentType;
      } else {
        throw new Error("Failed to fetch file metadata");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const Docs = ({ row }) => {
    let content;
    fetchFileMetadata(row.fileUrl)
      .then((contentType) => {
        if (contentType) {
          content = contentType;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    console.log("content", content);

    if (content === "application/pdf") {
      return (
        <embed
          src={row.fileUrl}
          type="application/pdf"
          width="100%"
          height="600px"
        />
      );
    }
    if (content === "image/") {
      return (
        <img src={row.fileUrl} alt="File Image" style={{ maxWidth: "100%" }} />
      );
    }
  };

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });
  let roleStr = UserSystemRole.Types.find((e) => e.id === systemRole);
  console.log(userDoc);
  const column = [
    {
      name: "Document NAME",
      selector: (row) => row.fileName,
      sortable: true,
      width: "50%",
    },
    {
      selector: (row) => (
        // <Docs row={row} />,
        <embed
          src={row.fileUrl}
          type="application/pdf"
          width="100px"
          height="90px"
        />
      ),
      center: true,
    },
    {
      name: "Download",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px", cursor: "pointer" }}
          src={DownloadIcon}
          alt="Download"
          onClick={() => handleDownload(row.fileUrl, row.fileName)}
        />
      ),
      center: true,
      width: "150px",
    },
    {
      name: "Delete",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px", cursor: "pointer" }}
          src={DeleteIcon}
        />
      ),
      center: true,
      width: "80px",
    },
  ];

  return (
    <div className="team-popup">
      <div className="team-info">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title1">User Info</div>
          <div className="close d-flex " onClick={() => handleVisible()}>
            X
          </div>
        </div>
        <div className="d-flex mt-4 ">
          <div className="d-flex align-item-center w-50">
            <div>
              {profile?.ProfilePhotoUrl ? (
                <img
                  src={profile?.ProfilePhotoUrl}
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              ) : (
                <img
                  src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              )}
            </div>
            <div className="">
              <h2 className="title_name_player ">
                {profile?.FirstName} {profile?.LastName}{" "}
              </h2>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}>
                  {" "}
                  Phone number :
                </span>{" "}
                <span style={{ fontSize: 14 }}> {profile?.PhoneNumber} </span>
              </p>
              {/* <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}>
                  {" "}
                  System Role :
                </span>{" "}
                <span style={{ fontSize: 14 }}> {roleStr?.Status} </span>
              </p> */}
              <div
                className="update-btn mx-4  "
                onClick={() => handleChangePassword()}
              >
                {" "}
                Change Password
              </div>
              <div
                className="update-btn mx-4 mt-2 "
                onClick={() => handleUploadDoc()}
              >
                {" "}
                Upload Documnets
              </div>
            </div>
          </div>
          <div className="user-doc-table mt-4">
            {userDoc.map((item, index) => (
              <div className="user-doc-con" key={index}>
                <div className="d-flex  align-items-center">
                  <h1 style={{ fontSize: 14 }} className="title">
                    {" "}
                    File Name :{" "}
                  </h1>
                  <h1
                    style={{ fontSize: 14, color: "var(--text-color)" }}
                    className="title ms-1"
                  >
                    {item.fileName}
                  </h1>
                </div>
                <div className="d-flex align-items-center mt-2 gap-3">
                  <iframe
                    className="iframe-content"
                    src={item.fileUrl}
                  ></iframe>
                  <div className="d-flex flex-column  gap-2 ">
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      src={DownloadIcon}
                      alt="Download"
                      onClick={() =>
                        handleDownload(item.fileUrl, item.fileName)
                      }
                    />
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      src={DeleteIcon}
                    />
                  </div>
                </div>
              </div>
            ))}
            {userDoc.length === 0 && (
              <h1 style={{ color: "var(--text-color)", alignSelf: "center" }}>
                No Documents
              </h1>
            )}
          </div>
        </div>

        {/* <div className="w-50  my-4"> 
          <DataTable
            data={userDoc}
            columns={column}
            customStyles={customdocStyles}
            theme="solarized"
            fixedHeader
            fixedHeaderScrollHeight="300px"
          />
        </div>*/}
      </div>

      <div className="logout" onClick={() => handleNavigation("/")}>
        Log Out
      </div>
    </div>
  );
};
export const ShowPlayerInfo = (
  handlePlayerDocShow,
  isPlayerInfo,
  handlePlayerDoc,
  userDoc
) => {
  console.log(isPlayerInfo);
  return (
    <div className="team-popup">
      <div className="team-info">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title1">User Info</div>
          <div className="close d-flex " onClick={() => handlePlayerDocShow()}>
            X
          </div>
        </div>
        <div className="d-flex mt-4 ">
          <div className="d-flex align-item-center w-50">
            <div>
              {isPlayerInfo.User.ProfilePhotoUrl ? (
                <img
                  src={isPlayerInfo.User.ProfilePhotoUrl}
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              ) : (
                <img
                  src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                  alt=""
                  width={200}
                  height={200}
                  style={{ borderRadius: 20 }}
                />
              )}
              <div
                className="update-btn mt-4"
                onClick={() => handlePlayerDoc()}
              >
                {" "}
                Upload Documnets
              </div>
            </div>
            <div className="">
              <h2 className="title_name_player ">
                {isPlayerInfo.User.FirstName} {isPlayerInfo.User.LastName}{" "}
              </h2>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}>
                  {" "}
                  Father Name :
                </span>{" "}
                <span style={{ fontSize: 14 }}>
                  {isPlayerInfo?.User.FatherName}{" "}
                </span>
              </p>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}>
                  {" "}
                  Date of Birth :
                </span>{" "}
                <span style={{ fontSize: 14 }}>{isPlayerInfo?.User.DOB} </span>
              </p>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}> Email :</span>{" "}
                <span style={{ fontSize: 14 }}>
                  {isPlayerInfo?.User.EMail}{" "}
                </span>
              </p>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}> Gender :</span>{" "}
                <span style={{ fontSize: 14 }}> Male</span>
              </p>
              <p className="title_name_player_role">
                <span style={{ color: "var(--title-color)" }}>
                  {" "}
                  Phone number :
                </span>{" "}
                <span style={{ fontSize: 14 }}>
                  {" "}
                  {isPlayerInfo?.User.PhoneNumber}
                </span>
              </p>
            </div>
          </div>
          <div className="user-doc-table mt-4">
            {userDoc.map((item, index) => (
              <div className="user-doc-con" key={index}>
                <div className="d-flex  align-items-center">
                  <h1 style={{ fontSize: 14 }} className="title">
                    {" "}
                    File Name :{" "}
                  </h1>
                  <h1
                    style={{ fontSize: 14, color: "var(--text-color)" }}
                    className="title ms-1"
                  >
                    {item.fileName}
                  </h1>
                </div>
                <div className="d-flex align-items-center mt-2 gap-3">
                  <iframe
                    className="iframe-content"
                    src={item.fileUrl}
                  ></iframe>
                  <div className="d-flex flex-column  gap-2 ">
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      src={DownloadIcon}
                      alt="Download"
                      onClick={() =>
                        handleDownload(item.fileUrl, item.fileName)
                      }
                    />
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      src={DeleteIcon}
                    />
                  </div>
                </div>
              </div>
            ))}
            {userDoc.length === 0 && (
              <h5
                className="d-flex justify-content-end"
                style={{ color: "var(--text-color)" }}
              >
                No Documents
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const conformationPopup = (info, closeConformationPopup) => {
  return (
    <div className="validation-popup">
      <div className="validation-success-popup-inner ">
        <div className="d-flex  align-items-center justify-content-between">
          <h1 className="danger ms-2 mt-4"> Info</h1>
          <div onClick={() => closeConformationPopup()} className="close">
            X
          </div>
        </div>
        <div className="inner-layout">
          <div className="mx-4">
            <p className="success"> {info} </p>
          </div>
        </div>
        <div className="d-flex justify-content-end me-3">
          <button className="btn1 mt-4 me-4">OK</button>
          <button className="btn1 mt-4">Cancle</button>
        </div>
      </div>
    </div>
  );
};

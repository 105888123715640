import React, { useState, useEffect } from "react";
import "../styles/analyseaddplayer.css";
import { get_player_data } from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";
import { conformationPopup } from "../componants/CommonFuncations";
function AnalyseAddPlayer({
  handleAddPlayerPopup,
  matchInfo,
  innInfo,
  playerType,
}) {
  const [BattingPlayer, setBattingPlayer] = useState([]);
  const [BowlingPlayer, setBowlingPlayer] = useState([]);
  const [replaceBattingSelected, setBattingReplaceSelected] = useState(false);
  const [replaceBowlingSelected, setBowlingReplaceSelected] = useState(false);

  useEffect(() => {
    getAllBattingplayer();
    getAllBowlingplayer();
  }, []);
  const getAllBattingplayer = async () => {
    try {
      let resp = await get_player_data(innInfo?.BattingTeamId);

      setBattingPlayer(resp.data.playersList);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllBowlingplayer = async () => {
    try {
      let resp = await get_player_data(innInfo?.BowlingTeamId);

      setBowlingPlayer(resp.data.playersList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReplaceBattingSelectChange = (event) => {
    const value = event.target.value;
    setBattingReplaceSelected(true);
  };

  const handleReplaceBowlingSelectChange = (event) => {
    setBowlingReplaceSelected(true);
  };

  const renderBattingPlayer = () => {
    if (matchInfo && innInfo) {
      let battingPlayerList =
        innInfo.BattingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      const seen = new Set();
      battingPlayerList = battingPlayerList.filter((player) => {
        const isDuplicate = seen.has(player.PlayerId);
        seen.add(player.PlayerId);
        return !isDuplicate;
      });

      let resp = battingPlayerList.map((player, index) => {
        // Exclude the selected player from the options
        if (player.PlayerId) {
          return (
            <option key={index} value={player.PlayerId}>
              {player.Player.User.FirstName} {player.Player.User.LastName}
            </option>
          );
        }
        return null;
      });

      return resp;
    } else {
      return <option>No players available</option>;
    }
  };
  const renderBowlingPlayer = () => {
    if (matchInfo && innInfo) {
      let fieldingPlayerList =
        innInfo.BowlingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      const seen = new Set();
      fieldingPlayerList = fieldingPlayerList.filter((player) => {
        const isDuplicate = seen.has(player.PlayerId);
        seen.add(player.PlayerId);
        return !isDuplicate;
      });

      return fieldingPlayerList.map((player, index) => (
        <option key={index} value={player.PlayerId}>
          {player.Player.User.FirstName} {player.Player.User.LastName}
        </option>
      ));
    } else {
      return <option>No players available</option>;
    }
  };

  const renderAddBattingPlayer = () => {
    if (matchInfo && innInfo) {
      let battingPlayerList =
        innInfo.BattingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      const seen = new Set();
      battingPlayerList = battingPlayerList.filter((player) => {
        const isDuplicate = seen.has(player.PlayerId);
        seen.add(player.PlayerId);
        return !isDuplicate;
      });

      const existingPlayerIds = new Set(
        battingPlayerList.map((player) => player.PlayerId)
      );
      const filteredNewPlayers = BattingPlayer.filter(
        (player) => !existingPlayerIds.has(player.PlayerId)
      );

      return filteredNewPlayers.map((player, index) => (
        <option key={index} value={player.PlayerId}>
          {player.Player.User.FirstName} {player.Player.User.LastName}
        </option>
      ));
    } else {
      return <option>No players available</option>;
    }
  };
  const renderAddBowlingPlayer = () => {
    if (matchInfo && innInfo) {
      let fieldingPlayerList =
        innInfo.BowlingTeamId === matchInfo.TeamAId
          ? matchInfo.TeamAPlayerList
          : matchInfo.TeamBPlayerList;

      const seen = new Set();
      fieldingPlayerList = fieldingPlayerList.filter((player) => {
        const isDuplicate = seen.has(player.PlayerId);
        seen.add(player.PlayerId);
        return !isDuplicate;
      });

      const existingPlayerIds = new Set(
        fieldingPlayerList.map((player) => player.PlayerId)
      );
      const filteredNewPlayers = BowlingPlayer.filter(
        (player) => !existingPlayerIds.has(player.PlayerId)
      );

      return filteredNewPlayers.map((player, index) => (
        <option key={index} value={player.PlayerId}>
          {player.Player.User.FirstName} {player.Player.User.LastName}
        </option>
      ));
    } else {
      return <option>No players available</option>;
    }
  };

  const [isConfirm, setISconfirm] = useState(false);
  const handleAddPlayer = () => {
    if (replaceBattingSelected || replaceBowlingSelected) {
      setISconfirm(true);
    } else {
      setValidationData(`Please select the Replace and ${playerType} `);
    }
  };

  const [validationData, setValidationData] = useState(null);
  const onClose = () => {
    setValidationData(null);
  };

  const [info,setInfo] = useState("")

  const closeConformationPopup = () =>{
     
    setISconfirm(false);
  }

  // const conformationPopup = () => {
  //   return (
  //     <div className="validation-popup">
  //       <div className="validation-success-popup-inner ">
  //         <div className="d-flex  align-items-center justify-content-between">
  //           <h1 className="danger ms-2 mt-4"> Info</h1>
  //           <div onClick={() => closeConformationPopup()} className="close">
  //             X
  //           </div>
  //         </div>
  //         <div className="inner-layout">
  //           <div className="mx-4">
  //             <p className="success"> hjjhhb</p>
  //           </div>
  //         </div>
  //         <div className="d-flex justify-content-end me-3">
  //           <button className="btn1 mt-4 me-4">OK</button>
  //           <button className="btn1 mt-4">Cancle</button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="other-popup">
      <div className="other-btn-popup">
        <div className="d-flex  align-items-center justify-content-between">
          <h2 className="add-player-lable ms-2 "> Add {playerType} </h2>
          <div className="close" onClick={() => handleAddPlayerPopup(null)}>
            X
          </div>
        </div>
        <div className="d-flex team-title">
          <div style={{ width: "50%" }} className="">
            <div className="d-flex align-items-center">
              <h2 className="add-player-lable ms-1"> Batting Team : </h2>
              <h2 className="team-name-txt ms-1">
                {" "}
                {innInfo?.BattingTeam.TeamShortName}
              </h2>
            </div>
            <label className="add-player-lable mx-1 my-2">Replace player</label>
            <select
              className="add_player_select "
              name=""
              id=""
              onChange={handleReplaceBattingSelectChange}
            >
              <option value="">Replace player</option>
              {renderBattingPlayer()}
            </select>
            {replaceBattingSelected && (
              <div className="">
                <label className="add-player-lable mx-1 my-2">
                  Add {playerType}
                </label>
                <select className="add_player_select " name="" id="">
                  <option value="">Add {playerType} </option>
                  {renderAddBattingPlayer()}
                </select>
              </div>
            )}
            <div className="my-4 d-flex justify-content-center">
              <div className="update-btn w-50">Add Player </div>
            </div>
          </div>
          <div style={{ width: "50%" }} className="">
            <div className="d-flex align-items-center">
              <h2 className="add-player-lable ms-1"> Bowling Team : </h2>
              <h2 className="team-name-txt ms-1">
                {" "}
                {innInfo?.BowlingTeam.TeamShortName}
              </h2>
            </div>

            <label className="add-player-lable mx-1 my-2">Replace player</label>
            <select
              className="add_player_select "
              name=""
              id=""
              onChange={handleReplaceBowlingSelectChange}
            >
              <option value="">Replace player</option>
              {renderBowlingPlayer()}
            </select>
            {replaceBowlingSelected && (
              <div className="">
                <label className="add-player-lable mx-1 my-2">
                  {" "}
                  Add {playerType}{" "}
                </label>
                <select className="add_player_select " name="" id="">
                  <option value="">Add {playerType} </option>
                  {renderAddBowlingPlayer()}
                </select>
              </div>
            )}
            <div className="mt-4 d-flex  justify-content-center">
              <div className="update-btn w-50" onClick={handleAddPlayer}>
                Add Player{" "}
              </div>
            </div>
            {isConfirm &&
             conformationPopup(info,closeConformationPopup)
              }
            {validationData && (
              <ValidationModal data={validationData} onClose={onClose} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyseAddPlayer;

class ResetPassword {
  constructor() {
    this.UserId = null;
    this.OldPassword = null;
    this.newPassword = null;
    this.PhoneNumber = null;
  }
}

export default ResetPassword;

class Playerdetails {
  constructor() {
    this.playerId = null;
    this.RegistrationId = null;
    this.DistrictId = null;
    this.PlayerTypeId = null;
    this.userInfo = {};
    this.BattingStyleId = 1;
    this.BowlingStyleId = 1;
    this.BowlingHandId = 1;
    this.BattingHandId = 1;
    this.TeamId = 1;
    // this.PlayerRegisterationTypeId = null;
    // this.StatusId = null;
  }
}

export default Playerdetails;

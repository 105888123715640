import React, { useEffect, useState } from "react";
import "../styles/menubars.css";
import "../styles/darkTheme.css";
import { THEME_COLOR } from "../utils/localstorage_variables";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import Register from "./Register";
import { getData } from "../utils/localstorage_constants";
import {
  IS_LOGGED,
  USER_DATA,
  USER_ID,
  USER_SYSTEM_ROLE,
} from "../utils/localstorage_variables";
import { ShowUserInfo } from "../componants/CommonFuncations";
import { removeData } from "../utils/localstorage_constants";
import { get_user_by_id, get_user_documnets } from "../api/serverWrapper";
import ThemeToggler from "./ThemeToggler";
import {
  ActiveAnalyseIcon,
  ActiveDashBoardIcon,
  ActiveGroundIcon,
  ActiveHomeIcon,
  ActiveMatchIcon,
  ActiveOfficialIcon,
  ActivePlayerIcon,
  ActiveStatsIcon,
  ActiveTeamIcon,
  ActiveTournamentIcon,
  AnalyseIcon,
  DashBoardIcon,
  GroundIcon,
  HomeIcon,
  MatchIcon,
  OfficialIcon,
  PlayerIcon,
  StatsIcon,
  TeamIcon,
  TournamentIcon,
} from "../assets/SvgIcons";
import UserSystemRole from "../componants/UserSystemRole";
import UploadDocs from "./UploadDocs";
import ChangePassword from "./ChangePassword";
export default function Menubars() {
  const navigate = useNavigate();
  const [logged, setLogged] = useState(false);
  let [visible, setVisible] = useState(false);
  let [profile, setProfile] = useState();
  let [systemRole, setSystemRole] = useState();
  let [isPlayer, setIsPlayer] = useState(false);
  let [isCoach, setIsCoach] = useState(false);
  let [isAnalyst, setIsAnalyst] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [userDoc, setUserDoc] = useState([]);
  let loadeddata = false;
  useEffect(() => {
    if (loadeddata) {
      return;
    }
    loadeddata = true;
    let islogged = getData(IS_LOGGED);
    if (islogged) {
      let userid = getData(USER_ID);
      loadinitialData(userid);
      setLogged(islogged);
      systemRole = getData(USER_SYSTEM_ROLE);
      setSystemRole(systemRole);
    }
    const theme = localStorage.getItem(THEME_COLOR);
    document.documentElement.className = theme;
  }, []);

  const loadinitialData = async (userid) => {
    let userData = await get_user_by_id(userid);
    console.log("userData", userData);
    profile = userData.data.user;
    setProfile(profile);
    if (userData.data.player) {
      isPlayer = true;
      setIsPlayer(isPlayer);
      console.log("isPlayer", isPlayer);
    }
    if (userData.data.official) {
      if (userData.data.official.DesignationId === 3) {
        isCoach = true;
        setIsCoach(isCoach);
        console.log("isCoach", isCoach);
      }
      if (userData.data.official.DesignationId === 12) {
        isAnalyst = true;
        setIsAnalyst(isAnalyst);
        console.log("isanalyst", isAnalyst);
      }
    }

    let docData = await get_user_documnets(userid);
    console.log("docData", docData);

    let d = convertDataToArray(docData.data);
    setUserDoc(d);
  };
  const handleNavigation = (to) => {
    removeData();
    navigate(to, { replace: true });
    window.location.reload();
  };

  const convertDataToArray = (data) => {
    return Object.keys(data).map((key) => ({
      fileName: key,
      fileUrl: data[key].fileUrl,
    }));
  };

  const handleVisible = () => {
    visible = !visible;
    setVisible(visible);
  };

  const [uploadDocModel, setUploadDocModal] = useState(false);
  const [changepasswordModel, setChangePasswordModel] = useState(false);
  const handleUploadDoc = () => {
    setUploadDocModal(!uploadDocModel);
  };

  const handleChangePassword = () => {
    setChangePasswordModel(!changepasswordModel);
  };

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };
  return (
    <nav className="navbar1">
      <div className="d-flex">
        <div className="hpca_logo_name_container">
          <img
            className="logo"
            src={require("../assets/navbar_icons/sdm_logo.png")}
          />
          <span className="hpca_name">
            Himachal Pradesh Cricket Association
          </span>
        </div>

        {logged && (
          <ul>
            <CustomLink
              to="/"
              activeImage={ActiveHomeIcon}
              inactiveImage={HomeIcon}
              name="Home"
            ></CustomLink>

            <CustomLink
              to="/scorecard"
              activeImage={ActiveDashBoardIcon}
              inactiveImage={DashBoardIcon}
              name="Dashboard"
            ></CustomLink>

            {(systemRole === UserSystemRole.ADMIN.id ||
              profile?.IsApproved === 1) &&
              !isAnalyst && (
                <CustomLink
                  to="/matches"
                  activeImage={ActiveMatchIcon}
                  inactiveImage={MatchIcon}
                  name="Matches"
                ></CustomLink>
              )}

            {(systemRole === UserSystemRole.ADMIN.id ||
              systemRole === UserSystemRole.MEMBER.id ||
              systemRole === UserSystemRole.DISTRICT_ADMIN.id) &&
              (systemRole === UserSystemRole.ADMIN.id ||
              systemRole === UserSystemRole.DISTRICT_ADMIN.id
                ? true
                : isPlayer || isCoach) &&
              (systemRole === UserSystemRole.ADMIN.id ||
              systemRole === UserSystemRole.DISTRICT_ADMIN.id
                ? true
                : profile?.IsApproved === 1) && (
                <CustomLink
                  to="/teams"
                  activeImage={ActiveTeamIcon}
                  inactiveImage={TeamIcon}
                  name="Teams"
                ></CustomLink>
              )}

            {(systemRole === UserSystemRole.ADMIN.id ||
              systemRole === UserSystemRole.DISTRICT_ADMIN.id) && (
              <CustomLink
                to="/players"
                activeImage={ActivePlayerIcon}
                inactiveImage={PlayerIcon}
                name="Players"
              ></CustomLink>
            )}
            {systemRole === UserSystemRole.ADMIN.id && (
              <CustomLink
                to="/grounds"
                activeImage={ActiveGroundIcon}
                inactiveImage={GroundIcon}
                name="Grounds"
              ></CustomLink>
            )}
            {systemRole === UserSystemRole.ADMIN.id && (
              <CustomLink
                to="/officials"
                activeImage={ActiveOfficialIcon}
                inactiveImage={OfficialIcon}
                name="Officials"
              ></CustomLink>
            )}
            {systemRole === UserSystemRole.ADMIN.id && (
              <CustomLink
                to="/tournaments"
                activeImage={ActiveTournamentIcon}
                inactiveImage={TournamentIcon}
                name="Tournaments"
              ></CustomLink>
            )}
            {(systemRole === UserSystemRole.ADMIN.id || isAnalyst) && (
              <CustomLink
                to="/scoring"
                activeImage={ActiveStatsIcon}
                inactiveImage={StatsIcon}
                name="Analyze"
              ></CustomLink>
            )}
            {!isAnalyst && (
              <CustomLink
                to="/leaderboard"
                activeImage={ActiveAnalyseIcon}
                inactiveImage={AnalyseIcon}
                name="Statistics"
              ></CustomLink>
            )}
            {/* <CustomLink
              to="/pendingapproval"
              activeImage={ActiveAnalyseIcon}
              inactiveImage={AnalyseIcon}
              name="Pending Approval"
            ></CustomLink> */}
          </ul>
        )}
      </div>

      {logged ? (
        <div className="navigation_righticons ">
          <div className="d-flex">
            <div className="d-flex align-items-center flex-column">
              <img
                className="theme-icon"
                height={50}
                width={50}
                src={require("../assets/images/icon-theme.png")}
                alt=""
                onClick={togglePopup}
              />
              <div className="theme-change-txt">Theme</div>
            </div>
            {popupVisible && <ThemeToggler close={setPopupVisible} />}
            <div className="d-flex align-items-center flex-column">
              <div className="" onClick={handleVisible}>
                {profile?.ProfilePhotoUrl ? (
                  <img className="user" src={profile?.ProfilePhotoUrl} />
                ) : (
                  <img
                    className="user "
                    src={require("../assets/images/profile-icon.png")}
                  />
                )}
              </div>
              <div className="user-name">
                {/* <h6> */}
                {profile?.FirstName + "   "}
                {profile?.LastName ? profile.LastName : ""}
                {/* </h6> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <div className="d-flex align-items-center flex-column">
            <img
              className="theme-icon"
              height={50}
              width={50}
              src={require("../assets/images/icon-theme.png")}
              alt=""
              onClick={togglePopup}
            />
            <div className="theme-change-txt">Theme</div>
          </div>
          {popupVisible && <ThemeToggler close={setPopupVisible} />}
          <div className="navigation_righticons gap-2">
            <div
              onClick={() => handleNavigation("/login")}
              className="login-txt"
            >
              <h6>Login</h6>
            </div>
          </div>
        </div>
      )}
      {logged &&
        visible &&
        // <div onClick={() => handleNavigation("/")} className="logout">
        //   Log Out
        // </div>

        ShowUserInfo(
          handleVisible,
          handleNavigation,
          profile,
          systemRole,
          handleUploadDoc,
          userDoc,
          handleChangePassword
        )}
      {logged && uploadDocModel && (
        <UploadDocs onclose={handleUploadDoc} uid={getData(USER_ID)} />
      )}

      {logged && changepasswordModel && (
        <ChangePassword onclose={handleChangePassword} />
      )}
    </nav>
  );
}

function CustomLink({
  to,
  children,
  activeImage,
  name,
  inactiveImage,
  ...props
}) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        <div className="icon_name_container">
          <img
            className="icon"
            src={isActive ? activeImage : inactiveImage}
            alt={isActive ? "Active Icon" : "Inactive Icon"}
          />
          <span className={isActive ? "icon_activename" : "icon_inactivename"}>
            {name}
          </span>
        </div>
        {children}
      </Link>
    </li>
  );
}

class Groundadd {
  constructor() {
    // this.id = null;
    this.Groundname = null;
    this.Description = null;
    this.AddressInfo = {};
  }
}

export default Groundadd;

import React, { useEffect, useState } from "react";
import "../styles/official.css";
import DataTable, { createTheme } from "react-data-table-component";
import { tablecreateTheme } from "../assets/globalcolors";
import { EditIcon, DeleteIcon } from "../assets/SvgIcons";
import {
  get_official_data,
  get_official_category,
  get_gender,
  create_official,
  update_official,
  get_designations,
  get_districts,
  update_image,
  get_tournaments_type,
  get_umpire_stats,
  get_appealt_types,
  get_match_data,
  get_tornament_umpire_stats,
  get_country_states,
  get_state_districts,
  get_district_cities,
  approve_user,
} from "../api/serverWrapper";
import ValidationModal from "./ValidationModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  customtableStyles,
  conditionalRowStylesUmpire,
  conditionalRowStyles,
} from "../assets/tablestyle";
import OfficialDetails from "../componants/Official";
import UserInfo from "../componants/UserInfo";
import { OFFICIAL_IMG_TITLE } from "../utils/constants";
import UserSystemRole from "../componants/UserSystemRole";
import Address from "../componants/Address";
import {
  LOGIN_USER_DISTRICT_ID,
  USER_SYSTEM_ROLE,
} from "../utils/localstorage_variables";
import { getData } from "../utils/localstorage_constants";

function Umpire() {
  let offset = null;
  // let superadmin = true;
  let [superadmin, setSuperadmin] = useState(false);
  let [systemrole, setSystemRole] = useState();
  let [admindistrictid, setAdminDistrictId] = useState(null);
  let [tournaments, setTournaments] = useState([]);

  const [officials, setOfficials] = useState([]);
  const [officials1, setOfficials1] = useState([]);
  const [officialCategories, setOfficialCategories] = useState([]);
  let [designationtype, setDesignationType] = useState(2);
  const [officialDesignations, setOfficialDesignations] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  let [isEdit, setIsEdit] = useState(false);

  const [genderLsit, setGenderLsit] = useState();

  let [officialData, setOfficialData] = useState(new OfficialDetails());
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState();
  const [respsucces, setRespSucces] = useState(false);
  let [districts, setDistricts] = useState();
  let [selectedofficialimg, setSelectedOfficailImg] = useState(null);
  let [officialimg, setOfficailImg] = useState("");
  let [isLoading, setIsLoading] = useState(false);

  let [firstname, setFirstName] = useState(null);
  let [latsname, setLastName] = useState(null);
  let [districtid, setDistrictId] = useState(null);
  let [country, setCountry] = useState(null);
  let [phonenumber, setPhoneNumber] = useState(null);
  let [gender, setGender] = useState(1);
  let [registrationid, setRegistartionId] = useState(null);
  let [designation, setDesignation] = useState(null);
  let [category, setCategory] = useState(null);
  let [isapprove, setIsApprove] = useState(false);
  const [appealtTypes, setAppealtTypes] = useState([]);
  let [filterdistrictid, setFilterDistrictId] = useState(null);
  const [activeTab, setActiveTab] = useState("approved");
  let [approval, setApproval] = useState(true);
  let [states, setStates] = useState();
  let [statedistricts, setStatedistricts] = useState();
  let [districtcities, setDistrictCities] = useState();
  let [addressdetails, setAddressDetails] = useState();
  let [selectedstate, setSelectedState] = useState();
  let [selecteddist, setSelectedDistrict] = useState();
  let [selectedcity, setSelectedCity] = useState();
  let [zipCode, setZipCode] = useState(null);

  // let [address1, setAddress1] = useState(new Address());
  // let [address2, setAddress2] = useState(new Address());
  let [addressdetails2, setAddressDetails2] = useState();
  let [statedistricts2, setStatedistricts2] = useState();
  let [districtcities2, setDistrictCities2] = useState();
  let [selectedstate2, setSelectedState2] = useState();
  let [selecteddist2, setSelectedDistrict2] = useState();
  let [selectedcity2, setSelectedCity2] = useState();
  let [zipCode2, setZipCode2] = useState(null);

  createTheme("solarized", {
    background: {
      default: "var(--main-background-color)",
    },
  });

  const getAppealtTypes = async () => {
    try {
      let resp = await get_appealt_types();
      setAppealtTypes(resp.data);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleApprovepopup = (row) => {
    // if (officialData) {
    //   setOfficialData(new OfficialDetails());
    // } else {
    if (isapprove) {
      officialData = new OfficialDetails();
      setOfficialData(officialData);
    }
    officialData = new OfficialDetails(row);
    console.log("officialData", officialData);
    setOfficialData(officialData);
    // }
    isapprove = !isapprove;
    setIsApprove(isapprove);
  };

  const handleOfficialApprove = async (type) => {
    let user = { userId: officialData.User.UserId, approveState: type };
    let resp = await approve_user(user);
    if (resp.success) {
      if (type === 1) {
        setMessage("Official is Approved...!!!");
      }
      if (type === 2) {
        setMessage("Official is Rejected...!!!");
      }
    }
    handleApprovepopup();
    succespopup();
  };

  const handleApproveOfficial = () => {
    return (
      <div className="overlay">
        <div className="approval_popup">
          <div
            className="title2 d-flex justify-content-end me-4 "
            style={{ cursor: "pointer" }}
            onClick={handleApprovepopup}
          >
            X
          </div>
          <div className="pop_up_add_text_container d-flex flex-column align-items-center justify-content-between me-5">
            <span className="pop_up_add_text">
              Are you sure to approve Official..!
            </span>
            <div>
              <button
                className="popup_button_save"
                onClick={() => handleOfficialApprove(1)}
              >
                Approve
              </button>
              <button
                className="popup_button_cancel"
                onClick={() => handleOfficialApprove(2)}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Approval = ({ row }) => {
    if (row?.User.IsApproved === 1) {
      return "Approved";
    } else if (row?.User.IsApproved === 2) {
      return "Rejected";
    } else {
      return (
        <div className="approve_btn" onClick={() => handleApprovepopup(row)}>
          <span>Approve/Reject</span>
        </div>
      );
    }
  };
  const OfficialNameCell = ({ row }) => {
    const firstNameLetter = row.User.FirstName
      ? row.User.FirstName.charAt(0)
      : "";
    const lastNameLetter = row.User.LastName ? row.User.LastName.charAt(0) : "";
    return (
      <div
        onClick={() => handleOfficialInfo(row)}
        className="batsman_bowler_container"
      >
        {row.User.ProfilePhotoUrl ? (
          <img
            className="batsman_bowler_img"
            src={row.User.ProfilePhotoUrl}
            alt=""
          />
        ) : (
          <div className="name_cell">
            {firstNameLetter}
            {lastNameLetter}
          </div>
        )}
        <div className="">
          {row.User.FirstName +
            "   " +
            (row.User.LastName ? row.User.LastName : "")}
        </div>{" "}
      </div>
    );
  };
  const conditionalRowStylesdis = [
    {
      when: (row) => row.id === designationtype,
      style: {
        color: "var( --active-border)",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  const columnsdesignation = [
    {
      name: "Designations",
      selector: (row) => row.Title,
      sortable: true,
      center: true,
    },
  ];
  const [selectedTournament, setSelectedTournament] = useState("");
  const columns = [
    {
      name: "Name",
      selector: (row) => <OfficialNameCell row={row} />,
      sortable: true,
      width: "300px",
    },
    {
      name: "Approval",
      selector: (row) => <Approval row={row} />,
      center: true,
      width: "200px",
    },
    // {
    //   name: "Name",
    //   selector: (row) =>
    //     row.User.FirstName + " " + (row.User.LastName ? row.User.LastName : ""),
    //   sortable: true,
    // },
    {
      name: "Designation",
      selector: (row) => row.DesignationInfo?.Title,
      sortable: true,
      width: "200px",
      center:true,
    },
    {
      name: "Registration Id",
      selector: (row) => (row.RegistrationId ? row.RegistrationId : "-"),
      sortable: true,
      width: "200px",
      center:true,
    },
    ...(selectedTournament
      ? [
          {
            name: "Match Name",
            selector: (row) => "-",
            sortable: true,
            width: "200px",
            center: true,
          },
          {
            name: "Acceptance status",
            selector: (row) => "-",
            sortable: true,
            center: true,
          },
        ]
      : []),
    // {
    //   name: "Category",
    //   selector: (row) => row.CategoryInfo.type,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Country",
    //   selector: (row) => row.Country,
    //   sortable: true,
    // },
    {
      name: "Edit",
      selector: (row) => (
        <img
          style={{ height: "25px", width: "25px",   }}
          src={EditIcon}
          onClick={() => editOfficial(row)}
        />
      ),
      width: "100px",
      center: true,
       
    },
    {
      name: "Delete",
      selector: (row) => (
        <img style={{ height: "25px", width: "25px" }} src={DeleteIcon} />
      ),
      width: "100px",
      center: true,
    },
  ];
  let is_loading_data = false;

  useEffect(() => {
    fetchOfficials();
  }, []);

  const fetchOfficials = async () => {
    if (is_loading_data) {
      return;
    }
    is_loading_data = true;
    isLoading = true;
    setIsLoading(isLoading);
    let districtId = getData(LOGIN_USER_DISTRICT_ID);
    let systemRole = getData(USER_SYSTEM_ROLE);
    systemrole = systemRole;
    setSystemRole(systemrole);
    if (systemRole === UserSystemRole.ADMIN.id) {
      setSuperadmin(true);
    } else if (systemRole === UserSystemRole.DISTRICT_ADMIN.id) {
      districtid = districtId;
      setDistrictId(districtid);
      setSuperadmin(false);
    } else {
      setSuperadmin(false);
    }
    let state = await get_country_states();
    states = state.data;
    setStates(states);
    let res = await get_official_data(
      offset,
      filterdistrictid,
      designationtype
    );
    console.log("get_official_data", res);
    setOfficials(res.data.officials);
    setOfficials1(res.data.officials);
    let catRes = await get_official_category();
    setOfficialCategories(catRes.data);
    let genderList = await get_gender();

    setGenderLsit(genderList.data);
    let desRes = await get_designations();
    let removedElement = desRes.data.splice(1);
    setOfficialDesignations(removedElement);
    console.log("setOfficialDesignations", officialDesignations);

    let district = await get_districts();
    districts = district.data;
    setDistricts(districts);

    let tournamentsResp = await get_tournaments_type();
    settournamentType(tournamentsResp.data.tournaments);
    tournaments = tournamentsResp.data.tournaments;
    setTournaments(tournaments);

    getAppealtTypes();
    getMatchList();

    
    

    isLoading = false;
    setIsLoading(isLoading);
  };

  useEffect(() => {
    setFirstName(officialData.User.FirstName);
    setLastName(officialData.User.LastName);
    setGender(officialData.User.Gender);
    setCategory(officialData.CategoryId);
    setPhoneNumber(officialData.User.PhoneNumber);
    setCountry(officialData.Country);
    setDistrictId(officialData.DistrictId);
    setDesignation(officialData.DesignationId);
    setRegistartionId(officialData.RegistrationId);
    setSelectedOfficailImg(officialData.User.ProfilePhotoUrl);
    setAddressDetails(officialData.address1?.AddressDetails);
    setSelectedState(officialData.address1?.StateId);
    setSelectedDistrict(officialData.address1?.DistrictId);
    setSelectedCity(officialData.address1?.CityId);
    setAddressDetails2(officialData.address2?.AddressDetails);
    setSelectedState2(officialData.address2?.StateId);
    setSelectedDistrict2(officialData.address2?.DistrictId);
    setSelectedCity2(officialData.address2?.CityId);
    if (officialData?.address1?.StateId && officialData?.address1.DistrictId) {
      editAddressDetails1();
    }
    if (officialData?.address2?.StateId && officialData?.address2.DistrictId) {
      editAddressDetails2();
    }
  }, [officialData]);
  const editAddressDetails1 = async () => {
    // console.log(
    //   "editAddressDetails1",
    //   officialData.address1.StateId,
    //   officialData.address1.DistrictId
    // );
    let dist = await get_state_districts(officialData?.address1.StateId);
    statedistricts = dist.data;
    setStatedistricts(statedistricts);
    let city = await get_district_cities(
      officialData?.address1.StateId,
      officialData?.address1.DistrictId
    );
    districtcities = city.data;
    setDistrictCities(districtcities);
    // console.log("selectedcity1", selectedcity, districtcities);
    const selectedCityData = districtcities.find(
      (city) => city.id === officialData.address1.CityId
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData.Pin !== null) {
      zipCode = selectedCityData.Pin;
      setZipCode(zipCode);
    } else {
      zipCode = "";
      setZipCode(zipCode);
    }
  };

  const editAddressDetails2 = async () => {
    // console.log("editAddressDetails2");
    let dist = await get_state_districts(officialData?.address2.StateId);
    statedistricts2 = dist.data;
    setStatedistricts2(statedistricts2);
    let city = await get_district_cities(
      officialData?.address2.StateId,
      officialData?.address2.DistrictId
    );
    districtcities2 = city.data;
    setDistrictCities2(districtcities2);
    // console.log("selectedcity2", selectedcity2, districtcities2);
    const selectedCityData = districtcities2.find(
      (city) => city.id === officialData.address2.CityId
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData.Pin !== null) {
      zipCode2 = selectedCityData.Pin;
      setZipCode2(zipCode2);
    } else {
      zipCode2 = "";
      setZipCode2(zipCode2);
    }
  };
  const [matchList, setMatchList] = useState([]);
  const getMatchList = async () => {
    try {
      let resp = await get_match_data();
      setMatchList(resp.data.matches);
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handledesignationChange = async (row) => {
    // console.log("district", row);
    if (row.id !== "") {
      designationtype = row.id;
    } else {
      designationtype = null;
    }
    setDesignationType(designationtype);
    await fetchOfficials();
  };

  const handleDistrictChange = async (event) => {
    const id = event.target.value;
    console.log("district", id);
    if (id !== "") {
      filterdistrictid = id;
    } else {
      filterdistrictid = null;
    }
    setFilterDistrictId(filterdistrictid);
    await fetchOfficials();
  };

  const editOfficial = (official) => {
    officialData = new OfficialDetails(official);
    console.log("officialData", officialData);
    setOfficialData(officialData);

    console.log("selectedofficialimg", selectedofficialimg);
    isEdit = true;
    setIsEdit(isEdit);
    setIsPopupOpen(!isPopupOpen);

    // toggleAddOrEditPopup();
  };
  const hanbleTabClick = (tab, isapproved) => {
    setActiveTab(tab);
    setApproval(isapproved);
    // localStorage.setItem("activeTab", tab);
  };
  const handleAddOfficial = () => {
    officialData = new OfficialDetails();
    console.log("officialData", officialData);
    setOfficialData(officialData);
    setIsEdit(false);
    toggleAddOrEditPopup();
  };

  const toggleAddOrEditPopup = () => {
    if (isEdit) {
      isEdit = false;
      setIsEdit(isEdit);
    }
    // setOfficialData(officialData);
    if (officialData && isEdit === false) {
      setOfficialData(new OfficialDetails());
      setZipCode(null);
      setZipCode2(null);
    }
    setIsPopupOpen(!isPopupOpen);
    if (selectedofficialimg) {
      setSelectedOfficailImg(null);
    }
  };

  const handleImg = (e) => {
    let file = e.target.files[0];
    if (file) {
      officialimg = file;
      setOfficailImg(officialimg);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedOfficailImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log("selectedofficialimg", selectedofficialimg);
  };
  const handleDistrictId = (event) => {
    const { value } = event.target;
    console.log("officialDetails ", officialData);
    setDistrictId(value);
    officialData.DistrictId = value;
    setOfficialData(officialData);
  };
  const handlefirstname = (event) => {
    const { value } = event.target;
    console.log("officialDetails ", officialData);
    setFirstName(value);
    officialData.User.FirstName = value;
    setOfficialData(officialData);
  };

  const handlelastname = (event) => {
    const { value } = event.target;
    setLastName(value);
    officialData.User.LastName = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };

  const handlOfficialDesignation = (event) => {
    const { value } = event.target;
    setDesignation(value);
    officialData.DesignationId = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };

  const handlOfficialCategory = (event) => {
    const { value } = event.target;
    setCategory(value);
    officialData.CategoryId = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };

  // const handledob = (event) => {
  //   const { value } = event.target;
  //   officialData.User.DOB = value;
  //   setOfficialData(officialData);
  //   console.log("officialDetails ", officialData);
  // };

  const handlephonenumber = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    officialData.User.PhoneNumber = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };

  const handlegender = (event) => {
    const { value } = event.target;

    officialData.User.Gender = value;
    setGender(value);
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };

  const handleregisterationid = (event) => {
    const { value } = event.target;
    setRegistartionId(value);
    officialData.RegistrationId = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };
  const handlecountry = (event) => {
    const { value } = event.target;
    setCountry(value);
    officialData.Country = value;
    setOfficialData(officialData);
    console.log("officialDetails ", officialData);
  };
  const handleeditofficial = async () => {
    let resp;

    if (isapprove) {
      officialData.User.IsApproved = 1;
    }
    if (officialData.Country === null) {
      delete officialData.Country;
    }
    if (officialData.User.LastName === null) {
      delete officialData.User.LastName;
    }
    if (officialData.User.PhoneNumber === null) {
      delete officialData.User.PhoneNumber;
    }
    if (officialData.RegistrationId === null) {
      delete officialData.RegistrationId;
    }
    if (officialData.DistrictId === null) {
      delete officialData.DistrictId;
    }
    let data = new OfficialDetails(officialData);
    // console.log("officialDetails ", data);
    if (officialData.User.FirstName) {
      if (officialData.User.Gender) {
        if (officialData.address1.AddressDetails) {
          if (officialData.address1.StateId) {
            if (officialData.address1.DistrictId) {
              if (officialData.address1.CityId) {
                if (officialData.DesignationId) {
                  if (officialData.CategoryId) {
                    resp = await update_official(officialData);
                    let id = resp.data ? resp.data.UserId : "";
                    if (resp.code == 200) {
                      if (officialimg !== "" && id !== "") {
                        try {
                          const resp = await update_image(
                            id,
                            officialimg,
                            OFFICIAL_IMG_TITLE
                          );
                          console.log(resp);
                          if (resp.code === 200) {
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }
                      if (isapprove === false) {
                        setMessage("Official Updated  ..!!");
                        toggleAddOrEditPopup();
                      }
                      if (isapprove) {
                        handleApprovepopup();
                        setMessage("Official Approved  ..!!");
                      }
                      console.log(resp);
                      succespopup();
                    } else {
                      setMessage(resp.message);
                      console.log(resp);
                      validationpopupvisible();
                    }
                  } else {
                    setMessage("Select Category");
                    validationpopupvisible();
                  }
                } else {
                  setMessage("Select Designation");
                  validationpopupvisible();
                }
              } else {
                setMessage("Select City");
                validationpopupvisible();
              }
            } else {
              setMessage("Select District");
              validationpopupvisible();
            }
          } else {
            setMessage("Select State");
            validationpopupvisible();
          }
        } else {
          setMessage("Enter Address1");
          validationpopupvisible();
        }
      } else {
        setMessage("Select Gender");
        validationpopupvisible();
      }
    } else {
      setMessage("Enter FirstName");
      validationpopupvisible();
    }
    console.log("resp", resp);
  };
  const handleaddofficial = async () => {
    let resp;
    delete officialData.id;
    delete officialData.User.FatherName;
    delete officialData.User.EMail;
    delete officialData.User.Password;
    delete officialData.User.DOB;
    delete officialData.User.KycDataId;
    delete officialData.User.DistrictId;
    delete officialData.User.DesignationId;
    officialData.User.SystemRole = UserSystemRole.MEMBER.id;
    officialData.User.IsApproved = false;
    if (officialData.Country === null) {
      delete officialData.Country;
    }
    if (officialData.User.LastName === null) {
      delete officialData.User.LastName;
    }
    if (officialData.User.PhoneNumber === null) {
      delete officialData.User.PhoneNumber;
    }
    if (officialData.RegistrationId === null) {
      delete officialData.RegistrationId;
    }
    if (officialData.DistrictId === null) {
      delete officialData.DistrictId;
    }
    if (officialData.User.FirstName) {
      if (officialData.User.Gender) {
        if (officialData.address1.AddressDetails) {
          if (officialData.address1.StateId) {
            if (officialData.address1.DistrictId) {
              if (officialData.address1.CityId) {
                if (officialData.DesignationId) {
                  if (officialData.CategoryId) {
                    resp = await create_official(officialData);
                    let id = resp.data ? resp.data.UserId : "";
                    if (resp.code == 200) {
                      if (officialimg !== "" && id !== "") {
                        try {
                          const resp = await update_image(
                            id,
                            officialimg,
                            OFFICIAL_IMG_TITLE
                          );
                          console.log(resp);
                          if (resp.code === 200) {
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }
                      setMessage("Official created ..!!");
                      toggleAddOrEditPopup();
                      console.log(resp);
                      succespopup();
                    } else {
                      setMessage(resp.message);
                      console.log(resp);
                      validationpopupvisible();
                    }
                  } else {
                    setMessage("Select Category");
                    validationpopupvisible();
                  }
                } else {
                  setMessage("Select Designation");
                  validationpopupvisible();
                }
              } else {
                setMessage("Select City");
                validationpopupvisible();
              }
            } else {
              setMessage("Select District");
              validationpopupvisible();
            }
          } else {
            setMessage("Select State");
            validationpopupvisible();
          }
        } else {
          setMessage("Enter Address1");
          validationpopupvisible();
        }
      } else {
        setMessage("Select Gender");
        validationpopupvisible();
      }
    } else {
      setMessage("Enter FirstName");
      validationpopupvisible();
    }
  };
  const handleSaveButtonClick = async () => {
    console.log(officialData);
    let resp;
    if (isEdit || isapprove) {
      delete officialData.User.FatherName;
      delete officialData.User.EMail;
      delete officialData.User.Password;
      delete officialData.User.DOB;
      delete officialData.User.KycDataId;
      delete officialData.User.DisplayName;
      delete officialData.User.ProfilePhotoUrl;
      delete officialData.address1.id;
      delete officialData.address2.id;
      delete officialData.address1.latitude;
      delete officialData.address1.longitude;
      delete officialData.address2.latitude;
      delete officialData.address2.longitude;
      if (officialData.address2?.AddressDetails) {
        if (officialData.address2?.StateId) {
          if (officialData.address2?.DistrictId) {
            if (officialData.address2?.CityId) {
              await handleeditofficial();
            } else {
              validationpopupvisible();
              setMessage("Select Secondary City ");
            }
          } else {
            validationpopupvisible();
            setMessage("Select Secondary District ");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Secondary State ");
        }
      } else {
        delete officialData.address2;
        await handleeditofficial();
      }
    } else {
      if (officialData.address2?.AddressDetails) {
        if (officialData.address2?.StateId) {
          if (officialData.address2?.DistrictId) {
            if (officialData.address2?.CityId) {
              await handleaddofficial();
            } else {
              validationpopupvisible();
              setMessage("Select Secondary City ");
            }
          } else {
            validationpopupvisible();
            setMessage("Select Secondary District ");
          }
        } else {
          validationpopupvisible();
          setMessage("Select Secondary State ");
        }
      } else {
        delete officialData.address2;
        await handleaddofficial();
      }
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      window.location.reload();
    } else {
      setRespSucces(!respsucces);
    }
  };

  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const validationpopup = (popup) => {
    console.log("open");
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };

  const changeHandler = (event) => {
    const filteredData = officials
      .filter((item) =>
        item.User.FirstName.toLowerCase().includes(
          event.target.value.toLowerCase()
        )
      )
      .sort((a, b) => a.User.FirstName.localeCompare(b.User.FirstName));
    setOfficials1(filteredData);
  };

  const handleAddress1 = async (e) => {
    addressdetails = e.target.value;
    setAddressDetails(addressdetails);
    officialData.address1.AddressDetails = e.target.value;
    setOfficialData(officialData);
    console.log("address1", officialData);
  };

  const handleStateChange1 = async (e) => {
    selectedstate = e.target.value;
    setSelectedState(selectedstate);
    let dist = await get_state_districts(selectedstate);
    statedistricts = dist.data;
    setStatedistricts(statedistricts);
    officialData.address1.StateId = selectedstate;
    setOfficialData(officialData);
  };

  const handlestateDistrictChange1 = async (e) => {
    selecteddist = e.target.value;
    setSelectedDistrict(selecteddist);
    let city = await get_district_cities(selectedstate, selecteddist);
    districtcities = city.data;
    setDistrictCities(districtcities);
    officialData.address1.DistrictId = selecteddist;
    setOfficialData(officialData);
    console.log(districtcities);
  };

  const handleCityChange1 = async (e) => {
    selectedcity = e.target.value;
    setSelectedCity(selectedcity);
    officialData.address1.CityId = selectedcity;
    setOfficialData(officialData);
    // console.log("selectedcity", selectedcity);
    const selectedCityData = districtcities.find(
      (city) => String(city.id) === selectedcity
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      zipCode = selectedCityData.Pin;
      setZipCode(zipCode); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode = "";
      setZipCode(zipCode);
    }
  };

  const handleAddress2 = async (e) => {
    console.log("officialData", officialData);

    addressdetails2 = e.target.value;
    setAddressDetails2(addressdetails2);
    console.log("addressdetails2", addressdetails2);
    officialData.address2.AddressDetails = e.target.value;
    setOfficialData(officialData);
    console.log("officialData", officialData);
  };
  const handleStateChange2 = async (e) => {
    selectedstate2 = e.target.value;
    setSelectedState2(selectedstate2);
    let dist = await get_state_districts(selectedstate2);
    statedistricts2 = dist.data;
    setStatedistricts2(statedistricts2);
    officialData.address2.StateId = e.target.value;
    setOfficialData(officialData);
  };

  const handlestateDistrictChange2 = async (e) => {
    selecteddist2 = e.target.value;
    setSelectedDistrict2(selecteddist2);
    let city = await get_district_cities(selectedstate2, selecteddist2);
    districtcities2 = city.data;
    setDistrictCities2(districtcities2);
    officialData.address2.DistrictId = selecteddist2;
    setOfficialData(officialData);
  };

  const handleCityChange2 = async (e) => {
    selectedcity2 = e.target.value;
    setSelectedCity2(selectedcity2);
    officialData.address2.CityId = selectedcity2;
    setOfficialData(officialData);
    // console.log(id, name);
    const selectedCityData = districtcities2.find(
      (city) => String(city.id) === selectedcity2
    );

    // console.log("selectedCityData", selectedCityData);
    if (selectedCityData && selectedCityData?.Pin !== null) {
      setZipCode2(selectedCityData.Pin); // Assuming 'pin' is the property name for the pin code
    } else {
      zipCode2 = "";
      setZipCode2(zipCode2);
    }
  };

  const addOreditumpirepopup = () => {
    return (
      <div className="overlay">
        <div className="officials_popup">
          <div className="pop_up_add_text_container_official me-5">
            <span className="pop_up_add_text">
              {isEdit ? "Edit Official" : "Add Official"}
            </span>
            <input
              type="file"
              id="officialimgUrl"
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
            <label
              htmlFor="officialimgUrl"
              className="d-flex ms-2 mt-2 align-items-center gap-2 select-doc"
            >
              {/* <div className="title">Upload Team Logo</div> */}
              {selectedofficialimg ? (
                <div className="d-flex gap-2 align-items-center close-doc">
                  <img
                    height={44}
                    width={44}
                    src={selectedofficialimg}
                    alt="Selected Image"
                    className="profile-img"
                    style={{ borderRadius: 18 }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <img
                    src={require("../assets/images/profile-icon.png")}
                    alt=""
                    height={44}
                    width={44}
                    className="profile-img"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="pop_up_inputs">
            <select
              className="umpire_details"
              name="DistrictId"
              value={districtid}
              onChange={handleDistrictId}
            >
              <option>Choose District</option>
              {Array.isArray(districts) &&
                districts.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <input
              className="umpire_details"
              type="text"
              name="FirstName"
              placeholder="First Name"
              value={firstname}
              onChange={handlefirstname}
            />
            <input
              className="umpire_details"
              type="text"
              name="LastName"
              placeholder="Last Name"
              value={latsname}
              onChange={handlelastname}
            />
            <input
              className="umpire_details"
              type="text"
              placeholder="Country"
              value={country}
              onChange={handlecountry}
            />
          </div>
          <span className="ms-2 pop_up_add_text">Primary Address</span>
          <div className="pop_up_inputs">
            <input
              className="umpire_details"
              type="text"
              name="AddressDetails"
              placeholder="Address 1"
              value={addressdetails}
              onChange={handleAddress1}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="umpire_details"
              value={selectedstate}
              onChange={handleStateChange1}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="umpire_details"
              value={selecteddist}
              onChange={handlestateDistrictChange1}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts) &&
                statedistricts?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="umpire_details"
              value={selectedcity}
              onChange={handleCityChange1}
            >
              <option>Select City</option>
              {Array.isArray(districtcities) &&
                districtcities?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="umpire_details"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Zipcode"
              value={zipCode}
            />
          </div>
          <span className="ms-2 pop_up_add_text">Secondary Address</span>
          <div className="pop_up_inputs">
            <input
              className="umpire_details"
              type="text"
              name="AddressDetails"
              placeholder="Address 2"
              value={addressdetails2}
              onChange={handleAddress2}
            />
          </div>
          <div className="pop_up_inputs">
            <select
              className="umpire_details"
              name="TeamId"
              value={selectedstate2}
              onChange={handleStateChange2}
            >
              <option>Select State</option>
              {Array.isArray(states) &&
                states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.Name}
                  </option>
                ))}
            </select>
            <select
              className="umpire_details"
              name="TeamId"
              value={selecteddist2}
              onChange={handlestateDistrictChange2}
            >
              <option>Select District</option>
              {Array.isArray(statedistricts2) &&
                statedistricts2?.map((statedistrict) => (
                  <option key={statedistrict.id} value={statedistrict.id}>
                    {statedistrict.Name}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <select
              className="umpire_details"
              name="TeamId"
              value={selectedcity2}
              onChange={handleCityChange2}
            >
              <option>Select City</option>
              {Array.isArray(districtcities2) &&
                districtcities2?.map((districtcitie) => (
                  <option key={districtcitie.id} value={districtcitie.id}>
                    {districtcitie.Name}
                  </option>
                ))}
            </select>
            <input
              className="umpire_details"
              type="text"
              name="PhoneNumber"
              inputMode="text"
              placeholder="Zipcode"
              value={zipCode2}
            />
          </div>
          <div className="pop_up_inputs">
            <input
              className="umpire_details"
              type="text"
              name="PhoneNumber"
              inputMode="email"
              placeholder="Email"
              // value={phonenumber}
              // onChange={handlephonenumber}
            />
          </div>
          <div className="pop_up_inputs">
            {/* <input
              className="umpire_details"
              type="date"
              name="DOB"
              placeholder="select date of birth"
              value={officialData.User.DOB}
              onChange={handledob}
            /> */}

            <input
              className="umpire_details"
              type="text"
              name="PhoneNumber"
              inputMode="number"
              placeholder="Phone number"
              value={phonenumber}
              onChange={handlephonenumber}
            />

            <select
              className="umpire_details"
              value={gender}
              onChange={handlegender}
            >
              <option>Gender</option>
              {Array.isArray(genderLsit) &&
                genderLsit.map((Gender) => (
                  <option key={Gender.id} value={Gender.id}>
                    {Gender.Gender}
                  </option>
                ))}
            </select>
          </div>
          <div className="pop_up_inputs">
            <input
              className="umpire_details"
              type="text"
              name="RegistrationId"
              inputMode="text"
              placeholder="Registration ID"
              value={registrationid}
              onChange={handleregisterationid}
            />

            <select
              className="umpire_details"
              value={designation}
              onChange={handlOfficialDesignation}
            >
              <option>Official Designation</option>
              {Array.isArray(officialDesignations) &&
                officialDesignations.map((designations) => (
                  <option key={designations.id} value={designations.id}>
                    {designations.Title}
                  </option>
                ))}
            </select>

            <select
              className="umpire_details"
              value={category}
              onChange={handlOfficialCategory}
            >
              <option>Select Category</option>
              {Array.isArray(officialCategories) &&
                officialCategories.map((UmpireTypes) => (
                  <option key={UmpireTypes.id} value={UmpireTypes.id}>
                    {UmpireTypes.type}
                  </option>
                ))}
            </select>
          </div>
          <div className="popup_buttons">
            <button
              className="popup_button_cancel"
              onClick={toggleAddOrEditPopup}
            >
              cancel
            </button>

            <button
              className="popup_button_save"
              onClick={handleSaveButtonClick}
            >
              {isEdit ? "Save" : "Create"}
            </button>
          </div>
          <button className="close-button" onClick={toggleAddOrEditPopup}>
            X
          </button>
          {isValid && validationpopup(validationpopupvisible)}
        </div>
      </div>
    );
  };
  const [matchName, setMatchName] = useState([]);
  const [officialSummery, setOfficialsSummery] = useState({});
  const [officialsID, setOfficialsID] = useState(null);
  const [matchsUmpried, setMatchesUmpired] = useState(null);
  const handleOfficialInfo = async (row) => {
    console.log(row);

    if (row.DesignationInfo.id !== 2) {
      setRespSucces(true);
      setMessage(" Please Select Only Umpires  ");
      return;
    }
    setOfficialsID(row.id);

    setIsOfficialInfo(row);

    let resp = await get_umpire_stats(row.id);
    console.log(resp.data.matchStats);
    console.log(resp.data.matchStats.length);
    setMatchesUmpired(resp.data.matchStats.length);
    setOfficialsSummery(resp.data.summary);
    console.log(resp.data.summary);

    let allApealData = [];

    for (let i = 0; i < resp.data.matchStats.length; i++) {
      console.log(i);
      allApealData = allApealData.concat(resp.data.matchStats[i].apealData);
    }

    console.log(allApealData);

    matchStats = allApealData;
    setMatchStats(matchStats);

    console.log(matchStats);
    console.log(resp.data.matchStats.length);

    const matchNameList = resp.data.matchStats.map(
      (stat) =>
        matchList.find((m) => m.id === stat.matchId)?.MatchNameOrTitle || ""
    );

    console.log(matchNameList);

    setMatchName(matchNameList);
  };

  let [matchStats, setMatchStats] = useState([]);

  const [isOfficialInfo, setIsOfficialInfo] = useState(null);

  const closeOfficialInfo = () => {
    setIsOfficialInfo(null);
    setMatchStats([]);
    matchStats = [];
    setMatchName(matchStats);
    setOfficialsSummery({});
  };

  const [tournamentType, settournamentType] = useState([]);
  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };
  const [loading, setLoading] = useState(false);
  const handleTournament = async (event) => {
    console.log(event.target.value);

    setLoading(true);

    if (event.target.value !== "all") {
      try {
        let resp = await get_tornament_umpire_stats(
          event.target.value,
          officialsID
        );
        console.log(resp.data.matchStats);
        console.log(resp.data.matchStats.length);
        setMatchesUmpired(resp.data.matchStats.length);
        setOfficialsSummery(resp.data.summary);
        console.log(resp.data.summary);

        let allApealData = [];

        for (let i = 0; i < resp.data.matchStats.length; i++) {
          console.log(i);
          allApealData = allApealData.concat(resp.data.matchStats[i].apealData);
        }

        console.log(allApealData);

        matchStats = allApealData;
        setMatchStats(matchStats);

        console.log(matchStats);
        console.log(resp.data.matchStats.length);
        const matchNameList = resp.data.matchStats.map(
          (stat) =>
            matchList.find((m) => m.id === stat.matchId)?.MatchNameOrTitle || ""
        );

        console.log(matchNameList);
        setMatchName(matchNameList);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    } else {
      try {
        let resp = await get_umpire_stats(officialsID);
        console.log(resp.data.matchStats);
        console.log(resp.data.matchStats.length);
        setMatchesUmpired(resp.data.matchStats.length);
        setOfficialsSummery(resp.data.summary);
        console.log(resp.data.summary);

        let allApealData = [];

        for (let i = 0; i < resp.data.matchStats.length; i++) {
          console.log(i);
          allApealData = allApealData.concat(resp.data.matchStats[i].apealData);
        }

        console.log(allApealData);
        matchStats = allApealData;
        setMatchStats(matchStats);

        console.log(matchStats);
        console.log(resp.data.matchStats.length);
        const matchNameList = resp.data.matchStats.map(
          (stat) =>
            matchList.find((m) => m.id === stat.matchId)?.MatchNameOrTitle || ""
        );
        console.log(matchNameList);
        setMatchName(matchNameList);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      }
    }
    setLoading(false);
  };
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 10;
    doc.setTextColor("#ffffff");
    doc.setFillColor("#000000");
    doc.rect(0, 0, pageWidth, pageHeight, "F");
    doc.setFontSize(9);
    const imgData = isOfficialInfo.User.ProfilePhotoUrl;
    const imgWidth = 50;
    const imgHeight = 50;
    const imgX = pageWidth - imgWidth - margin;
    const imgY = margin;
    doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Name : " +
        isOfficialInfo.User.FirstName +
        " " +
        isOfficialInfo.User.LastName,
      margin,
      margin + 15
    );
    doc.text(
      "Umpire Type  : " + isOfficialInfo.CategoryInfo.type,
      margin,
      margin + 21
    );
    const middle = pageWidth / 2;
    let startXLeft = margin;
    let startXRight = middle + margin;
    doc.setTextColor("#ffc52f");
    doc.text("Umpire Summary", startXLeft, margin + 30);
    doc.setTextColor("#ffffff");
    doc.setFont("helvetica", "normal");
    const textContent = [
      {
        text: `Matchs Umpired : ${matchsUmpried}`,
        x: startXLeft,
        y: margin + 40,
      },
      {
        text: `Stumped: ${officialSummery.apealData[1].Given} / ${officialSummery.apealData[1].NotGiven}`,
        x: startXLeft,
        y: margin + 50,
      },
      {
        text: `Caught: ${officialSummery.apealData[2].Given} / ${officialSummery.apealData[2].NotGiven}`,
        x: startXLeft,
        y: margin + 60,
      },
      {
        text: `LBW: ${officialSummery.apealData[3].Given} / ${officialSummery.apealData[3].NotGiven}`,
        x: startXLeft,
        y: margin + 70,
      },
      {
        text: `Time Out: ${officialSummery.apealData[9].Given} / ${officialSummery.apealData[9].NotGiven}`,
        x: startXLeft,
        y: margin + 80,
      },
      {
        text: `Obstructing Field Out: ${officialSummery.apealData[10].Given} / ${officialSummery.apealData[10].NotGiven}`,
        x: startXLeft,
        y: margin + 90,
      },
      {
        text: `Run Out: ${officialSummery.apealData[4].Given} / ${officialSummery.apealData[4].NotGiven}`,
        x: startXRight,
        y: margin + 40,
      },
      {
        text: `Wide Ball: ${officialSummery.apealData[5].Given} / ${officialSummery.apealData[5].NotGiven}`,
        x: startXRight,
        y: margin + 50,
      },
      {
        text: `Hit Wicket: ${officialSummery.apealData[6].Given} / ${officialSummery.apealData[6].NotGiven}`,
        x: startXRight,
        y: margin + 60,
      },
      {
        text: `No Ball: ${officialSummery.apealData[7].Given} / ${officialSummery.apealData[7].NotGiven}`,
        x: startXRight,
        y: margin + 70,
      },
      {
        text: `Hitting Twice: ${officialSummery.apealData[8].Given} / ${officialSummery.apealData[8].NotGiven}`,
        x: startXRight,
        y: margin + 80,
      },
      {
        text: `Other: ${officialSummery.apealData[11].Given} / ${officialSummery.apealData[11].NotGiven}`,
        x: startXRight,
        y: margin + 90,
      },
    ];
    textContent.forEach(({ text, x, y }) => {
      doc.text(text, x, y);
    });
    const tableElement = document.querySelector(".table-responsive table");
    html2canvas(tableElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = margin;
      const imgY = margin + 100;
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save(`upmire_stats_${isOfficialInfo.User.FirstName}.pdf`);
    });
  };

  const officialInfo = () => {
    return (
      <div className="team-popup">
        <div className="offical-info">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => closeOfficialInfo()}
            >
              <div>
                {" "}
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <path
                    fill="#2a8853"
                    d="M14.78 18.362c-.469.469-1.07.703-1.768.703-.698 0-1.299-.234-1.768-.703l-6.364-6.364c-.977-.977-.977-2.561 0-3.535l6.364-6.364c.977-.977 2.561-.977 3.535 0s.977 2.558 0 3.535L9.707 12l5.062 5.062c.977.977.977 2.561 0 3.535z"
                  />
                </svg>{" "}
              </div>
              <div className="title1">Umpire Info</div>
            </div>
            <div className="close d-flex " onClick={() => closeOfficialInfo()}>
              X
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-end me-4">
            <select
              className="combobox_board  w-25 me-4"
              name=""
              id=""
              onChange={handleTournament}
              defaultValue="all"
            >
              <option value="all">All Tounamnet</option>
              {renderTournamentTypeOptions()}
            </select>
          </div>

          <div className="d-flex ">
            <div className="d-flex align-item-center w-75 ">
              <div>
                {isOfficialInfo.User.ProfilePhotoUrl ? (
                  <img
                    src={isOfficialInfo.User.ProfilePhotoUrl}
                    alt=""
                    width={200}
                    height={200}
                    style={{ borderRadius: 15 }}
                  />
                ) : (
                  <img
                    src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                    alt=""
                    width={200}
                    height={200}
                    style={{ borderRadius: 15 }}
                  />
                )}
                <div className="update-btn mt-4" onClick={generatePDF}>
                  {" "}
                  Download Umpires Details{" "}
                </div>
              </div>

              <div className="">
                <h2 className="title_name_player ">
                  {isOfficialInfo.User.FirstName} {isOfficialInfo.User.LastName}{" "}
                </h2>

                <p className="title_name_player_role">
                  {" "}
                  Umpire Type : {isOfficialInfo.CategoryInfo.type}
                </p>
              </div>
            </div>

            <div id="ui-2" className="offical-summmery-container ">
              <div>
                <h1 className="team-summery">Umpire Summary </h1>
              </div>

              {officialSummery.apealData &&
                officialSummery.apealData.length > 0 && (
                  <div className="d-flex gap-3">
                    <div>
                      <div className="d-flex align-items-center">
                        <h6> Matchs Umpired</h6>
                        <h5>:</h5>
                        <h4> {matchsUmpried}</h4>
                      </div>
                      <div className="d-flex  align-items-center">
                        <h6> Stumped </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[1].Given} /{" "}
                          {officialSummery.apealData[1].NotGiven}{" "}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6> Caught </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[2].Given} /{" "}
                          {officialSummery.apealData[2].NotGiven}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>LBW </h6>
                        <h5>:</h5>
                        <h4>
                          {officialSummery.apealData[3].Given} /{" "}
                          {officialSummery.apealData[3].NotGiven}{" "}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Time Out </h6>
                        <h5>:</h5>
                        <h4>
                          {officialSummery.apealData[9].Given} /{" "}
                          {officialSummery.apealData[9].NotGiven}{" "}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Obstructing Field Out </h6>
                        <h5>:</h5>
                        <h4>
                          {officialSummery.apealData[10].Given} /{" "}
                          {officialSummery.apealData[10].NotGiven}{" "}
                        </h4>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex align-items-center">
                        <h6>Run Out </h6>
                        <h5>:</h5>
                        <h4>
                          {officialSummery.apealData[4].Given} /{" "}
                          {officialSummery.apealData[4].NotGiven}{" "}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Wide Ball </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[5].Given} /{" "}
                          {officialSummery.apealData[5].NotGiven}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Hit Wicket </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[6].Given} /{" "}
                          {officialSummery.apealData[6].NotGiven}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>No Ball </h6>
                        <h5>:</h5>
                        <h4>
                          {officialSummery.apealData[7].Given} /{" "}
                          {officialSummery.apealData[7].NotGiven}{" "}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Hitting Twice </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[8].Given} /{" "}
                          {officialSummery.apealData[8].NotGiven}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6>Other </h6>
                        <h5>:</h5>
                        <h4>
                          {" "}
                          {officialSummery.apealData[11].Given} /{" "}
                          {officialSummery.apealData[11].NotGiven}
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="title1 d-flex justify-content-end mt-3">
            {" "}
            (Given/Not Given){" "}
          </div>

          {loading && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                color: "#fff",
                marginTop: 100,
              }}
            >
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div
            className="my-4"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <div className="">
              <div style={{ borderRadius: 10 }} className="table-responsive ">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "var(--card-header)",
                          color: "#fff",
                          fontSize: 13,
                          width: "200px",
                        }}
                      >
                        <div className="m-name">Match Name</div>
                      </th>
                      {appealtTypes.map((item, index) => (
                        <th
                          style={{
                            backgroundColor: "var(--card-header)",
                            color: "#fff",
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                          key={index}
                          className="text-center"
                        >
                          {item.AppealType}
                          <br />
                          {/* <div
                          style={{
                            backgroundColor: "#101713",
                            color: "#fff",
                            fontSize: 10,
                            fontWeight:'500'
                          }}
                          className="mt-1"
                        >
                          G/NG
                        </div> */}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {Array(matchName.length)
                      .fill()
                      .map((_, rowIndex) => (
                        <tr key={rowIndex}>
                          <td
                            style={{
                              color: "var(--text-color)",
                              backgroundColor: "var(--main-background-color)",
                            }}
                          >
                            {matchName[rowIndex] || ""}
                          </td>

                          {matchStats.length > 12 &&
                            matchStats
                              .slice(rowIndex * 12 + 1, (rowIndex + 1) * 12) // Start from index 1
                              .map((item, index) => (
                                <td
                                  key={index}
                                  style={{
                                    color: "var(--text-color)",
                                    backgroundColor:
                                      "var(--main-background-color)",
                                  }}
                                  className="text-center"
                                >
                                  {item.Given} / {item.NotGiven}
                                </td>
                              ))}
                          {Array.from({
                            length:
                              12 -
                              Math.min(12, matchStats.length - rowIndex * 12),
                          }).map((_, index) => (
                            <td key={index}></td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const handleTournament1 = async (event) => {
    setSelectedTournament(event.target.value);
  };

  return (
    <div className="container_official">
      <div className="layout_conatiner">
        <div>
          <div className="btn_layout">
            <div className="search_content">
              <div>
                <span className="search_text">Match Officials</span>
              </div>
              {/* <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Search"
                  onChange={changeHandler}
                />
              </div> */}
            </div>

            <div className="d-flex">
              <div>
                {/* <select
                  value={filterdistrictid}
                  onChange={handleDistrictChange}
                  className="search_input"
                >
                  <option value="">Filter by District </option>
                  {Array.isArray(districts) &&
                    districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.Name}
                      </option>
                    ))}
                </select> */}
                 <select
                  onChange={handleTournament1}
                  className="search_input"
                >
                  <option value=""> Select Tournament </option>
                  {Array.isArray(tournaments) &&
                    tournaments.map((Tournamnet) => (
                      <option key={Tournamnet.id} value={Tournamnet.id}>
                        {Tournamnet.TournamentName}
                      </option>
                    ))}
                </select> 
              </div>
              <button className="btn1" onClick={handleAddOfficial}>
                Create
              </button>
            </div>
          </div>
          {isLoading === false ? (
            <div className="d-flex">
              {superadmin && (
                <div className="district_table_container">
                  <DataTable
                    columns={columnsdesignation}
                    data={officialDesignations}
                    customStyles={customtableStyles}
                    conditionalRowStyles={conditionalRowStylesdis}
                    fixedHeader
                    theme="solarized"
                    onRowClicked={(row) => handledesignationChange(row)}
                    fixedHeaderScrollHeight="600px"
                  ></DataTable>
                </div>
              )}
              <div className={superadmin ? "umpire_table" : "umpire_table2"}>
                {/* {superadmin && (
                  <div className="player_tabs">
                    <button
                      className={
                        activeTab === "approved" ? "active-front" : "front-btn"
                      }
                      onClick={() => hanbleTabClick("approved", true)}
                    >
                      {" "}
                      Approved
                    </button>
                    <button
                  className={
                    activeTab === "nonapproved"
                      ? "active-back-stats"
                      : "back-btn-stats"
                  }
                  onClick={() => hanbleTabClick("nonapproved", false)}
                >
                  {" "}
                  Non-Approved
                </button>

                    <button
                      className={
                        activeTab === "nonapproved" ? "active-back" : "back-btn"
                      }
                      onClick={() => hanbleTabClick("nonapproved", false)}
                    >
                      {" "}
                      Non-Approved
                    </button>
                  </div>
                )} */}
                <DataTable
                  columns={columns}
                  data={officials1}
                  customStyles={customtableStyles}
                  conditionalRowStyles={conditionalRowStyles}
                  fixedHeader
                  theme="solarized"
                  fixedHeaderScrollHeight="600px"
                  onRowClicked={(row) => handleOfficialInfo(row)}
                ></DataTable>
              </div>
            </div>
          ) : (
            <div className="loading_style ">
              <div class="spinner-border wating-cursor" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        {isPopupOpen && addOreditumpirepopup()}
        {respsucces && validationpopup(succespopup)}
        {isOfficialInfo && officialInfo()}
        {isapprove && handleApproveOfficial()}
      </div>
    </div>
  );
}

export default Umpire;

import React, { useState, useEffect } from "react";
import "../styles/login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { forgot_password, login_user } from "../api/serverWrapper";
import UserInfo from "../componants/UserInfo";
import { saveData } from "../utils/localstorage_constants";
import {
  IS_LOGGED,
  USER_DATA,
  USER_ID,
  USER_SYSTEM_ROLE,
  LOGIN_USER_DISTRICT_ID,
} from "../utils/localstorage_variables";
import ValidationModal from "./ValidationModal";
import UserSystemRole from "../componants/UserSystemRole";
import ResetPassword from "../componants/Resetpassword";

function Login() {
  const navigate = useNavigate();
  let [activeTab, setActiveTab] = useState("hpcamember");
  const { state } = useLocation();
  let [isUser, setIsUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState();
  const [isforgetpassword, setIsForgetPassword] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [respsucces, setRespSucces] = useState(false);
  let [user, setUser] = useState(new UserInfo());
  let [userphonenumber, setUserPhoneNumber] = useState(new ResetPassword());
  const [otp, setOtp] = useState();
  const [isValid, setIsValid] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    isLoading = true;
    setIsLoading(isLoading);
    if (state?.data) {
      setIsUser(state?.data);
    }
    isLoading = false;
    setIsLoading(isLoading);
  }, []);
  const handlemobilenumberChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) || value === "") {
      setUser({ ...user, [name]: value });
      setMobileNumber(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Mobile number should contain only numbers.");
    }
  };

  const handleResetPasswordMobileNumber = (e) => {
    const phnumber = e.target.value;
    if (!isNaN(phnumber) || phnumber === "") {
      userphonenumber.PhoneNumber = phnumber;
      setUserPhoneNumber(userphonenumber);
      setErrorMessage("");
    } else {
      setErrorMessage("Mobile number should contain only numbers.");
    }
  };

  const succespopup = () => {
    if (respsucces) {
      setRespSucces(!respsucces);
      setIsForgetPassword(!isforgetpassword);
    } else {
      setRespSucces(!respsucces);
    }
  };

  const handlePassword = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleOtp = (e) => {
    const value = e.target.value;
    setOtp(value);
  };

  const handleNavigationtoregister = () => {
    navigate("/register");
  };

  const validationpopup = (popup) => {
    return (
      <div>
        <ValidationModal data={message} onClose={popup} />
      </div>
    );
  };
  const validationpopupvisible = () => {
    if (isValid) {
      setIsValid(!isValid);
    } else {
      setIsValid(!isValid);
    }
  };

  const handleTabClick = (active) => {
    isLoading = true;
    setIsLoading(isLoading);
    user = new UserInfo();
    setUser(user);
    activeTab = active;
    setActiveTab(activeTab);
    if (active === "hpcamember") {
      isUser = false;
      setIsUser(isUser);
    }
    if (active === "fans") {
      isUser = true;
      setIsUser(isUser);
    }
    isLoading = false;
    setIsLoading(isLoading);
  };
  const handleForgetPassword = () => {
    setIsForgetPassword(!isforgetpassword);
  };
  const handleResetPassword = async () => {
    console.log("clicked");
    delete userphonenumber.OldPassword;
    delete userphonenumber.UserId;
    delete userphonenumber.newPassword;
    if (userphonenumber.PhoneNumber) {
      let resp = await forgot_password(userphonenumber);
      console.log(resp);
      if (resp.success) {
        succespopup();
        setMessage("Reset password Email sent to your mail account");
        userphonenumber = new ResetPassword();
        setUserPhoneNumber(userphonenumber);
        // Alert.alert("Info", "Reset password Email sent to your mail account", [
        //   {
        //     name: "Ok",
        //     onPress: () => handleNavigateProfile(),
        //   },
        // ]);
      } else {
        succespopup();
        setMessage(
          "Email is not configured,please contact admin to reset password"
        );
        userphonenumber = new ResetPassword();
        setUserPhoneNumber(userphonenumber);
        // Alert.alert("Email is not configured", "", [
        //   {
        //     name: "Ok",
        //     onPress: () => handleNavigateProfile(),
        //   },
        // ]);
      }
    } else {
      validationpopupvisible();
      setMessage("Enter phone number ");
    }
  };
  const handleNavigationtoScorecard = async () => {
    delete user.DOB;
    delete user.EMail;
    delete user.FatherName;
    delete user.FirstName;
    delete user.Gender;
    delete user.LastName;
    delete user.UserId;
    delete user.DistrictId;
    delete user.KycDataId;
    delete user.DesignationId;
    delete user.SystemRole;
    delete user.IsApproved;
    console.log(user.PhoneNumber, user.Password);
    if (user.PhoneNumber && user.Password) {
      let resp = await login_user(user);
      if (resp.data?.isLoginPass) {
        console.log(resp);

        if (isUser) {
          if (resp.data.user.SystemRole === UserSystemRole.FAN.id) {
            saveData(IS_LOGGED, true);
            saveData(USER_ID, resp.data.user.UserId);
            saveData(USER_SYSTEM_ROLE, resp.data.user.SystemRole);

            navigate("/", { replace: true });
          } else {
            validationpopupvisible();
            setMessage("Only Fans are allowed to login...!");
          }
        } else {
          if (resp.data.user.SystemRole !== UserSystemRole.FAN.id) {
            if (
              resp.data.user.SystemRole === UserSystemRole.MEMBER.id ||
              resp.data.user.SystemRole === UserSystemRole.ADMIN.id ||
              resp.data.user.SystemRole === UserSystemRole.DISTRICT_ADMIN.id
            ) {
              saveData(IS_LOGGED, true);
              saveData(USER_ID, resp.data.user.UserId);
              saveData(USER_SYSTEM_ROLE, resp.data.user.SystemRole);
              saveData(LOGIN_USER_DISTRICT_ID, resp.data.user.DistrictId);
              navigate("/", { replace: true });
            } else {
              validationpopupvisible();
              setMessage(
                "Your Registeration approval is pending after approval You can proced to login...!"
              );
            }
          } else {
            validationpopupvisible();
            setMessage("Only HPCA members are allowed to login...!");
          }
        }
      } else {
        // alert("Incorect mobile number or password");
        // console.log(resp);
        // navigate("/login_register");
        validationpopupvisible();
        setMessage("User not registered Please register...!");
      }
    } else {
      validationpopupvisible();
      setMessage("enter mobile number and password");
    }
  };
  return (
    <div>
      {isLoading === false ? (
        <div>
          {isforgetpassword ? (
            <div className="login_tabs">
              <div className="login_popup">
                <div className="pop_up_text_container">
                  <span className="pop_up_text">Reset Password</span>
                </div>
                <div className="login_pop_up_inputs">
                  <input
                    className="user_details"
                    name="PhoneNumber"
                    placeholder="Phone number"
                    // value={user.PhoneNumber}
                    onChange={handleResetPasswordMobileNumber}
                  />
                </div>
                {errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
                <div className="d-flex justify-content-center">
                  <div className="button">
                    <button
                      type="submit"
                      className="loginbtn"
                      onClick={handleForgetPassword}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="button">
                    <button
                      type="submit"
                      className="loginbtn"
                      onClick={handleResetPassword}
                    >
                      Confirm & Reset
                    </button>
                  </div>
                </div>
              </div>
              {isValid && validationpopup(validationpopupvisible)}
              {respsucces && validationpopup(succespopup)}
            </div>
          ) : (
            <div className="login_tabs">
              <div className="tabs">
                <div
                  className={`tab ${
                    activeTab === "hpcamember" ? "activetab1" : "inactivetab1"
                  }`}
                  onClick={() => handleTabClick("hpcamember")}
                >
                  HPCA member
                </div>
                <div
                  className={`tab ${
                    activeTab === "fans" ? "activetab2" : "inactivetab2"
                  }`}
                  onClick={() => handleTabClick("fans")}
                >
                  Fans
                </div>
              </div>{" "}
              <div className="login_popup">
                <div className="pop_up_text_container">
                  <span className="pop_up_text">Login</span>
                </div>
                <div className="login_pop_up_inputs">
                  <input
                    className="user_details"
                    name="PhoneNumber"
                    placeholder="Phone number"
                    value={user.PhoneNumber}
                    onChange={handlemobilenumberChange}
                  />
                  {/* {isUser && <button className="registerbtn">send otp</button>} */}
                </div>
                {errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
                <div className="login_pop_up_inputs">
                  {/* {isUser ? (
            <input className="user_details" placeholder="Enter OTP" />
          ) : ( */}
                  <input
                    className="user_details"
                    type="password"
                    name="Password"
                    placeholder="Enter password"
                    onChange={handlePassword}
                  />
                  {/* )} */}
                </div>
                <div className="button">
                  <button
                    type="submit"
                    className="loginbtn"
                    onClick={handleNavigationtoScorecard}
                  >
                    Login
                  </button>
                </div>
                <div className="button">
                  {/* <span style={{ color: "var(--text-color)" }}>
                {" "}
                Registration ?
              </span> */}
                  <button
                    className="clickhere"
                    onClick={() => handleForgetPassword()}
                  >
                    Forgotten password?
                  </button>
                </div>
                <div className="button">
                  <span style={{ color: "var(--text-color)" }}>
                    {" "}
                    Registration ?
                  </span>
                  <button
                    className="clickhere"
                    onClick={handleNavigationtoregister}
                  >
                    click here
                  </button>
                </div>
                {isValid && validationpopup(validationpopupvisible)}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;

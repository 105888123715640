import React, { useEffect, useState } from "react";
import "../styles/squad.css";
function Squad(props) {
  let {
    teamAplayers,
    teamBplayers,
    matchplayersdata,
    filters,
    validationpopup,
    isValid,
  } = props;

  let [players, setPlayers] = useState(teamAplayers);

  const [btnteamB, setBtnTeamB] = useState(false);
  const [btnteamA, setBtnTeamA] = useState(true);

  const toggleteamsquad = (team, playing11) => {
    if (team === "teamA") {
      setBtnTeamA(true);
      setBtnTeamB(false);
    }
    if (team === "teamB") {
      setBtnTeamA(false);
      setBtnTeamB(true);
    }
    players = playing11;
    setPlayers(playing11);
  };
  const getPlaying11 = () => {
    return players?.playersList.map((item) => {
      const matchPlayer = matchplayersdata?.find(
        (m) => item.PlayerId === m.PlayerId
      );
      if (matchPlayer) {
        return (
          <div className="card">
            {item.Player.User.ProfilePhotoUrl ? (
              <img
                src={item.Player.User.ProfilePhotoUrl}
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            ) : (
              <img
                src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            )}

            <div className="player-name pt-2">
              <h4>
                {item.Player.User.FirstName} {item.Player.User.LastName}
              </h4>
              <h5>{item.Player.PlayerType.PlayerType}</h5>
            </div>
          </div>
        );
      }
    });
  };

  const getRestSquad = () => {
    return players?.playersList.map((item) => {
      const matchPlayer = matchplayersdata?.find(
        (m) => item.PlayerId === m.PlayerId
      );
      if (!matchPlayer) {
        return (
          <div className="card">
            {item.Player.User.ProfilePhotoUrl ? (
              <img
                src={item.Player.User.ProfilePhotoUrl}
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            ) : (
              <img
                src="https://static.cricbuzz.com/a/img/v1/152x152/i1/c182026/abishek-porel.jpg"
                style={{ borderRadius: 20 }}
                width={"100%"}
                height={175}
                alt=""
              />
            )}

            <div className="player-name pt-2">
              <h4>
                {item.Player.User.FirstName} {item.Player.User.LastName}
              </h4>
              <h5>{item.Player.PlayerType.PlayerType}</h5>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="">
      <div className="d-flex">
        {teamAplayers && teamBplayers && matchplayersdata ? (
          <div className="squad_container_container">
            <div className="squad_container">
              <div className="togglebtn">
                <button
                  className={btnteamA ? "btnteamA" : "btnteamA1"}
                  onClick={() => toggleteamsquad("teamA", teamAplayers)}
                >
                  Team {teamAplayers?.TeamName}{" "}
                </button>
                <button
                  className={btnteamB ? "btnteamB" : "btnteamB1"}
                  onClick={() => toggleteamsquad("teamB", teamBplayers)}
                >
                  Team {teamBplayers?.TeamName}{" "}
                </button>
              </div>
            </div>

            <div>
              <h4 className="heading_color ms-3">Playing 11</h4>
              <div className="d-flex player-container">{getPlaying11()}</div>
            </div>
            <div>
              <h4 className="heading_color ms-3">Rest of Squad</h4>
              <div className="d-flex player-container">{getRestSquad()}</div>
            </div>
          </div>
        ) : (
          <div className="select_match_hint squad_container_container">
            <span>Select the Tournament and Match</span>
          </div>
        )}
        {filters}
        {isValid && validationpopup}
      </div>
    </div>
  );
}
export default Squad;

import React from "react";
import "../styles/terms.css";

function Terms() {
  return (
    <div className="container">
      <div class="terms-condition">
        <h1 class="title-terms mt-4">"Privacy Policy"</h1>
        <div class="heading mt-4">
          <p>
            At the HPMC Sales Limited ("HPMC SALES LIMITED") we are committed to
            respecting the privacy rights of all visitors/users to/of our
            website.
          </p>
          <p className="mt-2">
            Your privacy is important to us and we aim for our website to be a
            safe and enjoyable environment for our audience. We respect and
            safeguard the personally identifiable information we receive from
            you on our website and in the course of our other interactions with
            you online on our website.
          </p>
          <p className="mt-2">
            If you do not agree to this Privacy Policy, please do not access or
            use our website or any services or content provided on our website.
            Your access to or use of any part of our website and/or any services
            or content provided therein indicates your acceptance of this
            Privacy Policy and all the terms contained herein.
          </p>
          <p className="mt-2">
            This Privacy Policy forms an integral part of HPMC SALES LIMITED’s
            website Terms of Use, published and made available on our website,
            and as available at:{" "}
            <a href="https://www.3rd-eye.tv">https://www.3rd.eye.tv.</a>
          </p>
          <p className="mt-1">
            This Privacy Policy was last modified on 01st August, 2024.
          </p>
        </div>

        <h1 class="genreal mt-4">
          1. This Privacy Policy ("Privacy Policy") explains:
        </h1>
        <div className="heading" mt-2>
          <ul>
            <li>
              What information we may collect about you on/through our website;
            </li>
            <li>
              The use and role of cookies and similar technologies on our
              website;
            </li>
            <li>
              How we will protect and use information we collect about and from
              you;
            </li>
            <li>
              The circumstances in which we might disclose your details to
              anyone else;
            </li>
            <li>When we may use your details to contact you; and</li>
            <li>
              How to ensure that the information we hold about you is accurate
              and current.
            </li>
          </ul>
        </div>

        <div className="genreal mt-4">2. Information Gathered</div>
        <div className="heading mt-2">
          <p>
            In general, you can visit our website without revealing your
            identity or any Personal Information about yourself. However, you
            may not be able to access certain areas, content or services of/on
            our website that require registration or need you to reveal your
            identity and/or other information about you, that we may require
            from time to time.
          </p>

          <p mt-2>
            Information that we may collect from you will vary depending upon
            the activity you seek to engage in and may include your name, email
            address, phone number, mobile number, home address, date of birth,
            and credit card information. In addition, we may collect demographic
            information pertaining to you such as gender, information about your
            computer, hardware, software, platform, media, Internet Protocol
            (IP) address and connection, information about your online activity
            on our website such as feature usage and click paths, and other data
            that you may provide in surveys or opinion polls undertaken by or on
            behalf of us or with respect to your online profiles. We may combine
            your demographic information with Personal Information to tailor our
            offerings, services, content, website or web pages to your
            preferences or interests.
          </p>
        </div>
        <div className="genreal mt-4">3. Personal Information</div>
        <div className="heading mt-2">
          <p>
            “Personal Information” means any information that may be used to
            identify you and that is not otherwise publicly available,
            including, but not limited to, your first and last name, home or
            other physical address and email address, phone number or other
            contact information.
          </p>
        </div>
        <div className="genreal mt-4">
          4. Cookies and Other Tracking Technologies
        </div>
        <div className="heading">
          <p>
            Some of our web pages may also utilise "cookies" and other tracking
            technologies. A "cookie" is a small text file that may be used, for
            example, to collect information about your activity on our website.
            Some cookies and other technologies may serve to save and/or recall
            Personal Information previously indicated by you. Most browsers
            allow you to control cookies, including whether or not to accept
            them and how to remove them. You may set most browsers to notify you
            if you receive a cookie, or you may choose to block cookies with
            your browser, but please note that if you choose to erase or block
            your cookies, you may need to re-enter such of your information as
            may be required to gain access to certain parts of our website.
            Further, while the option of accepting cookies is up to you, certain
            features or content of/on our website may not be accessible without
            accepting cookies. If you choose to eliminate cookies, the full
            functionality of our website may be impaired for you.
          </p>
          <p className="mt-2">
            Additionally, you may encounter cookies or other tracking
            technologies on our website or any part thereof that are placed by
            third parties. We do not control the use of cookies and other
            tracking technologies by third parties and shall not be liable for
            any reason whatsoever for the third-party cookies and other tracking
            technologies placed on our website or any part thereof.
          </p>
          <p className="mt-2">
            Cookies and tracking technologies may automatically collect, record
            and store certain information such as Internet domain and host
            names; other information about your geographic location; IP
            addresses; browser software and operating system types; CPU type;
            your manner of connecting to the Internet (e.g., connection speed
            through narrowband or broadband access); click paths; and dates and
            times that our website is accessed.
          </p>
          <p className="mt-2">
            Our use of cookies and other tracking technologies allows us to
            improve our website and consequently your web experience. We may
            also analyse information that does not include Personal Information
            for trends and statistics.
          </p>
        </div>
        <div className="genreal mt-4">5. Children</div>
        <div className="heading mt-2">
          <p>
            Our content, services and our website are intended for a general
            audience. The products or services offered on/through our website
            are not intended for purchase directly by children under the age of
            18 years. If you are a child under the age of 18 and desire to use
            our website or purchase our products or use our services, please
            request your parent/guardian to register with us and to complete the
            transaction. Any use of our
          </p>
          <p className="mt-2">
            website or any of its features by a child under the age of 18 must
            be with parental supervision. We do not contact children under the
            age of 18 about special offers or for marketing purposes without a
            parent's permission. We do not ask a child under the age of 18 for
            more Personal Information, as a condition of participation, than is
            reasonably necessary to participate in a given activity on our
            website.
          </p>
        </div>
        <div className="genreal mt-4">6. Uses of Personal Information</div>
        <div className="heading mt-2">
          <p>
            We may use Personal Information and other information received and
            collected from you: (i) to provide the content or services you have
            requested, including services that display customised content; (ii)
            to contact you upon your request and when otherwise necessary; (iii)
            for auditing, research and analysis; (iv) to operate and improve our
            website, content and services; (v) to facilitate your use of our
            website; (vi) to conduct internal reviews and data analysis for our
            website, content and services; (vii) for sending you surveys and
            marketing communications; (viii) to engage in technical maintenance
            and trouble-shooting problems and errors associated with our
            website; or (ix) as otherwise set forth in this Privacy Policy or
            any terms or policies applicable to our website. In certain
            circumstances, we may share your aggregated non-personal information
            with third parties. When we use third parties to assist us in
            processing your Personal Information, we require that they comply
            with our Privacy Policy and any other appropriate confidentiality
            and security measures. We may also share such information with third
            parties in limited circumstances, including when complying with
            legal process, preventing fraud or imminent harm, and ensuring the
            security of our network and services.
          </p>
          <p className="mt-2">
            Where we propose to use your Personal Information for any other uses
            we will ensure that we notify you first. You will also be given the
            opportunity to withhold or withdraw your consent for your use of
            Personal Information other than as listed above.
          </p>
        </div>
        <div className="genreal mt-4">7. Confidentiality and Security</div>
        <div className="heading mt-2">
          <p>
            We will keep confidential and protect your Personal Information
            except where disclosure is required or permitted by law, or in
            instances where we have a good-faith belief that it is necessary to
            comply with a court order, legal obligation, legal process, request
            from government or judicial authorities, or to exercise our legal
            rights or defend against legal claims or to detect, prevent, or
            otherwise address security, fraud, imminent harm or technical
            issues, or to enforce this Privacy Policy and any other policies
            pertaining to our website, including investigation of any potential
            violation thereof, or where use and disclosure thereof has been
            permitted by you or is otherwise permitted under this Privacy
            Policy.
          </p>
          <p className="mt-2">
            We follow generally accepted industry standards to protect the
            Personal Information submitted to us, both during transmission and,
            once we receive it, for storage and disposal. When you enter
            sensitive information on our registration or order forms, we encrypt
            that information using secure socket layer technology (SSL). All
            information we gather is securely stored within databases controlled
            by us. The databases are stored on servers secured behind a
            firewall; access to the servers is password-protected and is
            strictly limited. No method of transmission over the Internet, or
            method of electronic/data storage, is 100% secure, however.
            Therefore, while we strive to use commercially acceptable means to
            protect your Personal Information, we cannot guarantee the security
            of the systems and measures we use to protect your Personal
            Information, nor can we guarantee that any information you supply or
            transmit to us will not be intercepted or its confidentiality will
            not be compromised while being transmitted to us over the internet.
          </p>
          <p className="mt-2">
            We limit access to Personal Information to our employees, agents,
            representatives, personnel, advisors and consultants whom we believe
            reasonably need access to your Personal Information to provide
            content, products or services to you or in order to do their jobs.
          </p>
          <p className="mt-2">
            Third parties provide certain services available on our website and
            on our behalf. We may provide information, including Personal
            Information, to third-party service providers to help us deliver our
            products, information, and services efficiently and effectively.
            Service providers are also an important means by which we maintain
            our website and mailing lists. We will take reasonable steps to
            ensure that these third-party service providers are obligated to
            protect Personal Information on our behalf through confidentiality
            agreements and otherwise. We do not intend to transfer Personal
            Information without your consent to third parties who are not bound
            to act on our behalf unless such transfer is legally required.
            Similarly, it is against our policy to sell Personal Information
            collected online without consent.
          </p>
          <p className="mt-2">
            If you choose to provide us with your Personal Information, we may
            transfer that Personal Information from your country or jurisdiction
            to other countries or jurisdictions around the world. Your Personal
            Information may be collected and stored on servers located in the
            territory of India and such other countries as we may disclose from
            time to time.
          </p>
        </div>
        <div className="genreal mt-4">8. Third Party Websites</div>
        <div className="heading mt-2">
          <p>
            Our website may contain links, references, and content from third
            party websites, applications or services (“External Resources”)
            outside of our control. Your visit to or interaction with any
            External Resource including any information provided by you therein,
            irrespective of whether the link to such External Resource
            originated on our website, is governed by the terms and privacy
            policies of such External Resource, and your interaction with any
            External Resource is outside the purview of our control and is at
            your own risk. Because we cannot control the activities of External
            Resources, we cannot accept responsibility for any collection, use,
            treatment, processing, maintenance, disclosure, transfer and
            management of your personal information by such External Resources,
            and we cannot guarantee that they will adhere to the same privacy
            and security practices as we do. We are not responsible for the
            functionality, security measures, privacy practices or the content
            of External Resources and this Privacy Policy shall not govern your
            access or use of any External Resource that you visit or use.
            Therefore, if you visit any External Resource, irrespective of
            whether that External Resource is linked or referenced to our
            website, you should consult that External Resource's privacy policy
            and terms before providing any Personal Information therein.
          </p>
        </div>
        <div className="genreal mt-4">9. Contacting You</div>
        <div className="heading mt-2">
          <p>
            We may contact you using the Personal Information you have given us:
          </p>
          <ul className="mt-2">
            <li>
              In relation to the functioning of any services you have signed up
              for on our website in order to ensure that we can deliver the
              services to you;
            </li>
            <li>Where you have opted to receive further correspondence;</li>
            <li>
              To invite you to participate in surveys, opinion polls, etc. about
              our website, content, services or otherwise (participation is
              always voluntary); and
            </li>
            <li>
              To provide you information about our content, products and/or
              services, including new content, products and/or services, and
              other communications, where you have specifically agreed to
              receive this.
            </li>
          </ul>
        </div>
        <div className="genreal mt-4">10. Change of Control</div>
        <div className="heading mt-2">
          <p>
            In the event that control of the HPMC SALES LIMITED was to change,
            your Personal Information may be deemed to have been transferred,
            shared, disclosed or assigned and you hereby expressly grant consent
            and permission thereto. If such a transfer, sharing, disclosure or
            assignment results in a material change in the nature of use of your
            Personal Information, the HPMC SALES LIMITED will provide you notice
            about the choices you have to decline to permit such a transfer,
            sharing, disclosure or assignment of your Personal Information.
          </p>
        </div>
        <div className="genreal mt-4">
          11. Accuracy and Updating Information
        </div>
        <div className="heading mt-2">
          <p>
            HPMC SALES LIMITED shall not be liable, for any reason whatsoever,
            for the authenticity or accuracy of any information provided by you
            on our website
          </p>
          <p className="mt-2">
            To the extent that you do provide us with Personal Information, we
            wish to maintain accurate and current Personal Information. Where we
            collect Personal Information from you on our website, should you
            need to update or correct that Personal Information, please contact
            us and we will make reasonable efforts to incorporate the changes in
            your Personal Information that we hold as soon as practicable.
          </p>
          <p className="mt-2">
            You can obtain the information we currently hold on you by emailing
            the HPMC SALES LIMITED at navneet@hpmcsales.com. We will use our
            best efforts to send you the information within 14 days of receipt
            of your email.
          </p>
        </div>
        <div className="genreal mt-4">
          12. Consent; Amendments; Condition of Use; Law and Jurisdiction
        </div>
        <div className="heading mt-2">
          <p>
            By using our website, you consent to the terms of this Privacy
            Policy and to our use and management of your Personal Information
            for the purposes and in the manner herein provided and as provided
            under applicable laws. Should this Privacy Policy change we intend
            to take every reasonable step to ensure that these changes are
            brought to your attention by posting all changes prominently on this
            page. You are advised to review this Privacy Policy periodically by
            visiting this page, as your continued access to or use of our
            website following the posting of any changes to this Privacy Policy
            on this page will constitute your binding acceptance of such changes
            and the updated Privacy Policy.
          </p>
          <p className="mt-2">
            Your visit and any dispute over privacy are subject to this Privacy
            Policy. The said Privacy Policy shall be governed by and construed
            in accordance with the laws of the Republic of India. Further, it is
            irrevocably and unconditionally agreed by you that the courts of
            Mumbai, India shall have exclusive jurisdiction to entertain any
            proceedings in relation to any disputes arising out of the same.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;

import React, { useEffect, useState } from "react";
import "../styles/scorestats.css";
import {
  get_tournaments_type,
  get_match_info,
  get_match_inn_partnership,
  get_match_data,
  get_match_inn_overs,
} from "../api/serverWrapper";

import DataTable, { createTheme } from "react-data-table-component";
import { customtableStyles } from "../assets/tablestyle";

import {
  Chart as Chartjs,
  BarElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  registerables,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { getPlayerList } from "../componants/CommonFuncations";

Chartjs.register(
  ...registerables,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Title
);

function ScoreStats({ filterPlayerData }) {
  let data = filterPlayerData;

  const [tournamentType, settournamentType] = useState([]);
  let [tournamentid, Tournamentid] = useState(data[0]?.tournamentid || null);
  let [matchid, setMatchId] = useState(data[0]?.data.matchId || null);
  const [matchList, setMatchList] = useState([]);
  let [matchList1, setMatchList1] = useState([]);
  const [matchInfo, setMatchInfo] = useState({});
  const [inningInfo, setInningInfo] = useState([]);
  const [inningInfo2, setInningInfo2] = useState([]);
  const [inningInfo3, setInningInfo3] = useState([]);
  const [inningInfo4, setInningInfo4] = useState([]);

  const [inning1Over, setInning1Over] = useState([]);
  const [inning2Over, setInning2Over] = useState([]);

  const [inning3, setInning3] = useState([]);
  const [inning4, setInning4] = useState([]);

  let [playersData, setPlayersData] = useState();

  const loadInitialData = async () => {
    let tournamentsResp = await get_tournaments_type();
    settournamentType(tournamentsResp.data.tournaments);
    let resp = await get_match_data();
    setMatchList(resp.data.matches);
    setMatchList1(resp.data.matches);

    if (data.length > 0) {
      getallData(data[0]?.data.matchId);
    }
  };

  const renderTournamentTypeOptions = () => {
    return tournamentType.map((item, index) => (
      <option key={index} value={item.id}>
        {item.TournamentName}
      </option>
    ));
  };

  const handleTournament = async (event) => {
    setIsLoading(true);

    const selectedTournamentId = event.target.value;
    let respmatches = await get_match_data(selectedTournamentId, true);
    // const filteredMatches = matchList.filter(
    //   (item) => item.TournamentOrSeriesId === parseInt(id)
    // );
    matchList1 = respmatches.data.matches;

    setMatchList1(matchList1);

    setIsLoading(false);
  };

  const renderMatchListOptions = () => {
    return matchList1.map((item, index) => (
      <option key={index} value={item.id}>
        {item.MatchNameOrTitle} ({item.id})
      </option>
    ));
  };

  const getMatchInfo = async (matchId) => {
    try {
      console.log(matchId);
      let resp = await get_match_info(matchId);
      let matchData = resp.data;
      console.log("matchdata", matchData);
      setMatchInfo(matchData);

      if (resp.data) {
        playersData = getPlayerList(resp.data);
        setPlayersData(playersData);
        console.log("playersData", playersData);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  const [matchTypeInning, setmatchTypeInning] = useState(2);
  const handleMatch = async (event) => {
    setIsLoading(true);
    let matchId = parseInt(event.target.value);
    matchid = event.target.value;
    setMatchId(matchid);
    getallData(matchId);

    setIsLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const getallData = async (matchId) => {
    getMatchInfo(matchId);
    console.log(matchId);
    let resp = await get_match_inn_partnership(matchId, 1);
    console.log("data inning 1", resp.data);
    setInningInfo(resp.data);
    let resp2 = await get_match_inn_partnership(matchId, 2);
    console.log("data inning 2", resp2.data);
    setInningInfo2(resp2.data);

    let ingOver1Resp = await get_match_inn_overs(matchId, 1);
    console.log(ingOver1Resp.data);
    setInning1Over(ingOver1Resp.data);

    let ingOver2Resp = await get_match_inn_overs(matchId, 2);
    console.log(ingOver2Resp.data);
    setInning2Over(ingOver2Resp.data);

    const selectedMatchId = parseInt(matchId);
    const selectedMatch = matchList.find(
      (match) => match.id === selectedMatchId
    );
    if (selectedMatch?.MatchType === 3) {
      setmatchTypeInning(selectedMatch.MatchType);
      let resp3 = await get_match_inn_partnership(matchId, 1);
      console.log("data inning 1", resp3.data);
      setInningInfo3(resp.data);
      let resp4 = await get_match_inn_partnership(matchId, 2);
      console.log("data inning 1", resp4.data);
      setInningInfo4(resp.data);

      let ing3Resp = await get_match_inn_overs(matchId, 3);
      console.log("inning overs inn 3 ", ing3Resp);
      setInning3(ing3Resp.data);

      let ing4Resp = await get_match_inn_overs(matchId, 4);
      console.log("inning overs inn 4 ", ing4Resp);
      setInning4(ing4Resp.data);
    }
  };

  const PlayerAName = ({ row }) => {
    let s;
    let batsmanName;
    if (row.playerAId && playersData) {
      s = playersData.get(row.playerAId);
      batsmanName = `${s?.Player.User.FirstName} ${
        s?.Player.User.LastName ? s?.Player.User.LastName : ""
      }`;
    }

    return (
      <div className="">
        <div>{batsmanName}</div>

        <div className="ms-4">
          {row.playerARuns} <span>({row.playerABallPlayed})</span>{" "}
        </div>
      </div>
    );
  };
  const PlayerBName = ({ row }) => {
    let s;
    let batsmanName;
    if (row.playerBId && playersData) {
      s = playersData.get(row.playerBId);
      batsmanName = `${s?.Player.User.FirstName} ${
        s?.Player.User.LastName ? s?.Player.User.LastName : ""
      }`;
    }

    return (
      <div className="">
        <div>{batsmanName}</div>

        <div className="ms-4">
          {row.playerBRuns} <span>({row.playerBBallPlayed})</span>{" "}
        </div>
      </div>
    );
  };
  const PlayerAName2 = ({ row }) => {
    let s;
    let batsmanName;
    if (row.playerAId && playersData) {
      s = playersData.get(row.playerBId);
      batsmanName = `${s?.Player.User.FirstName} ${
        s?.Player.User.LastName ? s?.Player.User.LastName : ""
      }`;
    }

    return (
      <div className="">
        <div>{batsmanName}</div>

        <div className="ms-4">
          {row.playerARuns} <span>({row.playerABallPlayed})</span>{" "}
        </div>
      </div>
    );
  };
  const PlayerBName2 = ({ row }) => {
    let s;
    let batsmanName;
    if (row.playerBId && playersData) {
      s = playersData.get(row.playerBId);
      batsmanName = `${s?.Player.User.FirstName} ${
        s?.Player.User.LastName ? s?.Player.User.LastName : ""
      }`;
    }
    return (
      <div className="">
        <div>{batsmanName}</div>

        <div className="ms-4">
          {row.playerBRuns} <span>({row.playerBBallPlayed})</span>{" "}
        </div>
      </div>
    );
  };

  const PartnerShip = ({ row }) => {
    const barHeight = 6;
    const totalBall = row.playerABallPlayed + row.playerBBallPlayed;

    const playerARunPercentage = (row.playerARuns / row.runs) * 100;
    const playerBRunPercentage = (row.playerBRuns / row.runs) * 100;

    const playerAStyle = {
      width: `${playerARunPercentage}%`,
      height: `${barHeight}px`,
      backgroundColor: "#85712B",
    };

    const playerBStyle = {
      width: `${playerBRunPercentage}%`,
      height: `${barHeight}px`,
      backgroundColor: "#214E35",
    };

    return (
      <div className="partnership-stats ">
        <div className="">
          <div className="partnership ">
            <div className="progress-bar1" style={playerAStyle}></div>
            <div className="progress-bar2" style={playerBStyle}></div>
          </div>
          <div className="">
            {row.runs} <span>({totalBall})</span>
          </div>
        </div>
      </div>
    );
  };

  const TeamAName = matchInfo.TeamA ? matchInfo.TeamA.TeamName : "";
  const TeamBName = matchInfo.TeamB ? matchInfo.TeamB.TeamName : "";

  const customtableStylesStats = {
    headRow: {
      style: {
        display: "none",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "white",
        margin: "0px",
      },
    },

    rows: {
      style: {
        borderRadius: "30px",
        color: "var(--text-color)",
        height: "60px",
        "&:nth-child(2n)": {
          backgroundColor: "var(--row-color)",
        },
      },
    },
  };

  const teamAColumn = [
    {
      selector: (row) => <PlayerAName row={row} />,
      width: "40%",
    },
    {
      selector: (row) => <PartnerShip row={row} />,
      width: "33%",
    },
    {
      selector: (row) => <PlayerBName row={row} />,
    },
  ];
  const teamBColumn = [
    {
      selector: (row) => <PlayerAName2 row={row} />,
      width: "40%",
    },
    {
      selector: (row) => <PartnerShip row={row} />,
      width: "33%",
    },
    {
      selector: (row) => <PlayerBName2 row={row} />,
    },
  ];

  let longOver =
    inning1Over > inning2Over
      ? inning1Over.map((data) => data.OverNum)
      : inning2Over.map((data) => data.OverNum);

  const overs = longOver;
  const TeamARun = inning1Over.map((data) => data.TotalRun);
  const TeamBRun = inning2Over.map((data) => data.TotalRun);

  const partnerShipData = {
    labels: overs,
    datasets: [
      {
        label: TeamAName,
        data: TeamARun,
        backgroundColor: "#A98D28",
        borderColor: "#A98D28",
        borderWidth: 1,
      },
      {
        label: TeamBName,
        data: TeamBRun,
        backgroundColor: "#2B8A54",
        borderColor: "#A98D28",
        borderWidth: 1,
      },
    ],
  };

  const maxOver = Math.max(inning1Over.length, inning2Over.length);
  const oversTotal = Array.from({ length: maxOver }, (_, i) => i + 1);

  const TeamARunTotal = [];
  let totalRunsTeamA = 0;
  for (let i = 0; i < inning1Over.length; i++) {
    if (i < inning1Over.length) {
      totalRunsTeamA += parseInt(inning1Over[i].TotalRun, 10);
    }
    TeamARunTotal.push(totalRunsTeamA.toString());
  }

  const TeamBRunTotal = [];
  let totalRunsTeamB = 0;
  for (let i = 0; i < inning2Over.length; i++) {
    if (i < inning2Over.length) {
      totalRunsTeamB += parseInt(inning2Over[i].TotalRun, 10);
    }
    TeamBRunTotal.push(totalRunsTeamB.toString());
  }

  const LinechartData = {
    labels: oversTotal,
    datasets: [
      {
        label: TeamAName,
        data: TeamARunTotal,
        backgroundColor: "#A98D28",
        borderColor: "#A98D28",
        borderWidth: 1,
      },
      {
        label: TeamBName,
        data: TeamBRunTotal,
        backgroundColor: "#2B8A54",
        borderColor: "#2B8A54",
        borderWidth: 1,
      },
    ],
  };

  // Test match graphs logic starts
  let longOver1 = 0;
  const lengths = [
    inning1Over.length,
    inning2Over.length,
    inning3.length,
    inning4.length,
  ];
  const maxLength = Math.max(...lengths);

  if (maxLength === inning1Over.length) {
    longOver1 = inning1Over.map((data) => data.OverNum);
  } else if (maxLength === inning2Over.length) {
    longOver1 = inning2Over.map((data) => data.OverNum);
  } else if (maxLength === inning3.length) {
    longOver1 = inning3.map((data) => data.OverNum);
  } else {
    longOver1 = inning4.map((data) => data.OverNum);
  }

  const overs1 = longOver1;
  const TeamARun1 = inning1Over.map((data) => data.TotalRun);
  const TeamBRun2 = inning2Over.map((data) => data.TotalRun);
  const TeamARun3 = inning3 ? inning3.map((data) => data.TotalRun) : null;
  const TeamBRun4 = inning4 ? inning4.map((data) => data.TotalRun) : null;

  const partnerShipData1 = {
    labels: overs1,
    datasets: [
      {
        label: TeamAName + " Inn 1",
        data: TeamARun1,
        backgroundColor: "#A98D28",
      },
      {
        label: TeamBName + " Inn 2",
        data: TeamBRun2,
        backgroundColor: "#2B8A54",
      },
      {
        label: TeamAName + " Inn 3",
        data: TeamARun3,
        backgroundColor: "#314B8E",
      },
      {
        label: TeamBName + " Inn 4",
        data: TeamBRun4,
        backgroundColor: "#83324F",
      },
    ],
  };

  const maxOver2 = Math.max(
    inning1Over.length,
    inning2Over.length,
    inning3.length,
    inning4.length
  );
  const oversTotal2 = Array.from({ length: maxOver2 }, (_, i) => i + 1);

  const TeamARunTotal1 = [];
  let totalRunsTeamA1 = 0;
  for (let i = 0; i < inning1Over.length; i++) {
    if (i < inning1Over.length) {
      totalRunsTeamA1 += parseInt(inning1Over[i].TotalRun, 10);
    }
    TeamARunTotal1.push(totalRunsTeamA1.toString());
  }

  const TeamBRunTotal2 = [];
  let totalRunsTeamB2 = 0;
  for (let i = 0; i < inning2Over.length; i++) {
    if (i < inning2Over.length) {
      totalRunsTeamB2 += parseInt(inning2Over[i].TotalRun, 10);
    }
    TeamBRunTotal2.push(totalRunsTeamB2.toString());
  }
  const TeamARunTotal3 = [];
  let totalRunsTeamA3 = 0;
  for (let i = 0; i < inning3.length; i++) {
    if (i < inning3.length) {
      totalRunsTeamA3 += parseInt(inning3[i].TotalRun, 10);
    }
    TeamARunTotal3.push(totalRunsTeamA3.toString());
  }
  const TeamBRunTotal4 = [];
  let totalRunsTeamB4 = 0;
  for (let i = 0; i < inning4.length; i++) {
    if (i < inning4.length) {
      totalRunsTeamB4 += parseInt(inning4[i].TotalRun, 10);
    }
    TeamBRunTotal4.push(totalRunsTeamB4.toString());
  }

  const LinechartDataTest = {
    labels: oversTotal2,
    datasets: [
      {
        label: TeamAName + " Inn 1",
        data: TeamARunTotal1,
        backgroundColor: "#A98D28",
        borderColor: "#A98D28",
        borderWidth: 1,
      },
      {
        label: TeamBName + " Inn 2",
        data: TeamBRunTotal2,
        backgroundColor: "#2B8A54",
        borderColor: "#2B8A54",
        borderWidth: 1,
      },
      {
        label: TeamAName + " Inn 3",
        data: TeamARunTotal3,
        backgroundColor: "#314B8E",
        borderColor: "#314B8E",
        borderWidth: 1,
      },
      {
        label: TeamAName + " Inn 4",
        data: TeamBRunTotal4,
        backgroundColor: "#83324F",
        borderColor: "#83324F",
        borderWidth: 1,
      },
    ],
  };

  const [Loading, setIsLoading] = useState(false);
  return (
    <div>
      {Loading && (
        <div className="overlay">
          <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <div class="spinner-border text-light ms-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div className="text-light my-4 load-txt"> Loading... </div>
          </div>
        </div>
      )}
      <div className="partnership-container">
        <h1 className="title my-4">PARTNERSHIP</h1>
        <div className="stats-container ">
          {matchInfo && inningInfo.length > 1 ? (
            <div className="">
              <div className="d-flex gap-3">
                <div className="">
                  <div className="custom-table2">
                    <div className="team-name-header">{TeamAName}</div>
                    <DataTable
                      columns={teamAColumn}
                      data={inningInfo}
                      customStyles={customtableStylesStats}
                      theme="solarized"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="custom-table2">
                    <div className="team-name-header">{TeamBName}</div>
                    <DataTable
                      columns={teamBColumn}
                      data={inningInfo2}
                      customStyles={customtableStylesStats}
                      theme="solarized"
                    />
                  </div>
                </div>
              </div>
              {(inningInfo3.length > 0 || inningInfo4.length > 0) && (
                <div className="d-flex gap-3">
                  <div className="">
                    <div className="custom-table2">
                      <div className="team-name-header">{TeamAName}</div>
                      <DataTable
                        columns={teamAColumn}
                        data={inningInfo3}
                        customStyles={customtableStylesStats}
                        theme="solarized"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="custom-table2">
                      <div className="team-name-header">{TeamBName}</div>
                      <DataTable
                        columns={teamBColumn}
                        data={inningInfo4}
                        customStyles={customtableStylesStats}
                        theme="solarized"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-4">
                <h1>{matchInfo ? matchInfo.MatchNameOrTitle : ""}</h1>
                {matchTypeInning === 2 && (
                  <Bar
                    data={partnerShipData}
                    options={{
                      indexAxis: "y" && "x",
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: "Overs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: "Runs per Over",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          align: "top",
                          formatter: (value, context) =>
                            context.chart.data.labels[context.dataIndex],
                          font: {
                            weight: "bold",
                            color: "var(--text-color)",
                            fontSize: 16,
                          },
                        },
                      },
                    }}
                  />
                )}
                {matchTypeInning === 3 && (
                  <Bar
                    data={partnerShipData1}
                    options={{
                      indexAxis: "y" && "x",
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: "Overs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: "Runs per Over",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          align: "top",
                          formatter: (value, context) =>
                            context.chart.data.labels[context.dataIndex],
                          font: {
                            weight: "bold",
                            color: "var(--text-color)",
                            fontSize: 16,
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>

              <div className="mt-4">
                <h1>Worm</h1>
                {matchTypeInning === 2 && (
                  <Line
                    data={LinechartData}
                    options={{
                      indexAxis: "y" && "x",
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: "Overs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: "Runs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                      },
                    }}
                  />
                )}
                {matchTypeInning === 3 && (
                  <Line
                    data={LinechartDataTest}
                    options={{
                      indexAxis: "y" && "x",
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: "Overs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: "Runs",
                            font: {
                              size: 16,
                            },
                            color: "var(--text-color)",
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                paddingTop: 30,
              }}
              className="custom-table3"
            >
              {data.length > 0 ? (
                <div className=""> Loading... </div>
              ) : (
                <div className=""> Select the Tournament and Match</div>
              )}
            </div>
          )}

          <div className="ms-4 " style={{ width: "18%" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <span className="text">Filters</span>
              <button className="btn1" style={{ marginLeft: "100px" }}>
                OK
              </button>
            </div>
            <h1> Tournament </h1>

            <select
              className="select-box "
              value={tournamentid}
              onChange={handleTournament}
            >
              <option value="">Select Tournament</option>
              {renderTournamentTypeOptions()}
            </select>

            <h1 className="mt-2">Select Match</h1>
            <select
              className="select-box mt-1"
              value={matchid}
              onChange={handleMatch}
            >
              <option value=""> Select Match</option>
              {renderMatchListOptions()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreStats;
